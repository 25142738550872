import { mapGetters, mapState } from 'vuex'
import CONSTANTS from '@/constants'

export const labCommons = {
  computed: {
    ...mapState({
      currentLab: state => state.labs.currentLab,
    }),
    ...mapGetters({
      isOnTrialSubscription: 'subscriptions/isOnTrialSubscription',
      isCourtesyTrialUser: 'subscriptions/isCourtesyTrialUser',
    }),
    isWebAccess() {
      if (
        this.content &&
        this.content.content_type === CONSTANTS.CONTENT_TYPES.LAB
      ) {
        return this.content.is_web_access
      }
      return this.currentLab?.access_type === CONSTANTS.LABS.ACCESS_TYPES.WEB
    },
    labNotAvailable() {
      return (
        this.isOnTrialSubscription &&
        this.isWebAccess &&
        !this.isCourtesyTrialUser
      )
    },
  },
}
