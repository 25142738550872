import { isPasswordValid } from './isPasswordValid'

const validatePresence = value => {
  if (!value) return false
  return value.length > 0
}

const validatePassword = value => {
  if (!value) return false
  return isPasswordValid({ value, requiredLength: 8 })
}

export const validateEmail = email => {
  if (!email) return false
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const ruleMap = {
  presence: (field, value) => {
    const transformedField = field?.charAt(0).toUpperCase() + field?.slice(1)
    return {
      isValid: validatePresence(value),
      message: `${transformedField} is required`,
    }
  },
  password: (_, value) => {
    return {
      isValid: validatePassword(value),
      message: `Password must contain at least one number, one uppercase letter, and be at least 8 characters in length`,
    }
  },
  email: (_, value) => {
    return {
      isValid: validateEmail(value),
      message: `The email is not a valid format.`,
    }
  },
}

export const clearFormErrors = () => {
  return { messages: [], fields: [] }
}

export const validateForm = fields => {
  const errors = clearFormErrors()

  // eslint-disable-next-line no-unused-vars
  Object.entries(fields).map(([field, current], _) => {
    const { rules, value } = current
    rules.map(rule => {
      const { isValid, message } = ruleMap[rule](field, value)
      if (!isValid) {
        errors.fields.push(field)
        errors.messages.push(message)
        errors[field] = message
      }
    })
  })
  return {
    isValid: errors.fields.length === 0,
    errors,
  }
}
