import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchCourseById = async id => {
  const route = `courses?ids=${id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results[0]))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchNewCourses = async () => {
  const route = `courses?ordering=-published_date&page_size=20&status=published`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAllCourses = async () => {
  const route = `courses?page_size=none&ordering=-published_date`
  return CONTENT.get(route)
    .then(response => response.data)
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchActiveCourses = async () => {
  const route = `courses/active-courses?page_size=none`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postActiveCourse = async courseId => {
  const route = `courses/${courseId}/active`
  return CONTENT.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const deleteActiveCourse = async courseId => {
  const route = `courses/${courseId}/active`
  return CONTENT.delete(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchInstructorById = async instructorId => {
  const route = `instructors/${instructorId}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postCoursesBasicInfo = async payload => {
  const route = 'courses/search'
  return CONTENT.post(route, { ids: payload.join(',') })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchNewCourses,
  fetchCourseById,
  fetchAllCourses,
  fetchActiveCourses,
  postActiveCourse,
  deleteActiveCourse,
  fetchInstructorById,
  postCoursesBasicInfo,
}
