import { GetterTree } from 'vuex'
import {
  ISonarState,
  IRootState,
  ISonarLabData,
  ISonarAssessment,
  ISonarAssessmentsGroups,
} from '@/@types'
import CONSTANTS from '@/constants'
import { isEmpty } from 'lodash'

const groupAssessments = (fetched_assessments: ISonarAssessment[]) => {
  const groupedAssessments: { [key: string]: ISonarAssessmentsGroups } = {}
  fetched_assessments.forEach((assessment: ISonarAssessment) => {
    const assessmentGroupName = assessment.learning_area_group.name || ''
    const assessmentGroupSlug = assessment.learning_area_group.slug || ''
    const group: ISonarAssessmentsGroups = {
      name: assessmentGroupName,
      slug: assessmentGroupSlug,
      assessments: groupedAssessments[assessmentGroupName]?.assessments || [],
    }
    group.assessments?.push(assessment)
    groupedAssessments[assessmentGroupName] = group
  })
  const groupedAssessmentsArray = Object.entries(groupedAssessments).map(
    ([name, assessments]) => ({
      name,
      slug: assessments.slug,
      assessments: assessments.assessments,
    })
  )
  return groupedAssessmentsArray
}

const getters: GetterTree<ISonarState, IRootState> = {
  displaySonar: sonar => {
    return sonar.userStatus?.sonar_enabled
  },
  hasUserAnyActivity: sonar => {
    return sonar.userStatus.attempts_per_learning_area.some(
      la => la.last_attempted_at !== null
    )
  },
  lastActiveLearningArea: sonar => {
    if (!getters.hasUserAnyActivity) return []
    const learningAreas = sonar.userStatus?.attempts_per_learning_area.sort(
      (a, b) =>
        new Date(b.last_attempted_at).getTime() -
        new Date(a.last_attempted_at).getTime()
    )
    return learningAreas.length ? learningAreas[0] : {}
  },
  sonarSatus: sonar => sonar.sonarStatus,
  showStartAssessmentModal: sonar => sonar.sonarStatus.showStartAssessmentModal,
  currentLearningArea: sonar => sonar.sonarStatus?.currentLearningArea,
  currentScore: sonar => {
    const la_id = sonar.sonarStatus?.currentLearningArea?.id
    return sonar.currentScores.find(score => score.id === la_id) || {}
  },
  assessments: sonar => {
    const la_id = sonar.sonarStatus?.currentLearningArea?.id
    const laAssessments = sonar.assessments?.filter(
      a => a.learning_area_id === la_id
    )
    return groupAssessments(laAssessments)
  },
  assessmentAttempt: sonar => {
    let hasLab = false
    let lastLabQuestionIndex = 0
    const questionsLabData = <ISonarLabData>{}
    sonar.assessmentAttempt?.questions?.forEach((question, index) => {
      const labModality = question?.modality.find(
        m => m.content_type === CONSTANTS.CONTENT_TYPES.LAB
      )
      question.hasLab = !!labModality
      question.labData = labModality
      if (isEmpty(labModality)) return
      if (!hasLab && labModality) hasLab = true

      const labContent = {
        ...labModality,
        position: index + 1,
      }

      if (!questionsLabData?.firstLabQuestion)
        questionsLabData.firstLabQuestion = labContent

      if (lastLabQuestionIndex < index)
        questionsLabData.lastLabQuestion = labContent
    })
    return { ...sonar.assessmentAttempt, hasLab, questionsLabData }
  },
  currentQuestion: sonar => sonar.currentQuestion,
  lastAssessment: sonar => sonar.lastAssessment,
  isLastQuestionAnswered: sonar => sonar.isLastQuestionAnswered,
  isAssessmentOutOfTime: sonar => sonar.isAssessmentOutOfTime,
  canAnswerQuestions: sonar => sonar.canAnswerQuestions,
  currentAssessmentsAttempts: sonar => sonar.assessmentAttempts,
  isSonarQuizRunning: sonar => sonar.isSonarQuizRunning,
  isAssessmentStarted: sonar =>
    sonar.assessmentAttempt.status ===
    CONSTANTS.SONAR.ASSESSMENT.STATUS.STARTED,
  isSummary: sonar =>
    sonar.assessmentAttemptStatus.current_step ===
    CONSTANTS.SONAR.ASSESSMENT.STEP.SUMMARY,
  showResults: sonar =>
    sonar.assessmentAttemptStatus.current_step ===
    CONSTANTS.SONAR.ASSESSMENT.STEP.RESULTS,
  showAssessment: sonar =>
    sonar.assessmentAttemptStatus.current_step ===
    CONSTANTS.SONAR.ASSESSMENT.STEP.ASSESSMENT,
  isAssessmentRunning: sonar =>
    sonar.assessmentAttemptStatus.isAssessmentRunning,
  isRequiredAssessment: sonar =>
    sonar.assessmentAttemptStatus.isRequiredAssessment,
  algoliaSearchIndex: sonar => sonar.algoliaSearchIndex,
  displayCorrectAnswers: sonar => sonar.displayCorrectAnswers,
}

export default getters
