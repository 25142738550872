import { ICPEProgramState } from '@/@types'
import CONSTANTS from '@/constants'
const { STEPS } = CONSTANTS.CPE

const state: ICPEProgramState = {
  cpeGlobalCredits: 0,
  cpeCreditHistory: [],
  cpeCreditHistoryExternal: [],
  cpeCreditHistoryHasNextPage: false,
  cpeRenewModalStatus: {
    step: STEPS.OPTIONS,
    prevStep: null,
    currentSelection: null,
    currentOption: null,
    isCurrentCert: false,
    renewalDate: '',
  },
}

export default state
