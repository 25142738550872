import { IContentState } from '@/@types'

const state: IContentState = {
  jwVideo: {},
  currentCertifications: [],
  currentVideos: [],
  currentQuizzes: [],
  currentExercises: [],
  currentLabs: [],
  currentIFrames: [],
  currentBookmarks: [],
  currentLinks: [],
  currentHoveredNote: '',
  playingVideoId: '',
  playingVideoTime: 0,
  isWatchingFullscreen: false,
  currentQuizId: '',
  currentExercisesId: '',
  isContentLoading: false,
  coursesForBookmarks: [],
  videosForBookmarks: [],
  recentActivity: {
    learning_path: {
      id: '',
      name: '',
      progress: 0,
    },
    course: {
      id: '',
      name: '',
      progress: 0,
    },
  },
  recentLearningPath: {},
  recentActivityNextUp: {},
  currentParent: { id: '', type: '' },
  contentIssues: null,
  currentIssue: {
    subject: '',
    comment: '',
    parent_id: null,
    parent_type: null,
    content_type: '',
    content_subtype: '',
    content_id: null,
    extra_args: {
      /*
        // Fields expected by content type
        "video_player_time": 0,
        "video_player_status": "started",
        "iframe_cdn_url": "http://iframe-cdn-url.com/123",
        "quiz_question_id": "8345fc0e-91c2-43b3-a768-c347c9caf485",
        "azure_lab_github_url": "http://azure-lab-github-url.com/123",
        "els_lab_id": "8345fc0e-91c2-43b3-a768-c347c9caf485",
        "els_lab_status": "started",
        "els_lab_session_id": "8345fc0e-91c2-43b3-a768-c347c9caf485",
        "student_code": "def hello_world()"
      */
    },
  },

  allVideosWithBookmarks: {},
  trainingHistoryItems: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
}

export default state
