import CONSTANTS from '@/constants'
export default [
  {
    path: '/lab-experience',
    name: 'lab-experience',
    component: () => import('@/pages/LabsExperience'),
  },
  {
    path: '/dive',
    name: 'skill-dive',
    component: () => import('@/pages/LabsExperience'),
  },
  {
    path: '/collection/:id',
    name: 'collection',
    component: () => import('@/pages/LabsCollection'), // Old Lab Experience Collection
  },
  {
    path: '/collections',
    name: 'collection-all',
    component: () => import('@/pages/CollectionsList'),
  },
  {
    path: '/collections/:id',
    name: 'collection-detail',
    component: () => import('@/pages/CollectionDetail'),
  },
  {
    path: '/collections/:id/labs/:labId',
    name: 'collection-detail-lab',
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COLLECTION },
    component: () => import('@/pages/LabPage'),
  },
  {
    path: '/labs/:labId',
    name: 'lab-page',
    component: () => import('@/pages/LabPage.vue'),
  },
]
