var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"navbar",attrs:{"role":"navigation"},on:{"mouseleave":function($event){_vm.isMegaMenuShown = false}}},[_c('nav',{staticClass:"navbar__container"},[_c('transition',{attrs:{"name":"fadeHeight"}},[(_vm.scheduledMaintenance)?_c('MaintenanceScheduledBannerVue'):_vm._e()],1),(_vm.hasBanner)?_c('SonarHomeBanner',{staticClass:"navbar__sonar-banner"}):_vm._e(),_c('div',{staticClass:"navbar__wrapper"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"navbar__ine-logo",attrs:{"src":require("@/assets/images/home-logo.png"),"alt":"INE Logo"}})]),_c('div',{staticClass:"navbar__menu"},[(!_vm.primaryAreas.length)?_c('div',{staticClass:"navbar__loader-wrapper"},[_c('ActionLoader',{attrs:{"size":0.5,"color":"#fff","name":"ball-pulse-sync"}})],1):_c('div',{staticClass:"navbar__mega-menu-wrapper"},[_c('button',{class:[
              'navbar__menu-btn',
              _vm.isMegaMenuShown && 'navbar__menu-btn--active',
            ],on:{"click":function($event){return _vm.toggleMegaMenu()}}},[_vm._v(" Learning Areas "),_c('ChevronDownIcon',{staticClass:"navbar__menu-chevron"})],1),_c('transition',{attrs:{"name":"fade"}},[_c('DesktopMegaMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMegaMenuShown),expression:"isMegaMenuShown"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMegaMenu),expression:"closeMegaMenu"}],attrs:{"primaryAreas":_vm.primaryAreas,"secondaryAreas":_vm.secondaryAreas,"isAuthenticated":_vm.isAuthenticated},on:{"close-mega-menu":_vm.toggleMegaMenu,"mouseleave":_vm.toggleMegaMenu}})],1),[_vm._l((_vm.onlyActiveLinks),function(item){return _c('router-link',{key:item.route,class:[
                'navbar__menu-link',
                _vm.$route.name &&
                  _vm.$route.name === item.route &&
                  'navbar__menu-link--selected',
              ],attrs:{"to":{ name: item.route }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),(_vm.displaySonar)?_c('router-link',{key:'sonar',class:[
                'navbar__menu-link',
                _vm.$route.name &&
                  _vm.$route.name === _vm.sonarHomeRoute &&
                  'navbar__menu-link--selected',
              ],attrs:{"to":{ name: _vm.sonarHomeRoute }}},[_vm._v(" Skill Sonar ")]):_vm._e(),(_vm.showSkillDiveLink)?_c('router-link',{class:[
                'navbar__menu-link',
                _vm.$route.name &&
                  _vm.$route.name === _vm.skillDiveRouteName &&
                  'navbar__menu-link--selected',
              ],attrs:{"to":{ name: _vm.skillDiveRouteName }}},[_vm._v(" "+_vm._s(_vm.skillDiveLabel)+" ")]):_vm._e()]],2)]),(_vm.isFreeTrialUser)?_c('PassUpgrade'):_vm._e(),(_vm.isAccOwnerOrTeamManager)?_c('div',{staticClass:"navbar__switch-container"},[_c('SwitchToBusiness')],1):_vm._e(),_c('div',{staticClass:"navbar__search-container"},[_c('SearchForm',{staticClass:"navbar__search-form",attrs:{"isSearchFormShown":_vm.isSearchFormShown},on:{"closed":function($event){return _vm.closeSearchForm()}}}),_c('SearchIcon',{staticClass:"navbar__search-icon",on:{"click":function($event){return _vm.toggleSearchForm()}}})],1),_c('Profile',{staticClass:"navbar__user-profile",attrs:{"isAuthenticated":_vm.isAuthenticated,"profile":_vm.profile,"businessUserLabel":_vm.businessUserLabel}})],1),(_vm.showSkillDive)?_c('SkillDiveTopBar',{staticClass:"navbar__skill-dive-bar"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }