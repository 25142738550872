import { MutationTree } from 'vuex'
import { ILearningAreasState, ILearningArea } from '@/@types'

const mutations: MutationTree<ILearningAreasState> = {
  LEARNING_AREAS_UPDATED: (state, learningAreas: Array<ILearningArea>) => {
    state.learningAreas = learningAreas
  },

  CURRENT_LEARNING_AREA_UPDATED: (state, learningArea: ILearningArea) => {
    state.currentLearningArea = learningArea
  },
}

export default mutations
