import { Module } from 'vuex'
import { IFaqsState, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import state from './state'

const playlists: Module<IFaqsState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default playlists
