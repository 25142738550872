import { IStatisticsState } from '@/@types'

const state: IStatisticsState = {
  trainingHistoryStats: {},
  coursesForHistory: [],
  monthlyStats: {},
  yearlyStats: {},
  lastMonthStats: {},
}

export default state
