import { FILES } from '@/clients'
import { djangoErrorHandler, shortenUUIDs } from '@/helpers'

/**
 * Get a File
 * @param fileId as UUID
 */
const fetchFileById = async fileId => {
  const route = `files/${fileId}`
  return FILES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

/**
 * Get a File list
 * @param fileIds as array
 */
const fetchFiles = async fileIds => {
  const route = `files?page_size=none&ids=${shortenUUIDs(fileIds).join(',')}`
  return FILES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

/**
 * Get a File download link
 * @param fileIds as UUID
 */
const fetchFileDownloadLink = async fileId => {
  const route = `files/${fileId}/download`
  return FILES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

/**
 * Get a File rendered content
 * @param fileIds as UUID
 */
const fetchFileRendered = async fileId => {
  const route = `files/${fileId}/render`
  return FILES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export { fetchFileById, fetchFiles, fetchFileDownloadLink, fetchFileRendered }
