var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade"}},[(_vm.isSearchFormShown)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"search-form"},[_c('form',{attrs:{"role":"search"},on:{"submit":function($event){$event.preventDefault();return _vm.startSearch()}}},[_c('div',{class:[
          'search-form__desktop',
          _vm.isSearchFormShown && 'search-form__desktop--show',
        ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userSearchQuery),expression:"userSearchQuery"}],ref:"searchInput",class:[
            'ine-input search-form__desktop-form',
            _vm.isSearchFormShown && 'search-form__desktop-form--show',
          ],attrs:{"type":"text","autocorrect":"off","autocapitalize":"off","placeholder":"Search...","autocomplete":"off","tabindex":"0"},domProps:{"value":(_vm.userSearchQuery)},on:{"focus":function($event){_vm.userSearchQuery = ''},"blur":function($event){return _vm.$emit('closed', true)},"input":function($event){if($event.target.composing)return;_vm.userSearchQuery=$event.target.value}}}),_c('SearchIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchFormShown),expression:"isSearchFormShown"}],staticClass:"search-form__search-icon",on:{"click":function($event){return _vm.startSearch()}}})],1)]),_c('form',{staticClass:"search-form__mobile",attrs:{"role":"search"},on:{"submit":function($event){$event.preventDefault();return _vm.startSearch()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userSearchQuery),expression:"userSearchQuery"}],staticClass:"ine-input search-form__mobile--form",attrs:{"type":"text","autocorrect":"off","autocapitalize":"off","autocomplete":"off","tabindex":"0","placeholder":"Enter search query..."},domProps:{"value":(_vm.userSearchQuery)},on:{"focus":function($event){_vm.userSearchQuery = ''},"input":function($event){if($event.target.composing)return;_vm.userSearchQuery=$event.target.value}}}),_c('SearchIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchFormShown),expression:"isSearchFormShown"}],staticClass:"search-form__search-icon",on:{"click":function($event){return _vm.startSearch()}}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }