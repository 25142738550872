import CONSTANTS from '@/constants'
import { ILearningAreasState } from '@/@types'

const state: ILearningAreasState = {
  learningAreas: [
    ...Object.values(CONSTANTS.LEARNING_AREAS.PRIMARY),
    ...Object.values(CONSTANTS.LEARNING_AREAS.SECONDARY),
  ],
  currentLearningArea: {},
}

export default state
