import { MutationTree } from 'vuex'
import { IFilesState, IFile, IStringKeys } from '@/@types'

const mutations: MutationTree<IFilesState> = {
  UPDATE_FILES_LIST: (state, files: Array<IFile>) => {
    const newObjects = files.reduce((acc: IStringKeys, file) => {
      acc[file.id] = file
      return acc
    }, {})
    state.contentFiles = { ...state.contentFiles, ...newObjects }
  },

  UPDATE_FILES_RENDERED: (state, rendered: { id: string; html: string }) => {
    state.filesRendered = {
      ...state.filesRendered,
      [rendered.id]: rendered.html,
    }
  },

  UPDATE_FILES_DOWNLOADED: (
    state,
    { id, html }: { id: string; html: string }
  ) => {
    state.filesDownloadLinks = {
      ...state.filesDownloadLinks,
      [id]: html,
    }
  },
}

export default mutations
