import { IBootcampsState } from '@/@types'

const state: IBootcampsState = {
  currentBootcamps: { upcoming: [] },
  selectedBootcamp: {
    id: '',
    name: '',
    slug: '',
    company: '',
    description: '',
    created: '',
    modified: '',
    learn_more_url: '',
    learning_areas: [],
    instructor: {
      id: '',
      name: '',
      profile_img_url: '',
      twitter_username: '',
      certifications: [],
    },
    background_image_url: '',
    featured_background_image_url: '',
    status: '',
    zoom_webinar_id: '',
    start_datetime: '',
    end_datetime: '',
    published_date: '',
    occurrences: [],
    overview: '',
    overview_html: '',
    instructions: '',
    instructions_html: '',
    files: [],
    links: [],
    registration_open: false,
    registration_status: '',
    content: [],
    user_status: {
      has_access: false,
      registered: false,
      registered_datetime: '',
    },
  },
  searchUserStatusBootcamps: [],
}

export default state
