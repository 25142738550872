<template>
  <transition name="slide-fade">
    <div v-if="isSearchFormShown" v-click-outside="close" class="search-form">
      <form role="search" @submit.prevent="startSearch()">
        <div
          :class="[
            'search-form__desktop',
            isSearchFormShown && 'search-form__desktop--show',
          ]"
        >
          <input
            ref="searchInput"
            v-model="userSearchQuery"
            :class="[
              'ine-input search-form__desktop-form',
              isSearchFormShown && 'search-form__desktop-form--show',
            ]"
            type="text"
            autocorrect="off"
            autocapitalize="off"
            placeholder="Search..."
            autocomplete="off"
            tabindex="0"
            @focus="userSearchQuery = ''"
            @blur="$emit('closed', true)"
          />
          <SearchIcon
            v-show="isSearchFormShown"
            class="search-form__search-icon"
            @click="startSearch()"
          />
        </div>
      </form>
      <form
        class="search-form__mobile"
        role="search"
        @submit.prevent="startSearch()"
      >
        <input
          v-model="userSearchQuery"
          class="ine-input search-form__mobile--form"
          type="text"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          tabindex="0"
          placeholder="Enter search query..."
          @focus="userSearchQuery = ''"
        />
        <SearchIcon
          v-show="isSearchFormShown"
          class="search-form__search-icon"
          @click="startSearch()"
        />
      </form>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { SearchIcon } from 'vue-feather-icons'

export default {
  name: 'SearchForm',
  components: {
    SearchIcon,
  },
  props: {
    isSearchFormShown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      userSearchQuery: '',
      timeout: null,
    }
  },
  computed: {
    ...mapState({
      searchQuery: state => state.search.searchQuery,
    }),
    isSearchActive() {
      return this.userSearchQuery.length >= 3
    },
  },
  watch: {
    searchQuery(query) {
      if (query === '') {
        this.userSearchQuery = ''
      }
    },
    isSearchFormShown() {
      if (this.isSearchFormShown) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    },
  },
  methods: {
    startSearch() {
      this.setQuery()
    },
    close() {
      this.$emit('closed', true)
    },
    setQuery() {
      this.$store.dispatch('search/setQuery', this.userSearchQuery)
      this.close()
      if (this.$route.name !== 'search') {
        this.$router.push({
          name: 'search',
          query: this.userSearchQuery && { search: this.userSearchQuery },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-form {
  position: absolute;
  $this: &;
  &__desktop {
    position: absolute;
    top: 3px;
    left: -250px;
    display: none;
    @include tablet {
      display: block;
    }
    opacity: 0;
    transition: opacity 0.1s ease-out;
    &--show {
      box-shadow: -26px 0px 39px 0px rgb(0 0 0 / 72%);
      width: 300px;
      height: 38px;
      opacity: 1;
      transition: opacity 0.1s ease-out;
    }
    &-form {
      display: none;
      font-size: rem(12px);
      line-height: 14px;
      background-color: var(--white);
      border: none;
      border-radius: 5px;
      width: 50px;
      color: var(--charcoal) !important;
      padding: 12px 30px 12px 43px;
      box-shadow: 0px 0px 0px 2px var(--butterfly-bush),
        inset 0px 0px 0px 1px var(--chetwode-blue);
      @include tablet {
        width: 300px;
      }
      @include desktop {
        width: 350px;
      }
      &:focus {
        border: 1px solid var(--flat-black);
        box-shadow: var(--purple-dark-box-shadow);
      }
      &:hover {
        color: var(--charcoal) !important;
        border: 1px solid var(--perfect-gray);
      }
      &--show {
        display: block;
        @keyframes fadeAnim {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        position: absolute;
        top: 0;
        left: 0;
        animation: fadeAnim 0.5s ease;
        transition: 0.3s all ease;
        display: block;
      }
    }
  }
  &__search-icon {
    position: absolute;
    top: 9px;
    left: 22px;
    color: var(--charcoal);
    @include tablet {
      top: 11px;
      left: 11px;
    }
    &:hover {
      cursor: pointer;
      color: var(--orange);
      transform: scale(1.05);
    }
  }
  &__mobile {
    @keyframes fadeAnim {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: fadeAnim 0.5s ease;
    transition: 0.3s all ease;
    display: block;
    margin: 0 10px;
    @include tablet {
      display: none;
    }
    &--form {
      width: 100%;
      height: 20px;
      font-size: 1rem;
      padding: 20px 20px 20px 42px;
      background: var(--white);
      box-shadow: 0px 0px 0px 2px var(--butterfly-bush),
        inset 0px 0px 0px 1px var(--chetwode-blue);
      border-radius: 5px;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translatex(-100%);
  opacity: 0;
}
</style>
