import { Module } from 'vuex'
import { ILabExperience, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

const labExperience: Module<ILabExperience, IRootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export default labExperience
