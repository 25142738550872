import { Module } from 'vuex'
import { ILearningAreasState, IRootState } from '@/@types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const learningAreas: Module<ILearningAreasState, IRootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

export default learningAreas
