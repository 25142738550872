import {
  allAttemptsInVoucher,
  voucherIsFinished,
  voucherIsInProgress,
  voucherIsInReview,
} from '@/helpers/vouchers'
import { hasPassedAttempt } from '@/helpers/certifications'
import { differenceInSeconds, format } from 'date-fns'
import CONSTANTS from '@/constants'
import { closestDate } from '@/helpers'

export const vouchersCommons = {
  data() {
    return {
      maxNumberOfExtensions: 3,
    }
  },
  computed: {
    activeVouchers() {
      return this?.voucher?.user_status?.active_vouchers ?? []
    },
    inactiveVouchers() {
      return this?.voucher?.user_status?.inactive_vouchers ?? []
    },
    allVouchers() {
      return [...this.activeVouchers, ...this.inactiveVouchers]
    },
    activeAttempts() {
      return this.allVouchers.reduce((acc, v) => {
        acc = [...acc, ...v.active_attempts]
        return acc
      }, [])
    },
    allAttempts() {
      return allAttemptsInVoucher(this.allVouchers)
    },
    allActiveVouchersAttempts() {
      return allAttemptsInVoucher(this.activeVouchers)
    },
    isInBeta() {
      return this.voucher?.versions?.[0]?.is_beta
    },
    lastVoucherExpired() {
      if (this.lastVoucherExpirationDate) {
        return (
          differenceInSeconds(
            new Date().getTime(),
            this.lastVoucherExpirationDate.getTime()
          ) >= 0
        )
      }
      return false
    },
    lastVoucherExpirationDate() {
      return this.allVouchers.length
        ? new Date(this.allVouchers[0].expiration_date)
        : null
    },
    lastVoucherPrice() {
      return this.allVouchers.length ? this.allVouchers[0].price : null
    },
    lastVoucherId() {
      return this.allVouchers.length ? this.allVouchers[0].id : null
    },
    passingAttempt() {
      return this.activeVouchers.length
        ? this.allActiveVouchersAttempts.find(a => hasPassedAttempt(a))
        : this.allAttempts.find(a => hasPassedAttempt(a))
    },
    hasPassed() {
      return !!this.passingAttempt
    },
    isInProgress() {
      return voucherIsInProgress(this.allActiveVouchersAttempts)
    },
    isFinished() {
      return voucherIsFinished(this.allActiveVouchersAttempts)
    },
    isInReview() {
      return voucherIsInReview(this.allAttempts)
    },
    isEJPT() {
      return this.voucher.id === CONSTANTS.CERTIFICATIONS.IDS.eJPT
    },
    isMCIT() {
      return this.voucher.id === CONSTANTS.CERTIFICATIONS.IDS.MCIT
    },
    isExpired() {
      return (
        !this.activeVouchers.length &&
        !this.passingAttempt &&
        !this.isInReview &&
        this.lastVoucherExpired
      )
    },
    currentStatus() {
      if (this.isExpired) {
        return 'Expired'
      }
      if (this.hasPassed) {
        return 'Passed'
      }
      if (!this.activeVouchers?.length && !this.isInBeta && !this.isInReview) {
        return 'Shop'
      }
      if (!this.activeVouchers?.length && this.isInBeta && !this.isInReview) {
        return 'Beta'
      }
      if (this.isInProgress) {
        return 'In Progress'
      }
      if (this.isInReview) {
        return 'Pending Review'
      }
      if (
        this.activeVouchers?.length &&
        !this.activeAttempts.length &&
        !this.isFinished
      ) {
        return 'Start'
      }
      return 'Failed'
    },
    retriesAvailable() {
      if (this.activeVouchers?.length) {
        return this.activeVouchers.reduce((acc, voucher) => {
          acc += voucher.remaining_attempts
          return acc
        }, 0)
      }
      return 0
    },
    retryExpiry() {
      if (this.retriesAvailable > 0 && this.activeVouchers?.length) {
        if (
          this.activeVouchers?.length &&
          this.activeVouchers[0].next_attempt_expiration_date
        ) {
          return this.formatDate(
            closestDate(
              this.activeVouchers[0].next_attempt_expiration_date,
              this.activeVouchers[0].expiration_date
            )
          )
        }
        return this.formatDate(this.activeVouchers[0].expiration_date)
      }
      return ''
    },
    learningArea() {
      return this?.voucher?.learning_area?.name ?? 'Networking'
    },
    learningPath() {
      return this.voucher?.learning_paths?.[0]
    },
    extensions() {
      return this.activeVouchers?.length
        ? this.activeVouchers[0].extensions
        : []
    },
    isExtendable() {
      return (
        !this.isInProgress &&
        !this.isInReview &&
        !this.isExpired &&
        this.currentStatus !== 'Failed' &&
        this.currentStatus !== 'Shop' &&
        this.extensions?.length < this.maxNumberOfExtensions
      )
    },
    reachedMaxExtensions() {
      return this.extensions?.length >= this.maxNumberOfExtensions
    },
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'LLLL d, yyyy')
    },
    updateVoucherExtensions(voucher) {
      const voucherToUpdate = this.allVouchers?.find(v => v.id === voucher.id)
      voucherToUpdate.extensions = voucher.extensions
      voucherToUpdate.expiration_date = voucher.expiration_date
      voucherToUpdate.next_attempt_expiration_date =
        voucher.next_attempt_expiration_date
    },
  },
}
