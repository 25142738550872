import { ActionTree, ActionContext } from 'vuex'
import { ISearchState, IRootState } from '@/@types'

const actions: ActionTree<ISearchState, IRootState> = {
  setQuery: (
    context: ActionContext<ISearchState, IRootState>,
    query: string
  ) => {
    context.commit('SEARCH_QUERY_UPDATED', query)
  },
}

export default actions
