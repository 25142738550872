import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchLearningPaths = async () => {
  const route = `learning-paths?page_size=none`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchSelectedLearningPath = async pathId => {
  const route = `learning-paths/${pathId}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postActiveLearningPath = async pathId => {
  const route = `learning-paths/${pathId}/enroll`
  return CONTENT.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchNextContent = async pathId => {
  const route = `learning-paths/${pathId}/resume`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchCertificationsForLearningPath = async payload => {
  const route = `learning-paths/${payload.pathId}/certifications`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchLearningPaths,
  fetchSelectedLearningPath,
  postActiveLearningPath,
  fetchNextContent,
  fetchCertificationsForLearningPath,
}
