import CONSTANTS from '@/constants'

const generateCustomSizeImgUrl = (host, id, width, height) => {
  return `${host}/fit-in/${width}x${height}/courses/images/${id}/original.jpg`
}

export const getBackgroundImg = (id, size) => {
  if (CONSTANTS.IMAGE_RULES.COURSE_IDS_WITH_LEGACY_BACKGROUNDS.includes(id)) {
    return `https://assets.ine.com/courses/backgrounds/processed/${id}/medium.jpg`
  }

  const host = 'https://courses.assets.ine.com'

  switch (size) {
    case 'xxxs':
      return generateCustomSizeImgUrl(host, id, '200', '113')
    case 'xxs':
      return generateCustomSizeImgUrl(host, id, '400', '225')
    case 'xs':
      return generateCustomSizeImgUrl(host, id, '500', '281')
    case 'small':
      return generateCustomSizeImgUrl(host, id, '800', '600')
    case 'medium':
      return generateCustomSizeImgUrl(host, id, '1200', '675')
    case 'large':
      return generateCustomSizeImgUrl(host, id, '1920', '1080')
    case 'fullsize':
      return `${host}/courses/images/${id}/original.jpg`
    default:
      return generateCustomSizeImgUrl(host, id, '1200', '600')
  }
}
export const getBackgroundSizeBasedOnHeight = height => {
  switch (true) {
    case height <= 225:
      return 'xxs'
    case height <= 281:
      return 'xs'
    case height <= 600:
      return 'small'
    case height <= 675:
      return 'medium'
    case height <= 1080:
      return 'large'
    default:
      return 'medium'
  }
}

export const getBackgroundSizeBaseOnBreakpoint = breakpoint => {
  switch (breakpoint) {
    case 'mobile':
      return 'small'
    case 'tablet':
      return 'medium'
    default:
      return 'large'
  }
}

export const getLearningPathBackground = id => {
  if (
    CONSTANTS.IMAGE_RULES.LEARNING_PATH_IDS_WITH_LEGACY_BACKGROUNDS.includes(id)
  ) {
    return `https://assets.ine.com/courses/learning+path/${id}/original.jpg`
  }
  const images = [
    'LearningPath_background_CCNA_DataCenter.jpg',
    'LearningPath_background_CCNA_DataCenter_v2.jpg',
    'LearningPath_background_CCNA_RS.jpg',
    'LearningPath_background_CCNA_Security.jpg',
    'LearningPath_background_CCNA_Security_v3.jpg',
  ]
  const imgUrl = `https://assets.ine.com/courses/learning+path/`
  return `${imgUrl}${images[Math.floor(Math.random() * images.length)]}`
}

export const getGradient = () => {
  // original: `to left bottom, rgb(239, 125, 34), rgb(249, 170, 13)`
  const gradients = [
    '#e04a13, #ef7d22',
    '#a82d40, #43349a',
    '#17861b, #9bdc1d',
    '#1f133a, #362153',
    '#48237b, #d52873',
    '#72237c, #d82775, #f5bf3b',
    '#081f63, #0f84a7, #40cbec',
    '#01a044, #0f3258',
    '#6c237d, #dc2d70, #e78a2d',
    '#ff2b4b, #43309c',
  ]
  const angle = 'to bottom right'
  return `${angle}, ${gradients[Math.floor(Math.random() * gradients.length)]}`
}

export const getInstructorImg = url => {
  if (
    url &&
    url !==
      'https://s3.amazonaws.com/ine-vegas/production/images/instructors/INE-Instructor.png'
  ) {
    return url
  }
  return require('@/assets/images/instructor_fallback.png')
}

export const getBootcampBackgroundImg = bootcamp => {
  return bootcamp?.background_image_url
}

export const isCurrentDefaultAvatar = imgUrl => {
  const currentAvatar = imgUrl?.split('/').slice(-2).join('/')
  return CONSTANTS.PROFILE.DEFAULT_AVATAR.includes(currentAvatar)
}
