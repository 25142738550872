import {
  ILab,
  ILabSession,
  ILabStatus,
  ILabUserStatus,
  ILabsState,
} from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ILabsState> = {
  CURRENT_LAB_UPDATED: (state, lab: ILab) => {
    state.currentLab = lab
  },

  LAB_USER_STATUS_UPDATED: (
    state,
    userLabStatus: { lab: string; modified: string }
  ) => {
    const { lab, modified } = userLabStatus
    const { currentLab: newCurrentLab }: { currentLab: ILab | null } = state
    if (newCurrentLab?.id === lab) {
      newCurrentLab.user_lab_status = userLabStatus
      newCurrentLab.user_status_modified = modified
    }
    state.currentLab = newCurrentLab
  },

  LAB_OBJETIVES_ATTEMPT_UPDATED: (
    state,
    userLabAttempt: { lab: string; lab_session: ILabSession; modified: string }
  ) => {
    const id = userLabAttempt?.lab_session.lab?.id
    const modified = userLabAttempt?.lab_session?.modified
    const { currentLab: newCurrentLab }: { currentLab: ILab | null } = state
    if (newCurrentLab?.id === id) {
      newCurrentLab.user_lab_attempt = userLabAttempt
      newCurrentLab.user_status_modified = modified
    }
    state.currentLab = newCurrentLab
  },

  LAB_STATUS_UPDATED: (
    state,
    userLabStatus: {
      lab: string
      status: string
      modified: string
      flags: object[]
    }
  ) => {
    const { currentLab } = state
    if (currentLab?.id === userLabStatus.lab) {
      state.currentLab = {
        ...currentLab,
        user_lab_status: userLabStatus,
        user_flags: userLabStatus.flags,
        user_status: userLabStatus.status,
        user_status_modified: userLabStatus.modified,
      }
    }
  },

  CURRENT_LAB_SESSION_UPDATED: (state, labSession: ILabSession) => {
    state.currentLabSession = labSession
  },

  CURRENT_LAB_GLOBAL_SESSION_UPDATED: (state, labSession: ILabSession) => {
    const { currentLab: newCurrentLab } = state
    if (newCurrentLab) {
      newCurrentLab.global_running_session = labSession
    }
    state.currentLab = newCurrentLab
  },

  DECREMENT_CURRENT_LAB_AVAILABLE_RESETS: state => {
    const { currentLab: newCurrentLab }: { currentLab: ILab | null } = state
    if (newCurrentLab?.available_resets !== undefined) {
      newCurrentLab.available_resets -= 1
    }
    state.currentLab = newCurrentLab
  },

  FOCUSED_LAB_ID_UPDATED: (state, labId) => {
    state.focusedLabId = labId
  },
  UPDATE_USER_FLAGS_STATUS: (state, flags) => {
    state.userCurrentFlags = flags
  },
  RUNNING_LAB_SESSION_UPDATED: (state, runningLabSession: Object) => {
    state.runningLabSession = runningLabSession
  },
  CURRENT_LAB_STATUS_UPDATED: (state, payload: ILabStatus) => {
    state.labStatus = {
      ...state.labStatus,
      ...payload,
    }
  },
  UPDATE_LAB_STATUSES(state, payload: ILabUserStatus[]) {
    state.labUserStatuses = payload.reduce(
      (acc, labStatus) => {
        acc[labStatus.id] = labStatus
        return acc
      },
      { ...state.labUserStatuses }
    )
  },
}

export default mutations
