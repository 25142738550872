import CONSTANTS from '@/constants'

export default [
  {
    path: '/bootcamps',
    name: 'bootcamps',
    component: () => import('@/pages/Bootcamps.vue'),
  },
  {
    path: '/bootcamps/:id',
    name: 'bootcamp',
    component: () => import('@/pages/Bootcamp.vue'),
  },
  {
    path: '/bootcamps/:id/lab/:labId',
    name: 'bootcamp-lab',
    component: () => import('@/pages/LabPage.vue'),
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.BOOTCAMP },
  },
]
