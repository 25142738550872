import algoliasearch from 'algoliasearch'
import algoliasearchHelper from 'algoliasearch-helper'

export const createAlgoliaSearchInstance = (appId, appKey) => {
  const searchInstance = algoliasearch(appId, appKey)
  return searchInstance
}

export const createAlgoliaIndices = (
  {
    keys,
    contexts,
    resultsHandler,
    updatesHandler,
    disjunctiveFacets,
    initWithSearch = true,
  },
  parentFilter = null
) => {
  try {
    const { appId, appKey } = keys
    const instance = createAlgoliaSearchInstance(appId, appKey)
    const indices = {}

    contexts.map(context => {
      const { name, index, facets } = context
      const selectedFacetsType = disjunctiveFacets
        ? { disjunctiveFacets: facets }
        : { facets }
      indices[name] = algoliasearchHelper(instance, index, selectedFacetsType)
      indices[name].algoliaIndex = instance.initIndex(index)
      /* @ToDo, Trying to avoid pagination in favor of grouping results by time mark (current, upcoming, past) */
      if (indices.Bootcamps) {
        indices.Bootcamps.setQueryParameter('hitsPerPage', 500)
      }
      /* Group by parent_id. "attributeForDistinct": "parent_id" Internetworkexpert/video-engine/#126 */
      if (indices.Videos) {
        indices.Videos.setQueryParameter('distinct', 6)
      }
      indices[name].on('result', ({ results }) => resultsHandler(name, results))
      if (updatesHandler) {
        indices[name].on('change', ({ state }) => updatesHandler(name, state))
      }

      if (parentFilter) {
        const { facet, value } = parentFilter
        indices[name].addFacetRefinement(facet, value)
      }
      if (initWithSearch) {
        indices[name].search()
      }
    })

    return { instance, indices }
  } catch (error) {
    //TODO: Add error handling
    return
  }
}

export const toggleAlgoliaFacet = (index, { facet, value }) => {
  index.toggleFacetRefinement(facet, value)
}

export const removeAlgoliaFacet = (index, { facet, value }) => {
  index.removeFacetRefinement(facet, value)
}

export const clearAlgoliaFacets = index => {
  index.clearRefinements()
}

export const searchAlgolia = index => {
  index.search()
}
