<template>
  <header
    class="navbar"
    role="navigation"
    @mouseleave="isMegaMenuShown = false"
  >
    <nav class="navbar__container">
      <transition name="fadeHeight">
        <MaintenanceScheduledBannerVue v-if="scheduledMaintenance" />
      </transition>
      <SonarHomeBanner v-if="hasBanner" class="navbar__sonar-banner" />
      <div class="navbar__wrapper">
        <router-link :to="{ name: 'home' }">
          <img
            class="navbar__ine-logo"
            src="@/assets/images/home-logo.png"
            alt="INE Logo"
          />
        </router-link>
        <div class="navbar__menu">
          <div v-if="!primaryAreas.length" class="navbar__loader-wrapper">
            <ActionLoader :size="0.5" color="#fff" name="ball-pulse-sync" />
          </div>
          <div v-else class="navbar__mega-menu-wrapper">
            <button
              :class="[
                'navbar__menu-btn',
                isMegaMenuShown && 'navbar__menu-btn--active',
              ]"
              @click="toggleMegaMenu()"
            >
              Learning Areas
              <ChevronDownIcon class="navbar__menu-chevron" />
            </button>
            <transition name="fade">
              <DesktopMegaMenu
                v-show="isMegaMenuShown"
                v-click-outside="closeMegaMenu"
                :primaryAreas="primaryAreas"
                :secondaryAreas="secondaryAreas"
                :isAuthenticated="isAuthenticated"
                @close-mega-menu="toggleMegaMenu"
                @mouseleave="toggleMegaMenu"
              />
            </transition>
            <template>
              <router-link
                v-for="item in onlyActiveLinks"
                :key="item.route"
                :class="[
                  'navbar__menu-link',
                  $route.name &&
                    $route.name === item.route &&
                    'navbar__menu-link--selected',
                ]"
                :to="{ name: item.route }"
              >
                {{ item.name }}
              </router-link>
              <router-link
                v-if="displaySonar"
                :key="'sonar'"
                :class="[
                  'navbar__menu-link',
                  $route.name &&
                    $route.name === sonarHomeRoute &&
                    'navbar__menu-link--selected',
                ]"
                :to="{ name: sonarHomeRoute }"
              >
                Skill Sonar
              </router-link>
              <router-link
                v-if="showSkillDiveLink"
                :class="[
                  'navbar__menu-link',
                  $route.name &&
                    $route.name === skillDiveRouteName &&
                    'navbar__menu-link--selected',
                ]"
                :to="{ name: skillDiveRouteName }"
              >
                {{ skillDiveLabel }}
              </router-link>
            </template>
          </div>
        </div>
        <PassUpgrade v-if="isFreeTrialUser" />
        <div v-if="isAccOwnerOrTeamManager" class="navbar__switch-container">
          <SwitchToBusiness />
        </div>
        <div class="navbar__search-container">
          <SearchForm
            class="navbar__search-form"
            :isSearchFormShown="isSearchFormShown"
            @closed="closeSearchForm()"
          />
          <SearchIcon class="navbar__search-icon" @click="toggleSearchForm()" />
        </div>
        <Profile
          class="navbar__user-profile"
          :isAuthenticated="isAuthenticated"
          :profile="profile"
          :businessUserLabel="businessUserLabel"
        />
      </div>
      <SkillDiveTopBar v-if="showSkillDive" class="navbar__skill-dive-bar" />
    </nav>
  </header>
</template>

<script>
import CONSTANTS from '@/constants'
import { mapState, mapGetters, mapActions } from 'vuex'
import ActionLoader from '@/components/shared/ActionLoader.vue'
import SearchForm from '@/components/forms/SearchForm.vue'
import { SearchIcon, ChevronDownIcon } from 'vue-feather-icons'
import DesktopMegaMenu from './DesktopMegaMenu.vue'
import PassUpgrade from './PassUpgrade.vue'
import Profile from './Profile.vue'
import SwitchToBusiness from './SwitchToBusiness.vue'
import SonarHomeBanner from '@/sonar/components/SonarHomeBanner.vue'
import SkillDiveTopBar from '@/components/lab-experience/SkillDiveTopBar.vue'
import MaintenanceScheduledBannerVue from '@/components/banners/MaintenanceScheduledBanner.vue'

export default {
  name: 'NavBar',
  components: {
    SearchForm,
    DesktopMegaMenu,
    Profile,
    PassUpgrade,
    ActionLoader,
    SearchIcon,
    ChevronDownIcon,
    SwitchToBusiness,
    SonarHomeBanner,
    SkillDiveTopBar,
    MaintenanceScheduledBannerVue,
  },
  props: {
    hasBanner: {
      type: Boolean,
      required: true,
      default: false,
    },
    scheduledMaintenance: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSkillDive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMegaMenuShown: false,
      isSearchFormShown: false,
      subscribersMenuLinks: [
        {
          route: 'certifications',
          name: 'Certifications',
          active: process.env.VUE_APP_CERTIFICATIONS_ENABLED,
        },
        {
          route: 'playlists',
          name: 'Playlists',
          active: false,
        },
        {
          route: 'sonar',
          name: 'Sonar',
          active: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      isAdmin: state => state.auth.isAdmin,
      profile: state => state.auth.userProfile,
      learningAreas: state => state.learningAreas.learningAreas,
    }),
    ...mapGetters({
      hasPlaylists: 'auth/hasPlaylists',
      isFreeTrialUser: 'auth/isFreeTrialUser',
      primaryAreas: 'learningAreas/primaryAreas',
      secondaryAreas: 'learningAreas/secondaryAreas',
      businessUser: 'business/getBusinessUser',
      isAccOwnerOrTeamManager: 'auth/isAccOwnerOrTeamManager',
      isBusinessUser: 'business/isBusinessUser',
      userProfileEmail: 'auth/userProfileEmail',
      displaySonar: 'sonar/displaySonar',
      isLabExperienceUser: 'auth/isLabExperienceUser',
      isSkillDiveB2CUser: 'auth/isSkillDiveB2CUser',
      isSkillDiveB2BUser: 'auth/isSkillDiveB2BUser',
      isLabExperienceLegacyB2BUser: 'auth/isLabExperienceLegacyB2BUser',
      labExperienceEnhance: 'app/labExperienceEnhance',
    }),
    showSkillDiveLink() {
      return (
        !this.isBusinessUser ||
        (this.isBusinessUser &&
          !CONSTANTS.BUSINESS_OLD_PRICING_PLANS.includes(
            this.businessUser?.account.pricing_plan
          )) ||
        this.isLabExperienceUser ||
        this.labExperienceEnhance
      )
    },
    isViewingSACourse() {
      return this.$route?.params?.id === this.saCourseLink.params.id
    },
    onlyActiveLinks() {
      return this.subscribersMenuLinks.filter(link => link.active)
    },
    businessUserLabel() {
      if (!this.businessUser) return ''
      const isAccountOwner = this.isBusinessAccountOwner
      if (isAccountOwner && this.businessUser.account)
        return `Account Owner of ${this.businessUser.account?.name}`
      const isManager = this.isBusinessTeamManager
      const teamName = this.businessUser.user?.team?.name
      if (isManager && this.businessUser.account)
        return teamName
          ? `Team Manager of ${teamName}, ${this.businessUser.account?.name}`
          : `Team Manager, ${this.businessUser.account?.name}`
      return ''
    },
    isBusinessAccountOwner() {
      return this.businessUser.user?.uaa_data?.roles?.data?.some(
        role => role.name === CONSTANTS.ROLES.ACCOUNT_ADMIN
      )
    },
    isBusinessTeamManager() {
      return this.businessUser.user?.uaa_data?.roles?.data?.some(
        role => role.name === CONSTANTS.ROLES.TEAM_ADMIN
      )
    },
    sonarHomeRoute() {
      return CONSTANTS.SONAR.ROUTES.HOME
    },
    skillDiveLabel() {
      return !this.isLabExperienceLegacyB2BUser
        ? 'Skill Dive'
        : 'Lab Experience'
    },
    skillDiveRouteName() {
      return !this.isLabExperienceLegacyB2BUser
        ? 'skill-dive'
        : 'lab-experience'
    },
  },
  watch: {
    isAuthenticated: {
      handler() {
        if (this.isAuthenticated) {
          this.userMeOnContent()
        }
      },
      immediate: true,
    },
    hasPlaylists(value) {
      const index = this.subscribersMenuLinks.findIndex(
        m => m.route === 'playlists'
      )
      this.subscribersMenuLinks[index].active = value
    },
  },
  mounted() {
    const desktopNavBar = document.querySelector('.navbar__wrapper')
    const ineLogo = document.querySelector('.navbar__ine-logo')
    window.addEventListener('scroll', event => {
      event.preventDefault()

      if (window.scrollY >= 25) {
        desktopNavBar.classList.add('collapse-navbar')
        ineLogo.classList.add('collapse-logo')
      } else {
        desktopNavBar.classList.remove('collapse-navbar')
        ineLogo.classList.remove('collapse-logo')
      }
    })
  },
  methods: {
    ...mapActions('auth', ['userMeOnContent']),
    toggleMegaMenu() {
      this.isMegaMenuShown = !this.isMegaMenuShown
    },
    toggleSearchForm() {
      this.isSearchFormShown = !this.isSearchFormShown
    },
    closeSearchForm() {
      this.isSearchFormShown = false
    },
    closeMegaMenu(event) {
      const classList = event?.target?.classList
      if (
        classList?.contains('navbar__menu-btn') ||
        classList?.contains('navbar__menu-chevron')
      ) {
        return
      }
      this.isMegaMenuShown = false
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 202;
  background: transparent;
  transition: 0.5s all ease;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: none;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  @include tablet {
    display: block;
    background: linear-gradient(180deg, var(--dark-gray) 30%, transparent 80%);
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    height: 100px;
    transition: 0.5s all ease;
    background: linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1),
      transparent
    );
    z-index: 2;
    @include tablet {
      padding: 0 15px 0 53px;
    }
  }
  &__ine-logo {
    transition: 0.5s all ease;
    max-height: 35px;
  }
  &__loader-wrapper {
    margin: 0 0 0 50px;
  }
  &__menu-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--athens-gray);
    font-family: var(--font-book);
    font-size: rem(14);
    padding: 19.5px 0 20.5px 0;
    white-space: nowrap;
    line-height: 20px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    @include tablet {
      margin: 0 15px;
    }
    &:hover {
      border-bottom: 3px solid var(--orange);
      color: var(--white);
    }
    &:active {
      border-bottom: 3px solid var(--white);
      color: var(--white);
    }
    &--active {
      color: var(--white);
      border-bottom: 3px solid var(--orange);
      &:hover {
        opacity: 1;
      }
      > svg {
        transform: rotate(180deg);
        transition: transform 0.1s ease-out;
      }
    }
    > svg {
      width: 16px;
      margin-left: 5px;
      transition: transform 0.1s ease-out;
    }
  }
  &__user-profile {
    margin: 0 0 0 20px;
  }
  &__menu {
    margin: 0 auto 0 24px;
  }
  &__mega-menu-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 3px 0 0 0;
  }
  &__menu-link {
    font-family: var(--font-book);
    color: var(--athens-gray);
    font-size: rem(14px);
    white-space: nowrap;
    margin: 0;
    padding: 19.5px 0 20.5px 0;
    line-height: 20px;
    border-bottom: 3px solid transparent;
    @include tablet {
      margin: 0 15px;
    }
    &:hover {
      cursor: pointer;
      border-bottom: 3px solid var(--orange);
      color: var(--white);
    }
    &:active {
      border-bottom: 3px solid var(--white);
    }
    &--selected {
      pointer-events: none;
      color: var(--white);
      border-bottom: 3px solid var(--orange);
      &:visited {
        color: var(--white);
      }
    }
  }
  .collapse-navbar {
    position: relative;
    background: var(--menu-gradient);
    height: 63px !important;
    backdrop-filter: saturate(180%) blur(20px);
  }
  .collapse-logo {
    height: 28px !important;
  }
  &__switch-container {
    display: flex;
    align-items: center;
  }

  &__search {
    &-container {
      display: flex;
    }
    &-form {
      position: relative;
      top: -4px;
      right: -4px;
      @include desktop {
        right: 45px;
      }
    }
    &-icon {
      width: 100%;
      margin-left: 5px;
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      color: var(--white);
      padding: 10px;
      height: 44px;
      border-radius: 5px;
      cursor: pointer;
      @include mobile {
        width: auto;
        margin-left: 10px;
      }
      &:hover,
      &:active {
        background: var(--white);
        color: var(--charcoal);
      }
      &:target {
        background: var(--athens-gray);
        color: var(--charcoal);
      }
    }
  }
  &__sonar-banner {
    display: none;
    @include tablet {
      display: block;
      top: 0;
      width: 100%;
      z-index: 100;
    }
  }
}
</style>
