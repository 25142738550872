import { CONTENT, PROFILE, UAA } from '@/clients'

import { laravelErrorHandler } from '@/helpers'

const loginUser = async formData => {
  const { email: username, password, recaptcha: captchaToken } = formData
  const route = `users/authenticate`
  const postData = {
    username,
    password,
    captchaToken,
  }
  try {
    const {
      data: { data },
    } = await UAA.post(route, postData)
    return data
  } catch (error) {
    laravelErrorHandler(error)
    throw error?.response?.data
  }
}

const fetchUserMeOnContent = async () => {
  const route = `users/me`

  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchStatus = async () => {
  const route = `auth/state/status?embed=profile`
  return UAA.get(route)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const logoutUser = async () => {
  const route = `logout`
  return UAA.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postMFACode = async code => {
  const route = `users/mfa/validate`
  return UAA.post(route, { code })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchMFACode = async () => {
  const route = `users/mfa/resend`
  return UAA.post(route, {})
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchAuthProviders = async () => {
  const route = `providers?referrer=my-ine`
  return UAA.get(route)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const federatedLoginInfo = async company => {
  const route = `federated/login-providers?name=${company}`
  return UAA.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchSampleAvatars = async () => {
  const route = `users/avatar/default?number=all`
  return PROFILE.get(route)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postUserAvatar = async fileAndType => {
  const { file, type } = fileAndType

  const formData = new FormData()
  switch (type) {
    case 'binary':
      formData.append('user_avatar', file)
      break
    case 'base64':
      formData.append('base64_avatar', file)
      break
    default:
      formData.append('user_avatar', file)
      break
  }

  const route = `users/avatar`
  return PROFILE.post(route, formData)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postDefaultUserAvatar = async () => {
  const route = `users/avatar`
  return PROFILE.post(route, { user_avatar: null })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postConfirmNewAccount = async codeAndUUID => {
  const { code: confirmation_code, username } = codeAndUUID
  const postData = { confirmation_code, username }
  const route = `users/confirm`
  return UAA.post(route, postData)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(error))
}

const postForgotPassword = async email => {
  const route = `users/reset-password`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postNewPasswordByCode = async formData => {
  const { code: confirmation_code, username, password } = formData
  const postData = {
    confirmation_code,
    username,
    password,
    confirm_password: password,
  }
  const route = `users/reset-password/confirm`
  return UAA.post(route, postData)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const putNewPasswordBySession = async formData => {
  const { session, username, password } = formData
  const putData = {
    username,
    session,
    new_password: password,
    confirm_password: password,
  }
  const route = `users/complete-required-reset`
  return UAA.put(route, putData)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postChangePassword = async currentAndNewPasswords => {
  const { currentPassword: old_password, password: new_password } =
    currentAndNewPasswords
  const route = `users/change-password`
  return UAA.post(route, { old_password, new_password })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postChangePhoneNumber = async phone_number => {
  const route = `users/phone`
  return PROFILE.post(route, { phone_number })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postChangeMFAMethod = async method => {
  const route = `users/change-mfa`
  return UAA.post(route, { method })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchUserPreferences = async () => {
  const route = `users/preferences`

  try {
    const {
      data: { data },
    } = await PROFILE.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const putUserPreferences = async preferences => {
  if (preferences.passes?.length) {
    preferences.passes = preferences.passes.map(pass => pass.id)
  }
  const route = `users/preferences`
  try {
    const {
      data: { data },
    } = await PROFILE.put(route, preferences)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postConfirmationEmail = async email => {
  const route = `users/confirm/resend`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchUserOnboardingSurvey = async () => {
  const route = 'users/survey-response/survey/user_onboarding_survey'
  try {
    const {
      data: { data },
    } = await PROFILE.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postUserOnboardingSurvey = async payload => {
  const route = 'users/survey-response'
  try {
    const { data } = await PROFILE.post(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postBackupEmail = async payload => {
  const route = '/users/profile/add-email'
  try {
    const { data } = await PROFILE.post(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const deleteBackupEmail = async payload => {
  const route = '/users/profile/remove-email'
  try {
    const { data } = await PROFILE.delete(route, { data: payload })
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const putBirthday = async payload => {
  const route = '/users/birthday'
  try {
    const { data } = await PROFILE.put(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}
const fetchUserBackupEmails = async () => {
  const route = 'users/profile/backup-emails'
  try {
    const {
      data: { data },
    } = await PROFILE.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchUserCurrentProfile = async payload => {
  const route = '/users/profile'
  try {
    const { data } = await PROFILE.get(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

export {
  federatedLoginInfo,
  fetchAuthProviders,
  fetchMFACode,
  fetchSampleAvatars,
  fetchStatus,
  fetchUserPreferences,
  loginUser,
  logoutUser,
  postChangeMFAMethod,
  postChangePassword,
  postChangePhoneNumber,
  postConfirmNewAccount,
  postForgotPassword,
  postMFACode,
  postNewPasswordByCode,
  postUserAvatar,
  postDefaultUserAvatar,
  putUserPreferences,
  fetchUserMeOnContent,
  postConfirmationEmail,
  fetchUserOnboardingSurvey,
  postUserOnboardingSurvey,
  postBackupEmail,
  putNewPasswordBySession,
  putBirthday,
  fetchUserBackupEmails,
  fetchUserCurrentProfile,
  deleteBackupEmail,
}
