import CONSTANTS from '@/constants'

export const getOrdinalNum = n => {
  return (
    n +
    (n > 0
      ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : '')
  )
}

export const textDate = date => {
  if (!date) return ''
  const d = new Date(date)
  const day = getOrdinalNum(d.getDate())
  const month = CONSTANTS.YEAR_MONTHS.find(m => m.number === d.getMonth() + 1)
  return `${day} of ${month.label} ${d.getFullYear()}`
}

export const stringDateToMilliseconds = date => {
  return date ? new Date(date).getTime() : null
}

// create a function given two string with UTC date format, return the closest one to today
export const closestDate = (date1, date2) => {
  const d1 = stringDateToMilliseconds(date1)
  const d2 = stringDateToMilliseconds(date2)
  if (!d1 && !d2) return null
  if (!d1) return d2
  if (!d2) return d1
  const today = new Date().getTime()
  return Math.abs(d1 - today) < Math.abs(d2 - today) ? date1 : date2
}
