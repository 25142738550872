import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'
import { getAccessToken } from '@internetworkexpert/js-common'

const fetchCPECredits = async () => {
  const route = `cpe/credits`
  return CONTENT.get(route)
    .then(response => response.data)
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchCPECreditsHistory = async (page_size = 'none', page = 1) => {
  const route = `cpe/history?page_size=${page_size}&page=${page}`
  return CONTENT.get(route)
    .then(response => response.data)
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postExternalDocumentation = async payload => {
  const route = 'cpe/external'
  const headers = {
    accept: 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  }
  return CONTENT.post(route, payload, { headers })
    .then(response => response.data)
    .catch(error => {
      if (error?.message) {
        error.message =
          'Something went wrong uploading documentation. Please try again.'
      }
      djangoErrorHandler(error)
      return error
    })
}

export { fetchCPECredits, postExternalDocumentation, fetchCPECreditsHistory }
