import { ActionContext, ActionTree } from 'vuex'
import { IAppState, IRootState } from '@/@types'

import CONSTANTS from '@/constants'
import { logError } from '@/helpers/bugsnag'
import { woopraParams } from '@/helpers'

const actions: ActionTree<IAppState, IRootState> = {
  setIsPageLoading: (
    context: ActionContext<IAppState, IRootState>,
    isPageLoading: boolean
  ) => {
    context.commit('IS_PAGE_LOADING_UPDATED', isPageLoading)
  },
  setIsComponentLoading: (
    context: ActionContext<IAppState, IRootState>,
    isComponentLoading: boolean
  ) => {
    context.commit('IS_COMPONENT_LOADING_UPDATED', isComponentLoading)
  },
  setError: (context: ActionContext<IAppState, IRootState>, error: string) => {
    const firendlyError = CONSTANTS.FRIENDLY_ERRORS[error]
    const preparedError = firendlyError || error
    context.commit('APP_ERROR_UPDATED', preparedError)
    logError(preparedError)
  },
  setNotification: (
    context: ActionContext<IAppState, IRootState>,
    notification: string
  ) => {
    context.commit('APP_NOTIFICATION_UPDATED', notification)
  },
  setWarning: (
    context: ActionContext<IAppState, IRootState>,
    warning: string
  ) => {
    context.commit('APP_WARNING_UPDATED', warning)
  },
  setSuccess: (
    context: ActionContext<IAppState, IRootState>,
    success: string
  ) => {
    context.commit('APP_SUCCESS_UPDATED', success)
  },
  setIsAppLoading: (
    context: ActionContext<IAppState, IRootState>,
    isAppLoading: boolean
  ) => {
    context.commit('IS_APP_LOADING_UPDATED', isAppLoading)
  },
  setOnboardingModalStatus: (
    context: ActionContext<IAppState, IRootState>,
    onboardingModalStatus: object
  ) => {
    context.commit('APP_ONBOARDING_MODAL_STATUS_UPDATED', onboardingModalStatus)
  },
  setVideoActive: (
    context: ActionContext<IAppState, IRootState>,
    videoActive: boolean
  ) => {
    context.commit('VIDEO_ACTIVE_UPDATED', videoActive)
  },
  setKinesisHeartbeatData: (
    context: ActionContext<IAppState, IRootState>,
    kinesisHeartbeatData: object
  ) => {
    context.commit('KINESIS_HEARTBEAT_DATA_UPDATED', kinesisHeartbeatData)
  },
  storeWoopraParams(context: ActionContext<IAppState, IRootState>) {
    const urlParams = new URLSearchParams(window.location.search)
    const isExternalReferrer = !window.location.ancestorOrigins[0]?.includes(
      window.location.hostname
    )
    const isNewTab = window.location.ancestorOrigins.length === 0

    if (isExternalReferrer || isNewTab) {
      let woopraData: { [key: string]: any } = {}

      woopraParams.forEach(paramKey => {
        if (urlParams.has(paramKey)) {
          woopraData[paramKey] = urlParams.get(paramKey)
        }
      })

      context.commit('WOOPRA_DATA_UPDATED', woopraData)
    }
  },
  setIsLoadingCaptcha: (
    context: ActionContext<IAppState, IRootState>,
    isLoadingCaptcha: boolean
  ) => {
    context.commit('IS_LOADING_CAPTCHA_UPDATED', isLoadingCaptcha)
  },
}

export default actions
