import Bugsnag from '@bugsnag/js'
import { CONTENT, EXERCISES, MIXPANEL, QUIZ, VIDEOS } from '@/clients'
import { djangoErrorHandler, isParentPresent, shortenUUIDs } from '@/helpers'
import CONSTANTS from '@/constants'

const fetchJWVideo = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `videos/${id}/media?parent_type=${parentType}&parent_id=${parentId}`
    : `videos/${id}/media`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchRecentActivity = async () => {
  const route = `courses/content-activity`
  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    djangoErrorHandler(error)
  }
}

const fetchVideos = async videoIds => {
  const route = `videos?page_size=none&ids=${shortenUUIDs(videoIds).join(',')}`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchVideosForBootcamp = async videoIdsAndBootcampId => {
  const { videoIds, bootcampId } = videoIdsAndBootcampId
  const route = `videos?page_size=none&ids=${shortenUUIDs(videoIds).join(
    ','
  )}&parent_type=bootcamp&parent_id=${bootcampId}`
  try {
    const { data } = await VIDEOS.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchVideosForParent = async videoIdsAndParentId => {
  const { videoIds, parentId, parentType } = videoIdsAndParentId
  const route = `videos?page_size=none&ids=${shortenUUIDs(videoIds).join(
    ','
  )}&parent_type=${parentType}&parent_id=${parentId}`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchVideosForLearningPath = async videoIdsAndPathId => {
  const { videoIds, pathId } = videoIdsAndPathId
  const route = `videos?page_size=none&ids=${shortenUUIDs(videoIds).join(
    ','
  )}&parent_type=learning-path&parent_id=${pathId}`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchVideo = async payload => {
  const { id, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `videos/${id}?parent_type=${parentType}&parent_id=${parentId}`
    : `videos/${id}`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postVideoStatus = async videoIdAndStatus => {
  const { id, status, parentId, parentType } = videoIdAndStatus
  const route = isParentPresent({ parentId, parentType })
    ? `videos/${id}/status?parent_type=${parentType}&parent_id=${parentId}`
    : `videos/${id}/status?`
  return VIDEOS.post(route, { status })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postVideoPosition = async videoIdAndPosition => {
  const { id, current_position, parentId, parentType } = videoIdAndPosition
  const route = isParentPresent({ parentId, parentType })
    ? `videos/${id}/current_position?parent_type=${parentType}&parent_id=${parentId}`
    : `videos/${id}/current_position?`
  return VIDEOS.post(route, { current_position })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLanguageVideo = async payload => {
  const { language, course_id, video_id } = payload
  return VIDEOS.post('subtitles', {
    language,
    course_id,
    video_id,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchQuizzes = async payload => {
  const { quizIds, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes?page_size=none&ids=${shortenUUIDs(quizIds).join(
        ','
      )}&parent_type=${parentType}&parent_id=${parentId}`
    : `quizzes?page_size=none&ids=${shortenUUIDs(quizIds).join(',')}`
  return QUIZ.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchQuizzesForLearningPath = async quizIdsAndPathId => {
  const { quizIds, pathId } = quizIdsAndPathId
  const route = `quizzes?page_size=none&ids=${shortenUUIDs(quizIds).join(
    ','
  )}&parent_type=learning-path&parent_id=${pathId}`
  return QUIZ.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchExercises = async payload => {
  const { exerciseIds, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises?page_size=none&ids=${shortenUUIDs(exerciseIds).join(
        ','
      )}&parent_type=${parentType}&parent_id=${parentId}`
    : `exercises?page_size=none&ids=${shortenUUIDs(exerciseIds).join(',')}`
  return EXERCISES.get(route)
    .then(response => {
      // service doesn't return objects in the same order than IDs array,
      // ordering here avoid us to transfer 2 arrays through components and refactor code.
      const idsresponse = response.data.reduce((acc, e) => {
        acc[e.id] = e
        return acc
      }, {})
      const orderedResponse = []
      for (let index = 0; index < exerciseIds.length; index++) {
        const id = exerciseIds[index]
        orderedResponse.push(idsresponse[id])
      }
      return Promise.resolve(orderedResponse)
    })
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchExercisesForLearningPath = async exerciseIdsAndPathId => {
  const { exerciseIds, pathId } = exerciseIdsAndPathId
  const route = `exercises?page_size=none&ids=${shortenUUIDs(exerciseIds).join(
    ','
  )}&parent_type=learning-path&parent_id=${pathId}`
  return EXERCISES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchLabs = async payload => {
  const { labIds, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `labs?page_size=none&ids=${shortenUUIDs(labIds).join(
        ','
      )}&parent_type=${parentType}&parent_id=${parentId}`
    : `labs?page_size=none&ids=${shortenUUIDs(labIds).join(',')}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchLabsForLearningPath = async labIdsAndPathId => {
  const { labIds, pathId } = labIdsAndPathId
  const route = `labs?page_size=none&ids=${shortenUUIDs(labIds).join(
    ','
  )}&parent_type=learning-path&parent_id=${pathId}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postIFrameStatus = async iframeIdAndStatus => {
  const { id, status, parentId, parentType } = iframeIdAndStatus
  const route = isParentPresent({ parentId, parentType })
    ? `iframes/${id}/status?parent_type=${parentType}&parent_id=${parentId}`
    : `iframes/${id}/status`
  return CONTENT.post(route, {
    status,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchIFramesForLearningPath = async iframeIdsAndPathId => {
  const { iframeIds, pathId } = iframeIdsAndPathId
  const route = `iframes?page_size=none&ids=${shortenUUIDs(iframeIds).join(
    ','
  )}&parent_type=learning-path&parent_id=${pathId}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchBookmarks = async () => {
  const route = `bookmarks?page_size=none`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postBookmark = async bookmark => {
  const { videoId: video, time, notes, courseId: course_id } = bookmark
  const route = `bookmarks`
  return VIDEOS.post(route, { video, time, notes, metadata: { course_id } })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const deleteBookmark = async id => {
  const route = `bookmarks/${id}`
  return VIDEOS.delete(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchCoursesByIds = async (
  courseIds,
  page_size = 'none',
  page = 1,
  ordering
) => {
  let route = `courses?page_size=${page_size}&page=${page}&ids=${shortenUUIDs(
    courseIds
  ).join(',')}`
  if (ordering) {
    route += `&ordering=${ordering}`
  }
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postMixpanelEvent = async payload => {
  return MIXPANEL.post('track', payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchContentIssues = async () => {
  const route = `content-issues/types`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postContentIssue = async issue => {
  const route = `content-issues`
  return CONTENT.post(route, issue)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLinkStatus = async linkIdAndStatus => {
  const { id, status, parentId, parentType } = linkIdAndStatus
  const route = isParentPresent({ parentId, parentType })
    ? `links/${id}/status?parent_type=${parentType}&parent_id=${parentId}`
    : `links/${id}/status`
  return CONTENT.post(route, { status })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAllFaqs = async () => {
  const route = `faqs`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchContentRating = async params => {
  const route = `ratings`
  return CONTENT.get(route, {
    params,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postContentRating = async payload => {
  const route = `ratings`
  return CONTENT.post(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const patchContentRating = async payload => {
  const { id } = payload
  const route = `ratings/${id}`
  return CONTENT.patch(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchVideoHeartbeatStatus = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `videos/${id}/heartbeat_status?parent_type=${parentType}&parent_id=${parentId}`
    : `videos/${id}/heartbeat_status`
  return VIDEOS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => {
      sendBugsnagNotification(error, 'fetchVideoHeartbeatStatus')
      return false
    })
}

const sendBugsnagNotification = (error, method) => {
  const notificationData = {
    message: `Endpoint error: ${method}`,
    data: {
      error,
      timestamp: new Date().toISOString(),
    },
  }

  Bugsnag.addMetadata('websocket_event_data', notificationData)
  Bugsnag.notify(JSON.stringify(notificationData))
}

const postCertificateCompletion = async payload => {
  const route = `/content/certificate`
  return CONTENT.post(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchTrainingHistoryItems = async payload => {
  const { type, page_size, page, status, ordering } = payload
  const { SKILL_DIVE, SKILL_CHECK } = CONSTANTS.ANALYTICS.HISTORY_CONTENT_TYPES
  const { CONTENT_STATUS, LAB_CONTENT_STATUS } = CONSTANTS.ANALYTICS

  let routePath = 'content'
  let content_type_param = `content_type=${type}`
  let filteredStatus = []

  if ([SKILL_DIVE, SKILL_CHECK].includes(type)) {
    routePath = 'labs'
    content_type_param = `lab_type=${type}`
    filteredStatus = status.filter(s =>
      Object.values(LAB_CONTENT_STATUS).includes(s)
    )
  } else {
    filteredStatus = status.filter(s =>
      Object.values(CONTENT_STATUS).includes(s)
    )
  }

  let route = `/${routePath}/completion?${content_type_param}&page_size=${page_size}&page=${page}`

  if (filteredStatus?.length) {
    filteredStatus.forEach(s => {
      route += `&status=${s}`
    })
  }
  if (ordering) route += `&ordering=${ordering}`

  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  deleteBookmark,
  fetchAllFaqs,
  fetchBookmarks,
  fetchContentIssues,
  fetchContentRating,
  fetchCoursesByIds,
  fetchExercises,
  fetchExercisesForLearningPath,
  fetchIFramesForLearningPath,
  fetchJWVideo,
  fetchLabs,
  fetchLabsForLearningPath,
  fetchTrainingHistoryItems,
  fetchQuizzes,
  fetchQuizzesForLearningPath,
  fetchRecentActivity,
  fetchVideo,
  fetchVideoHeartbeatStatus,
  fetchVideos,
  fetchVideosForBootcamp,
  fetchVideosForLearningPath,
  fetchVideosForParent,
  patchContentRating,
  postBookmark,
  postCertificateCompletion,
  postContentIssue,
  postContentRating,
  postIFrameStatus,
  postLanguageVideo,
  postLinkStatus,
  postMixpanelEvent,
  postVideoPosition,
  postVideoStatus,
}
