/* eslint-disable prettier/prettier */

import { courseIds, lpIds } from './contentIdsForImageRules'

import { Constants } from './constantInterface'

const CONSTANTS: Constants = {
  COOKIE_NAMES: {
    ACCESS_TOKEN: 'ine_access_token',
    ORIGINAL_USER_ACCESS_TOKEN: 'ine_original_access_token',
  },
  AUTH_PROVIDERS: [
    {
      color: '4185f4',
      icon:
        'https://inedev-uaa.s3.amazonaws.com/federated-login-icons/google.png',
      id: '1fcc86dc-cf5e-4eaa-88d6-02f9c6a9e0fc',
      name: 'Google',
      url: process.env.VUE_APP_GOOGLE_AUTH_URL,
    },
  ],
  ROLES: {
    INE_ADMIN: ['Admin', 'Sales', 'Support'],
    CONTENT_CREATOR: 'Content Creator',
    ACCOUNT_ADMIN: 'Account Owner',
    TEAM_ADMIN: 'Team Manager',
  },
  SUBSCRIPTIONS: {
    MANAGEABLE_STATES: ['active', 'cancelled', 'pending'],
    ACTIVE_STATE: 'active',
    PENDING_STATE: 'pending',
    FAILED_STATE: 'failed-payment',
    EXPIRED_STATE: 'expired',
    CANCELLED_STATE: 'cancelled',
    PAYPAL_PAYMENT_TYPE: 'paypal_billing_agreement',
    THREE_D_SECURE: 'three_d_secure_action_required',
  },
  PLANS: {
    ALL: {
      '2_year_premium': '2_year_premium',
      ine_premium: 'ine_premium',
      ine_ind_monthly: 'ine_ind_monthly',
      ine_ind_annual: 'ine_ind_annual',
      'ine-ind-plan-boogo-2yr': 'ine-ind-plan-boogo-2yr',
      'ine-ind-black-friday-2021-bogo-2yr':
        'ine-ind-black-friday-2021-bogo-2yr',
      ine_legacy_streaming: 'ine_legacy_streaming',
      'pta-ind-premium-defense-bundle-1yr':
        'pta-ind-premium-defense-bundle-1yr',
      fundamentals_monthly: 'fundamentals_monthly',
      fundamentals_annual: 'fundamentals_annual',
      bah_premium_plus: 'bah_premium_plus',
      premium_monthly: 'premium_monthly',
      aap_ind_premium: 'aap_ind_premium',
      aap_ind_starter: 'aap_ind_starter',
      aap_ind_starter_sp: 'aap_ind_starter_sp',
      aap_ind_starter_sp29: 'aap_ind_starter_sp29',
      aap_ind_ultimate: 'aap_ind_ultimate',
      ine_1month: 'ine_1month',
      ine_1year: 'ine_1year',
      ine_aap_2year: 'ine_aap_2year',
      monthly: 'monthly',
      'monthly-aap': 'monthly-aap',
      pro_18mon: 'pro_18mon',
      pro_2year: 'pro_2year',
      pro_3mo: 'pro_3mo',
      pro_3mo_7dt: 'pro_3mo_7dt',
      pro_6mo: 'pro_6mo',
      pro_monthly: 'pro_monthly',
      pro_monthly_3dt: 'pro_monthly_3dt',
      pro_year: 'pro_year',
      six_month_aap: 'six_month_aap',
      summer_success: 'summer_success',
      ejptv2_funda_monthly: 'ejptv2_funda_monthly',
      eda_premium: 'eda_premium',
      eda_premium_cont: 'eda_premium_cont',
      skill_dive_annual: 'skill_dive_annual',
      skill_dive_pta: 'skill_dive_pta',
      ejpt_fundamentals: 'ejpt_fundamentals',
    },
    PREMIUM: 'ine_premium',
    MONTHLY: 'fundamentals_monthly',
    ANNUAL: 'fundamentals_annual',
    EJPT_FUNDAMENTALS: 'ejpt_fundamentals',
    LEGACY_ALL_ACCESS_PASS: 'aap_ind_starter_sp',
    PREMIUM_PLANS: {
      INE_PREMIUM: 'ine_premium',
      INE_PREMIUM_BOOGO_2Y: 'ine-ind-plan-boogo-2yr',
      INE_PREMIUM_BLACK_FRIDAY_2Y: 'ine-ind-black-friday-2021-bogo-2yr',
      INE_PREMIUM_DEFENSE: 'pta-ind-premium-defense-bundle-1yr',
      INE_PREMIUM_2Y: '2_year_premium',
      INE_PREMIUM_BAH_PLUS: 'bah_premium_plus',
      INE_PREMIUM_MONTHLY: 'premium_monthly',
      INE_PREMIUM_EDA: 'eda_premium',
      INE_PREMIUM_EDA_CONT: 'eda_premium_cont',
      INE_PREMIUM_EWPT_PREMIUM: 'ewpt_premium',
      INE_PREMIUM_BOOGO_2Y_2023: 'ine_premium_2_year',
      INE_PREMIUM_ECPPT: 'ecppt_premium',
      aap_ind_premium: 'aap_ind_premium',
    },
    BUSINESS: {
      INE_B2B_PRO: 'ine_b2b_pro',
      INE_B2B_ENTERPRISE: 'ine_b2b_enterprise',
      INE_B2B_LEGACY: 'ine_b2b_legacy',
    },
    ATTACK_DEFENSE: 'ine_ind_attack_defense_lab',
    LANDING_PAGE_LINK: 'ine.com/pages/plans',
    LEGACY_PLAN_CODES: [
      'ine_1month',
      'ine_1year',
      'ine_all_access',
      'aap_ind_starter_sp',
      'ine_b2b_legacy',
      'ine_ind_monthly',
      'ine_ind_annual',
    ],
    LAB_CODES: {
      INE_LAB_EXPERIENCE: 'ine_ind_attack_defense_lab',
      CYBER_LAB_EXPERIENCE: 'ine_cyber_lab',
      NETWORKING_LAB_EXPERIENCE: 'ine_networking_lab',
      CLOUD_LAB_EXPERIENCE: 'ine_cloud_lab',
    },
    SKILL_DIVE_ANNUAL: 'skill_dive_annual',
  },
  PLANS_CANNOT_BE_UPGRADED: [
    'ejptv2_funda_monthly',
    'eda_premium',
    'eda_premium_cont',
  ],
  BUSINESS_OLD_PRICING_PLANS: ['Lab Experience', 'Enterprise + Lab Experience'],
  LAB_EXPERIENCE_PASSES: [
    'dad3362e-e158-4f0c-8988-9806e101366c', // Networking Lab Experience
    '6af79d46-486d-415e-899e-3ee005e4f76d', // Cloud Lab Experience
    '89620b29-3f24-4c59-b265-c1bc4347b7a4', // Cyber Security Lab Experience
    '77f182da-5c95-4019-ab04-21ac8cd30556', // INE Lab Experience (Bundle)
    '55cec27a-6fce-4d9c-a33d-d7cc31a2ac41', // B2B Enterprise + Lab experience
  ],
  BUSINESS_PASSES: [
    '1f92d62a-53a9-4b4d-b650-46a019b7fdfd', // B2B Enterprise
    '07bf99fe-f021-41a7-a951-0f4f577d7451', // B2B Professional
  ],
  LAB_EXPERIENCE_LEGACY_B2B_PASSES: [
    '77f182da-5c95-4019-ab04-21ac8cd30556', // INE Lab Experience (Bundle)
    '55cec27a-6fce-4d9c-a33d-d7cc31a2ac41', // B2B Enterprise + Lab experience
  ],
  LAB_EXPERIENCE_B2C_PASSES: [
    'dad3362e-e158-4f0c-8988-9806e101366c', // Networking Lab Experience
    '6af79d46-486d-415e-899e-3ee005e4f76d', // Cloud Lab Experience
    '89620b29-3f24-4c59-b265-c1bc4347b7a4', // Cyber Security Lab Experience
  ],
  LAB_EXPERIENCE_B2C_NETWORKING_PASS: 'dad3362e-e158-4f0c-8988-9806e101366c',
  LAB_EXPERIENCE_B2C_CLOUD_PASS: '6af79d46-486d-415e-899e-3ee005e4f76d',
  LAB_EXPERIENCE_B2C_CYBER_SECURITY_PASS:
    '89620b29-3f24-4c59-b265-c1bc4347b7a4',
  SKILL_DIVE_B2B_PASSES: [
    `1153818c-7a97-42d2-93ff-c84037f41311`, // B2B Skill Dive assigned with the Add-on
  ],
  SKILL_DIVE_B2C_PASSES_LEARNING_AREAS: {
    'dad3362e-e158-4f0c-8988-9806e101366c': {
      id: 'e73fd5a8-2ead-4159-9a25-38b50ad4ab20',
      name: 'Networking',
    },
    '6af79d46-486d-415e-899e-3ee005e4f76d': {
      id: 'd3f82c70-f4e7-428e-afd7-4b1d28988e18',
      name: 'Cloud',
    },
    '89620b29-3f24-4c59-b265-c1bc4347b7a4': {
      id: '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa',
      name: 'Cyber Security',
    },
  },
  PLAN_LABELS: {
    '2_year_premium': 'Premium (2 Years)',
    ine_premium: 'Premium',
    ine_ind_monthly: 'Individual Monthly',
    ine_ind_annual: 'Individual Annual',
    'ine-ind-plan-boogo-2yr': 'Boogo 2 years',
    'ine-ind-black-friday-2021-bogo-2yr': 'Black Friday 2021 2 years',
    ine_legacy_streaming: 'Legacy',
    'pta-ind-premium-defense-bundle-1yr': 'Premium Defense Bundle',
    fundamentals_monthly: 'Fundamentals Monthly',
    fundamentals_annual: 'Fundamentals Annual',
    bah_premium_plus: 'BAH Premium Plus',
    premium_monthly: 'Premium Monthly',
    aap_ind_premium: 'All access pass Premium',
    aap_ind_starter: 'All access pass starter',
    aap_ind_starter_sp: 'All access pass sp',
    aap_ind_starter_sp29: 'All access pass sp29',
    aap_ind_ultimate: 'All access pass ultimate',
    ine_1month: 'INE 1 Month',
    ine_1year: 'INE 1 Year',
    ine_aap_2year: 'INE All access pass 2 years',
    monthly: 'Monthly',
    'monthly-aap': 'Monthly All access pass',
    pro_18mon: 'Promo 18 months',
    pro_2year: 'Promo 2 years',
    pro_3mo: 'Promo 3 months',
    pro_3mo_7dt: 'Promo 3 months 7dt',
    pro_6mo: 'Promo 6 months',
    pro_monthly: 'Promo monthly',
    pro_monthly_3dt: 'Promo monthly 3dt',
    pro_year: 'Promo year',
    six_month_aap: 'Six month All access pass',
    summer_success: 'Summer success',
    ejptv2_funda_monthly: 'eJPT + Fund Monthly',
    ejpt_fundamentals: 'eJPT + 3 months of Fundamentals',
  },
  VOUCHER_LABELS: {
    'ine-ind-cert-icca-cloud-associate':
      'INE Certified Cloud Associate Exam Voucher',
    'ine-ind-cert-ejpt-v2': 'eJPT Voucher',
  },
  VOUCHERS: {
    ICCA: 'ine-ind-cert-icca-cloud-associate',
    EJPT: 'ine-ind-cert-ejpt-v2',
    EDA: 'ine-ind-cert-eeda',
    ECDFP: 'els-cert-ecdfp',
    ECIR: 'els-cert-ecir',
    ECPPT: 'els-cert-ecppt-v2',
    ECTHP: 'els-cert-ecthp-v2',
    EMAPT: 'els-cert-emapt',
    EWPTX: 'ine-ind-cert-ewptx',
  },
  VOUCHER_NAMES: {
    'ine-ind-cert-icca-cloud-associate': 'INE Certified Cloud Associate',
    'ine-ind-cert-ejpt-v2': 'Junior Penetration Tester',
    'ine-ind-cert-eeda': 'Enterprise Defense Associate',
    'ine-ind-cert-ecppt':
      'INE Security’s Certified Professional Penetration Tester',
    'ine-ind-cert-ewpt': 'Web Application Penetration Tester',
    'ine-ind-cert-ewptx': 'Web Application Penetration Tester eXtreme',
    'els-cert-emapt': 'Mobile Application Penetration Tester',
    'ine-ind-cert-ptx': 'eLearnSecurity Penetration Tester Extreme',
    'ine-ind-cert-ecdfp': 'Certified Digital Forensics Professional',
    'els-cert-ecir': 'Certified Incident Responder',
    'els-cert-ecthp-v2': 'Certified Threat Hunting Professional',
  },
  LEARNING_AREAS: {
    PRIMARY: {
      Cloud: {
        id: 'd3f82c70-f4e7-428e-afd7-4b1d28988e18',
        description:
          'Cloud Computing has transformed the IT industry by opening the possibility for near infinite or at least highly elastic scalability in the delivery of enterprise applications and software as a service (SaaS). From AWS to Google, our experts will train you to work alongside the best of the best...',
        name: 'Cloud',
        slug: 'cloud',
        communitySlug: 'cloud',
      },
      'Cyber Security': {
        id: '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa',
        description:
          'Cyber security is a constantly evolving field, with innovation quickly leading to new attack vectors and defensive tactics. Our security experts stay up to date with the latest protective measures, providing training that keeps you at the forefront of cyberdefense.',
        name: 'Cyber Security',
        slug: 'cyber-security',
        communitySlug: 'cyber-security',
      },
      'Data Science': {
        id: 'db4652c5-2a80-4be8-a8f1-8852cf0e5230',
        description:
          'Conclusive data is a valuable commodity for companies, but how do we make sense of it all? Data Science compiles numerous strategies to extract and utilize information. Quality data helps companies to provide better products and services. Let our expert instructors guide you through the ins and outs of Machine Learning and other useful Data Science tools to set your team up for success.',
        name: 'Data Science',
        slug: 'data-science',
        communitySlug: 'data-science',
      },
      Networking: {
        id: 'e73fd5a8-2ead-4159-9a25-38b50ad4ab20',
        description:
          'Maintain communication throughout the system by expertly navigating the ins and outs of Networking. Earn your CCIE certification, expand your knowledge of Routing & Switching, or conquer Cisco wireless technology. Our instructors are plugged in to the best, most advanced Networking options today. Find your path here.',
        name: 'Networking',
        slug: 'networking',
        communitySlug: 'networking',
      },
    },
    SECONDARY: {
      Development: {
        id: '47306190-b47d-46d5-bbf7-06ab3cdca942',
        description:
          'Great ideas require great tools in order to become reality. Understanding the art and nuance of software development gives you an advantage that will elevate your work and opportunities. Train with top architects who will guide you through Java, Python, and more.',
        name: 'Development',
        slug: 'development',
        communitySlug: 'dev',
      },
      'IT Essentials': {
        id: 'ff0cb823-5bec-4b3a-b096-415dcf6c0adb',
        description:
          'The daily maintenance of technology products requires a broad range of knowledge and skill.  Strengthen your foundation in the basics with our experts who know them inside and out.',
        name: 'IT Essentials',
        slug: 'it-essentials',
        communitySlug: 'it',
      },
    },
    COLORS: {
      'e73fd5a8-2ead-4159-9a25-38b50ad4ab20': '#FAD740',
      'db4652c5-2a80-4be8-a8f1-8852cf0e5230': '#66C19A',
      '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa': '#BB85FF',
      'd3f82c70-f4e7-428e-afd7-4b1d28988e18': '#88A9FF',
      'ff0cb823-5bec-4b3a-b096-415dcf6c0adb': '#F647C0',
      '47306190-b47d-46d5-bbf7-06ab3cdca942': '#D73F47',
    },
  },
  TRAINING_GOALS: {
    regularHabit: {
      id: 'a2fb8966-45d2-47c1-968d-0651fc82ac7b',
      slug: 'want_to_make_training_regular_habit',
      description: 'I want to make training a regular habit',
      hasTextIntput: false,
    },
    skillInTopic: {
      id: 'a2bda513-170a-488d-9ac6-6ef15cca065b',
      slug: 'want_to_up_skill_in_topic',
      description: 'I want to up-skill in a particular topic',
      hasTextIntput: false,
    },
    getCertified: {
      id: '88c8fc24-2f50-4c91-93e0-47728b296da0',
      slug: 'want_to_get_certified',
      description: 'I want to get certified',
      hasTextIntput: false,
    },
    nextLevel: {
      id: 'd21f49d3-f107-4d78-98ce-3c3d5bd5907a',
      slug: 'want_to_take_career_to_next_level',
      description: 'I want to take my career to the next level',
      hasTextIntput: false,
    },
    other: {
      id: '62be4833-c4fc-4250-bc56-b7914ce51df7',
      slug: 'other_training_goals',
      description: 'Other',
      hasTextIntput: true,
      inputPlaceholder: 'Tell us about your training goals',
    },
  },
  ROUTING_RULES: {
    REDIRECT_IF_AUTHENTICATED: ['login', 'reset-password', 'forgot-password'],
    NAVBAR_HIDDEN: [
      'login',
      'reset-password',
      'signup',
      'admin',
      'two-factor',
      'forgot-password',
      'reset-password',
      'update-password',
      'starter-pass',
      'register',
      'registration-complete',
      'invitation-landing',
      'certificate-verified',
      'maintenance',
    ],
    DOORBELL_FEEDBACK: ['sonar', 'sonar-share', 'sonar-quiz'],
  },
  ALGOLIA_FACETS: {
    // TODO - keep moving algolia facets to constants
    CONTENT_TYPE: 'content_type',
    HAS_LABS: 'has_labs',
    HAS_CC: 'has_cc',
    HAS_QUIZZES: 'has_quizzes',
    IS_POPULAR: 'is_popular',
    IS_TRENDING: 'is_trending',
    HAS_SAMPLE_CONTENT: 'has_sample_content',
    HAS_CERTIFICATIONS: 'has_certifications',
    IS_LAB_EXPERIENCE: 'is_lab_experience',
  },
  SEARCH: {
    CONTEXTS: ['Courses', 'Learning Paths', 'Videos', 'Bootcamps', 'Labs'],
    FEATURES: [
      'is_popular',
      'is_trending',
      'has_quizzes',
      'has_labs',
      'has_cc',
      'has_sample_content',
      'has_certifications',
    ],
    SKILL_DIVE: ['is_lab_experience'],
    FILTER_MAP: {
      'learning-path': 'Learning Path',
      content_type: 'Content Type',
      feature: 'Features',
      course: 'Course',
      bootcamp: 'Bootcamp',
      video: 'Video',
      lab: 'Lab',
      has_quizzes: 'Has Quizzes',
      has_labs: 'Hands-on content',
      is_popular: 'Popular',
      is_trending: 'Trending',
      has_cc: 'Has closed captioning',
      'learning_areas.name': 'Learning Areas',
      'categories.name': 'Categories',
      'instructors.name': 'Instructors',
      'instructor.name': 'Instructor',
      difficulty: 'Difficulties',
      has_sample_content: 'Included with Starter Pass',
      has_certifications: 'Certification Prep',
      is_lab_experience: 'Skill Dive',
      severity: 'Vulnerability Severity',
      'tags.name': 'Tags',
      classification: 'CVE Classification',
      year: 'Year',
      'vendor.name': 'vendor',
    },
    AVAILABLE_FACETS: {
      CONTENT: [
        'content_type',
        'is_lab_experience',
        'learning_areas.name',
        'categories.name',
        'instructors.name',
        'difficulty',
        'has_labs',
        'has_cc',
        'has_certifications',
      ],
      Courses: [
        'learning_areas.name',
        'categories.name',
        'instructors.name',
        'difficulty',
        'is_popular',
        'is_trending',
        'has_quizzes',
        'has_labs',
        'has_cc',
        'has_sample_content',
      ],
      'Learning Paths': [
        'learning_areas.name',
        'categories.name',
        'instructors.name',
        'difficulty',
        'has_certifications',
      ],
      Videos: [
        'instructor.name',
        'learning_areas.name',
        'categories.name',
        'difficulty',
      ],
      Bootcamps: ['instructors.name', 'learning_areas.name'],
      Labs: [
        'instructor.name',
        'learning_areas.name',
        'categories.name',
        'difficulty',
        'severity',
      ],
    },
    ALGOLIA_KEYS: {
      appKey: process.env.VUE_APP_ALGOLIA_APP_KEY,
      appId: process.env.VUE_APP_ALGOLIA_APP_ID,
      searchIndex: process.env.VUE_APP_ALGOLIA_SEARCH_INDEX,
      searchIndexNameAsc: process.env.VUE_APP_ALGOLIA_SEARCH_INDEX_NAME_ASC,
      searchIndexNameDesc: process.env.VUE_APP_ALGOLIA_SEARCH_INDEX_NAME_DESC,
      searchIndexCpeDesc: process.env.VUE_APP_ALGOLIA_SEARCH_INDEX_CPE_DESC,
      coursesIndex: process.env.VUE_APP_ALGOLIA_COURSES_INDEX,
      learningPathsIndex: process.env.VUE_APP_ALGOLIA_PLAYLISTS_INDEX,
      videosIndex: process.env.VUE_APP_ALGOLIA_VIDEOS_INDEX,
      bootcampsIndex: process.env.VUE_APP_ALGOLIA_BOOTCAMPS_INDEX,
      labsIndex: process.env.VUE_APP_ALGOLIA_LABS_INDEX,
    },
    PARENTS: {
      course: 'Courses',
      learning_path: 'Learning Paths',
      bootcamp: 'Bootcamps',
      videos: 'Videos',
      lab: 'Labs',
    },
  },
  IMAGE_RULES: {
    LEARNING_AREA_HEROES_URL:
      'https://s3.amazonaws.com/my.ine.com/assets/learning-areas/heroes',
    COURSE_IDS_WITH_LEGACY_BACKGROUNDS: courseIds,
    LEARNING_PATH_IDS_WITH_LEGACY_BACKGROUNDS: lpIds,
  },
  CONTENT_USER_STATUS: {
    FINISHED: 'finished',
    UNSTARTED: 'unstarted',
    STARTED: 'started',
    VISITED: 'visited',
  },
  CONTENT_TYPES: {
    BOOTCAMP: 'bootcamp',
    COURSE: 'course',
    EXERCISE_GROUP: 'exercise_group',
    EXERCISE: 'exercise',
    IFRAME: 'iframe',
    LAB: 'lab',
    LEARNING_PATH: 'learning-path',
    QUIZ: 'quiz',
    SLIDES: 'slides',
    VIDEO: 'video',
    IMAGE: 'image',
    EXAM: 'attempt',
    CERTIFICATION: 'certification',
  },
  CONTENT_TYPE_TITLES: {
    bootcamp: 'Bootcamp',
    course: 'Course',
    lab: 'Lab',
    'learning-path': 'Learning Path',
    quiz: 'Quiz',
    slide: 'Slide',
    video: 'Video',
  },
  PLAYLIST_CONTENT_TYPES: {
    COURSE: 'course',
    LINK: 'link',
    LEARNING_PATH: 'learning-path',
    VIDEO: 'video',
    LAB: 'lab',
  },
  PLAYLIST_TYPES: {
    B2B: 'B2B',
    SONAR_RECOMMENDED: 'sonar_recommended',
    SONAR_GOAL: 'sonar_goal',
  },
  BOOTCAMP_REGISTRATION_STATUS: {
    OPEN: 'open',
    COMING_SOON: 'coming_soon',
    CLOSED: 'closed',
    FINISHED: 'finished',
  },
  BOOTCAMP_CONTENT_STATUS: {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    ARCHIVED: 'archived',
    UNPUBLISHED: 'unpublished',
    CONTENT_COMING_SOON: 'content_coming_soon',
    CONTENT_PUBLISHED: 'content_published',
  },
  KNOWN_API_ERRORS: {
    REQUIRES_REAUTHENTICATION: [
      "Unexpected response status from UAA, got: '401'",
      "Unexpected response status from UAA, got: '404'",
    ],
    PHP: [
      'Invalid username or password.',
      'Account is suspended',
      'Invalid refresh token',
      'Billing information not found',
      'The transaction was declined. Please use a different card or contact your bank.',
      'Billing account not found',
      'Unauthenticated.',
      'User is not yet confirmed',
      'User already confirmed',
      'Incorrect MFA code',
      'Unauthorized',
      'You do not have a phone number on your account',
      'There was a problem uploading your avatar file',
      'MFA code expired',
      'User not found',
      'Cannot upgrade this subscription',
      'Your MFA code has already been validated',
      'MFA required',
      'Confirmation code has expired',
    ],
    PYTHON: [
      'Not found.',
      'Resource not found',
      'You do not have permission to perform this action.',
      "Unexpected response status from UAA, got: '401'",
      "Unexpected response status from UAA, got: '403'",
      "Unexpected response status from UAA, got: '404'",
      'Unauthenticated.',
      'Authentication credentials were not provided.',
      'Invalid Bearer token',
      'Session status must be "started" in order to stop it',
      'Error trying to signup',
    ],
    CODES: {
      USER_NOT_CONFIRMED: 'user_not_confirmed',
      COGNITO_PASSWORD_RESET_REQUIRED: 'cognito_password_reset_required',
    },
  },
  FRIENDLY_ERRORS: {
    'Resource not found':
      'We were unable to locate this content. It may have been moved, deleted, or does not exist.',
    'Invalid username or password.':
      'We were unable to log you in with the username and password provided. Please check your entries and try again.',
    'Account is suspended':
      'Your account has been suspended. Please contact INE support for assistance.',
    'User is not yet confirmed':
      'Please check your email for a registration email with instructions on how to confirm your email address. Otherwise, please contact INE support',
    'Authentication credentials were not provided.':
      'You must be logged in to do that.',
    'Cannot upgrade this subscription':
      'Unfortunately, your subscription cannot currently be upgraded. Please contact INE support to complete the purchase using the Support link at the bottom of the page.',
    'Error trying to signup': 'The email has already been taken',
  },
  HOME_PAGE: {
    FALLBACK_CONTENT: {
      LEARNING_PATH: {
        id:
          process.env.VUE_APP_ENVIRONMENT === 'development' ||
          process.env.VUE_APP_ENVIRONMENT === 'local'
            ? 'ee9ff705-2f7b-496f-8e04-dfdacbd0cc9c'
            : '019938d9-11cf-459b-b8ee-e662e10515f2',
        slug: 'exploit-development-student',
        name: 'Exploit Development Student',
      },
      COURSE: {
        id: 'eb1c83e7',
        name: 'Linux Exploit Development',
        slug: 'linux-exploit-development',
      },
    },
    CAROUSEL_METRICS: [
      {
        name: 'Popular',
        facet: 'is_popular',
        value: 'true',
      },
      {
        name: 'New',
        facet: 'status',
        value: 'published',
      },
      {
        name: 'Trending',
        facet: 'is_trending',
        value: 'true',
      },
    ],
  },
  VIDEO_PLAYBACK_PREFERENCES: {
    DEFAULTS: {
      isFullscreen: false,
      playbackSpeed: 1,
      volume: 90,
      isMuted: false,
    },
    STORAGE_KEY: 'videoPlaybackPreferences',
  },
  VIDEO_EVENTS: {
    READY: 'ready',
    PLAY: 'play',
    TIME: 'time',
    PAUSE: 'pause',
    SEEK: 'seek',
    SEEKED: 'seeked',
    COMPLETE: 'complete',
  },
  INTERESTS: [
    'a981cebe-f448-11ea-beed-12fac9350b1f',
    '7c38c72d-edf8-3b06-a058-22ef7b4c7025',
    '302eec08-0511-4c87-b2dc-816b46812959',
    '2399d6b6-70bd-3413-b4c7-ffa300ef17b1',
  ],
  PARENT_CONTENT_TYPES: [
    'course',
    'learning-path',
    'learning-area',
    'bootcamp',
    'certification',
    'playlist',
    'assessment',
  ],
  LEARNING_PATH_SHORT_SLUG: 'path',
  PARENT_CONTENT_TYPES_KEYS: {
    COURSE: 'course',
    LEARNING_PATH: 'learning-path',
    BOOTCAMP: 'bootcamp',
    CERTIFICATION: 'certification',
    PLAYLIST: 'playlist',
    SONAR: 'sonar',
    EXAM_ATTEMPT: 'userexamattempt',
    ASSESSMENT: 'assessment',
    COLLECTION: 'collection',
  },
  CONTENT_STATUS: {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    ARCHIVED: 'archived',
    UNPUBLISHED: 'unpublished',
    COMING_SOON: 'coming_soon',
  },
  CERTIFICATES: {
    TYPES: {
      INE: 'ine',
      ELS: 'els',
      EDA: 'eda',
    },
  },
  CERTIFICATIONS: {
    IDS: {
      CFAzure: 'c9ca5cf4-6230-480d-9ab4-77c40a1792fc',
      CFC: 'c9ca5cf4-6230-480d-9ab4-77c40a1792fb',
      EDA: '3f567abf-193d-4889-a384-1b6fa636469a',
      CAC: '317c1b9c-0994-4fca-b973-ccc4e645009d',
      eJPT: 'f60cc015-3448-4f45-a84d-ddf068def165',
      ICCA: 'a66a3dce-36b1-4eac-8be1-7f7216b510d0',
      CFAWS: 'f102d58a-960b-48ed-826c-a62952e5b0ce',
      ECPPT: '2abdf5ff-0689-4502-a770-082917235cd5',
      CDFP: '27a2e156-2728-42ab-8115-3f3cfc597616',
      CDFP_old: '5c3b4ec8-2b7c-49f4-95e8-303e13efe240',
      eWPT: '026cf09a-f245-44a0-a128-27b536ea695d', // new eWPT in my.ine.com
      eWPT_old: 'ce616fe5-9994-4c95-adfa-fb21c124ec15', // old eWPT in my.ine.com'
      MCIT: '7144bdf7-eab3-41b0-8472-7e884615f815',
      EWPTX_old: '76003cbd-4191-4c17-aadf-cfb0d8eef909',
      eWPTX: '07b2b8c2-7c12-4336-be78-719d1ea1824a',
    },
    CANT_EXTEND: ['7144bdf7-eab3-41b0-8472-7e884615f815'],
    CANT_SHOW: ['7144bdf7-eab3-41b0-8472-7e884615f815'],
    ATTEMPT_STATUS: {
      IN_PROGRESS: 'in_progress',
      REVIEW: 'review',
      FINISHED: 'finished',
      TIMEDOUT: 'timedout',
    },
    STATUS: {
      DRAFT: 'draft',
      PUBLISHED: 'published',
      ARCHIVED: 'archived',
      UNPUBLISHED: 'unpublished',
      COMING_SOON: 'coming_soon',
    },
    ALERTS: {
      ALERT_AT_10: 'cert-alert-10',
      ALERT_AT_30: 'cert-alert-30',
      ALERT_AT_45: 'cert-alert-45',
    },
    PRACTICE_STATUS: {
      IDDLE: 'Not sent',
      SUBMITTING: 'Submitting',
      SUBMITTED: 'Submitted',
      ERROR: 'Error',
    },
    USER_STATUS: {
      ATTEMPT_IN_PROGRESS: 'attempt_in_progress',
      NO_VOUCHERS: 'no_vouchers',
      ATTEMPT_IN_REVIEW: 'pending_review',
      AWARDED: 'awarded',
      CAN_DO_RETAKE: 'can_do_a_retake',
      READY_FOR_FIRST_ATTEMPT: 'ready_for_first_attempt',
      NOT_AVAILABLE: 'not_available',
    },
    SHORT_NAMES: {
      'c9ca5cf4-6230-480d-9ab4-77c40a1792fc': 'CFAzure',
      'c9ca5cf4-6230-480d-9ab4-77c40a1792fb': 'CFC',
      '3f567abf-193d-4889-a384-1b6fa636469a': 'EDA',
      '317c1b9c-0994-4fca-b973-ccc4e645009d': 'CAC',
      'f60cc015-3448-4f45-a84d-ddf068def165': 'eJPT',
      'a66a3dce-36b1-4eac-8be1-7f7216b510d0': 'ICCA',
      'f102d58a-960b-48ed-826c-a62952e5b0ce': 'CFAWS',
      '7144bdf7-eab3-41b0-8472-7e884615f815': 'MCIT',
      '2abdf5ff-0689-4502-a770-082917235cd5': 'ECPPT',
      '27a2e156-2728-42ab-8115-3f3cfc597616': 'CDFP',
      '5c3b4ec8-2b7c-49f4-95e8-303e13efe240': 'CDFP', //CDFP_old
      '026cf09a-f245-44a0-a128-27b536ea695d': 'eWPT',
      'ce616fe5-9994-4c95-adfa-fb21c124ec15': 'eWPT', //eWPT_old
    },
    IDS_MERCHANT_IDS: {
      'a66a3dce-36b1-4eac-8be1-7f7216b510d0':
        'ine-ind-cert-icca-cloud-associate', //ICCA
      'f60cc015-3448-4f45-a84d-ddf068def165': 'ine-ind-cert-ejpt-v2', // eJPT
      '27a2e156-2728-42ab-8115-3f3cfc597616': 'ine-ind-cert-ecdfp', // new eCDFP
      'f95ab494-ed74-4902-84e6-7f069aa38faa': 'els-cert-ecir', //eCIR
      '2abdf5ff-0689-4502-a770-082917235cd5': 'ine-ind-cert-ecppt', //eCPPT
      'edf85741-ce19-4e62-81cb-f4b88d12b83f': 'els-cert-ecthp-v2', //eCTHP
      'cc011802-99c5-401f-80a5-8fd104bfabc4': 'els-cert-emapt', //eMAPT
      '07b2b8c2-7c12-4336-be78-719d1ea1824a': 'ine-ind-cert-ewptx', //eWPTX
      '3f567abf-193d-4889-a384-1b6fa636469a': 'ine-ind-cert-eeda', //eEDA
      '026cf09a-f245-44a0-a128-27b536ea695d': 'ine-ind-cert-ewpt', // eWPT
      '7144bdf7-eab3-41b0-8472-7e884615f815': 'ine-ind-cert-mcit', //MCIT
    },
    CERT_MERCHANT_PLAN_MERCHANT: {
      'ine-ind-cert-eeda': 'eda_premium', //eEDA
      'ine-ind-cert-ewpt': 'ewpt_premium', // eWPT
      'ine-ind-cert-ecppt': 'ecppt_premium', //eCPPT
      'ine-ind-cert-ejpt-v2': 'ejpt_fundamentals', // eJPT
    },

    SLUGS: {
      DEFAULT: {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
      },
      'a66a3dce-36b1-4eac-8be1-7f7216b510d0': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-icca-cloud-associate',
          },
        },
        BETA: {
          REDIRECT_URL: process.env.VUE_APP_INFO_URL,
          PATH: '/ejptv2-beta/',
        },
      },
      '317c1b9c-0994-4fca-b973-ccc4e645009d': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
      },
      // eJPT
      'f60cc015-3448-4f45-a84d-ddf068def165': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-ejpt-v2',
            PLAN: 'ejptv2_funda_monthly',
          },
        },
      },
      // CDFP
      '5c3b4ec8-2b7c-49f4-95e8-303e13efe240': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-ecdfp',
          },
        },
      },
      // new CDFP
      '27a2e156-2728-42ab-8115-3f3cfc597616': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-ecdfp',
          },
        },
      },
      // CIR
      'f95ab494-ed74-4902-84e6-7f069aa38faa': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-ecir',
          },
        },
      },
      // CPPT
      '32ab0741-05f3-4e34-97ae-d252b9f11298': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-ecppt-v2',
          },
        },
      },
      // CTHP
      'edf85741-ce19-4e62-81cb-f4b88d12b83f': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-ecthp-v2',
          },
        },
      },
      // MAPT
      'cc011802-99c5-401f-80a5-8fd104bfabc4': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-emapt',
          },
        },
      },
      // eWPTX
      '07b2b8c2-7c12-4336-be78-719d1ea1824a': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-ewptx',
            PLAN: 'ewptx_premium',
          },
        },
      },
      // old WAPT
      'ce616fe5-9994-4c95-adfa-fb21c124ec15': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'els-cert-ewpt',
          },
        },
      },
      // EDA
      '3f567abf-193d-4889-a384-1b6fa636469a': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-eeda',
            PLAN: 'eda_premium',
          },
        },
      },
      // new WPT
      '026cf09a-f245-44a0-a128-27b536ea695d': {
        REDIRECT_URL: process.env.VUE_APP_CHECKOUT_URL,
        PARAMS: {
          KEY: '?product_selected=',
          VALUES: {
            VOUCHER: 'ine-ind-cert-ewpt',
            PLAN: 'ewpt_premium',
          },
        },
      },
    },
    RENEWAL_STATUS: {
      EARLY: 'early',
      EXPIRED: 'expired',
      TO_EXPIRE: 'to_expire',
      GRACE_PERIOD: 'grace_period',
      RENEWAL_AVAILABLE: 'renewal_available',
    },
  },
  LABS: {
    FIXED_PARAMS: {
      WAITING_TIME: 25,
    },
    SESSION_STATUS: {
      IDDLE: 'iddle',
      UNSTARTED: 'unstarted',
      STARTING: 'starting',
      STARTED: 'started',
      STOPPING: 'stopping',
      STOPPED: 'stopped',
      TERMINATING: 'terminating',
      TERMINATED: 'terminated',
      ERROR: 'error',
    },
    REGIONS: [
      'US-East',
      'US-West',
      'EU-Germany',
      'Asia-Singapore',
      'Asia-India',
      'ME-Bahrain',
    ],
    ACCESS_TYPES: {
      WEB: 'web',
      DEFAULT: 'default',
    },
    EXTEND_STATUSES: {
      SUCCESS: 'success',
      PENDING: 'pending',
      ERROR: 'error',
    },
    EXTENSION_START_TIME: 300,
    EXTENSION_END_TIME: 40,
  },
  PROMOTIONS: {
    PLACES: {
      HOMEPAGE_TOP: 'homepage_top_banner',
      SUBSCRIPTION_TOP: 'subscriptions_view_top_banner',
    },
    CODES: {
      LEGACY_ADD_YEAR: 'legacy-upgrade-to-premium-and-add-a-year-for-500',
      EXISTING_ADD_YEAR: 'add-a-year-to-existing-promo-for-500',
      LEGACY_EOY_ADD_YEAR: 'a5c1-0242ac170003-upgrade-premium-add-year',
      EXISTING_EOY_ADD_YEAR: 'a5c1-0242ac170003-premium-add-year',
    },
  },
  COMMUNITY_BASE_URL: 'https://community.ine.com/c/',
  MARKETING_WEBSITE_DIRECTORY_BASE_URL: 'https://ine.com/learning',
  PROFILE: {
    AVATAR_FILE_SIZE_LIMIT: 10485760,
    DEFAULT_AVATAR: [
      'defaults/default.png',
      'defaults/default_icon.png',
      'avatars/QzZGUtUVyMQVoYfwgQnrmSGkTrdE191IhWqn6prr.png',
    ],
    MFA: {
      GOOGLE_AUTHENTICATOR: 'google_auth',
    },
    ONBOARDING: {
      STEPS: {
        PROFILE_PHOTO: 'profile_photo',
        INTEREST_AREA: 'interest_area',
        BACKUP_EMAIL: 'backup_email',
        TRAINING_GOALS: 'training_goals',
      },
      ITEMS_IDS: {
        AVATAR: 'avatar',
        LEARNING_AREAS: 'learning_areas',
        BACKUP_EMAIL: 'backup_email',
        TRAINING_GOALS: 'training_goals',
      },
    },
  },
  PROFILE_COLORS: {
    purple: {
      dark: '#7965C6',
      base: '#A793F6',
      light: '#C4B7F9',
      lighter: '#D3C9FA',
    },
    blue: {
      dark: '#0F305C',
      base: '#3E6496',
      light: '#95B2D7',
      lighter: '#C1D8F8',
    },
    orange: {
      dark: '#D0803F',
      base: '#F19E5B',
      light: '#F6BE92',
      lighter: '#F8CEAD',
    },
    red: {
      dark: '#A43238',
      base: '#D73F47',
      light: '#DE5F66',
      lighter: '#EB9FA3',
    },
    green: {
      dark: '#4A857F',
      base: '#65AAA3',
      light: '#98C6C2',
      lighter: '#B2D4D1',
    },
    yellow: {
      dark: '#AA8F37',
      base: '#E8BF39',
      light: '#EFD47B',
      lighter: '#F3DF9C',
    },
    violet: {
      dark: '#69325B',
      base: '#9F6490',
      light: '#BF97B5',
      lighter: '#CFB1C7',
    },
    pink: {
      dark: '#BD717B',
      base: '#FD8897',
      light: '#FEAFBA',
      lighter: '#FEC3CB',
    },
    beige: {
      dark: '#83746F',
      base: '#ABA09C',
      light: '#C7BFBD',
      lighter: '#D5CFCD',
    },
  },
  JW_PLAYER: {
    MEDIA_URL: 'https://cdn.jwplayer.com/v2/media/',
  },
  YEAR_MONTHS: [
    {
      number: 1,
      label: 'January',
      days: 31,
    },
    {
      number: 2,
      label: 'February',
      days: 29,
    },
    {
      number: 3,
      label: 'March',
      days: 31,
    },
    {
      number: 4,
      label: 'April',
      days: 30,
    },
    {
      number: 5,
      label: 'May',
      days: 31,
    },
    {
      number: 6,
      label: 'June',
      days: 30,
    },
    {
      number: 7,
      label: 'July',
      days: 31,
    },
    {
      number: 8,
      label: 'August',
      days: 31,
    },
    {
      number: 9,
      label: 'September',
      days: 30,
    },
    {
      number: 10,
      label: 'October',
      days: 31,
    },
    {
      number: 11,
      label: 'November',
      days: 30,
    },
    {
      number: 12,
      label: 'December',
      days: 31,
    },
  ],
  SPECIAL_LEARNING_PATHS: {
    SECURITY_LAB_EXPERIENCE: 'adf83ef3-b9ac-4383-9f13-4ac870d0130e',
    NETWORKING_LAB_EXPERIENCE: 'b3893234-39e3-487f-9ec8-dae827f5305e',
    CLOUD_LAB_EXPERIENCE: 'c5ff8786-4c70-484a-93d7-cec81e69505a',
  },
  LABS_COLLECTIONS: {
    SECURE_CODING: '1f3076be-9446-4cb2-bc4b-aaccdc35f65b',
    VULNERABILITIES: 'bd6f07ba-b00e-4a43-afa5-6b2bdc3a25d8',
  },
  EXPERTISE_LEVELS: {
    NOVICE: 'Novice',
    PROFESSIONAL: 'Professional',
    ADVANCED: 'Advanced',
    EXPERT: 'Expert',
    NOT_SURE: 'Not Sure',
  },
  EXPERTISE_LEVELS_SLUGS: [
    { areaName: 'Cloud', slug: 'learning_areas_cloud_level' },
    { areaName: 'Cyber Security', slug: 'learning_areas_cyber_security_level' },
    { areaName: 'Data Science', slug: 'learning_areas_data_science_level' },
    { areaName: 'Networking', slug: 'learning_areas_networking_level' },
  ],
  SURVEY_INTERESTS: [
    {
      id: '2399d6b6-70bd-3413-b4c7-ffa300ef17b1',
      description:
        'Cloud Computing has transformed the IT industry by opening the possibility for near infinite or at least highly elastic scalability in the delivery of enterprise applications and software as a service (SaaS). From AWS to Google, our experts will train you to work alongside the best of the best...',
      name: 'Cloud',
      slug: 'cloud',
      communitySlug: 'cloud',
    },
    {
      id: 'a981cebe-f448-11ea-beed-12fac9350b1f',
      description:
        'Cyber security is a constantly evolving field, with innovation quickly leading to new attack vectors and defensive tactics. Our security experts stay up to date with the latest protective measures, providing training that keeps you at the forefront of cyberdefense.',
      name: 'Cyber Security',
      slug: 'cyber-security',
      communitySlug: 'cyber-security',
    },
    {
      id: '302eec08-0511-4c87-b2dc-816b46812959',
      description:
        'Conclusive data is a valuable commodity for companies, but how do we make sense of it all? Data Science compiles numerous strategies to extract and utilize information. Quality data helps companies to provide better products and services. Let our expert instructors guide you through the ins and outs of Machine Learning and other useful Data Science tools to set your team up for success.',
      name: 'Data Science',
      slug: 'data-science',
      communitySlug: 'data-science',
    },
    {
      id: '7c38c72d-edf8-3b06-a058-22ef7b4c7025',
      description:
        'Maintain communication throughout the system by expertly navigating the ins and outs of Networking. Earn your CCIE certification, expand your knowledge of Routing & Switching, or conquer Cisco wireless technology. Our instructors are plugged in to the best, most advanced Networking options today. Find your path here.',
      name: 'Networking',
      slug: 'networking',
      communitySlug: 'networking',
    },
  ],
  LANGUAGE_CODES: [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Assamese', value: 'as' },
    { label: 'Aymara', value: 'ay' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bambara', value: 'bm' },
    { label: 'Basque', value: 'eu' },
    { label: 'Belarusian', value: 'be' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bhojpuri', value: 'bho' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Cebuano', value: 'ceb' },
    { label: 'Chinese (Simplified)', value: 'zh-CN' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Corsican', value: 'co' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dhivehi', value: 'dv' },
    { label: 'Dogri', value: 'doi' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Esperanto', value: 'eo' },
    { label: 'Estonian', value: 'et' },
    { label: 'Ewe', value: 'ee' },
    { label: 'Filipino (Tagalog)', value: 'fil' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'Frisian', value: 'fy' },
    { label: 'Galician', value: 'gl' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Guarani', value: 'gn' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hawaiian', value: 'haw' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hmong', value: 'hmn' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Igbo', value: 'ig' },
    { label: 'Ilocano', value: 'ilo' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Javanese', value: 'jv' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Khmer', value: 'km' },
    { label: 'Kinyarwanda', value: 'rw' },
    { label: 'Konkani', value: 'gom' },
    { label: 'Korean', value: 'ko' },
    { label: 'Krio', value: 'kri' },
    { label: 'Kurdish', value: 'ku' },
    { label: 'Kurdish (Sorani)', value: 'ckb' },
    { label: 'Kyrgyz', value: 'ky' },
    { label: 'Lao', value: 'lo' },
    { label: 'Latin', value: 'la' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lingala', value: 'ln' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Luganda', value: 'lg' },
    { label: 'Luxembourgish', value: 'lb' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Maithili', value: 'mai' },
    { label: 'Malagasy', value: 'mg' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Maori', value: 'mi' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Meiteilon (Manipuri)', value: 'mni-Mtei' },
    { label: 'Mizo', value: 'lus' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Myanmar (Burmese)', value: 'my' },
    { label: 'Nepali', value: 'ne' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Nyanja (Chichewa)', value: 'ny' },
    { label: 'Odia (Oriya)', value: 'or' },
    { label: 'Oromo', value: 'om' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Persian', value: 'fa' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Portugal, Brazil)', value: 'pt' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Quechua', value: 'qu' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Samoan', value: 'sm' },
    { label: 'Sanskrit', value: 'sa' },
    { label: 'Scots Gaelic', value: 'gd' },
    { label: 'Sepedi', value: 'nso' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sesotho', value: 'st' },
    { label: 'Shona', value: 'sn' },
    { label: 'Sindhi', value: 'sd' },
    { label: 'Sinhala (Sinhalese)', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Sundanese', value: 'su' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tagalog (Filipino)', value: 'tl' },
    { label: 'Tajik', value: 'tg' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Tatar', value: 'tt' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Tigrinya', value: 'ti' },
    { label: 'Tsonga', value: 'ts' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Turkmen', value: 'tk' },
    { label: 'Twi (Akan)', value: 'ak' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uyghur', value: 'ug' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' },
    { label: 'Xhosa', value: 'xh' },
    { label: 'Yiddish', value: 'yi' },
    { label: 'Yoruba', value: 'yo' },
    { label: 'Zulu', value: 'zu' },
  ],
  SONAR: {
    ROUTES: {
      HOME: 'sonar',
      SONAR_QUIZ: 'sonar-quiz',
    },
    ASSESSMENT: {
      STATUS: {
        STARTED: 'started',
        FINISHED: 'finished',
        ABORTED: 'aborted',
        TIMECAPED: 'timecaped',
      },
      SCORE_PRESERVATION: {
        STATUS: {
          OUTDATED: 'outdated',
        },
      },
      CONTENT_STATUS: {
        DRAFT: 'draft',
        PUBLISHED: 'published',
        ARCHIVED: 'archived',
        UNPUBLISHED: 'unpublished',
        COMING_SOON: 'coming_soon',
      },
      DIFFICULTY: {
        NOVICE: 'novice',
        PROFESSIONAL: 'professional',
        ADVANCED: 'advanced',
      },
      LEVEL_STATUS: {
        LOCKED: 'locked',
        UNLOCKED: 'unlocked',
        PASSED: 'passed',
      },
      ANSWER: {
        DONT_KNOW: 'na',
      },
      STEP: {
        SUMMARY: 'summary',
        ASSESSMENT: 'assessment',
        RESULTS: 'results',
      },
      DEFAULT_TIMECAP: 3600,
      MIN_WIDTH: 1250,
    },
    LEARNING_AREAS: {
      'Cyber Security': {
        id: '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa',
        name: 'Cyber Security',
        slug: 'cyber-security',
        disabled: false,
      },
      Networking: {
        id: 'e73fd5a8-2ead-4159-9a25-38b50ad4ab20',
        name: 'Networking',
        slug: 'networking',
        disabled: false,
      },
      Cloud: {
        id: 'd3f82c70-f4e7-428e-afd7-4b1d28988e18',
        name: 'Cloud',
        slug: 'cloud',
        disabled: false,
      },
    },
    QUESTION_TYPES: {
      INPUT: 'input',
    },
  },
  LAB_EXPERIENCE: {
    LEARNING_AREAS: [
      {
        id: '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa',
        name: 'Cyber Security',
        slug: 'cyber-security',
      },
      {
        id: 'e73fd5a8-2ead-4159-9a25-38b50ad4ab20',
        name: 'Networking',
        slug: 'networking',
      },
      {
        id: 'd3f82c70-f4e7-428e-afd7-4b1d28988e18',
        name: 'Cloud',
        slug: 'cloud',
      },
    ],
  },
  SKILL_DIVE: {
    SKILL_DIVE_ANNUAL: 'skill_dive_annual',
    SKILL_DIVE_PTA: 'skill_dive_pta',
    ITEM_CODES: {
      skill_dive_cyber: 'Cyber Security',
      skill_dive_cloud: 'Cloud',
      skill_dive_networking: 'Networking',
    },
    SKILL_DIVE_ADD_HOURS: 'skill_dive_20_hours',
    SKILL_DIVE_ADD_HOURS_MIN_VALUE: 20,
  },
  KEYBOARD_LAYOUTS: [
    {
      country: 'Brazilian (Portuguese)',
      code: 'pt-br-qwerty',
      flag: '🇧🇷',
    },
    {
      country: 'English (UK)',
      code: 'en-gb-qwerty',
      flag: '🇬🇧',
    },
    {
      country: 'English (US)',
      code: 'en-us-qwerty',
      flag: '🇺🇸',
    },
    {
      country: 'Danish',
      code: 'da-dk-qwerty',
      flag: '🇩🇰',
    },
    {
      country: 'French',
      code: 'fr-fr-azerty',
      flag: '🇫🇷',
    },
    {
      country: 'French (Belgian)',
      code: 'fr-be-azerty',
      flag: '🇫🇷',
    },
    {
      country: 'French (Swiss)',
      code: 'fr-ch-qwertz',
      flag: '🇫🇷',
    },
    {
      country: 'German',
      code: 'de-de-qwertz',
      flag: '🇩🇪',
    },
    {
      country: 'German (Swiss)',
      code: 'de-ch-qwertz',
      flag: '🇩🇪',
    },
    {
      country: 'Hungarian',
      code: 'hu-hu-qwertz',
      flag: '🇭🇺',
    },
    {
      country: 'Italian',
      code: 'it-it-qwerty',
      flag: '🇮🇹',
    },
    {
      country: 'Japanese',
      code: 'ja-jp-qwerty',
      flag: '🇯🇵',
    },
    {
      country: 'Norwegian',
      code: 'no-no-qwerty',
      flag: '🇳🇴',
    },
    {
      country: 'Spanish',
      code: 'es-es-qwerty',
      flag: '🇪🇸',
    },
    {
      country: 'Spanish (Latin American)',
      code: 'es-latam-qwerty',
      flag: '🇪🇸',
    },
    {
      country: 'Swedish',
      code: 'sv-se-qwerty',
      flag: '🇸🇪',
    },
    {
      country: 'Turkish-Q',
      code: 'tr-tr-qwerty',
      flag: '🇹🇷',
    },
  ],
  RATING_STATUSES: {
    EMPTY: 'empty',
    DIRTY: 'dirty',
    CONFIRMED: 'confirmed',
  },
  CPE: {
    STEPS: {
      OPTIONS: 'options',
      PURCHASE: 'purchase',
      PAYMENT: 'payment',
      SUCCESS: 'success',
    },
    UNALLOWED_CERTIFICATIONS: [
      'f60cc015-3448-4f45-a84d-ddf068def165', // eJPT
      '2abdf5ff-0689-4502-a770-082917235cd5', //eCPPT
      '3f567abf-193d-4889-a384-1b6fa636469a', // eEDA
      '026cf09a-f245-44a0-a128-27b536ea695d', // eWPT
    ],
    HIGHER_CERT: 'higher_level_cert',
    CREDITS: {
      GENERAL: 'general',
      EXTERNAL: 'external',
      MANUAL: 'manual',
      EXPIRED: 'expiration',
      EARNED: 'earned',
      REDEEMED: 'redeemed',
    },

    LINK_TO_CPE_PROGRAM: 'https://ine.com/certification-renewal',
    CPE_PROGRAM_CONTENT_TOOLTIP_MSG:
      'Earn CPE credits upon content completion,<br /> reflected in your profile if your INE Certification is active',
    CERT_EXPIRATION_DAYS_TO_ADD: 1095, // 1095 days / 365 days per year = 3 years
  },
  PAYMENT_METHODS: {
    CREDIT_CARD: 'credit_card',
    PAYPAL: 'paypal',
  },
  PAYMENT_CONSTANTS: {
    CARD: 'card',
    CREDIT_CARD: 'credit_card',
    PAYPAL: 'paypal',
    PAYPAL_BILLING: 'paypal_billing_agreement',
    REPLACE: 'replace',
  },
  LINKEDIN: {
    ORGANIZATION_ID: '144650',
    SHARE_URL: 'https://www.linkedin.com/profile/add',
  },
  UTM_VARS: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_adgroup',
    'utm_promo',
    'utm_purpose',
    'utm_network',
    'utm_placement',
    'gcl_id',
    'fbcl_id',
    'special_offer',
    'available_plans',
  ],
  ANALYTICS: {
    HISTORY_CONTENT_TYPES: {
      SKILL_DIVE: 'skill_dive',
      SKILL_CHECK: 'skill_check',
      LEARNING_PATH: 'learning_path',
      COURSE: 'course',
    },
    CONTENT_STATUS: {
      IN_PROGRESS: 'in_progress',
      COMPLETED: 'completed',
    },
    LAB_CONTENT_STATUS: {
      STARTED: 'started',
      PARTIAL: 'partial',
      COMPLETE: 'complete',
    },
  },
}

export default CONSTANTS
