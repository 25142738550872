var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-progress"},[(!_vm.isProfileCompleted)?_c('div',_vm._l((_vm.progressStatus),function(status,index){return _c('svg',{key:index,class:[
        'profile-progress__ellipsis',
        status && 'profile-progress__ellipsis--completed',
        _vm.ellipsisClass(index),
      ],attrs:{"width":"100%","height":"100%","viewBox":"-25 -25 400 400"}},[_c('circle',{attrs:{"cx":"177","cy":"177","r":"188","stroke-dasharray":"1100","stroke-width":"20","stroke-dashoffset":"840","stroke-linecap":"round","fill":"none"}})])}),0):_vm._e(),_c('button',{staticClass:"profile-progress__toggle-btn",attrs:{"type":"button"}},[(_vm.showAvatar)?_c('img',{class:[
        'profile-progress__image',
        _vm.isProfileCompleted && 'profile-progress__image--completed',
      ],attrs:{"src":_vm.currentAvatar,"alt":"Icon representing the user"}}):_c('span',{staticClass:"profile-progress__image-user-letter"},[_vm._v(_vm._s(_vm.nameLetters(_vm.userProfile)))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }