import { mapState, mapActions } from 'vuex'
import CONSTANTS from '@/constants'
import { getAccessToken } from '@internetworkexpert/js-common'
import { cloneDeep } from 'lodash'
import * as Bugsnag from '@bugsnag/js'

export const webSocketCommons = {
  data() {
    return {
      connection: null,
      inactivityTimeout: null,
      inactivityLimit: 900000, // 15 minutes
      kinesisVideoId: null,
      wsClosedBackupInterval: null,
      newKinesisHeartbeatData: false,
      reconnectionAttempts: 0,
      maxReconnectionAttempts: 5,
    }
  },
  computed: {
    ...mapState({
      isWebSocketEnabled: state => state.app.isWebSocketEnabled,
      kinesisHeartbeatData: state => state.app.kinesisHeartbeatData,
      userProfile: state => state.auth.userProfile,
    }),
    newKinesisHeartbeatComputed() {
      return this.newKinesisHeartbeatData
    },
    reconnectionAttemptsAvailable() {
      return this.reconnectionAttempts < this.maxReconnectionAttempts
    },
  },
  methods: {
    ...mapActions('content', ['getVideoHeartbeatStatus']),
    connectWebSocket(videoId = null) {
      const accessToken = getAccessToken()
      const url = {
        string: process.env.VUE_APP_WEBSOCKET_URL,
        params: {
          token: accessToken,
          user_id: this.userProfile.uaaId,
        },
      }
      if (videoId) {
        url.params.video_id = videoId
        this.connection = new WebSocket(
          `${url.string}?token=Bearer${encodeURIComponent(
            ` ${url.params.token}`
          )}&video_id=${encodeURIComponent(
            url.params.video_id
          )}&user_id=${encodeURIComponent(url.params.user_id)} `
        )
      } else {
        this.connection = new WebSocket(
          `${url.string}?token=${encodeURIComponent(
            url.params.token
          )}&user_id=${encodeURIComponent(url.params.user_id)}`
        )
      }

      // timeoutDuration is the time in milliseconds to wait before attempting to reconnect
      const timeoutDuration = 5000 // Start with a 2 second delay

      // Set up a timeout to handle connection timeout
      const timeoutId = setTimeout(() => {
        if (this.connection.readyState === WebSocket.CONNECTING) {
          // WebSocket connection timeout. Attempt to close the connection.
          this.connection.close()
        }
      }, timeoutDuration)

      this.connection.onopen = () => {
        this.clearTimeouts([timeoutId])
        this.resetInactivityTimeout()
        if (videoId) {
          const videoData = JSON.parse(this.kinesisHeartbeatData.Data)
          const kinesisHeartbeatData = cloneDeep(this.kinesisHeartbeatData)
          kinesisHeartbeatData.Data = videoData
          this.sendWebSocketMessage(kinesisHeartbeatData)
        }
      }

      // Event listener for incoming messages
      this.connection.onmessage = event => {
        let incomingData = {}
        this.resetInactivityTimeout()
        try {
          Object.assign(incomingData, JSON.parse(event.data))
        } catch (error) {
          // JSON could not be parsed
          this.sendBugsnagNotification(error)
          incomingData = null
        }
        this.handleWebSocketMessage(incomingData)
      }

      this.connection.onerror = error => {
        // WebSocket error
        this.sendBugsnagNotification(error)
        this.clearTimeouts([timeoutId])
      }

      this.connection.onclose = event => {
        if (!event.wasClean) {
          // WebSocket connection closed unexpected.
          // Check the reason(event.reason)
          this.sendBugsnagNotification(event)
          if (this.reconnectionAttempts <= this.maxReconnectionAttempts) {
            this.reconnectionAttempts++
          }
        } else if (event.code >= 4000) {
          this.reconnectionAttempts = this.maxReconnectionAttempts
        } else {
          // WebSocket connection closed.
          this.clearTimeouts([timeoutId])
        }
      }
    },
    disconnectWebSocket() {
      if (this.connection) {
        this.connection.close()
        this.connection = null
      }
    },
    sendWebSocketMessage(message) {
      // if false, WebSocket is not connected
      if (this.connection && this.connection.readyState === WebSocket.OPEN) {
        const messageToSend =
          typeof message === 'object' ? JSON.stringify(message) : message
        this.connection.send(messageToSend)
        this.resetInactivityTimeout()
      }
    },
    async handleWebSocketMessage(message) {
      if (message && message.video_id && message.completed) {
        const videoStatus = await this.$store.dispatch(
          'content/getVideoStatus',
          message.video_id
        )
        if (
          videoStatus.user_status !== CONSTANTS.CONTENT_USER_STATUS.FINISHED
        ) {
          await this.$store.dispatch('content/updateVideoStatus', {
            videoId: message.video_id,
            status: CONSTANTS.CONTENT_USER_STATUS.FINISHED,
          })
        }
      }
    },
    resetInactivityTimeout() {
      clearTimeout(this.inactivityTimeout)
      this.inactivityTimeout = setTimeout(() => {
        // WebSocket closed due to inactivity
        this.disconnectWebSocket()
      }, this.inactivityLimit)
    },
    clearTimeouts(timeoutIds) {
      timeoutIds.forEach(timeoutId => {
        clearTimeout(timeoutId)
      })
    },
    sendBugsnagNotification(event) {
      const notificationData = {
        message: `WebSocket event: ${event.type}`,
        data: {
          event,
          heartbeat_data: this.kinesisHeartbeatData,
          heartbeat_version: process.env.VUE_APP_AWS_VERSION,
          timestamp: new Date().toISOString(),
        },
      }

      Bugsnag.addMetadata('websocket_event_data', notificationData)
      Bugsnag.notify(JSON.stringify(notificationData))
    },
  },
  created() {
    this.wsClosedBackupInterval = setInterval(async () => {
      if (
        this.isWebSocketEnabled &&
        this.newKinesisHeartbeatComputed &&
        this.connection &&
        this.connection.readyState !== WebSocket.OPEN
      ) {
        this.newKinesisHeartbeatData = false
        const response = await this.getVideoHeartbeatStatus(this.kinesisVideoId)
        this.reconnectionAttempts = 0
        this.handleWebSocketMessage({
          user_id: this.userProfile.uaaId,
          video_id: this.kinesisVideoId,
          completion: response.watched_percentage,
          completed: response.status === CONSTANTS.CONTENT_USER_STATUS.FINISHED,
        })
      }
    }, 30000)
  },
  watch: {
    kinesisHeartbeatData: {
      handler(newData) {
        if (newData) {
          const videoData = JSON.parse(newData.Data)
          this.kinesisVideoId = videoData.attributes.video_id
          if (
            this.connection &&
            this.connection.readyState === WebSocket.OPEN
          ) {
            const kinesisHeartbeatData = JSON.parse(newData.Data)
            const videoDataClone = cloneDeep(newData)
            videoDataClone.Data = kinesisHeartbeatData
            this.sendWebSocketMessage(videoDataClone)
          } else {
            if (this.isWebSocketEnabled && this.reconnectionAttemptsAvailable) {
              if (this.reconnectionAttempts === 0) {
                this.connectWebSocket(videoData.attributes.video_id)
              } else {
                setTimeout(() => {
                  if (
                    this.connection &&
                    (this.connection.readyState !== WebSocket.OPEN ||
                      this.connection.readyState !== WebSocket.CONNECTING)
                  ) {
                    this.connectWebSocket(videoData.attributes.video_id)
                  }
                }, 500 * this.reconnectionAttempts)
              }
            }
            this.newKinesisHeartbeatData = true
          }
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.wsClosedBackupInterval)
    this.disconnectWebSocket()
  },
}
