import { GetterTree } from 'vuex'
import CONSTANTS from '@/constants'
import { ILearningAreasState, ILearningArea, IRootState } from '@/@types'

const getters: GetterTree<ILearningAreasState, IRootState> = {
  primaryAreas: state => {
    if (state.learningAreas && state.learningAreas.length) {
      return state.learningAreas
        .filter(
          (area: ILearningArea) =>
            area.name && CONSTANTS.LEARNING_AREAS.PRIMARY[area.name]
        )
        .reverse()
    }
    return []
  },
  secondaryAreas: state => {
    if (state.learningAreas && state.learningAreas.length) {
      return state.learningAreas.filter(
        (area: ILearningArea) =>
          area.name && CONSTANTS.LEARNING_AREAS.SECONDARY[area.name]
      )
    }
    return []
  },
}

export default getters
