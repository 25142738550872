import CONSTANTS from '@/constants'

export default [
  {
    path: '/courses/:name/list',
    name: 'allCourses',
    component: () => import('@/pages/AllCourses'),
  },
  {
    path: '/layout/all-courses',
    name: 'layout-all-courses',
    component: () => import('@/pages/Design'),
  },
  {
    path: '/course/:slug/:id',
    name: 'course',
    props: true,
    component: () => import('@/pages/Course'),
  },
  {
    path: '/course/:slug/:id/lab/:labId',
    name: 'course-lab',
    component: () => import('@/pages/LabPage.vue'),
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COURSE },
  },
]
