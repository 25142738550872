import { IAppState, IRootState } from '@/@types'
import { getEnvVarBoolean, getEnvVarString } from '@/helpers/env'

import { GetterTree } from 'vuex'

const getters: GetterTree<IAppState, IRootState> = {
  showScheduledBanner: (state, getters, rootState) => {
    return (
      !state.scheduledMaintenanceClosed &&
      getters.maintenanceScheduled !== '' &&
      rootState.auth.isAuthenticated
    )
  },
  labExperienceEnhance: () => {
    return getEnvVarBoolean('VUE_APP_LAB_EXPERIENCE_ENHANCE_ENABLED')
  },
  contactSupportToUpgrade: () => {
    return getEnvVarBoolean('VUE_APP_CONTACT_SUPPORT_TO_UPGRADE_ENABLED')
  },
  labExperienceCollection: () => {
    return getEnvVarBoolean('VUE_APP_LAB_EXPERIENCE_COLLECTION_ENABLED')
  },
  maintenanceOngoing: () => {
    return getEnvVarBoolean('VUE_APP_MAINTENANCE_ONGOING_ENABLED')
  },
  maintenanceScheduled: () => {
    return getEnvVarString('VUE_APP_MAINTENANCE_SCHEDULED')
  },
  currentVideoPreferences: state => {
    return state.videoPlaybackPreferences
  },
  isAnyVideoActive: state => {
    return state.videoActive
  },
  isComponentLoading: state => state.isComponentLoading,
  isPageLoading: state => state.isPageLoading,
  getKinesisHeartbeatData: state => state.kinesisHeartbeatData,
  getIsLoadingCaptcha: state => state.isLoadingCaptcha,
}

export default getters
