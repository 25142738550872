import { ActionContext, ActionTree } from 'vuex'
import {
  IAuthState,
  IBackupEmail,
  IOnboardingSurvey,
  IRole,
  IRootState,
  IUser,
} from '@/@types'
import {
  deleteBackupEmail,
  federatedLoginInfo,
  fetchMFACode,
  fetchSampleAvatars,
  fetchStatus,
  fetchUserBackupEmails,
  fetchUserCurrentProfile,
  fetchUserMeOnContent,
  fetchUserOnboardingSurvey,
  fetchUserPreferences,
  loginUser,
  logoutUser,
  postBackupEmail,
  postChangeMFAMethod,
  postChangePassword,
  postChangePhoneNumber,
  postConfirmNewAccount,
  postConfirmationEmail,
  postDefaultUserAvatar,
  postForgotPassword,
  postMFACode,
  postNewPasswordByCode,
  postUserAvatar,
  postUserOnboardingSurvey,
  putBirthday,
  putNewPasswordBySession,
  putUserPreferences,
} from '../../../models/auth'
import {
  getAccessToken,
  getOriginalAccessToken,
  removeAllTokens,
  setAccessToken,
} from '@internetworkexpert/js-common'

import CONSTANTS from '@/constants'
import { handleExpiredSession } from '@/helpers'
import router from '@/router'
import { trackWoopraEvent } from '@/helpers'

const isUserAnAdmin = (roles: Array<IRole>) => {
  const userRoles = roles.map(role => role.name)
  const isAdmin: boolean = userRoles.some(name =>
    CONSTANTS.ROLES.INE_ADMIN.includes(name)
  )
  return isAdmin
}

const isUserAContentCreator = (roles: Array<IRole>) => {
  const userRoles = roles.map(role => role.name)
  return userRoles.includes(CONSTANTS.ROLES.CONTENT_CREATOR)
}

const isUserABusinessManager = (roles: Array<IRole>) => {
  const ROLES = [CONSTANTS.ROLES.ACCOUNT_ADMIN, CONSTANTS.ROLES.TEAM_ADMIN]
  const userRoles = roles.map(role => role.name)
  return !!userRoles.find(role => ROLES.includes(role))
}

const getSonarUserStatus = async (
  context: ActionContext<IAuthState, IRootState>
) => {
  if (
    context.rootGetters['business/isBusinessUser'] ||
    context.rootGetters['auth/isAdminOrContentCreator']
  ) {
    await context.dispatch('sonar/getUserStatus', null, {
      root: true,
    })
  }
}

const setAuthState = async (commit: Function, user: IUser) => {
  const {
    id: uaaId,
    email,
    content_api_id,
    account_id,
    team_ids,
    origin,
    mfa_method,
    mfa_required,
    subscriber_status,
    roles: { data: roles },
    profile: { data: profile },
  } = user
  if (user?.tokens?.data) {
    const { Bearer } = user.tokens.data
    await setAccessToken(Bearer)
  }
  commit('IS_ADMIN_UPDATED', isUserAnAdmin(roles))
  commit('IS_CONTENT_CREATOR_UPDATED', isUserAContentCreator(roles))
  commit('IS_BUSINESS_MANAGER_UPDATED', isUserABusinessManager(roles))
  commit('SUBSCRIPTION_STATUS_UPDATED', subscriber_status)
  commit('PROFILE_UPDATED', {
    uaaId,
    email,
    content_api_id,
    account_id,
    team_ids,
    origin,
    mfa_method,
    mfa_required,
    ...profile,
  })

  const replaceAvatarURL = profile.avatar
    ? profile.avatar.replace(
        'https://ine-profiles.s3.amazonaws.com/',
        'https://profiles.assets.ine.com/'
      )
    : profile.avatar
  commit('AVATAR_UPDATED', replaceAvatarURL)
}

const completeSuccessfullyLogin = async (
  context: ActionContext<IAuthState, IRootState>,
  user: IUser
) => {
  await setAuthState(context.commit, user)
  const { mfa_method } = user
  if (mfa_method && mfa_method.length) {
    context.commit('IS_TWO_FACTOR_REQUIRED_UPDATED', true)
    const isGoogleAuthenticatorMFA =
      mfa_method === CONSTANTS.PROFILE.MFA.GOOGLE_AUTHENTICATOR
    context.commit(
      'IS_GOOGLE_AUTHENTICATOR_MFA_UPDATED',
      isGoogleAuthenticatorMFA
    )
  } else {
    await fetchRequiredAuthDetails(context)
  }

  const { email, first_name, last_name } = context.rootState.auth.userProfile
  const userData = {
    email,
    firstname: first_name,
    lastname: last_name,
  }
  const woopraData = context.rootState.app.woopraData

  trackWoopraEvent('login', userData, woopraData)
}

const fetchRequiredAuthDetails = async (
  context: ActionContext<IAuthState, IRootState>
) => {
  const promises = []
  await context.dispatch('business/getBusinessUser', null, {
    root: true,
  })
  if (!context.state.redirectUrl) {
    if (!context.getters.isFreeTrialUser) {
      promises.push(
        context.dispatch('subscriptions/getSubscriptions', {}, { root: true })
      )
    } else {
      promises.push(
        context.dispatch('subscriptions/getPassTypes', {}, { root: true })
      )
    }
    promises.push(context.dispatch('getProfilePreferences'))
  }
  context.commit('IS_AUTHENTICATED_UPDATED', true)
  promises.push(context.dispatch('getOnboardingSurvey'))
  promises.push(getSonarUserStatus(context))
  await Promise.allSettled(promises)
}

const actions: ActionTree<IAuthState, IRootState> = {
  login: async (
    context: ActionContext<IAuthState, IRootState>,
    formData: { email: string; password: string; recaptcha: string }
  ) => {
    try {
      const user = await loginUser(formData)
      await completeSuccessfullyLogin(context, user)
    } catch (response) {
      const message =
        response &&
        typeof response === 'object' &&
        'error' in response &&
        response.error &&
        typeof response.error === 'object' &&
        'message' in response.error
          ? (response.error as { message: string }).message
          : 'An unknown error occurred'
      context.dispatch('app/setError', message, { root: true })
      return response
    }
  },

  userMeOnContent: async (context: ActionContext<IAuthState, IRootState>) => {
    try {
      const data = await fetchUserMeOnContent()
      context.commit('HAS_PLAYLISTS', data.has_playlists)
      context.commit('UPDATE_PASSES', data.available_passes)
      context.commit(
        'UPDATE_LAB_EXPERIENCE_USER_ACCESS',
        data.lab_experience_user_access
      )
      if (data.enabled_user_features) {
        context.commit(
          'UPDATED_ENABLED_USER_FEATURES',
          data.enabled_user_features
        )
      }
    } catch (error) {
      const { message } = error as any
      context.dispatch('app/setError', message, { root: true })
    }
  },

  tryAutoLogin: async (context: ActionContext<IAuthState, IRootState>) => {
    const token = getAccessToken()
    if (token) {
      try {
        const user = await fetchStatus()
        await setAuthState(context.commit, user)
        const heldToken = getOriginalAccessToken()
        if (heldToken && heldToken.length) {
          context.dispatch('admin/isImpersonating', true, { root: true })
        }
        await fetchRequiredAuthDetails(context)
      } catch (e) {
        context.commit('PROFILE_UPDATED', '')
        handleExpiredSession()
      }
    } else {
      await removeAllTokens()
      context.commit('PROFILE_UPDATED', '')
      context.commit('IS_AUTHENTICATED_UPDATED', false)
    }
  },

  federatedLogin: async (context: ActionContext<IAuthState, IRootState>) => {
    const token = getAccessToken()
    if (token) {
      try {
        const user = await fetchStatus()

        await setAuthState(context.commit, user)
        await fetchRequiredAuthDetails(context)

        const { email, first_name, last_name } =
          context.rootState.auth.userProfile
        const userData = {
          email,
          firstname: first_name,
          lastname: last_name,
        }
        const woopraData = context.rootState.app.woopraData

        trackWoopraEvent('login', userData, woopraData)
      } catch (error) {
        const { code, mfa_method } = error as {
          code: string
          mfa_method: string
        }
        const twofaCodes = ['MFAEXCEPTION-REQUIRED', 'mfa_required']
        if (code && twofaCodes.includes(code)) {
          context.commit('IS_TWO_FACTOR_REQUIRED_UPDATED', true)
          if (mfa_method && mfa_method.length) {
            context.commit('IS_TWO_FACTOR_REQUIRED_UPDATED', true)
            const isGoogleAuthenticatorMFA =
              mfa_method === CONSTANTS.PROFILE.MFA.GOOGLE_AUTHENTICATOR
            context.commit(
              'IS_GOOGLE_AUTHENTICATOR_MFA_UPDATED',
              isGoogleAuthenticatorMFA
            )
          }
        } else {
          await removeAllTokens()
          context.commit('PROFILE_UPDATED', '')
          context.commit('IS_AUTHENTICATED_UPDATED', false)
          router.push({
            name: 'login',
            params: {
              error:
                'We were unable to log you in with that method. Please try again.',
            },
          })
        }
      }
    } else {
      await removeAllTokens()
      context.commit('PROFILE_UPDATED', '')
      context.commit('IS_AUTHENTICATED_UPDATED', false)
    }
  },

  logout: async (context: ActionContext<IAuthState, IRootState>) => {
    try {
      await logoutUser()
    } finally {
      await removeAllTokens()
      await context.dispatch('cleanStore')
    }
  },

  submitMFACode: async (
    context: ActionContext<IAuthState, IRootState>,
    code: string
  ) => {
    try {
      const user = await postMFACode(code)
      context.commit('IS_TWO_FACTOR_REQUIRED_UPDATED', false)
      await setAuthState(context.commit, user)
      await fetchRequiredAuthDetails(context)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  resendMFACode: async (context: ActionContext<IAuthState, IRootState>) => {
    try {
      await fetchMFACode()
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getSampleAvatars: async (context: ActionContext<IAuthState, IRootState>) => {
    try {
      const response = await fetchSampleAvatars()
      context.commit('SAMPLE_AVATARS_UPDATED', response)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateUserAvatar: async (
    context: ActionContext<IAuthState, IRootState>,
    fileAndType: { type: string; file: BinaryType | string }
  ) => {
    try {
      const response = fileAndType.file
        ? await postUserAvatar(fileAndType)
        : await postDefaultUserAvatar()
      context.commit('AVATAR_UPDATED', response.data.avatar)
      context.dispatch('app/setNotification', 'Avatar updated', {
        root: true,
      })
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  confirmNewAccount: async (
    context: ActionContext<IAuthState, IRootState>,
    codeAndUUID: {
      code: string
      username: string
    }
  ) => {
    try {
      await postConfirmNewAccount(codeAndUUID)
      router.push({ name: 'login' })
      context.dispatch(
        'app/setNotification',
        'Your account is ready! Please login to continue.',
        { root: true }
      )
    } catch (error) {
      router.push({ name: 'login' })
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  triggerForgotPassword: async (
    context: ActionContext<IAuthState, IRootState>,
    email: string
  ) => {
    try {
      await postForgotPassword(email)
      context.dispatch(
        'app/setNotification',
        'An email has been sent with instructions on how to reset your password',
        { root: true }
      )
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateNewPasswordByCode: async (
    context: ActionContext<IAuthState, IRootState>,
    formData: {
      code: string
      username: string
      password: string
    }
  ) => {
    try {
      await postNewPasswordByCode(formData)
      context.dispatch(
        'app/setNotification',
        'Your password has been successfully updated! Please login using the new password.',
        { root: true }
      )
      router.push({ name: 'login' })
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateNewPasswordBySession: async (
    context: ActionContext<IAuthState, IRootState>,
    formData: {
      session: string
      username: string
      password: string
    }
  ) => {
    try {
      const user = await putNewPasswordBySession(formData)
      await completeSuccessfullyLogin(context, user)
      context.dispatch(
        'app/setNotification',
        'Your password has been successfully updated!',
        { root: true }
      )
      router.push({ name: 'login' })
    } catch (response) {
      const message =
        response &&
        typeof response === 'object' &&
        'error' in response &&
        response.error
          ? (response.error as { message: string }).message
          : 'An unknown error occurred'
      context.dispatch('app/setError', message, { root: true })
      return response
    }
  },

  updateUserPassword: async (
    context: ActionContext<IAuthState, IRootState>,
    currentAndNewPasswords: {
      currentPassword: string
      password: string
    }
  ) => {
    try {
      await postChangePassword(currentAndNewPasswords)
      context.dispatch(
        'app/setNotification',
        'Your password has been successfully changed!',
        { root: true }
      )
      await removeAllTokens()
      await context.dispatch('cleanStore')
      router.push({ name: 'login' })
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updatePhoneNumber: async (
    context: ActionContext<IAuthState, IRootState>,
    phoneNumber: string
  ) => {
    try {
      const { preferred_phone_number } = await postChangePhoneNumber(
        phoneNumber
      )
      context.commit('PHONE_NUMBER_UPDATED', preferred_phone_number)
      context.dispatch(
        'app/setNotification',
        'Your phone number has been successfully updated!',
        { root: true }
      )
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateMFAMethod: async (
    context: ActionContext<IAuthState, IRootState>,
    method: string | null = null
  ) => {
    try {
      const response = await postChangeMFAMethod(method)
      context.commit('MFA_METHOD_UPDATED', method)
      context.dispatch(
        'app/setNotification',
        method && method.length
          ? 'You now have two factor authentication enabled!'
          : 'Two factor authentication has been disabled',
        { root: true }
      )
      return response
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getProfilePreferences: async (
    context: ActionContext<IAuthState, IRootState>
  ) => {
    try {
      const preferences = await fetchUserPreferences()
      context.commit('PROFILE_PREFERENCES_UPDATED', preferences)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  submitProfilePreferences: async (
    context: ActionContext<IAuthState, IRootState>,
    preferences: object
  ) => {
    try {
      const profilePreferences = await putUserPreferences(preferences)
      context.commit('PROFILE_PREFERENCES_UPDATED', profilePreferences)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  setIsTwoFactorRequired: (
    context: ActionContext<IAuthState, IRootState>,
    isTwoFactorRequired: boolean
  ) => {
    context.commit('IS_TWO_FACTOR_REQUIRED_UPDATED', isTwoFactorRequired)
  },

  setIsAuthLoading: ({ commit }: { commit: Function }, isLoading: boolean) => {
    commit('IS_AUTH_LOADING_UPDATED', isLoading)
  },

  setRedirectUrl: (
    context: ActionContext<IAuthState, IRootState>,
    redirectUrl: string
  ) => {
    context.commit('REDIRECT_URL_UPDATED', redirectUrl)
  },

  saveRedirectUrl: (context: ActionContext<IAuthState, IRootState>) => {
    if (context.state.redirectUrl) {
      window.localStorage.setItem('redirectUrl', context.state.redirectUrl)
    }
  },

  restoreRedirectUrl: (context: ActionContext<IAuthState, IRootState>) => {
    const redirectUrl = window.localStorage.getItem('redirectUrl')
    if (redirectUrl) {
      context.commit('REDIRECT_URL_UPDATED', redirectUrl)
      window.localStorage.removeItem('redirectUrl')
    }
  },

  getFederatedUrl: async (
    context: ActionContext<IAuthState, IRootState>,
    company: object
  ) => {
    try {
      const external = await federatedLoginInfo(company)
      return external
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  cleanStore: (context: ActionContext<IAuthState, IRootState>) => {
    context.commit('PROFILE_UPDATED', {
      account_id: '',
      mfa_required: null,
      avatar: '',
      preferred_phone_number: '',
      mfa_method: '',
      first_name: '',
      last_name: '',
      email: '',
    })
    context.dispatch('business/cleanBusinessUser', null, { root: true })
    context.dispatch('admin/isImpersonating', false, { root: true })
    context.commit('UPDATE_PASSES', [])
    context.commit('IS_ADMIN_UPDATED', false)
    context.commit('HAS_PLAYLISTS', false)
    context.commit('IS_CONTENT_CREATOR_UPDATED', false)
    context.commit('IS_BUSINESS_MANAGER_UPDATED', false)
    context.commit('IS_AUTHENTICATED_UPDATED', false)
    context.commit('SUBSCRIPTION_STATUS_UPDATED', '')
    context.commit('subscriptions/CURRENT_SUBSCRIPTIONS_UPDATED', [], {
      root: true,
    })
    context.dispatch('cleanSonarModule')
  },

  cleanSonarModule: (context: ActionContext<IAuthState, IRootState>) => {
    context.commit(
      'sonar/CURRENT_USER_STATUS_UPDATED',
      {
        attempts_per_learning_area: [],
        sonar_enabled: false,
      },
      {
        root: true,
      }
    )
    context.commit('sonar/CURRENT_ASSESSMENT_ATTEMPT_UPDATED', null, {
      root: true,
    })
    context.commit('sonar/CLEAN_SCORE', [], {
      root: true,
    })
    context.commit('sonar/CLEAN_ASSESSMENTS', [], {
      root: true,
    })
    context.commit(
      'sonar/LAST_ASSESSMENT_UPDATED',
      {},
      {
        root: true,
      }
    )
  },

  resendConfirmationEmail: async (
    context: ActionContext<IAuthState, IRootState>,
    email: object
  ) => {
    try {
      await postConfirmationEmail(email)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getOnboardingSurvey: async (
    context: ActionContext<IAuthState, IRootState>
  ) => {
    try {
      const response = await fetchUserOnboardingSurvey()
      const lastOnboardingSurvey = response.sort(
        (a: IOnboardingSurvey, b: IOnboardingSurvey) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )?.[0]?.responses
      let itemsChecked = 0
      let totalItems = 0
      let completedPercentage = 0
      let firstAttempt = true
      if (lastOnboardingSurvey) {
        firstAttempt = false
        Object.values(CONSTANTS.PROFILE.ONBOARDING.ITEMS_IDS).forEach(step => {
          if (lastOnboardingSurvey[step]) ++itemsChecked
        })
        totalItems = Object.keys(CONSTANTS.PROFILE.ONBOARDING.STEPS).length
        completedPercentage = (100 / totalItems) * itemsChecked
      }
      const onboardingSurvey = {
        data: lastOnboardingSurvey,
        totalItems,
        itemsChecked,
        completedPercentage,
        firstAttempt,
      }
      context.commit('ONBOARDING_SURVEY_COLLECTION_UPDATED', onboardingSurvey)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },
  setOnboardingSurvey: async (
    context: ActionContext<IAuthState, IRootState>,
    payload: object
  ) => {
    try {
      const data = {
        survey_slug: 'user_onboarding_survey',
        subscription_id: null,
        responses: payload,
      }
      await postUserOnboardingSurvey(data)
      await context.dispatch('getOnboardingSurvey')
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateBackupEmail: async (
    context: ActionContext<IAuthState, IRootState>,
    backupEmail: string
  ) => {
    try {
      const { email } = await postBackupEmail(backupEmail)
      context.commit('BACKUP_EMAIL_UPDATED', email)
      context.dispatch(
        'app/setNotification',
        'Your backup email has been successfully updated!',
        { root: true }
      )
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  removeBackupEmail: async (
    context: ActionContext<IAuthState, IRootState>,
    payload: Object
  ) => {
    try {
      await deleteBackupEmail(payload)
      context.commit('BACKUP_EMAIL_UPDATED', null)
      context.dispatch(
        'app/setNotification',
        'Your backup email has been successfully removed!',
        { root: true }
      )
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  updateBirthday: async (
    context: ActionContext<IAuthState, IRootState>,
    birthday: object
  ) => {
    try {
      const { data } = await putBirthday(birthday)
      context.commit('CURRENT_PROFILE_UPDATED', data)
      context.dispatch(
        'app/setNotification',
        'Your birthday has been successfully updated!',
        { root: true }
      )
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getUserBackupEmails: async (
    context: ActionContext<IAuthState, IRootState>
  ) => {
    try {
      const backup_emails = await fetchUserBackupEmails()
      const lastBackupEmail = backup_emails?.sort(
        (a: IBackupEmail, b: IBackupEmail) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )?.[0]
      context.commit('BACKUP_EMAIL_UPDATED', lastBackupEmail)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getUserCurrentProfile: async (
    context: ActionContext<IAuthState, IRootState>
  ) => {
    try {
      const current_user_profile = await fetchUserCurrentProfile()
      context.commit('CURRENT_PROFILE_UPDATED', current_user_profile?.data)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
