import { GetterTree } from 'vuex'
import CONSTANTS from '@/constants'
import { ILabsState, IRootState, IUserFlag, IStringKeys } from '@/@types'

const getters: GetterTree<ILabsState, IRootState> = {
  userFlags(state) {
    return state.userCurrentFlags?.reduce((acc: IStringKeys, f: IUserFlag) => {
      acc[f.uuid] = f
      return acc
    }, {})
  },
  currentLabSession: state => {
    return state.currentLabSession
  },
  currentLabSessionStatus: state => state.currentLabSession?.status,
  isCurrentLabSessionStarting: state =>
    state.currentLabSession?.status === CONSTANTS.LABS.SESSION_STATUS.STARTING,
  isChangingLabState: state => {
    const { isStartingLab, isRestartingLab, isSwitchingLabs } = state.labStatus
    return isRestartingLab || isStartingLab || isSwitchingLabs
  },
  isStartingLab: state => state.labStatus.isStartingLab,
  isSwitchingLabs: state => state.labStatus.isSwitchingLabs,
  isRestartingLab: state => state.labStatus.isRestartingLab,
  isFetchingLab: state => state.labStatus.isFetchingLab,
  isTransitioningStates: state => {
    if (!state.currentLabSession?.status) return false
    const sessionTransitioningState = [
      CONSTANTS.LABS.SESSION_STATUS.STARTING,
      CONSTANTS.LABS.SESSION_STATUS.STOPPING,
    ]
    return sessionTransitioningState.includes(state.currentLabSession?.status)
  },
}
export default getters
