import { ILearningPathsState } from '@/@types'

const state: ILearningPathsState = {
  learningPaths: [],
  currentLearningPath: {},
  currentLearningPathCertifications: [],
  currentProgress: {},
  nextContent: {},
  learningPathsCache: {},
  learningPathsNextContentCache: {},
}

export default state
