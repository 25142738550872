import { QUIZ } from '@/clients'
import { djangoErrorHandler, isParentPresent } from '@/helpers'
import CONSTANTS from '@/constants'

const fetchQuizById = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes/${id}?parent_type=${parentType}&parent_id=${parentId}`
    : `quizzes/${id}`
  return QUIZ.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchQuizAttemptsByQuizId = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes/${id}/attempts?parent_type=${parentType}&parent_id=${parentId}&page_size=none`
    : `quizzes/${id}/attempts`
  return QUIZ.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postQuizAttemptByQuizId = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes/${id}/attempts?parent_type=${parentType}&parent_id=${parentId}`
    : `quizzes/${id}/attempts`
  return QUIZ.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const putQuizAttemptByQuizId = async userAttemptAndEntityIds => {
  const { quizId, attemptId, submission, parentId, parentType } =
    userAttemptAndEntityIds
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes/${quizId}/attempts/${attemptId}?parent_type=${parentType}&parent_id=${parentId}`
    : `quizzes/${quizId}/attempts/${attemptId}`
  const payload = {
    status: CONSTANTS.CONTENT_USER_STATUS.FINISHED,
    questions: submission,
  }
  return QUIZ.put(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const patchQuizAttemptByQuizId = async userAttemptAndEntityIds => {
  const { quizId, attemptId, submission, status, parentId, parentType } =
    userAttemptAndEntityIds
  const route = isParentPresent({ parentId, parentType })
    ? `quizzes/${quizId}/attempts/${attemptId}?parent_type=${parentType}&parent_id=${parentId}`
    : `quizzes/${quizId}/attempts/${attemptId}`
  const payload = {
    status,
    questions: submission,
  }
  return QUIZ.patch(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchQuizById,
  fetchQuizAttemptsByQuizId,
  postQuizAttemptByQuizId,
  putQuizAttemptByQuizId,
  patchQuizAttemptByQuizId,
}
