import { ActionContext, ActionTree } from 'vuex'
import { ICoursesState, ILevel, IRootState } from '@/@types'
import {
  deleteActiveCourse,
  fetchActiveCourses,
  fetchCourseById,
  fetchInstructorById,
  postActiveCourse,
} from '../../../models/courses'

import { errorHandler } from '@/utils'

const actions: ActionTree<ICoursesState, IRootState> = {
  getActiveCourses: async (
    context: ActionContext<ICoursesState, IRootState>
  ) => {
    try {
      const isUserAuthenticated = context.rootState.auth.isAuthenticated
      if (!isUserAuthenticated) throw new Error('User is not authenticated')
      const response = await fetchActiveCourses()
      context.commit('ACTIVE_COURSES_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateCurrentLevels: (
    context: ActionContext<ICoursesState, IRootState>,
    level: ILevel
  ) => {
    context.commit('CURRENT_LEVELS_UPDATED', level)
  },

  addActiveCourse: async (
    context: ActionContext<ICoursesState, IRootState>,
    courseId: string
  ) => {
    try {
      await postActiveCourse(courseId)
      context.dispatch(
        'app/setNotification',
        'The course was added to your active courses.',
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  removeActiveCourse: async (
    context: ActionContext<ICoursesState, IRootState>,
    courseId: string
  ) => {
    try {
      await deleteActiveCourse(courseId)
      context.dispatch(
        'app/setNotification',
        'The course was removed from your active courses.',
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getCourseById: async (
    context: ActionContext<ICoursesState, IRootState>,
    id: string
  ) => {
    try {
      const response = await fetchCourseById(id)
      context.commit('CURRENT_COURSE_UPDATED', response)
      if (response && response.user_status) {
        context.commit('COURSE_PROGRESS_UPDATED', response.user_status)
      } else {
        context.commit('COURSE_PROGRESS_UPDATED', {})
      }
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getInstructorById: async (
    context: ActionContext<ICoursesState, IRootState>,
    instructorId: string
  ) => {
    try {
      const response = await fetchInstructorById(instructorId)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },
}

export default actions
