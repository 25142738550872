import { eventsConstants } from '../eventsList'

export const mixpanelEvent = {
  computed: {
    eventsConstants() {
      return eventsConstants
    },
  },
  methods: {
    sendMixpanelEvent(event, payload = {}) {
      if (this.$store.state.auth.isAuthenticated) {
        this.$store.dispatch('content/sendMixpanelEvent', {
          event_name: event,
          properties: {
            ...payload,
          },
        })
      }
    },
  },
}
