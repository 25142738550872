import { ActionContext, ActionTree } from 'vuex'
import {
  IContent,
  IContentState,
  ICourse,
  IGroup,
  ILearningPath,
  ILevel,
  IPlaylist,
  IRecentActivity,
  IRootState,
  IStringKeys,
} from '@/@types'
import {
  deleteBookmark,
  fetchBookmarks,
  fetchContentIssues,
  fetchContentRating,
  fetchCoursesByIds,
  fetchExercises,
  fetchExercisesForLearningPath,
  fetchIFramesForLearningPath,
  fetchJWVideo,
  fetchLabs,
  fetchLabsForLearningPath,
  fetchQuizzes,
  fetchQuizzesForLearningPath,
  fetchRecentActivity,
  fetchTrainingHistoryItems,
  fetchVideo,
  fetchVideoHeartbeatStatus,
  fetchVideos,
  fetchVideosForBootcamp,
  fetchVideosForLearningPath,
  fetchVideosForParent,
  patchContentRating,
  postBookmark,
  postCertificateCompletion,
  postContentIssue,
  postContentRating,
  postIFrameStatus,
  postLanguageVideo,
  postLinkStatus,
  postMixpanelEvent,
  postVideoPosition,
  postVideoStatus,
} from '../../../models/content'

import { fetchIFrameById, fetchIFrames } from '../../../models/iframes'

import { errorHandler } from '@/utils'
import { eventsList } from '../../../helpers/eventsList'
import { fetchExerciseById } from '../../../models/exercises'
import { fetchLab, postLabStatus } from '../../../models/labs'
import { fetchQuizById } from '../../../models/quizzes'
import { fetchSelectedLearningPath } from '../../../models/learning-paths'
import { flattenObject, getParentFromRootState } from '@/helpers'
import { pick } from 'lodash'

const actions: ActionTree<IContentState, IRootState> = {
  getJWVideo: async (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      const response = await fetchJWVideo(payload)
      context.commit('JW_VIDEO_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      if (message === 'You do not have permission to perform this action.') {
        context.dispatch(
          'app/setError',
          'You do not have access to this content.',
          {
            root: true,
          }
        )
      } else {
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  updateVideoStatus: async (
    context: ActionContext<IContentState, IRootState>,
    videoIdAndStatus: {
      videoId: string
      status: string
    }
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id: videoIdAndStatus.videoId,
        status: videoIdAndStatus.status,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      await postVideoStatus(payload)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateVideoPosition: async (
    context: ActionContext<IContentState, IRootState>,
    videoIdAndStatus: {
      videoId: string
      position: string
    }
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id: videoIdAndStatus.videoId,
        current_position: videoIdAndStatus.position,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      if (process.env.VUE_APP_RESUME_VIDEO_ENABLED) {
        await postVideoPosition(payload)
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getVideoStatus: async (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      const video = await fetchVideo(payload)
      context.commit('VIDEO_STATUS_UPDATED', {
        ...video,
        parent_id: payload.parentId,
      })
      return {
        ...video,
        parent_id: payload.parentId,
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getVideoHeartbeatStatus: async (
    context: ActionContext<IContentState, IRootState>,
    videoId: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const video = await fetchVideoHeartbeatStatus({
        id: videoId,
        parentType,
        parentId,
      })
      return {
        ...video,
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  postVideoLanguageRequest: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { language: string; course_id: string; video_id: string }
  ) => {
    try {
      await postLanguageVideo(payload)
      return true
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      return false
    }
  },

  getParentVideos: async (
    context: ActionContext<IContentState, IRootState>,
    parentAndVideoIds: {
      parentType: string
      parentId: string
      videoIds: Array<string>
    }
  ) => {
    if (parentAndVideoIds.videoIds && parentAndVideoIds.videoIds.length) {
      try {
        const videos = await fetchVideosForParent(parentAndVideoIds)
        const stateVideos = videos.map((v: IContent) => ({
          ...v,
          parent_id: parentAndVideoIds.parentId,
        }))
        context.commit('CURRENT_VIDEOS_UPDATED', stateVideos)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  getQuizzes: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { quizIds: Array<string>; parentId: string; parentType: string }
  ) => {
    if (payload && payload.quizIds && payload.quizIds.length) {
      try {
        const quizzes = await fetchQuizzes(payload)
        context.commit('CURRENT_QUIZZES_UPDATED', quizzes)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  getExercises: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      exerciseIds: Array<string>
      parentId: string
      parentType: string
    }
  ) => {
    if (payload.exerciseIds && payload.exerciseIds.length) {
      try {
        const exercises = await fetchExercises(payload)
        context.commit('CURRENT_EXERCISES_UPDATED', exercises)
        context.commit('exercises/SET_STUDENT_CODE_EXERCISES', exercises, {
          root: true,
        })
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, {
          root: true,
        })
      }
    }
  },

  getLabs: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { labIds: Array<string>; parentId: string; parentType: string }
  ) => {
    if (payload.labIds && payload.labIds.length) {
      try {
        const labs = await fetchLabs(payload)
        context.commit('CURRENT_LABS_UPDATED', { labs })
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, {
          root: true,
        })
      }
    }
  },

  updateLabStatus: async (
    context: ActionContext<IContentState, IRootState>,
    params: {
      labId: string
      status: string
      parentId?: string
      parentType?: string
    }
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id: params.labId,
        status: params.status,
        parentType: currentParent?.type || params?.parentType || '',
        parentId: currentParent?.id || params?.parentId || '',
      }
      const userLabStatus = await postLabStatus(payload)
      context.commit('LAB_STATUS_UPDATED', userLabStatus)
      context.commit('labs/LAB_STATUS_UPDATED', userLabStatus, {
        root: true,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getIFrames: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      iframeIds: Array<string>
      parentType: string
      parentId: string
    }
  ) => {
    if (payload.iframeIds && payload.iframeIds.length) {
      try {
        const iframes = await fetchIFrames(payload)
        context.commit('CURRENT_IFRAMES_UPDATED', iframes)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, {
          root: true,
        })
      }
    }
  },

  updateIFrameStatus: async (
    context: ActionContext<IContentState, IRootState>,
    iframeIdAndStatus: {
      iframeId: string
      status: string
    }
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id: iframeIdAndStatus.iframeId,
        status: iframeIdAndStatus.status,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      await postIFrameStatus(payload)
      context.commit('IFRAME_STATUS_UPDATED', iframeIdAndStatus)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getPathVideos: async (
    context: ActionContext<IContentState, IRootState>,
    pathAndVideoIds: { pathId: string; videoIds: Array<string> }
  ) => {
    if (pathAndVideoIds.videoIds && pathAndVideoIds.videoIds.length) {
      try {
        const videos = await fetchVideosForLearningPath(pathAndVideoIds)
        const stateVideos = videos.map((v: IContent) => ({
          ...v,
          parent_id: pathAndVideoIds.pathId,
        }))
        context.commit('CURRENT_VIDEOS_UPDATED', stateVideos)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },
  getPathQuizzes: async (
    context: ActionContext<IContentState, IRootState>,
    pathAndQuizIds: { pathId: string; quizIds: Array<string> }
  ) => {
    if (pathAndQuizIds.quizIds && pathAndQuizIds.quizIds.length) {
      try {
        const quizzes = await fetchQuizzesForLearningPath(pathAndQuizIds)
        context.commit('CURRENT_QUIZZES_UPDATED', quizzes)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  getPathLabs: async (
    context: ActionContext<IContentState, IRootState>,
    pathAndLabIds: { pathId: string; labIds: Array<string> }
  ) => {
    if (pathAndLabIds.labIds && pathAndLabIds.labIds.length) {
      try {
        const labs = await fetchLabsForLearningPath(pathAndLabIds)
        context.commit('CURRENT_LABS_UPDATED', { labs })
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },
  getPathExercises: async (
    context: ActionContext<IContentState, IRootState>,
    pathAndExerciseIds: { pathId: string; exerciseIds: Array<string> }
  ) => {
    if (
      pathAndExerciseIds.exerciseIds &&
      pathAndExerciseIds.exerciseIds.length
    ) {
      try {
        const exercises = await fetchExercisesForLearningPath(
          pathAndExerciseIds
        )
        context.commit('CURRENT_EXERCISES_UPDATED', exercises)
        context.commit('exercises/SET_STUDENT_CODE_EXERCISES', exercises, {
          root: true,
        })
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },
  getPathIFrames: async (
    context: ActionContext<IContentState, IRootState>,
    pathAndIFrameIds: { pathId: string; iframeIds: Array<string> }
  ) => {
    if (pathAndIFrameIds.iframeIds && pathAndIFrameIds.iframeIds.length) {
      try {
        const iframes = await fetchIFramesForLearningPath(pathAndIFrameIds)
        context.commit('CURRENT_IFRAMES_UPDATED', iframes)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  getQuizStatus: async (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      const quiz = await fetchQuizById(payload)
      context.commit('QUIZ_STATUS_UPDATED', quiz)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getBookmarks: async (context: ActionContext<IContentState, IRootState>) => {
    try {
      const bookmarks = await fetchBookmarks()
      context.commit('CURRENT_BOOKMARKS_UPDATED', bookmarks)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  addBookmark: async (
    context: ActionContext<IContentState, IRootState>,
    bookmark: object
  ) => {
    try {
      await postBookmark(bookmark)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  removeBookmark: async (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    try {
      await deleteBookmark(id)
      const bookmarks = context.state?.currentBookmarks?.filter(
        bookmark => bookmark.id !== id
      )
      context.commit('CURRENT_BOOKMARKS_UPDATED', bookmarks)
      context.dispatch('app/setNotification', 'The bookmark was deleted.', {
        root: true,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getCoursesForBookmarks: async (
    context: ActionContext<IContentState, IRootState>,
    courseIds: Array<string>
  ) => {
    try {
      const courses = await fetchCoursesByIds(courseIds)
      context.commit('COURSES_FOR_BOOKMARKS_UPDATED', courses)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getVideosForBookmarks: async (
    context: ActionContext<IContentState, IRootState>,
    videoIds: Array<string>
  ) => {
    try {
      const videos = await fetchVideos(videoIds)
      context.commit('VIDEOS_FOR_BOOKMARKS_UPDATED', videos)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  setPlayingVideoId: (
    context: ActionContext<IContentState, IRootState>,
    videoId: String
  ) => {
    context.commit('PLAYING_VIDEO_ID_UPDATED', videoId)
  },

  setPlayingVideoTime: (
    context: ActionContext<IContentState, IRootState>,
    time: number
  ) => {
    context.commit('PLAYING_VIDEO_TIME_UPDATED', time)
  },

  setCurrentQuizId: (
    context: ActionContext<IContentState, IRootState>,
    quizId: string
  ) => {
    context.commit('CURRENT_QUIZ_ID_UPDATED', quizId)
  },

  setCurrentExercisesId: (
    context: ActionContext<IContentState, IRootState>,
    exercisesId: string
  ) => {
    context.commit('CURRENT_EXERCISES_ID_UPDATED', exercisesId)
  },

  setIsContentLoading: (
    context: ActionContext<IContentState, IRootState>,
    isLoading: boolean
  ) => {
    context.commit('IS_CONTENT_LOADING_UPDATED', isLoading)
  },

  setCurrentHoveredNote: (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    context.commit('CURRENT_HOVERED_NOTE_UPDATED', id)
  },

  sendMixpanelEvent: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { event_name: string; properties: object }
  ) => {
    if (!process.env.VUE_APP_MIXPANEL_ENABLED) return
    try {
      const sentPayload = {
        user_id: context.rootState.auth.userProfile.uaaId,
        event_name: payload.event_name,
        properties: {
          source: 'my.ine.com',
          starter_pass: context.rootState.auth.subscriptionStatus,
          ...flattenObject(
            pick(payload.properties, eventsList[payload.event_name])
          ),
        },
      }
      await postMixpanelEvent(sentPayload)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  fetchContentForCourse: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { course: ICourse; isAuthenticated: boolean }
  ) => {
    const { course, isAuthenticated } = payload
    const courseId = course.id
    const gatherContentIds = () => {
      const ids: IStringKeys = {}
      course.content.map(group =>
        group.content.map(level =>
          level.content.map(content => {
            const { content_type: type, uuid } = content
            if (ids[type] !== undefined) {
              ids[type].push(uuid)
            } else {
              ids[type] = [uuid]
            }
          })
        )
      )
      return ids
    }
    const { video, lab, iframe, quiz, exercise } = gatherContentIds()

    context.dispatch('clearCourseContents')

    const dispatcher = [
      context.dispatch('getParentVideos', {
        videoIds: video,
        parentId: courseId,
        parentType: 'course',
      }),
      context.dispatch('getLabs', {
        labIds: lab,
        parentType: 'course',
        parentId: courseId,
      }),
      context.dispatch('getIFrames', {
        iframeIds: iframe,
        parentType: 'course',
        parentId: courseId,
      }),
    ]

    if (isAuthenticated) {
      dispatcher.push(
        context.dispatch('getQuizzes', {
          quizIds: quiz,
          parentType: 'course',
          parentId: courseId,
        }),
        context.dispatch('getExercises', {
          exerciseIds: exercise,
          parentType: 'course',
          parentId: courseId,
        })
      )
    }

    await Promise.all(dispatcher)
  },

  clearCourseContents: (context: ActionContext<IContentState, IRootState>) => {
    context.commit('CURRENT_VIDEOS_UPDATED', [])
    context.commit('CURRENT_LABS_UPDATED', { labs: [] })
    context.commit('CURRENT_IFRAMES_UPDATED', [])
    context.commit('CURRENT_QUIZZES_UPDATED', [])
    context.commit('CURRENT_EXERCISES_UPDATED', [])
  },

  fetchContentForLearningPath: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { learningPath: ILearningPath; isAuthenticated: boolean }
  ) => {
    const dispatcher = []
    const { learningPath, isAuthenticated } = payload

    const ids: { [key: string]: any } = {
      video: [],
      quiz: [],
      exercise: [],
      lab: [],
      iframe: [],
    }

    if (learningPath.content) {
      learningPath.content.map(group =>
        group.content.map(level => {
          const { content_type, uuid } = level

          if (ids[content_type]) ids[content_type].push(uuid)
        })
      )
    }
    if (ids.video.length) {
      dispatcher.push(
        context.dispatch('getPathVideos', {
          videoIds: ids.video,
          pathId: learningPath.id,
        })
      )
    }
    if (ids.quiz.length && isAuthenticated) {
      dispatcher.push(
        context.dispatch('getPathQuizzes', {
          quizIds: ids.quiz,
          pathId: learningPath.id,
        })
      )
    }
    if (ids.lab.length) {
      dispatcher.push(
        context.dispatch('getPathLabs', {
          labIds: ids.lab,
          pathId: learningPath.id,
        })
      )
    }
    if (ids.exercise.length && isAuthenticated) {
      dispatcher.push(
        context.dispatch('getPathExercises', {
          exerciseIds: ids.exercise,
          pathId: learningPath.id,
        })
      )
    }
    if (ids.iframe.length) {
      dispatcher.push(
        context.dispatch('getPathIFrames', {
          iframeIds: ids.iframe,
          pathId: learningPath.id,
        })
      )
    }

    await Promise.all(dispatcher)
  },

  fetchContentForPlaylist: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { playlist: IPlaylist; isAuthenticated: boolean }
  ) => {
    const { playlist } = payload
    const gatherContentOrIds = () => {
      const ids: IStringKeys = {}
      playlist.content.map(playlistGroup =>
        playlistGroup.content.map(playlistItem => {
          const { content_type: type, uuid } = playlistItem
          const content = type !== 'video' ? playlistItem : uuid
          if (['video', 'link', 'lab'].includes(type)) {
            if (ids[type] !== undefined) {
              ids[type].push(content)
            } else {
              ids[type] = [content]
            }
          }
        })
      )
      return ids
    }
    const { video, link, lab } = gatherContentOrIds()
    await context.dispatch('getParentVideos', {
      videoIds: video,
      parentId: playlist.id,
      parentType: 'playlist',
    })
    if (lab && lab.length) {
      const labs = lab.map((l: IContent) => ({
        ...l,
        id: l.uuid,
        parent_id: playlist.id,
      }))
      context.commit('CURRENT_LABS_UPDATED', { labs, playlistId: playlist.id })
    }
    if (link && link.length) {
      const links = link.map((l: IContent) => ({
        ...l,
        parent_id: playlist.id,
      }))
      context.commit('CURRENT_LINKS_UPDATED', links)
    }
  },

  getBootcampVideos: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { videoIds: Array<string>; bootcampId: string }
  ) => {
    if (payload.videoIds && payload.videoIds.length) {
      try {
        const videos = await fetchVideosForBootcamp(payload)
        const stateVideos = videos.map((v: IContent) => ({
          ...v,
          parent_id: payload.bootcampId,
        }))
        context.commit('CURRENT_VIDEOS_UPDATED', stateVideos)
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  fetchContentForBootcamp: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      content: Array<IGroup>
      isAuthenticated: boolean
      bootcampId: string
    }
  ) => {
    const {
      content: bootcampContent,
      isAuthenticated,
      bootcampId,
    }: {
      content: Array<IGroup>
      isAuthenticated: boolean
      bootcampId: string
    } = payload
    const gatherContentIds = () => {
      const ids: IStringKeys = {}
      bootcampContent.map((group: IGroup) =>
        group.content.map((level: ILevel) =>
          level.content.map((content: IContent) => {
            const { content_type: type, uuid } = content
            if (ids[type] !== undefined) {
              ids[type].push(uuid)
            } else {
              ids[type] = [uuid]
            }
          })
        )
      )
      return ids
    }
    const { video, quiz, lab } = gatherContentIds()
    await context.dispatch('getBootcampVideos', { videoIds: video, bootcampId })
    await context.dispatch('getLabs', {
      labIds: lab,
      parentType: 'bootcamp',
      parentId: bootcampId,
    })
    if (isAuthenticated) {
      await context.dispatch('getQuizzes', {
        quizIds: quiz,
        parentId: bootcampId,
        parentType: 'bootcamp',
      })
    }
  },

  resetContentState: (context: ActionContext<IContentState, IRootState>) => {
    context.dispatch('setPlayingVideoId', '')
    context.dispatch('setPlayingVideoTime', 0)
    context.dispatch('setCurrentQuizId', '')
    context.dispatch('setCurrentHoveredNote', '')
    context.commit('CURRENT_PARENT', {})
    context.commit('CURRENT_CERTIFICATIONS_UPDATED', [])
  },

  getRecentActivity: async (
    context: ActionContext<IContentState, IRootState>
  ) => {
    const activity: IRecentActivity = await fetchRecentActivity()
    context.commit('RECENT_ACTIVITY', activity)
  },

  getRecentLearningPath: async (
    context: ActionContext<IContentState, IRootState>,
    id: string
  ) => {
    const learningPath: ILearningPath = await fetchSelectedLearningPath(id)
    context.commit('RECENT_LEARNING_PATH', learningPath)
    return learningPath
  },

  getRecentActivityNextUpContent: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      content_type: string
      id: string
    }
  ) => {
    const { content_type, id } = payload
    const actionMap: IStringKeys = {
      video: fetchVideo,
      quiz: fetchQuizById,
      lab: fetchLab,
      iframe: fetchIFrameById,
      exercise: fetchExerciseById,
    }
    const {
      state: { currentParent },
    } = context
    const content = await actionMap[content_type].call(null, {
      id,
      parentType: currentParent?.type ?? '',
      parentId: currentParent?.id ?? '',
    })
    context.commit('RECENT_ACTIVITY_NEXT_UP', content)
  },

  getContentIssues: async (
    context: ActionContext<IContentState, IRootState>
  ) => {
    try {
      const response = await fetchContentIssues()
      context.commit('UPDATE_ALL_ISSUES', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  sendContentIssue: async (
    context: ActionContext<IContentState, IRootState>,
    issue: object
  ) => {
    try {
      const payload = {
        ...context.state.currentIssue,
        ...issue,
      }
      const response = await postContentIssue(payload)

      context.dispatch(
        'content/sendMixpanelEvent',
        {
          event_name: 'content.issue.sent',
          properties: {
            ...context.state.currentIssue,
          },
        },
        { root: true }
      )
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
      throw error
    }
  },

  saveContentIssue: (
    context: ActionContext<IContentState, IRootState>,
    payload
  ) => {
    context.commit('CURRENT_ISSUE_PAYLOAD_UPDATED', payload)
  },

  resetContentIssue: (context: ActionContext<IContentState, IRootState>) => {
    context.commit('CURRENT_ISSUE_RESET')
  },
  saveVideosWithBookmarks: (
    context: ActionContext<IContentState, IRootState>,
    payload
  ) => {
    context.commit('UPDATE_VIDEOS_WITH_BOOKMARKS', payload)
  },
  updateLinkStatus: async (
    context: ActionContext<IContentState, IRootState>,
    linkIdAndStatus: {
      linkId: string
      status: string
    }
  ) => {
    try {
      const {
        state: { currentParent },
      } = context
      const payload = {
        id: linkIdAndStatus.linkId,
        status: linkIdAndStatus.status,
        parentType: currentParent?.type ?? '',
        parentId: currentParent?.id ?? '',
      }
      const userLinkStatus = await postLinkStatus(payload)
      context.commit('LINK_STATUS_UPDATED', userLinkStatus)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getContentRatingByModel: async (
    context: ActionContext<IContentState, IRootState>,
    payload: { model_id: string; model_type: string }
  ) => {
    try {
      return await fetchContentRating(payload)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  createContentRating: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      model_id: string
      model_type: string
      rating: string
      instructor: string
      instructor_rating: string
      observations: string
      confirmed: boolean
    }
  ) => {
    try {
      const response = await postContentRating(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  updateContentRating: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      id: string
      rating?: string
      instructor_rating?: string
      observations?: string
      confirmed?: boolean
    }
  ) => {
    try {
      const response = await patchContentRating(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },
  getTrainingHistory: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      type: string
      status: Array<string>
      page_size: string
      page: number
      ordering?: string
    }
  ) => {
    try {
      const { type, status, page_size, page, ordering } = payload

      const data = {
        type,
        page_size: page_size || 10,
        page: page || 1,
        status,
        ordering,
      }
      const response = await fetchTrainingHistoryItems(data)
      context.commit('TRAINING_HISTORY_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  requestCertificateCompletion: async (
    context: ActionContext<IContentState, IRootState>,
    payload: {
      content_type: string
      content_id: string
    }
  ) => {
    try {
      const response = await postCertificateCompletion(payload)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default actions
