import { ICertificationsState } from '@/@types'

const state: ICertificationsState = {
  currentCertificate: {
    attempt_id: null,
    certification_name: '',
    certificate_description_html: '',
    logo_image_url: '',
    exam_version: '',
    finished_at: '',
    first_name: '',
    last_name: '',
  },
  certificationList: [],
  currentCertifications: [],
  currentCertification: {
    id: null,
    name: '',
    amount_questions: 0,
    background_image_url: '',
    difficulty: null,
    instructions: '',
    logo_image_url: '',
    time_cap_in_secs: 0,
    user_status: null,
  },
  currentAttempt: {
    id: null,
    status: null,
    created: null,
    expiration_date: null,
    feedback: null,
    finished_at: null,
    has_pass: false,
    score: null,
    user_lab_attempt: null,
  },
  usersCertifications: [],
  usersVouchers: [],
  currentScoredAttempt: {
    id: null,
    status: null,
    created: null,
    expiration_date: null,
    feedback: null,
    finished_at: null,
    has_pass: false,
    score: null,
  },
  currentScoredCertification: null,
  certificationsCache: {},
  certRenewalsAvailable: [],
  certRenewalCost: null,
  certRenewalPurchase: null,
}

export default state
