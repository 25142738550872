// UGH

const courseIds = [
  '63c191fc-5de7-41aa-b6c0-f73bec72f9ac',
  '6e626eed-4268-11e4-a79f-22000b3582a3',
  '4f8e5cee-f735-4af8-8be7-db08991766a0',
  '72115f93-2071-4625-b955-af603e187b0f',
  '8835f212-d781-493e-9760-8728fe0c9ca6',
  'be2b1007-48c1-43aa-9212-9a207e95109b',
  'a7454259-950e-4680-908b-d5fe800ee522',
  'd1074464-aa90-43c6-9b82-dfbbedbfa1c8',
  '57fb0766-9486-4916-81cc-9819df096ada',
  '4c96a630-3ba4-4b25-8c4b-87a7f741fd9e',
  '2af3a330-cbbe-4857-8399-3ee79aa87278',
  'fafc53f1-27fe-473a-b36f-f28c72aed8cd',
  '1d8079de-f8fd-4c2f-a5e0-9ef35f80c716',
  'dfb83f8c-cb7a-48e3-82a8-07ab346aad5b',
  '17f5a23a-a1fb-42a4-8f40-21e33b8bbdee',
  'b032ef42-9faf-487d-97d2-5e9a4ba6fd26',
  '131b6deb-f09e-4f1b-9fa1-8156e69110fb',
  'c927e5e0-74b5-495e-ab91-687020437838',
  '432d0641-9a6d-11e4-8f7f-22000b4a8afe',
  '971b72aa-5690-480a-a75d-4792b8acbf9f',
  'eb114009-2b0c-4715-abaf-5334fbc1ce43',
  '38cc3d1f-c307-4259-8f34-2fc8afd1d3ec',
  '18dc9913-78ea-4f5e-b30d-51c79e4f732f',
  'c83aa6f4-724d-4873-bcf0-362feab2393e',
  '8b448b0d-fd5c-4e93-9e9c-c9db7c402853',
  '5b26577b-d094-4ce9-a8dc-53db836d6236',
  'dedb186e-5e5d-4d76-aaf9-e50742fff098',
  'f7eabce2-e51a-4176-90b0-b1c4c840c9e9',
  '87a50968-3f69-11e4-a79f-22000b3582a3',
  '7b753816-3ee8-4851-98e6-7fae0fe4df8e',
  '1d31a149-b1f3-4a0c-8307-2270fdb05f0b',
  '48ec295f-48f2-4f10-9e32-20c0785c9356',
  'e04bd21a-e7fd-413c-a6c9-382c83addfbd',
  '7015c99f-508c-4d38-b926-a50b61523691',
  'f29cef69-6df1-4eb0-9d0a-9de354c80647',
  '91a4a45b-1bba-48eb-9dea-105a1cbc5bed',
  '8ab6a5c5-4633-454b-9bb3-3aa1307d3e0b',
  'dd3f03f1-bf36-41a9-9711-fe0154084e23',
  'fafa70eb-fc81-40df-8775-cddd08066d0f',
  '1607e3aa-479e-4ee0-88cf-7bee107ec0a2',
  'b6171236-11fd-4635-985a-dd2c9c4310d6',
  'f18e6e7f-01e4-4dbb-918e-587ce68c9f18',
  '49d86e2e-55f7-4011-a64f-255d5c2df76e',
  '1a894156-400a-11e4-a79f-22000b3582a3',
  '340005b9-c44c-4035-ac5b-bde1ba348bcc',
  '0598541b-586d-4b4a-8853-557c59b1a0ed',
  '3060fcb7-8f2a-4138-9dae-626939583013',
  '7b1f7aec-a8d5-4d2c-a499-e154f0240bc5',
  'd10fc2d9-75f6-40d2-aae3-d7ff8dede982',
  'ab5d6762-4291-11e4-a79f-22000b3582a3',
  '288171d8-0534-4cbc-a4cb-65e83dbd37e6',
  'c589d6ec-b1db-43fd-8c3c-2608311322dd',
  '60077920-5e1a-4922-b752-a692870cf1e8',
  'bfba41be-429a-11e4-a79f-22000b3582a3',
  '41389b40-c07d-4998-9b1d-aa398a627865',
  'e6bc4793-1c3e-4798-b3b7-d988463d17bd',
  '8a5621f2-33f4-4d85-a2c5-cc9ab710d7d2',
  '20b482db-93c7-42ad-bf0c-96cbe5bbf7c7',
  '5501d34b-a240-4e33-8639-3a1d206f351f',
  'b0dc5ee0-3f66-11e4-a79f-22000b3582a3',
  'c10a99cb-0d49-4780-96e9-78866e3db87a',
  'a0e9812b-1924-4e2b-a7ff-00f8d1f75ada',
  'b9c0bfd4-4263-11e4-a79f-22000b3582a3',
  '0f37829c-b841-46a0-94f1-65642b271748',
  '2a5a9a2b-6244-470d-ba2f-32115e18ab0d',
  'e0286494-4f83-4cf3-ad45-021f6ba1442d',
  '6f6fddeb-d55c-4e73-9a36-5f3bac24f7b5',
  '8f1aecf4-a169-4119-b896-0cb1ff166dfa',
  '8786eab0-2efd-43a2-bb81-75bfe4224d0e',
  '917b20c0-4014-11e4-a79f-22000b3582a3',
  '50caea0a-24bf-4708-8e84-ca8e2fd3d97a',
  'e7227047-8052-4f57-9359-859278e077f0',
  'be76710c-f9b0-4103-aabf-214afe3b29fd',
  '9256f068-89f9-4966-91df-0a592ded27e8',
  '7954a4d7-a20c-477f-91eb-35c8f261d7f1',
  '7a9758de-3f67-11e4-a79f-22000b3582a3',
  'c7dfc37a-889f-45a7-8dfe-13d20e9f0902',
  'af48c0b3-c184-4f29-a8c0-db8e4153c10a',
  '1c20443a-8e96-457f-9ce0-4c73e1464444',
  '59183e4a-6c32-4dd0-a25b-b8aa59661d43',
  'bf393d95-5a39-49c4-9528-14de52e95eb2',
  'b3c11be7-fae2-45e0-82f6-dab4f768b92e',
  'f241e68f-3d7a-4691-8113-366333e1a5b2',
  'c1c9ee5d-fbb7-423a-bbbc-084b3d97e09c',
  '8c18eb5b-cb01-4f50-9fa4-6cc662b4fdc7',
  'd7c9a3c3-9a90-49d5-9882-d3aac7e8f8cf',
  'a0e3c44a-3999-45d1-a3b1-416c87bf0482',
  'f2e7ffce-bc20-4a2a-bd8f-9ab95282147b',
  '720db98e-1eb5-4d6d-aa05-b4e084345815',
  '0b8fcc45-7811-48f3-b44f-65d1b2d3adfb',
  '333a0536-4a92-4f9f-b342-d553ce691693',
  '8e82a5c2-bba2-4f35-a380-4b20d5ddf8a7',
  '33ee4d48-be69-4d01-9092-b4a83137284b',
  '536f4774-c130-4ffb-8a75-6dff7ebdf601',
  '9df364f7-428d-11e4-a79f-22000b3582a3',
  'c5e144b7-ac75-49f3-b8a9-4a33bd79867a',
  '070b8234-2b6b-4324-a7df-8da14e1046c2',
  '67329b5b-ffb8-4c75-aa96-a279a25eeb6b',
  'e2482661-57ed-4fd5-a29d-a0e8d52f89bc',
  'c7626f58-5639-49e4-a68c-850dc98c052b',
  '3e10121d-d324-4037-91ab-214a3f8d98d0',
  '1d636a3f-fb55-4c18-8abe-e5bb73fe98ee',
  '7b04d12c-e0ed-4470-9eae-653acfd03dad',
  'c075f75f-b458-47b2-9c24-a307e09e3162',
  '8ef511c6-9134-4380-a64a-4afe68560eaa',
  '67556e69-a9af-4f8d-8041-1a3b78abc06a',
  '2d55e05c-f148-4df7-a399-4bb4caacb9d8',
  'c5886d86-d23f-4ae4-b501-5d1bc44d1c34',
  'f5c54dae-216c-4d89-84a1-0709a345d9a5',
  '3b2f38ce-c6d1-473e-9e6d-a6fc9614e7c2',
  '62f2c6fd-3f6e-11e4-a79f-22000b3582a3',
  '70f6e6a2-12c5-4551-80cd-217ba48f5dcb',
  'cc7e10f4-5077-43ca-8d67-777a27a692fb',
  '62a39e08-a863-453e-b4a1-04d4749ad7a1',
  '3be5db2b-43fb-4510-9288-d9c6913a9037',
  '9348e596-3cca-4086-8aea-bf94df35109c',
  '60c68c44-49a5-4081-a5ab-78be2ad7fdfe',
  '1e69c05f-aa45-4d20-8244-b055cac545d5',
  'cbd2baa1-f0e2-469a-8d23-36ba689e40af',
  'a480e7bd-4e50-45a1-9877-29c9ccacc0bd',
  '139fe763-3f41-48ff-8e9f-2773a266c128',
  'ef44fc33-3f5a-11e4-a79f-22000b3582a3',
  'daa40c2b-e1cb-49ec-a06c-a6da10868370',
  'cc54c4bb-deb5-4e3c-a600-53dd8129a2f3',
  '38d6617a-bf6a-4b82-aebf-d575fd0bd7d2',
  'ef2d1911-3f4d-449e-bdd6-624f987f1d77',
  'cf513aaf-611e-49a6-ae40-f6d1d38af966',
  '845fbb7f-53aa-4723-b3ea-79f0e21f645c',
  '8d64165c-5eb6-4e98-b853-1b616a0e6aa5',
  '4a47b435-24f6-40ea-9e39-2c80c75db957',
  '6167921d-a881-46f8-96d5-81ccdf7455e7',
  '1ba24236-c8fd-42f7-a4b7-43bd1b107a6c',
  '4ca81e5f-188f-4fdb-a51b-7fece811f3b3',
  '44911610-e25b-4bb8-81aa-6e8bf1bfb05f',
  '4beec660-b78c-446d-b8c5-6e1ca5933e09',
  '09cd9c65-4801-41c3-bd31-e898b70aea4a',
  '1f81f353-cc4f-40a4-9cdf-35a312756629',
  '877ed325-e164-4533-83da-31a22efbdc68',
  '10ae5bfd-b574-4ea4-ae27-e0eadee23925',
  '58eb0543-671a-45c0-9239-5203014e31ef',
  '609683e1-63f9-432b-b384-c972e6a504f7',
  '120519f4-104a-4fbd-a4da-ba31158e3ddd',
  '9132069f-884e-493a-9493-8859430565fa',
  'ce7799d2-429b-11e4-a79f-22000b3582a3',
  'f512eff8-e15c-47c5-8eee-2964d21c3f51',
  '362c9804-db81-4ee9-9df6-2c7b613b677d',
  'ae6d7970-3f5e-11e4-a79f-22000b3582a3',
  '8cf91110-8c1d-4200-9e49-b6e7e6e0fdc9',
  'cffe3460-2fc3-406c-8936-8d5dca3b2ee0',
  '8912b44d-ae85-4537-a5ac-f2025fda1034',
  'b1075851-e69b-4d8a-b049-28a409884a0c',
  '7a55a73d-d22d-4b18-8795-8ce5089de059',
  '76ab4dd8-ff15-48cf-9ae8-76b3e60ff3d0',
  '82d580a8-a2c0-408d-8cf5-169191f4a8c6',
  '9b55018e-1dc4-4266-9aef-b4f3ae20a26e',
  'b720d00a-e25c-4156-97c7-e46ffeb45c61',
  '51709a2c-69ac-4a0a-9fc2-3e58eceb7923',
  '9579c59c-7349-4e80-b03c-760904906739',
  '5e540d31-88ed-4bfb-b561-7cf907d3b409',
  '9d1a008c-6e50-40fb-9e63-8e6f56c18634',
  '827074b0-8ca6-4166-8345-19993356ba2b',
  'a79e75a7-4270-11e4-a79f-22000b3582a3',
  'a4a4785c-4271-11e4-a79f-22000b3582a3',
  'd567343f-14e1-45b9-bf39-e3252c92b2ac',
  'aef0762f-7397-478d-a4b3-1d5b39ef4299',
  '459a24c1-4469-4923-a368-9db0c83a571f',
  '40b5fb91-7c5a-480a-8861-ca319885cdaa',
  'f169c36e-81c4-4ff9-8e23-c83aa1822180',
  '79c9420c-611e-4934-91af-11feac4514bd',
  '2d9572a2-9a7f-4437-b919-1c9b976ae856',
  '7201e8a1-3c3d-479b-bfaa-82e3e2206561',
  'd8aea5bb-f774-44c8-bc3b-dc4067562dce',
  'a7fbc04e-d8df-41b9-823a-aaa40636b483',
  '8f2860cc-73d7-400c-a2dc-2d0da70cb949',
  'f40e3f59-1b70-4af8-be11-cffea6bc3465',
  '13a39562-cbfd-4977-a024-0bdd97862089',
  'c7d6ee98-9c01-4e0c-8e7e-796099509e8d',
  'afe394e8-4261-11e4-a79f-22000b3582a3',
  '2dac3b40-0862-4bd9-bb25-e1de1d6f2895',
  '038b82b1-7a08-44c8-99e7-2ce6c2033b1d',
  'f4e5659e-6b1a-4e84-a297-d28df28fe026',
  '7477146c-397b-4510-b910-1108608879b5',
  'd95486af-7c00-4d40-bf06-e0dc07b6e9ca',
  '29f8d9bf-a62a-466e-8615-7fc070a4a548',
  'fc599b3a-275d-4907-8ed6-8b8f270ba5a9',
  'c973bee7-58ce-485e-82da-b22d78922df7',
  '52241f22-ba0d-47e6-bd6e-eb2712d228e3',
  '754adaa8-87f9-4096-923f-13c5660e1527',
  '8230d74d-5bf4-4c88-afe5-0cf4536a4cf1',
  '51c3718c-46dd-46e7-8b8d-32d847edb87c',
  '73d71506-2bee-4c5e-ba81-ea16174e0332',
  '4c6f4a8f-eec6-4071-8ce8-18b34414f86f',
  'fb7fea10-4526-428c-921c-db060fb2f5aa',
  'f68ed727-6a08-49f9-af1b-1b76af5a7ab1',
  '64d96fb7-51f2-4062-9ed7-7f72345b70f4',
  'b6996eea-70be-48e8-9cd6-8026c5fc3166',
  '6161d21a-4265-11e4-a79f-22000b3582a3',
  '48e301e2-400e-11e4-a79f-22000b3582a3',
  '54e5473c-4280-11e4-a79f-22000b3582a3',
  '9c1c85de-94c3-44df-b67c-2c09abe3c15e',
  'e479e6ca-4017-11e4-a79f-22000b3582a3',
  'd5652c82-d0b6-415f-bc32-a683b2475b48',
  '3130e896-7d30-4954-9252-da94cfa7cb97',
  'f3849e99-4bc3-4c22-ac6d-534590df9912',
  '1beca8e7-4b1e-11e4-89a3-22000b4a8afe',
  '30a7e041-4272-11e4-a79f-22000b3582a3',
  '4961c16b-7086-42c0-94d8-f960aad43627',
  '5a3a39a9-814e-4820-aa1b-7cc7a260e6d3',
  '1f09eded-6c92-4a68-ac7e-043c48bd35c1',
  'dd33bcc8-9fc8-4614-add3-3de6c1da1858',
  '8b213567-2691-4513-ad10-b20057c9296d',
  '38ae07f4-205c-443f-a736-b55c878f155f',
  '114db991-2964-4170-8783-33a198279d75',
  '8f34c388-3264-462a-b8e1-5609caf2a758',
  '71df49b5-4f80-4100-a878-bb289da661ea',
  'd323b3b3-55ad-4551-b12e-a4b37b06030a',
  '957a6820-1fc9-46e0-8b42-baf76593ceec',
  '617b6780-2ac2-44b2-bf8f-5e137f0be831',
  '33478de1-1249-4de4-9efc-861265883cab',
  'db09eb81-4266-11e4-a79f-22000b3582a3',
  '9ddbfc0a-2843-48c3-8870-969fa58de810',
  '23cdb8bc-426e-11e4-a79f-22000b3582a3',
  'eb656dff-b92e-427f-a6a2-422b9c361bbb',
  'd80ae43f-bb39-466c-a897-6d6ba1f238ff',
  'd8c24955-3ac8-4b40-91b4-5c8f0fb1dcbd',
  '91267558-889e-456a-8f5c-257f8690a30b',
  '802434b0-4292-11e4-a79f-22000b3582a3',
  '0683fa2d-9462-4ea9-99fb-ddeec0df8a15',
  '49a84c7e-429b-11e4-a79f-22000b3582a3',
  '6e1b29bd-429d-11e4-a79f-22000b3582a3',
  '60434665-2775-4254-89dc-024a99f08229',
  '40826ea4-8f5d-4ea0-bc5a-884d36bacc8a',
  '0080b1bd-f684-408b-a036-55b2ac34a180',
  '2b8b3af9-8491-42d3-bbe6-b2435a7354ad',
  '3f7592c3-9772-4ad5-9ae9-c26f5148077a',
  '83e858a5-a13a-4152-9928-51972fdeed43',
  'bf8755c1-e0a1-4b39-9103-2e8bfd532779',
  '7d802a3e-e273-427d-a745-32122396f0ce',
  '5e761833-a405-4e9b-b0be-25546cd46c41',
  '9fdcfe87-d533-4029-b048-2e8fb7794e1f',
  'a1af2a60-c382-45c2-b7e6-bd412c7c2c79',
  'ce3e0915-cf22-4554-8910-ea673270b46d',
  '1f90f9bb-1e97-42e6-a436-c43a31280f04',
  '730da2f6-4290-11e4-a79f-22000b3582a3',
  'c8fb0533-4262-11e4-a79f-22000b3582a3',
  'dde0a40c-ce1d-47d3-b464-32c34b43ce40',
  '8d4943f1-4c26-4661-8330-76da8d7d2400',
  '67f295e5-23f2-46b5-be7c-5ae04b0dbbb3',
  '6cfceb30-f802-4447-9803-be41778c8235',
  '5eafe59f-dbd5-44ee-ab99-6e250ae27c2e',
  '629aa40c-ec20-4888-b6d5-35692e3533d2',
  'a69e9d5a-6194-4c42-9da2-1aa560881afe',
  'b4ed7541-5191-4fd5-99c6-c0ebbf466f18',
  '816ec6b1-d679-461b-9da7-593567e8dec2',
  '40f23ca5-db5b-4bb0-b462-637d32f8890d',
  'a59ef1df-3123-4b47-97e3-6486e230cdb9',
  'c1479b9f-d43e-40d2-afd3-371b82c61ceb',
  '06619c94-7fc5-436b-8a13-95604d7df538',
  '293c48e1-6ba1-45cd-a13c-1a5ded8fc9bd',
  '7be0b2b4-2ef0-4d12-ac51-28aaf25a56fa',
  'b9dab6d4-1aa0-4548-8fd8-c133a5b5b08e',
  'd1f58424-740d-4679-88c6-77dcfce3adbb',
  '097572a4-9749-11e4-8f7f-22000b4a8afe',
  'a5a72cbe-1c18-4b39-bcb2-d74ea26b669c',
  '1f42378b-a609-45d7-baaa-151eb1d194ad',
  '0e5493b2-e8c0-4051-88c2-ddcf6d70535a',
  '2caa6ffc-4271-11e4-a79f-22000b3582a3',
  'c852cfef-fa90-454c-8659-dbfd83138f53',
  'd04b4b50-c0dd-4cff-9061-204c7538b825',
  '0fc34189-27da-4a7b-8b08-369857b873b6',
  'c7b23c2f-769e-4bc9-9d10-6b3f5295e8b3',
  'c471907f-4b1f-11e4-89a3-22000b4a8afe',
  '13edeade-86c6-4f45-a9ef-5725f89eebcf',
  'c8bd2982-64ab-4f55-8ea0-65afcc463fdf',
  '0061b950-b6d3-4867-a3b0-d6f77991ada4',
  '92b72673-6aeb-4f65-9d32-a30a3301d362',
  '7249d02b-78c9-460e-99a5-4a5f5ea93287',
  '080f7b02-dcd9-4e02-a9e8-1d5ae7857e74',
  '3e74ea42-5699-43c5-b6cf-66891345339b',
  '14c5356f-1abc-42cc-9182-ba3c1b5dd545',
  '22bb41d4-3f96-4147-993b-9a9a7d9c7879',
  '1f39dc5e-a2d0-47d0-89c1-f63fe80bce00',
  'c21b5db6-400a-11e4-a79f-22000b3582a3',
  '4896c433-3f2b-40a0-97f3-48cd230cc7cf',
  'bc29719a-028d-4f4f-a3d4-0f3572e53904',
  'fbfff324-e3dd-4a02-b65e-082b82a5656d',
  '5f02e13e-e8a6-4ad6-99d2-ac8ad1ddd8f8',
  '1e125ebc-ae38-4955-bace-3e4c0f02de89',
  'b4b3e1d9-a599-4b5c-b945-5d893f73de7e',
  'e72a6602-c740-4e76-a8b9-a7a26f0d2c4f',
  'b5dbf9e9-5cc9-4764-b303-6c3efd198074',
  '89912c55-4b7f-4fa7-9886-94a1ebcbf9a2',
  'a932ff19-d839-40a9-8393-e7ebd4529d51',
  'f7238a4d-8c90-4632-8423-d16b47d3bc19',
  'c413642e-426f-11e4-a79f-22000b3582a3',
  '46c4a7cd-a149-4105-b905-db34d3bb423b',
  'd81e74b7-51be-41de-aaa1-f2a6895d93b7',
  'c0ece3d1-e6ac-48b8-80fa-20e4bb691024',
  '1269c9a2-65a9-49ab-8b4d-d33e09d05b6e',
  '0725dfcd-5ef2-4eae-a383-ae97357c9f95',
  '5f921ba7-3d45-44fe-9577-f67cfa38d83f',
  'cff2f246-49cf-413c-a4c7-d8b33f30b038',
  'b9123bb7-5cdc-4894-86ee-534d308f3ebd',
  '33947ac3-f5cb-48b3-b1d5-70cd8e34e963',
  '8e930421-cfb3-4242-9130-04001018677e',
  '3056c579-ab15-4a30-8806-0bf1b7a44226',
  'eee712bf-4008-11e4-a79f-22000b3582a3',
  'fe681257-428b-11e4-a79f-22000b3582a3',
  'fc18b9f2-3f67-11e4-a79f-22000b3582a3',
  'e53849db-0d4e-4539-81e8-4456134d455d',
  '631f7418-a782-4912-ae7b-0c23a42d50cd',
  '1a3fa9c3-afc7-4a26-808d-161c50f140ba',
  '6057e3f0-5b40-476e-85d1-350b74a16836',
  '861f9304-5161-42d9-a603-54c659cbab30',
  '6280a00e-e128-4202-8964-0ab77a17a44b',
  'c3f76e28-3f62-11e4-a79f-22000b3582a3',
  '435964fa-4aed-401c-b176-c4c8c27ec167',
  '0b83df17-a8d7-494e-b340-1fdcbda6bb3f',
  'd57a7f7c-7fc3-42bc-a28e-dc827fb0d7f3',
  '6925b3fa-8ab4-471b-92f5-13251af4b01a',
  'dae29037-1b88-459a-b328-837f349f3dc5',
  '919184c3-45ae-435e-8125-657c09bbe912',
  '37a1ca18-400d-11e4-a79f-22000b3582a3',
  '2dc90d9d-6377-4d6b-96e7-faa8bc5d7180',
  '4483e70a-9068-4a16-b0e9-49d2b00984b7',
  'b33f2774-e31d-4da7-9aef-dd8370e7512e',
  'ed4fb580-062a-42ce-9275-c16767a6b10c',
  'e1a3d8ee-a479-41f1-bbf0-1505450e6375',
  '31194179-37c4-4540-aeb7-a30cdfbf7822',
  '1b86597d-3f60-11e4-a79f-22000b3582a3',
  'de6b68d9-6637-4983-8b32-c2fba6b49f81',
  'beaff018-0ac5-4773-8532-b8b679ff1517',
  '47cde0a7-c7f0-4bc2-add4-20c7e45b446c',
  '8536ecd3-4010-11e4-a79f-22000b3582a3',
  '448da730-773e-49a0-a4a2-6eb675288b7e',
  '9196fa3b-3f6b-11e4-a79f-22000b3582a3',
  'd26d507a-c212-4246-a9c3-ebb5172a0630',
  '6b84c821-6883-41f1-94e4-f1aee933f5a1',
  'fbb39d88-3ca4-44e3-a46e-c411172f910a',
  '3577a08b-703c-431b-a91b-1ab78be69f61',
  '43b76338-4e6a-40a6-acbc-76ef40be2557',
  '1cbbfb12-0880-44da-9b5f-714a0c6fb16d',
  '3445f502-05cb-47d5-9203-4e124e7f82be',
  '301bfd9d-d8b4-4b2a-a767-b5f09afa9765',
  'c2194440-c5e7-48bf-8a5d-69ce0c1c8fa5',
  '50177a2b-9b2f-4a17-8e44-14a752de9b92',
  '6cc337ef-3f6c-11e4-a79f-22000b3582a3',
  '08e2f4eb-1e06-4296-ab3b-dc71f5ac38eb',
  '4a5b1adf-ad31-4a01-949d-084a269817bd',
  '94e284fd-546d-47e5-a6d5-e351646fb109',
  '28fc052f-2374-40e0-9cd3-34d354f9dd8d',
  '76aff134-a36a-421d-9640-d8fe4fb961a8',
  'b2e4e744-400b-11e4-a79f-22000b3582a3',
  'e9021cfa-f4d2-4ac4-acd0-07ac7bdfd2f6',
  '7ff8f791-0122-4e2e-939a-e7de53ace03c',
  '7a3d006b-3607-4641-b21b-6ec7992998c4',
  '5aeba146-19c5-4bcb-9bb3-302e76bc16ce',
  '2b665b47-5e3d-4415-91d7-d9153ac12d1d',
  '2da60341-c38d-4cc9-a229-a8de86b127db',
  'ab60803e-d4e7-4b5f-bc8a-ecd2ecb37062',
  '2c82aaf9-ddb8-49e2-ac37-7bf76f37959f',
  'd256a300-7bcc-49d0-8ad6-25ec7b1245c8',
  '4a6df1e5-4013-11e4-a79f-22000b3582a3',
  'eca36267-d800-40a7-9a97-4a377acc7b9d',
  'abef6981-8342-4244-b40c-78bcb88b5c0f',
  'e21e9ff2-ab06-4130-bf30-b644325552c6',
  'f9820b5a-55cc-4c73-9620-68b2542395f5',
  '1a27094f-d3b9-414e-a5dc-ccf5b4617e74',
  'eaab1bdc-222b-4396-a256-b55071a079a2',
  'b0d8a9ff-e287-4810-923d-f280068d0b3b',
  '9df47d00-1e7f-41c2-b1c3-a2ced22826fe',
  'c1333dc4-e50c-4599-9bb1-54ab8306d16c',
  '612d6659-3d5a-47a7-96e2-14d226d63f5a',
  'e76649a6-bfb2-4bcf-a737-f1f021dab2a6',
  '949538bc-5be4-41da-ba2f-c4785bfeef2b',
  '3463689d-7c90-4a70-bf17-4fcbb9cc8f04',
  'cdf01235-7892-4ea9-8ed8-308a215b53f9',
]

const lpIds = [
  'a223968e-3a74-45ed-884d-2d16760b8bbd',
  '9a29e89e-1327-4fe8-a201-031780263fa9',
  '154876ad-ae9f-43d6-add4-f635cab537a7',
  'eec5479e-a8d1-4803-817f-c016bb528639',
  '50d07b7c-1224-4bda-a57b-3954e189bfc1',
  '7d3a5df8-a6cf-4855-b686-30e9d7e76425',
  '019938d9-11cf-459b-b8ee-e662e10515f2',
  '6acb4d9b-c8f2-4cdb-b01d-fb6110e4f43b',
  '57ec9bc2-be17-4f51-91b9-7ed250be8596',
  'e831d3ee-18b5-4b46-b1a7-9f7b3d65feae',
  '67c2d9f8-f4f5-4705-b5e2-56d6c3583030',
  '223e1932-1303-49f5-893b-9edb720b61fd',
  '1d6ff88f-8d17-4156-91fb-5af9cfb71a37',
  '54d03ae9-f161-4c34-85d1-ed4007d83f11',
  'a223968e-3a74-45ed-884d-2d16760b8bbd',
  '5c85bfd2-e900-4f3c-aebb-bdc8e096270f',
  'b5a97fdf-0de1-4c23-bd16-57a257661093',
  '27f8374d-ab51-41fa-89c0-862dfa9b1a3c',
  'adf83ef3-b9ac-4383-9f13-4ac870d0130e', // AttackDefense
]

export { courseIds, lpIds }
