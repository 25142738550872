import {
  djangoErrorHandler as _djangoErrorHandler,
  laravelErrorHandler as _laravelErrorHandler,
  removeAllTokens,
} from '@internetworkexpert/js-common'

import CONSTANTS from '@/constants'
import { logError } from './bugsnag'
import router from '../router'
import store from '../store'

export const handleExpiredSession = () => {
  const query = {}
  store.commit('auth/IS_AUTHENTICATED_UPDATED', false)
  removeAllTokens()

  store.dispatch(
    'app/setError',
    'Your session has expired. Please login to continue.'
  )
  if (store.state?.auth?.redirectUrl) {
    query.redirect_url = store.state.auth.redirectUrl
  }
  router.push({
    name: 'login',
    query,
  })
}

export const djangoErrorHandler = errorObj => {
  logError(errorObj)
  const preparedError = _djangoErrorHandler(errorObj)
  if (
    CONSTANTS.KNOWN_API_ERRORS.REQUIRES_REAUTHENTICATION.includes(
      preparedError.message
    )
  ) {
    handleExpiredSession()
    return null
  }
  return preparedError
}

export const laravelErrorHandler = errorObj => {
  logError(errorObj)
  const result = _laravelErrorHandler(errorObj)
  if (errorObj?.response?.data?.error?.validation_errors) {
    const errors = []
    Object.entries(errorObj.response.data.error.validation_errors).map(
      // eslint-disable-next-line no-unused-vars
      ([_, val]) => {
        if (val && val.length) {
          val.map(err => errors.push(err))
        }
      }
    )
    result.message = errors.join(', ')
  }
  return result
}
