import { MutationTree } from 'vuex'
import {
  ISubscriptionsState,
  IPass,
  IPlan,
  IProductCertificationVoucher,
  ISubscription,
  IInvoice,
  IAddons,
  ILabSession,
} from '@/@types'

const mutations: MutationTree<ISubscriptionsState> = {
  CURRENT_SUBSCRIPTIONS_UPDATED: (
    state,
    subscriptions: Array<ISubscription>
  ) => {
    state.currentSubscriptions = subscriptions
  },

  CURRENT_INVOICES_UPDATED: (state, invoices: Array<IInvoice>) => {
    state.currentInvoices = invoices
  },

  PASS_TYPES_UPDATED: (state, passes: Array<IPass>) => {
    state.passTypes = passes
  },

  AVAILABLE_PLANS_UPDATED: (state, plans: Array<IPlan>) => {
    state.availablePlans = plans
  },

  PRODUCT_CERTIFICATION_VOUCHERS_UPDATED: (
    state,
    vouchers: Array<IProductCertificationVoucher>
  ) => {
    state.productCertificationVouchers = vouchers
  },

  VALID_PLANS_UPDATED: (state, plan: ISubscription) => {
    const filteredValidPlans = state.validPlans?.filter(
      valid_plan => valid_plan.id !== plan.id
    )
    const validPlans =
      filteredValidPlans !== undefined ? filteredValidPlans : []
    state.validPlans = [...validPlans, plan]
  },

  IS_UPGRADE_PLAN_ALLOWED_UPDATED: (state, value: boolean) => {
    state.isUpgradePlanAllowed = value
  },

  CURRENT_ONE_TERM_PRICING_UPDATED: (state, pricing: object) => {
    state.addTermPricing = pricing
  },

  PLAN_COMPARISON_UPDATED: (state, comparison: object) => {
    state.currentPlanComparison = comparison
  },

  SUBSCRIPTION_UPGRADED: (state, plan: ISubscription) => {
    if (state.currentSubscriptions && state.currentSubscriptions.length) {
      const filteredSubs = state.currentSubscriptions.filter(
        sub => sub.id !== plan.id
      )
      state.currentSubscriptions = [...filteredSubs, plan]
    }
  },

  PAYMENT_INFO_UPDATED: (
    state,
    payload: {
      account_credit: string
      payment_method_mask: string
      payment_method_object: string
      billing_account_code: string
    }
  ) => {
    const {
      account_credit,
      payment_method_mask,
      payment_method_object,
      billing_account_code,
    } = payload
    state.paymentInfo = {
      last4_of_cc: payment_method_mask,
      account_credit,
      payment_method: payment_method_object,
      billing_account_code: billing_account_code,
    }
  },

  CANCELLATION_FIELDS_UPDATED: (state, fields: object) => {
    state.cancellationFields = fields
  },

  SET_CURRENT_PROMOTION_CONTEXT: (state, context: String) => {
    state.currentPromotionContext = context
  },

  VALID_ADDONS_UPDATED: (state, addons: Array<IAddons>) => {
    state.validAddons = addons
  },

  PLAN_CONSUMED_UPDATED: (state, skillDivePlanConsumed: Array<ILabSession>) => {
    state.skillDivePlanConsumed = skillDivePlanConsumed
  },

  SKILL_DIVE_ADD_HOURS_PREVIEW_PURCHASE_UPDATED: (
    state,
    skillDiveAddHoursPreviewPurchase: number
  ) => {
    state.skillDiveAddHoursPreviewPurchase = skillDiveAddHoursPreviewPurchase
  },
  ADD_HOURS_PRICE_LOADING_UPDATED: (state, addHoursPriceLoading: boolean) => {
    state.addHoursPriceLoading = addHoursPriceLoading
  },
  PROMOTIONS_AVAILABLE_UPDATED: (state, promotionsAvailable: Array<any>) => {
    state.promotionsAvailable = promotionsAvailable
  },
  SHOW_EARLY_YEAR_PROMO_UPDATED: (state, showEarlyYearPromo: boolean) => {
    state.showEarlyYearPromo = showEarlyYearPromo
  },
  CHANGE_VISIBILITY_PROMOTION_BANNER: (state, value: boolean) => {
    state.isEarlyYearPromoActivated = value
  },
  BILLING_TOKEN_UPDATED: (state, billingToken: string) => {
    state.billingToken = billingToken
  },
}

export default mutations
