import { Module } from 'vuex'
import { IBusinessState, IRootState } from '@/@types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const business: Module<IBusinessState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default business
