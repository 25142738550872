import { ActionContext, ActionTree } from 'vuex'
import {
  ICPEProgramState,
  ICPERenewModalStatus,
  IRootState,
  CpeCreditHistoryItem,
  BasicInfo,
  BasicInfoResponse,
} from '@/@types'
import {
  fetchCPECredits,
  fetchCPECreditsHistory,
  postExternalDocumentation,
} from '@/models/cpe-program'
import { postCertificationsBasicInfo } from '@/models/certifications'
import { postLabsBasicInfo } from '@/models/labs'
import { postCoursesBasicInfo } from '@/models/courses'
import CONSTANTS from '@/constants'

import { errorHandler } from '@/utils'

const {
  CONTENT_TYPES: { EXAM, LAB, COURSE, CERTIFICATION },
  CPE: {
    CREDITS: { GENERAL, EXTERNAL, MANUAL, EARNED, EXPIRED },
  },
} = CONSTANTS

const getSourceTypeName = (
  cpeCredit: CpeCreditHistoryItem,
  usersCertifications: any[],
  certificationsInfo: BasicInfoResponse,
  labsInfo: BasicInfoResponse,
  coursesInfo: BasicInfoResponse
) => {
  let sourceType: BasicInfo | undefined
  switch (cpeCredit.source_type) {
    case COURSE:
      sourceType = coursesInfo.results.find(
        course => course.id === cpeCredit.source_id
      )
      return sourceType?.name ?? 'Course'
    case LAB:
      sourceType = labsInfo.results.find(lab => lab.id === cpeCredit.source_id)
      return sourceType?.name ?? 'Lab'
    case EXAM:
      sourceType = certificationsInfo.results.find(
        cert => cert.id === cpeCredit.source_id
      )
      return sourceType?.name ?? 'Certification'
    case CERTIFICATION:
      const certName = usersCertifications.find(
        cert => cert.attempt_id === cpeCredit.source_id
      )?.name
      return `${certName ?? 'Certification'} Renewal`
    default:
      return cpeCredit.source_type!
  }
}

const formatOperationDate = (dateString: string) => {
  const date = new Date(dateString)
  const today = new Date()
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return 'Just now'
  } else {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
}

const actions: ActionTree<ICPEProgramState, IRootState> = {
  getCPECredits: async (
    context: ActionContext<ICPEProgramState, IRootState>
  ) => {
    try {
      const isUserAuthenticated = context.rootState.auth.isAuthenticated
      if (!isUserAuthenticated) return
      const response = await fetchCPECredits()
      context.commit('CPE_CREDITS_UPDATED', response.total)
      context.commit('CPE_HISTORY_EXTERNAL_UPDATED', response.external)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getCPECreditsHistory: async (
    context: ActionContext<ICPEProgramState, IRootState>,
    page: number = 1
  ) => {
    try {
      const isUserAuthenticated = context.rootState.auth.isAuthenticated
      const usersCertifications =
        context.rootState.certifications.usersCertifications
      if (!isUserAuthenticated) return
      const pageSize = '12'
      const response = await fetchCPECreditsHistory(pageSize, page)
      context.commit('CPE_HISTORY_NEXT_PAGE_UPDATED', response.next !== null)
      const cpeCreditHistoryResponse: CpeCreditHistoryItem[] = response.results
      const cpeCreditHistory = cpeCreditHistoryResponse.filter(
        item => item.type !== null
      )
      if (cpeCreditHistory.length) {
        const groupedBySourceType = cpeCreditHistory.reduce((acc, item) => {
          const { source_type, source_id } = item
          if (!source_type || !source_id) {
            return acc // Skip this iteration if source_type or source_id is null
          }
          if (!acc[source_type!]) {
            acc[source_type!] = []
          }
          const source_id_short = source_id!.split('-')[0]
          acc[source_type!].push(source_id_short)
          return acc
        }, {} as Record<string, string[]>)

        const [certificationsInfo, labsInfo, coursesInfo]: [
          BasicInfoResponse,
          BasicInfoResponse,
          BasicInfoResponse
        ] = await Promise.all([
          groupedBySourceType[EXAM]
            ? postCertificationsBasicInfo(groupedBySourceType[EXAM])
            : Promise.resolve({ results: [] }),
          groupedBySourceType[LAB]
            ? postLabsBasicInfo(groupedBySourceType[LAB])
            : Promise.resolve({ results: [] }),
          groupedBySourceType[COURSE]
            ? postCoursesBasicInfo(groupedBySourceType[COURSE])
            : Promise.resolve({ results: [] }),
        ])

        cpeCreditHistory.forEach(cpeCredit => {
          if (cpeCredit.type === GENERAL) {
            cpeCredit.source_type_name = getSourceTypeName(
              cpeCredit,
              usersCertifications,
              certificationsInfo,
              labsInfo,
              coursesInfo
            )
          }
          if (cpeCredit.type === EXTERNAL) {
            let label = ''
            switch (cpeCredit.op_type) {
              case EARNED:
                label = ' earned'
                break
              default:
                label = ''
                break
            }
            cpeCredit.source_type_name = `External training credits${label}`
          }
          if (cpeCredit.type === EXPIRED) {
            cpeCredit.source_type_name = 'External training credits expired'
          }
          if (cpeCredit.type === MANUAL) {
            cpeCredit.source_type_name = 'Training credits added'
          }
          if (cpeCredit.type === null && cpeCredit.source_type === null) {
            cpeCredit.source_type_name = 'Training credits'
          }
          cpeCredit.operation_date_formatted = formatOperationDate(
            cpeCredit.operation_date!
          )
        })
      }

      context.commit('CPE_HISTORY_UPDATED', cpeCreditHistory)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  postCPEExternalDocumentation: async (
    context: ActionContext<ICPEProgramState, IRootState>,
    payload: {
      source_id: string
      training_type: string
      files: Array<File>
    }
  ) => {
    try {
      const response = await postExternalDocumentation(payload)
      if (response.status === 'pending') {
        context.dispatch(
          'app/setSuccess',
          'Submission successful! Check your inbox for more information.',
          { root: true }
        )
        return { success: true }
      }
      context.dispatch('app/setError', response.message, { root: true })
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  setRenewModalStatus: (
    context: ActionContext<ICPEProgramState, IRootState>,
    payload: ICPERenewModalStatus
  ) => {
    if (payload.hasOwnProperty('step') && !payload.hasOwnProperty('prevStep'))
      payload.prevStep = context.state.cpeRenewModalStatus.step
    context.commit('CPE_RENEW_MODAL_STATUS_UPDATED', payload)
  },
  setRenewModalStep: (
    context: ActionContext<ICPEProgramState, IRootState>,
    step: String
  ) => {
    const payload = {
      step,
      prevStep: context.state.cpeRenewModalStatus.step,
    }
    context.commit('CPE_RENEW_MODAL_STATUS_UPDATED', payload)
  },
}

export default actions
