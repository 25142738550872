<template>
  <div class="profile">
    <div v-if="isAuthLoading" class="profile__loader-wrapper">
      <ActionLoader color="#fff" :size="0.5" name="ball-pulse-sync" />
    </div>
    <template v-else>
      <div
        v-if="isAuthenticated"
        :class="[
          'profile__toggle-wrapper',
          isImpersonating && 'profile__impersonating',
        ]"
        @click="isMenuShown = !isMenuShown"
      >
        <div class="profile__info">
          <div class="profile__info-fullname">{{ userFullName }}</div>
          <div v-if="!isProfileCompleted" class="profile__info-progress">
            {{ userProgressPercentage }}
          </div>
          <div class="profile__info-business">
            {{ businessUserLabel }}
          </div>
        </div>
        <ProfileProgress
          class="profile__progress"
          :currentAvatar="currentAvatar"
          :onboardingItems="onboardingSurvey"
        />
        <MenuIcon
          v-if="!isMenuShown || !isProfileMenuShown"
          class="profile__icon"
        />
        <XIcon v-else class="profile__icon" />
      </div>
      <div v-else class="profile__authenticate">
        <router-link
          class="profile__login-btn btn btn--secondary btn--small"
          :to="{ name: 'login' }"
        >
          Sign in
        </router-link>
        <a
          class="profile__login-btn btn btn--primary btn--small"
          :href="`//${checkoutUrl}`"
        >
          Sign up
        </a>
      </div>
    </template>
    <transition name="fade">
      <ProfileMenu
        v-if="isMenuShown && isProfileMenuShown"
        :profile="profile"
        :currentAvatar="currentAvatar"
        @closed="isMenuShown = false"
      />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ActionLoader from '@/components/shared/ActionLoader.vue'
import { MenuIcon, XIcon } from 'vue-feather-icons'
import ProfileMenu from './ProfileMenu.vue'
import ProfileProgress from './ProfileProgress.vue'

export default {
  name: 'UserProfile',
  components: {
    MenuIcon,
    XIcon,
    ProfileProgress,
    ProfileMenu,
    ActionLoader,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: [Object, String],
      required: false,
    },
    businessUserLabel: {
      type: String,
      required: false,
    },
    isProfileMenuShown: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      isMenuShown: false,
    }
  },
  methods: {
    closeProfileMenu() {
      this.isMenuShown = false
    },
  },
  computed: {
    ...mapState({
      isAuthLoading: state => state.auth.isAuthLoading,
      isImpersonating: state => state.admin.isImpersonating,
      currentAvatar: state => state.auth.currentAvatar,
      onboardingSurvey: state => state.auth.onboardingSurvey,
      userFullName() {
        return `${this.profile.first_name} ${this.profile.last_name}`
      },
      checkoutUrl() {
        return process.env.VUE_APP_CHECKOUT_URL
      },
      userProgressPercentage() {
        const completedPercentage =
          this.onboardingSurvey?.completedPercentage || 0
        return `Profile: ${completedPercentage}% complete`
      },
      isProfileCompleted() {
        return this.onboardingSurvey?.completedPercentage === 100
      },
    }),
  },
}
</script>

<style lang="scss" scoped>
.profile {
  $this: &;
  display: flex;
  align-items: center;
  &__loader-wrapper {
    margin: 0 50px 0 10px;
    padding: 0 12px 0 0;
    height: 40px;
  }
  &__impersonating {
    border: 5px solid red !important;
  }
  &__toggle-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-radius: 25px;
    background-color: transparent;
    @include mobile {
      height: 40px;
      padding: 0 13px 0 0;
    }
    &:hover {
      cursor: pointer;
      #{this}__toggle-btn {
        color: var(--orange);
      }
      #{this}__icon {
        fill: var(--orange);
      }
    }
  }
  &__info {
    display: none;
    @include mobile {
      display: flex;
      padding: 7.5px 0;
      flex-direction: column;
      text-align: end;
      &-fullname {
        font-size: rem(16px);
        color: var(--white);
        line-height: 18px;
      }
      &-progress {
        font-size: rem(10px);
        color: var(--orange);
        line-height: 12px;
      }
      &-business {
        font-size: rem(12px);
        color: var(--business-gray);
      }
    }
  }
  &__image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    transition: 0.5s all ease;
  }
  &__progress {
    width: 45px;
    min-width: 45px;
    height: 45px;
    margin-left: 5px;
    font-size: large;
    @include mobile {
      margin-left: 15px;
    }
  }
  &__icon {
    width: 100%;
    margin-left: 5px;
    transition: 0.5s all ease;
    color: var(--white);
    padding: 10px;
    height: 44px;
    border-radius: 5px;

    @include mobile {
      width: auto;
      display: block;
      margin-left: 10px;
    }
    &:hover,
    &:active {
      background: var(--white);
      color: var(--charcoal);
    }
    &:target {
      background: var(--athens-gray);
      color: var(--charcoal);
    }
  }
  &__login-btn {
    &:first-child {
      margin-right: 10px;
    }
    .navbar__wrapper:not(.collapse-navbar) & {
      --btn-border-color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
