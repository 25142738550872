import CONSTANTS from '@/constants'

export default [
  {
    path: '/area/:id',
    name: 'learning-area',
    props: true,
    component: () => import('@/pages/LearningArea'),
  },
  {
    path: '/:area/courses/:id/:slug',
    name: 'learning-area-course',
    props: true,
    component: () => import('@/pages/Course.vue'),
  },
  {
    path: '/:area/courses/:id/:slug/lab/:labId',
    name: 'learning-area-course-lab',
    component: () => import('@/pages/LabPage.vue'),
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COURSE },
  },
  {
    path: '/:area/learning-paths/:id/:slug',
    name: 'learning-area-path',
    props: true,
    component: () => import('@/pages/LearningPath.vue'),
  },
  {
    path: '/:area/learning-paths/:id/:slug/lab/:labId',
    name: 'learning-area-path-lab',
    component: () => import('@/pages/LabPage.vue'),
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.LEARNING_PATH },
  },
  {
    path: '/:area/bootcamps/:id/:slug',
    name: 'learning-area-bootcamp',
    props: true,
    component: () => import('@/pages/Bootcamp.vue'),
  },
]
