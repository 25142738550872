var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"onboarding-modal"},[_c('div',{staticClass:"ine-card onboarding-modal__wrapper"},[_c('div',{staticClass:"onboarding-modal__wrapper-overlay"}),_c('div',{staticClass:"onboarding-modal__header"},[(_vm.currentStep !== _vm.steps[0])?_c('ArrowLeftIcon',{staticClass:"onboarding-modal__header-icon onboarding-modal__header-icon--back",on:{"click":function($event){return _vm.goPreviusStep()}}}):_vm._e(),_c('XIcon',{staticClass:"onboarding-modal__header-icon onboarding-modal__header-icon--close",on:{"click":function($event){return _vm.closeOnboardingModal()}}})],1),_c('div',{staticClass:"onboarding-modal__profile"},[_c('div',{staticClass:"onboarding-modal__background-1 onboarding-modal__background--mobile"}),_c('div',{staticClass:"onboarding-modal__background-2 onboarding-modal__background--mobile"}),_c('ProfileProgress',{staticClass:"onboarding-modal__profile-progress",attrs:{"currentAvatar":_vm.currentAvatar}}),(_vm.isLoadingSurvey)?_c('ActionLoader',{staticClass:"onboarding-modal__profile-label--loading"}):_vm._e(),(!_vm.isLoadingSurvey)?_c('div',{staticClass:"onboarding-modal__profile-label"},[_vm._v(" "+_vm._s(_vm.profileCompleteLabel)+" ")]):_vm._e(),_c('div',{staticClass:"onboarding-modal__profile-info"},[_vm._v(" Finish setting up your profile to customize your experience! ")]),_c('div',{staticClass:"onboarding-modal__profile-bullets-container"},[(_vm.showBullets)?_c('div',{staticClass:"onboarding-modal__profile-bullets-wrapper"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:`step-${i}`,class:[
              'onboarding-modal__profile-bullet',
              'bullet',
              'bullet--clickeable',
              step === _vm.currentStep && 'bullet--active',
            ],on:{"click":function($event){return _vm.goToStep(step)}}})}),0):_vm._e()])],1),_c('div',{staticClass:"onboarding-modal__steps"},[_c('XIcon',{staticClass:"onboarding-modal__header-icon onboarding-modal__steps-icon onboarding-modal__steps-icon--close",on:{"click":function($event){return _vm.closeOnboardingModal()}}}),(_vm.currentStep === _vm.profilePhoto)?_c('ProfilePhoto',_vm._b({on:{"go-next-step":_vm.goNextStep,"set-loader":_vm.setLoader,"set-component-loader":_vm.setComponentLoader}},'ProfilePhoto',{
          stepBadgeLabel: _vm.stepBadgeLabel,
          onboardingSurvey: _vm.onboardingSurvey,
        },false)):_vm._e(),(_vm.currentStep === _vm.interestArea)?_c('InterestArea',_vm._b({attrs:{"isFirstStep":_vm.isFirstStep(_vm.interestArea)},on:{"go-next-step":_vm.goNextStep,"go-previus-step":_vm.goPreviusStep,"set-loader":_vm.setLoader,"set-component-loader":_vm.setComponentLoader}},'InterestArea',{
          stepBadgeLabel: _vm.stepBadgeLabel,
          onboardingSurvey: _vm.onboardingSurvey,
          profilePreferences: _vm.profilePreferences,
        },false)):_vm._e(),(_vm.currentStep === _vm.backupEmail)?_c('BackupEmail',_vm._b({attrs:{"isFirstStep":_vm.isFirstStep(_vm.backupEmail)},on:{"go-next-step":_vm.goNextStep,"go-previus-step":_vm.goPreviusStep,"set-loader":_vm.setLoader,"set-component-loader":_vm.setComponentLoader}},'BackupEmail',{
          stepBadgeLabel: _vm.stepBadgeLabel,
          onboardingSurvey: _vm.onboardingSurvey,
          userProfile: _vm.userProfile,
        },false)):_vm._e(),(_vm.currentStep === _vm.trainingGoals)?_c('TrainingGoals',_vm._b({on:{"close-onboarding-modal":_vm.closeOnboardingModal,"go-previus-step":_vm.goPreviusStep,"set-loader":_vm.setLoader,"set-component-loader":_vm.setComponentLoader}},'TrainingGoals',{
          stepBadgeLabel: _vm.stepBadgeLabel,
          onboardingSurvey: _vm.onboardingSurvey,
          userProfile: _vm.userProfile,
        },false)):_vm._e(),(_vm.isLoading)?_c('ActionLoader',{class:[
          'onboarding-modal__loader',
          _vm.isLoadingComponent && 'onboarding-modal__loader--black',
          _vm.profileProgressLoading &&
            'onboarding-modal__progress-loader--black',
        ]}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }