import { MutationTree } from 'vuex'
import {
  ISonarState,
  ISonarUserStatus,
  ISonarStatus,
  ISonarAssessment,
  ISonarAssesmentQuestion,
  ISonarScore,
  ISonarAssessmentAttemptStatus,
} from '@/@types'

const mutations: MutationTree<ISonarState> = {
  CURRENT_USER_STATUS_UPDATED: (state, currentStatus: ISonarUserStatus) => {
    state.userStatus = currentStatus
  },
  CURRENT_SONAR_STATUS_UPDATED: (state, payload: ISonarStatus) => {
    state.sonarStatus = { ...state.sonarStatus, ...payload }
  },
  CURRENT_SCORE_UPDATED: (state, payload: ISonarScore) => {
    const { id } = payload
    const scoreIndex = state.currentScores.findIndex(score => score.id === id)
    if (scoreIndex < 0) return state.currentScores.push(payload)
    const currentScore = [...state.currentScores]
    currentScore[scoreIndex] = payload
    state.currentScores = currentScore
  },
  CLEAN_SCORE: state => {
    state.currentScores = []
  },
  CURRENT_ASSESSMENT_ATTEMPT_STATUS_UPDATED: (
    state,
    payload: ISonarAssessmentAttemptStatus
  ) => {
    state.assessmentAttemptStatus = {
      ...state.assessmentAttemptStatus,
      ...payload,
    }
  },

  CURRENT_ASSESSMENTS_UPDATED: (state, payload: Array<ISonarAssessment>) => {
    const learning_area_id = payload[0]?.learning_area_id
    const assessments = state.assessments.filter(
      (a: ISonarAssessment) => a.learning_area_id !== learning_area_id
    )
    state.assessments = [...assessments, ...payload]
  },
  CLEAN_ASSESSMENTS: state => {
    state.assessments = []
  },
  CURRENT_ASSESSMENT_ATTEMPT_UPDATED: (state, assessment: ISonarAssessment) => {
    state.assessmentAttempt = assessment
  },
  ASSESSMENT_ATTEMPTS_UPDATED: (
    state,
    assessments: Array<ISonarAssessment>
  ) => {
    state.assessmentAttempts = assessments
  },
  LAST_ASSESSMENT_UPDATED: (state, assessment: ISonarAssessment) => {
    state.lastAssessment = assessment
  },
  CURRENT_QUESTION_UPDATED: (state, question: ISonarAssesmentQuestion) => {
    state.currentQuestion = question
  },
  UPDATE_QUESTIONS_ANSWERED: (state, questionsAnswered: Array<any>) => {
    state.questionsAnswered = questionsAnswered
  },
  CURRENT_ASSESSMENT_TIMER_UPDATED: (
    state,
    currentAssessmentTimer: Boolean
  ) => {
    state.isAssessmentOutOfTime = currentAssessmentTimer
  },
  LAST_QUESTION_ANSWERED_UPDATED: (state, isLastQuestionAnswered: Boolean) => {
    state.isLastQuestionAnswered = isLastQuestionAnswered
  },
  CAN_ANSWER_QUESTIONS_UPDATED: (state, canAnswerQuestions: Boolean) => {
    state.canAnswerQuestions = canAnswerQuestions
  },
  IS_SONAR_QUIZ_RUNNING_UPDATED: (state, isSonarQuizRunning: Boolean) => {
    state.isSonarQuizRunning = isSonarQuizRunning
  },
  DISPLAY_CORRECT_ANSWERS_UPDATED: (state, displayCorrectAnswers: Boolean) => {
    state.displayCorrectAnswers = displayCorrectAnswers
  },
  IS_SEARCH_INDEX_UPDATED: (state, searchIndex: Object) => {
    state.algoliaSearchIndex = searchIndex
  },
}

export default mutations
