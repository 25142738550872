import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchUserStatus = async () => {
  const route = 'sonar-user-status'
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}
const fetchAssessments = async learning_area_id => {
  const route = learning_area_id
    ? `assessments?learning_area_id=${learning_area_id}`
    : 'assessments'
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAssessmentsBySkillArea = async skill_area_ids => {
  const ids = skill_area_ids.join(',')
  const route = `assessments?skill_area_ids=${ids}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAssessmentRecommendations = async payload => {
  const { skill_area_ids } = payload
  const route = `recommended-contents?skill_area_ids=${skill_area_ids}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const createAssessmentAttempt = async id => {
  const route = `assessments/${id}/attempts`
  return CONTENT.post(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAssessmentAttempt = async payload => {
  const { assessment_id, assessment_attempt_id } = payload
  const route = `assessments/${assessment_id}/attempts/${assessment_attempt_id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAssessmentAttemptsByStatus = async attempt_status => {
  const route = `assessments/attempts?status=${attempt_status}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchScoreByLearningArea = async learning_area_id => {
  const route = `sonar-learning-areas/${learning_area_id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchSharedScore = async score_id => {
  const route = `public-scores/${score_id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postScoringByLearningArea = async learning_area_id => {
  const route = `public-scores`
  const payload = { learning_area_id }
  return CONTENT.post(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchAssessmentById = async id => {
  const route = `assessment/${id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postSubmitQuestion = async questionAttempt => {
  const {
    question_id,
    assessment_attempt_id,
    submitted_answer,
    lab_session_id,
  } = questionAttempt
  const route = `questions/${question_id}/attempts`
  const payload = {
    assessment_attempt_id,
    submitted_answer,
    lab_session_id,
  }
  return CONTENT.post(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const putAssessmentStatus = async assessmentAttempt => {
  const { status, assessment_id, assessment_attempt_id, metadata } =
    assessmentAttempt
  const route = `assessments/${assessment_id}/attempts/${assessment_attempt_id}`
  const payload = {
    status,
  }
  if (metadata) payload.metadata = metadata
  return CONTENT.put(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchUserStatus,
  fetchAssessments,
  fetchAssessmentRecommendations,
  fetchScoreByLearningArea,
  fetchSharedScore,
  postScoringByLearningArea,
  createAssessmentAttempt,
  fetchAssessmentById,
  postSubmitQuestion,
  putAssessmentStatus,
  fetchAssessmentAttempt,
  fetchAssessmentAttemptsByStatus,
  fetchAssessmentsBySkillArea,
}
