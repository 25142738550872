<template>
  <div class="skill-dive-top-bar">
    <div class="skill-dive-top-bar__container">
      <SkillDiveLogo class="skill-dive-top-bar__container-logo" />
      <img
        class="skill-dive-top-bar__container-logo--png"
        src="@/assets/images/lab-experience/skilldive-logo.png"
      />
      <div class="skill-dive-top-bar__container__right">
        <template v-if="isLabExperienceUser">
          <div class="skill-dive-top-bar__container__right__remaining-pill">
            <div
              class="skill-dive-top-bar__container__right__remaining-pill__label"
            >
              <InfoIcon
                v-if="remainingHoursLabel < 10"
                class="skill-dive-top-bar__container-info-icon"
                size="16"
              />
              <span>
                {{ remainingHoursLabel }}
              </span>
            </div>
            <div
              :class="[
                'skill-dive-top-bar__container__right__remaining-pill__bar',
                `skill-dive-top-bar__container__right__remaining-pill__bar--${remainingColor}`,
              ]"
              :style="{
                width: `${
                  !remainingHours ? '100%' : `${remainingPercentage}%`
                }`,
              }"
            ></div>
          </div>
          <button
            class="skill-dive-top-bar__container__right__arrow-btn btn btn--small btn--tertiary btn--default-icon-color"
            @click="goToDashboard"
          >
            <ArrowRightIcon />
          </button>
        </template>
        <template v-else>
          <a
            :class="[
              'btn',
              'btn--small',
              isLoading && 'skill-dive-top-bar--disabled',
            ]"
            @click="getOrRequestAccess"
          >
            {{
              isBusinessUser && !isAccOwnerOrTeamManager
                ? 'Request access to Skill Dive'
                : 'Get access to Skill Dive'
            }}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import SkillDiveLogo from '@/assets/images/addons/skilldive.svg'
import { InfoIcon, ArrowRightIcon } from 'vue-feather-icons'
import { mapGetters, mapState } from 'vuex'
import { licenseAddonCommons } from '@/helpers/mixins'

export default {
  name: 'SkillDiveTopBar',
  components: {
    SkillDiveLogo: { ...SkillDiveLogo, name: 'SkillDiveLogo' },
    InfoIcon,
    ArrowRightIcon,
  },
  mixins: [licenseAddonCommons],
  data() {
    return {
      checkoutUrl: `https://${process.env.VUE_APP_CHECKOUT_URL}`,
      skillDiveEngage: `https://${process.env.VUE_APP_SKILL_DIVE_ENGANGE}`,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      labExperienceUserAccess: state => state.auth.labExperienceUserAccess,
    }),
    ...mapGetters({
      isLabExperienceUser: 'auth/isLabExperienceUser',
      isAccOwnerOrTeamManager: 'auth/isAccOwnerOrTeamManager',
      isBusinessUser: 'business/isBusinessUser',
    }),
    remainingPercentage() {
      const {
        remainingHours,
        labExperienceUserAccess: { purchased_time },
      } = this
      if (!remainingHours) return 0

      return (remainingHours * 100) / Math.round(purchased_time / 3600)
    },
    remainingHours() {
      const available_time = this.labExperienceUserAccess?.available_time

      return available_time ? Math.round(available_time / 3600) : 0
    },
    remainingColor() {
      const { remainingHours } = this
      if (remainingHours <= 5) return 'danger'
      else if (remainingHours <= 10) return 'warning'
      else return 'default'
    },
    remainingHoursLabel() {
      const { remainingHours } = this
      if (!remainingHours) return 'No remaining time'

      if (remainingHours === 1) return '1 hour remaining'

      return `${remainingHours} hours remaining`
    },
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: 'user-dashboard',
        params: { mode: 'subscriptions' },
      })
    },
    async getOrRequestAccess() {
      if (this.isBusinessUser && !this.isAccOwnerOrTeamManager) {
        if (!this.isLoading) {
          this.isLoading = true
          await this.requestAddonLicense('skill-dive')
          this.isLoading = false
        }
      } else {
        window.location.href = this.isAccOwnerOrTeamManager
          ? this.skillDiveEngage
          : this.checkoutUrl
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.skill-dive-top-bar {
  display: flex;
  background: var(--glass-dark);
  padding: var(--skill-dive-top-bar-padding);
  flex: 1;

  @include mobile {
    padding: var(--skill-dive-top-bar-padding) 30px;
    max-width: unset;
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__container {
    display: flex;
    justify-content: flex-end;

    align-items: center;
    width: 100%;

    @include mobile {
      justify-content: space-between;
    }

    &-logo {
      display: none;

      @include tablet {
        display: block;
      }

      &--png {
        display: block;
        max-width: 115px;
        margin-right: auto;
        @include mobile {
          max-width: 140px;
        }
        @include tablet {
          display: none;
        }
      }
    }
    &-info-icon {
      display: none;

      @include tablet {
        display: block;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 10px;
      &__remaining-pill {
        position: relative;
        color: var(--white);
        padding: 5px 8px;
        border-radius: 30px;
        background: #212122;
        box-shadow: 0px 0px 10px 0px rgba(167, 147, 246, 0.5);
        overflow: hidden;
        z-index: -2;
        font-size: rem(12);
        @include mobile {
          font-size: rem(14);
          line-height: rem(21);
        }
        &__label {
          display: flex;
          align-items: center;
          gap: 5px;
          z-index: 0;
          position: relative;
        }
        &__bar {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
          border-radius: 30px 0px 0px 30px;

          &--default {
            background: var(--secondary-purple);
          }
          &--warning {
            background: var(--primary-ine-gradient);
          }
          &--danger {
            background: var(--alert-gradient-negative);
          }
        }
      }
      &__arrow-btn {
        padding: 0 12px;
      }
    }
  }
}
</style>
