import { Module } from 'vuex'
import { IShoppingCartState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const shoppingCart: Module<IShoppingCartState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export default shoppingCart
