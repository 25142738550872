import { Module } from 'vuex'
import { ISearchState, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import state from './state'

const search: Module<ISearchState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export default search
