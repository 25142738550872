import { mapState } from 'vuex'
import CONSTANTS from '@/constants'

export const kinesisCommons = {
  computed: {
    ...mapState({
      currentCourse: state => state.courses.currentCourse,
      currentLearningPath: state => state.learningPaths.currentLearningPath,
      currentBootcamp: state => state.bootcamps.selectedBootcamp,
      currentParent: state => state.content.currentParent,
      businessUser: state => state.business.businessUser,
    }),
    parentData() {
      if (this.manualParent) {
        const parent_data = {}
        const parent_data_id = this.manualParent.objectID
          ? this.manualParent.objectID
          : this.manualParent.id
        parent_data['parent_id'] = parent_data_id
        parent_data[`${this.parentType}_slug`] = this.manualParent.slug
        if (this.parentType.instructors?.length) {
          const { instructors } = this.manualParent
          parent_data[`${this.parentType}_instructor_id`] =
            this.instructorId(instructors)
        }
        return {
          ...parent_data,
          parent_type: this.parentType,
          user_account_type: this.businessUser?.account?.id
            ? 'business'
            : 'customer',
          account_id: this.businessUser?.account?.id || null,
          account_name: this.businessUser?.account?.name || null,
          account_license_type:
            this.businessUser?.account?.license_type || null,
          account_pricing_plan:
            this.businessUser?.account?.pricing_plan || null,
          account_status: this.businessUser?.account?.status || null,
        }
      }

      let constructedParentData = {}
      if (
        this.parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.LEARNING_PATH
      ) {
        const { id, slug, instructors } = this.currentLearningPath
        constructedParentData = {
          parent_id: id,
          parent_slug: slug,
          instructor_id: this.instructorId(instructors),
          parent_type: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.LEARNING_PATH,
        }
      }
      if (this.parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.PLAYLIST) {
        const { id, slug } = this.currentParent
        constructedParentData = {
          parent_id: id,
          parent_slug: slug,
          instructor_id: null,
          parent_type: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.PLAYLIST,
        }
      }
      if (this.parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COURSE) {
        const { id, slug, instructors } = this.currentCourse
        constructedParentData = {
          parent_id: id,
          parent_slug: slug,
          instructor_id: this.instructorId(instructors),
          parent_type: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COURSE,
        }
      }

      constructedParentData = {
        ...constructedParentData,
        user_account_type: this.businessUser?.account?.id
          ? 'business'
          : 'customer',
        account_id: this.businessUser?.account?.id || null,
        account_name: this.businessUser?.account?.name || null,
        account_license_type: this.businessUser?.account?.license_type || null,
        account_pricing_plan: this.businessUser?.account?.pricing_plan || null,
        account_status: this.businessUser?.account?.status || null,
      }

      return { ...constructedParentData }
    },
  },
  methods: {
    instructorId(instructors) {
      return instructors && instructors[0] ? instructors[0].id : ''
    },
  },
}
