export default {
  getInstructorName: content => {
    const multipleInstructors = content?.instructors?.length
    const defaultName = 'INE Instructor'
    if (multipleInstructors) {
      return content?.instructors[0]?.name ?? defaultName
    }
    return content?.instructor?.name ?? defaultName
  },
  getAmountOfInstructors: content => {
    return content?.instructors?.length ?? 0
  },
  getVendorName: content => {
    const { vendor, vendors } = content
    if (vendor && typeof vendor === 'string') {
      return vendor
    }
    if (vendor && vendor.name) {
      return vendor.name
    }
    if (vendors && vendors.length) {
      return vendors[0].name
    }
    return 'INE'
  },
  getNameLetters(name) {
    return `${name?.substring(0, 1) || 'N'}`
  },
  getFullnameLetters(name, lastName) {
    return `${name?.substring(0, 1) || 'N'}${lastName?.substring(0, 1)}`
  },
}
