export const windowResize = {
  provide() {
    return {
      getBreakpoint: () => this.breakpoint,
      getWindowWidth: () => this.windowWidth,
    }
  },
  data() {
    return {
      breakpoint: null,
      windowWidth: 0,
    }
  },
  computed: {
    isTablet() {
      return this.windowWidth <= 1024 && this.windowWidth > 768
    },
  },
  methods: {
    setBreakpoint(windowWidth) {
      if (windowWidth) {
        if (windowWidth >= 1800) this.breakpoint = 'lg-desktop'
        else if (windowWidth >= 1300) this.breakpoint = 'desktop'
        else if (windowWidth >= 1024) this.breakpoint = 'tablet'
        else if (windowWidth >= 900) this.breakpoint = 'medium-device'
        else if (windowWidth >= 768) this.breakpoint = 'mobile'
        else this.breakpoint = 'extra-small'

        this.windowWidth = windowWidth
      }
    },
  },
  mounted() {
    this.setBreakpoint(window.innerWidth)

    window.addEventListener('resize', () => {
      this.setBreakpoint(window.innerWidth)
    })
  },
  destroyed() {
    window.removeEventListener('resize', () => null)
  },
}
