<template>
  <div class="avatar-preview">
    <img
      class="avatar-preview__preview"
      :src="imageData"
      alt="Preview of the icon representing the user, as uploaded"
    />
  </div>
</template>

<script>
export default {
  name: 'AvatarPreview',
  props: {
    avatar: {
      type: [String, Object, File],
      required: false,
    },
  },
  data() {
    return {
      imageData: '',
    }
  },
  watch: {
    avatar() {
      this.previewImage()
    },
  },
  created() {
    this.previewImage()
  },
  methods: {
    previewImage() {
      if (typeof this.avatar === 'object') {
        const reader = new FileReader()
        reader.onload = e => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(this.avatar)
      } else {
        this.imageData = this.avatar
      }
    },
  },
}
</script>
