import { IFRAME, CONTENT } from '@/clients'
import { djangoErrorHandler, shortenUUIDs, isParentPresent } from '@/helpers'

const fetchIFrameById = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `iframes/${id}?parent_type=${parentType}&parent_id=${parentId}`
    : `iframes/${id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchIFrames = async payload => {
  const { iframeIds, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `iframes?page_size=none&ids=${shortenUUIDs(iframeIds).join(
        ','
      )}&parent_type=${parentType}&parent_id=${parentId}`
    : `iframes?page_size=none&ids=${shortenUUIDs(iframeIds).join(',')}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchIFrameURL = async payload => {
  const { id, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `/iframes/${id}/media?parent_type=${parentType}&parent_id=${parentId}`
    : `/iframes/${id}/media`
  return IFRAME.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export { fetchIFrameById, fetchIFrames, fetchIFrameURL }
