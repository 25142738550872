import { format } from 'date-fns'

export const bootcampCommons = {
  computed: {
    learningAreaName() {
      const {
        bootcamp: { learning_areas },
      } = this
      return learning_areas && learning_areas[0] && learning_areas[0].name
        ? learning_areas[0].name
        : 'IT Essentials'
    },
    lastInstructors() {
      const offset = this.instructorsOffset || 3
      const content = this.bootcamp || this.parent
      return content.instructors.length >= offset
        ? content.instructors
            .slice(offset, content.instructors.length)
            .map(item => {
              return item.name ? item.name : 'INE Instructor'
            })
            .join(', ')
        : ''
    },
    contentInstructors() {
      const offset = this.instructorsOffset || 3
      const content = this.bootcamp || this.parent
      if (content.instructors.length === 2) {
        return `${content.instructors[0].name} and ${content.instructors[1].name}`
      }
      const threeFirst = content.instructors
        .slice(0, offset)
        .map(item => {
          return item.name ? item.name : 'INE Instructor'
        })
        .join(', ')
      return `${threeFirst}${
        content.instructors.length > offset
          ? ` and ${content.instructors.length - offset} more`
          : ''
      }`
    },
    bootcampDate() {
      const {
        bootcamp: { start_datetime: start, end_datetime: end },
      } = this
      const startDate = new Date(start)
      const endDate = new Date(end)
      const sameMonth = format(endDate, 'MMMM') === format(startDate, 'MMMM')
      const toEndDate = sameMonth
        ? format(endDate, 'do')
        : format(endDate, 'MMMM do')
      return `
      ${format(startDate, 'MMMM do')} - ${toEndDate}`
    },
    userRegistered() {
      const { bootcamp } = this
      return bootcamp.user_status && bootcamp.user_status.registered
    },
    limitedInstructors() {
      const offset = this.instructorsOffset || 3
      return this.bootcamp.instructors && this.bootcamp.instructors.length
        ? this.bootcamp.instructors.slice(0, offset)
        : []
    },
  },
  methods: {
    getInstructorImg(instructor) {
      return instructor && instructor.profile_img_url
        ? instructor.profile_img_url
        : require('@/assets/images/instructor_fallback.png')
    },
    getInstructorName(instructor) {
      return instructor && instructor.name ? instructor.name : 'INE Instructor'
    },
  },
}
