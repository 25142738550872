<template>
  <section class="maintenance">
    <MaintenanceScheduledBannerVue :forceUnderway="true" />
    <div class="maintenance__msg-wrapper">
      <LoadingSpinner />
      <h1 class="maintenance__msg-title">We'll be back</h1>
      <div class="maintenance__msg-description">
        We’re currently updating our system. If you need immediate assistance,
        please
        <a class="maintenance__msg-mailto" href="mailto:support@ine.com"
          >contact customer support</a
        >. Otherwise, please check back soon.
      </div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/loaders/LoadingSpinnerV2.vue'
import MaintenanceScheduledBannerVue from '@/components/banners/MaintenanceScheduledBanner.vue'

export default {
  name: 'Maintenance',
  components: { LoadingSpinner, MaintenanceScheduledBannerVue },
  metaInfo() {
    return {
      title: `INE - maintenance`,
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.maintenance {
  &__msg-wrapper {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    display: flex;

    flex-direction: column;
    align-items: center;

    margin-top: 200px;

    width: 333px;

    ::v-deep svg {
      color: #3ae180;
      width: 24px;
      height: 24px;
    }
    ::v-deep .loading-spinner__label {
      display: none;
    }

    @include mobile {
      margin-top: 300px;
      width: 675px;
      gap: 20px;
    }
  }

  &__msg {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-style: normal;
  }
  &__msg-title {
    color: var(--white);
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    margin: 0;

    @include mobile {
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__msg-description {
    color: var(--secondary-light-blue-gray, #afb0bf);
    text-align: center;
    font-size: 18px;
    font-weight: 450;
    line-height: 24px;

    @include mobile {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__msg-mailto {
    color: var(--secondary-purple-pale, #c9bbff);
  }
}
</style>
