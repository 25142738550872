import { mapGetters, mapState } from 'vuex'
import CONSTANTS from '@/constants'

export const subscriptionsCommons = {
  data() {
    return {
      isPremiumUpgradeModalShown: false,
      isUpgradeModalShown: false,
      subscriptionIdForPlanUpgrade: '',
      couponCodeForPremium: null,
    }
  },
  computed: {
    ...mapState({
      validPlans: state => state.subscriptions.validPlans,
      isUpgradePlanAllowed: state => state.subscriptions.isUpgradePlanAllowed,
    }),
    ...mapGetters({
      usersSubscriptions: 'subscriptions/usersSubscriptions',
      currentActivePlans: 'subscriptions/activePlans',
    }),
    activePlans() {
      return this.currentActivePlans?.map(currentActivePlan => {
        const validPlans = this.validPlans.find(
          vp => vp.id === currentActivePlan.id
        )
        return { ...currentActivePlan, valid_plans: validPlans?.validPlans }
      })
    },
  },
  methods: {
    handlePlanUpgrade({ id, plan_code }) {
      this.subscriptionIdForPlanUpgrade = id
      this.isUpgradeModalShown = true
      const validCodes = this.validPlans.map(plan => plan.code)
      const premiumPlansCodes = Object.values(CONSTANTS.PLANS.PREMIUM_PLANS)
      if (
        validCodes &&
        validCodes.every(code => premiumPlansCodes.includes(code))
      ) {
        const coupon = new URLSearchParams(window.location.search).get('coupon')
        if (coupon && coupon.length) {
          this.couponCodeForPremium = coupon
        }
        this.isPremiumUpgradeModalShown = this.showOnlyPremiumPlans(plan_code)
      }
    },
    showOnlyPremiumPlans(plan_code) {
      return ![CONSTANTS.PLANS.MONTHLY, CONSTANTS.PLANS.ANNUAL].includes(
        plan_code
      )
    },
  },
}
