export function errorHandler(error, key = 'message') {
  let message = 'Something went wrong'
  message =
    error?.response?.data?.error?.[key] ||
    error?.response?.data?.[key] ||
    error?.[key] ||
    error?.error?.[key] ||
    message
  return message
}

export function customUAAErrorHandler(error) {
  let code,
    mfa_method = ''
  if (error instanceof Error) {
    code = error.code
    mfa_method = error.mfa_method
  }
  return { code, mfa_method }
}
