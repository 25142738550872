import {
  ICertification,
  ILearningPath,
  ILearningPathProgress,
  ILearningPathsState,
} from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ILearningPathsState> = {
  LEARNING_PATHS_UPDATED: (state, learningPaths: Array<ILearningPath>) => {
    state.learningPaths = learningPaths
  },

  CURRENT_LEARNING_PATH_UPDATED: (state, learningPath: ILearningPath) => {
    state.currentLearningPath = learningPath
  },

  LEARNING_PATHS_CACHE_UPDATED: (state, learningPath: ILearningPath) => {
    state.learningPathsCache = {
      ...state.learningPathsCache,
      [learningPath.id!]: learningPath,
    }
  },

  CURRENT_LEARNING_PATH_CERTIFICATIONS_UPDATED: (
    state,
    certifications: Array<ICertification>
  ) => {
    state.currentLearningPathCertifications = certifications
  },

  LEARNING_PATH_PROGRESS_UPDATED: (state, progress: ILearningPathProgress) => {
    state.currentProgress = progress
  },

  LEARNING_PATHS_NEXT_CONTENT_CACHE_UPDATED: (state, { pathId, next }) => {
    state.learningPathsNextContentCache = {
      ...state.learningPathsNextContentCache,
      [pathId]: next,
    }
  },
}

export default mutations
