export const licenseAddonCommons = {
  methods: {
    async requestAddonLicense(addon_code) {
      try {
        await this.$store.dispatch('business/requestLicenseAddon', addon_code)
        this.$store.dispatch(
          'app/setSuccess',
          "You've successfully requested an skill dive license"
        )
      } catch (error) {
        this.$store.dispatch('app/Error', error)
      }
    },
  },
}
