import {
  fetchFileById,
  fetchFiles,
  fetchFileDownloadLink,
  fetchFileRendered,
} from '@/models/files'
import { ActionTree, ActionContext } from 'vuex'
import { IFilesState, IRootState } from '@/@types'

import { errorHandler } from '@/utils'

const actions: ActionTree<IFilesState, IRootState> = {
  getFileById: async (
    context: ActionContext<IFilesState, IRootState>,
    fileId: string
  ) => {
    try {
      const response = await fetchFileById(fileId)
      context.commit('UPDATE_FILES_LIST', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getFiles: async (
    context: ActionContext<IFilesState, IRootState>,
    files: Array<string>
  ) => {
    try {
      const response = await fetchFiles(files)
      context.commit('UPDATE_FILES_LIST', response)
      return response.results
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getFileRender: async (
    context: ActionContext<IFilesState, IRootState>,
    fileId: string
  ) => {
    try {
      const response = await fetchFileRendered(fileId)
      context.commit('UPDATE_FILES_RENDERED', {
        id: fileId,
        html: response,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getFileDownloadLink: async (
    context: ActionContext<IFilesState, IRootState>,
    fileId: string
  ) => {
    try {
      const response = await fetchFileDownloadLink(fileId)
      context.commit('UPDATE_FILES_DOWNLOADED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
}

export default actions
