import { CERTIFICATIONS } from '@/clients'
import { SUBSCRIPTIONS } from '@/clients'
import CONSTANTS from '@/constants'
import { djangoErrorHandler } from '@/helpers'

const postExamAttempt = async userAttemptAndEntityIds => {
  const { voucherId } = userAttemptAndEntityIds
  const route = `vouchers/${voucherId}/attempts`
  return CERTIFICATIONS.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const getExamAttempt = async userAttemptAndEntityIds => {
  const { voucherId, attemptId } = userAttemptAndEntityIds
  const route = `vouchers/${voucherId}/attempts/${attemptId}`
  return CERTIFICATIONS.get(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const putExamAttempt = async userAttemptAndEntityIds => {
  const { voucherId, attemptId, submission } = userAttemptAndEntityIds
  const route = `vouchers/${voucherId}/attempts/${attemptId}`
  const payload = {
    status: CONSTANTS.CONTENT_USER_STATUS.FINISHED,
    questions: submission,
  }
  return CERTIFICATIONS.put(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const patchExamAttempt = async userAttemptAndEntityIds => {
  const { voucherId, attemptId, submission, status, feedback } =
    userAttemptAndEntityIds
  const route = `vouchers/${voucherId}/attempts/${attemptId}`
  const payload = {
    questions: submission,
  }
  if (status) {
    payload.status = status
  }
  if (feedback) {
    payload.feedback = feedback
  }
  return CERTIFICATIONS.patch(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchUsersCertifications = async () => {
  try {
    const route = 'certifications/me'
    const {
      data: { results },
    } = await CERTIFICATIONS.get(route)
    return results
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchUsersVouchers = async () => {
  try {
    const route = `vouchers/me`
    const {
      data: { results },
    } = await CERTIFICATIONS.get(route)
    return results
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchVoucher = async voucherId => {
  try {
    const route = `vouchers/${voucherId}`
    const { data } = await CERTIFICATIONS.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const getCertifications = async params => {
  const route = `certifications`
  return CERTIFICATIONS.get(route, { params })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const getCertificate = async attemptId => {
  const route = `attempts/${attemptId}/certificate`
  return CERTIFICATIONS.get(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const getPdfCertificate = async (certification_name, attempt_id) => {
  const route = `attempts/${attempt_id}/certificate-pdf`
  return CERTIFICATIONS.get(route, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${certification_name}.pdf`
      link.click()
      Promise.resolve()
    })
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postVerifyLab = async ({ attemptId, voucherId }) => {
  const route = `vouchers/${voucherId}/attempts/${attemptId}/submit-practice`
  return CERTIFICATIONS.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postSubmitQuestion = async userAttemptAndUserAnswer => {
  const { attemptId, voucherId, answer } = userAttemptAndUserAnswer
  const route = `vouchers/${voucherId}/attempts/${attemptId}/submit-question`
  const payload = {
    uuid: answer.question_id,
    user_answer: answer.answer,
  }
  return CERTIFICATIONS.post(route, payload)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postExamFeedback = async ({ attemptId, voucherId, feedback }) => {
  const route = `vouchers/${voucherId}/attempts/${attemptId}/feedback`
  return CERTIFICATIONS.post(route, { feedback })
}

const postAvailableRenewals = async attempt_id => {
  const route = 'certifications/renewal-attempts'
  return CERTIFICATIONS.post(route, { user_exam_attempt_id: attempt_id })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const getAvailableRenewals = async () => {
  const route = 'certifications/renewal-attempts'
  return CERTIFICATIONS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postCertificationsBasicInfo = async payload => {
  const route = 'attempts/search'
  return CERTIFICATIONS.post(route, { ids: payload.join(',') })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postRenewalCostPreview = async payload => {
  // The payload should contain an object with the following structure:
  // {
  //   cert_renewal_attempt_id: certRenewalAttemptId,
  // }
  // certRenewalAttemptId, // This ID can be obtained from the 'certifications/renewal-attempts' endpoint
  const route = 'certification/renewal/preview'
  return SUBSCRIPTIONS.post(route, payload)
    .then(response => response.data)
    .catch(error => {
      throw djangoErrorHandler(error)
    })
}
const postRenewalPurchase = async payload => {
  // The payload should contain an object with the following structure:
  // {
  //   {
  //   cert_renewal_attempt_id: {certRenewalAttemptId} (required),
  //   billing_token: {token} (optional, if adding/replacing payment method on file)
  //   payment_challenge_token: {token} (optional, if 3DS challenge required)
  // }
  // certRenewalAttemptId, // This ID can be obtained from the 'certifications/renewal-attempts' endpoint
  const route = 'certification/renewal'
  return SUBSCRIPTIONS.post(route, payload)
    .then(response => response.data)
    .catch(error => {
      throw djangoErrorHandler(error)
    })
}

export {
  postExamAttempt,
  putExamAttempt,
  patchExamAttempt,
  getExamAttempt,
  fetchUsersCertifications,
  fetchUsersVouchers,
  fetchVoucher,
  getCertifications,
  getCertificate,
  getPdfCertificate,
  postVerifyLab,
  postSubmitQuestion,
  postExamFeedback,
  getAvailableRenewals,
  postAvailableRenewals,
  postCertificationsBasicInfo,
  postRenewalCostPreview,
  postRenewalPurchase,
}
