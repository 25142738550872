import { mapActions, mapGetters } from 'vuex'
import CONSTANTS from '@/constants'

export const labHandlerCommons = {
  computed: {
    ...mapGetters({
      isChangingLabState: 'labs/isChangingLabState',
    }),
    isLabSessionRunning() {
      return (
        this.currentLabSession?.status === CONSTANTS.LABS.SESSION_STATUS.STARTED
      )
    },
  },
  methods: {
    ...mapActions('labs', [
      'getRunningLab',
      'updateLabStatus',
      'getLabSession',
      'getLabById',
      'partialUpdateLabSession',
      'createLabSession',
    ]),
    async updateSessionStatus(payload) {
      const { status, parentType, parentId } = payload
      const labSessionPayload = {
        labId: this.currentLab.id,
        sessionId: this.currentLabSession.id,
        status,
        parentId: this.parentId || parentId,
        parentType: this.parentType || parentType,
      }
      const response = await this.partialUpdateLabSession(labSessionPayload)
      if (response && response.errorCode) {
        this.$emit('custom-lab-error', response.errorCode)
      }
    },
    async toggleLab() {
      if (this.isLabSessionRunning) {
        await this.updateSessionStatus({
          status: CONSTANTS.LABS.SESSION_STATUS.TERMINATED,
        })
      }
    },
    async fetchContent() {
      const { labId, parentId, parentType } = this
      this.updateLabStatus({ isFetchingLab: true })
      await this.getRunningLab()
      const payload = {
        id: labId,
        parentId,
        parentType,
      }
      if (labId && parentId && parentType) await this.getLabById(payload)
      const sessionId = this.currentLab?.global_running_session?.id
      if (!this.isChangingLabState && sessionId) {
        const sessionPayload = {
          labId,
          parentId,
          parentType,
          sessionId,
        }
        await this.getLabSession(sessionPayload)
      }
      this.updateLabStatus({ isFetchingLab: false })
    },
    async switchLabs() {
      this.loadingText = this.isSwitchingLabs
        ? 'Switching labs'
        : 'Restarting lab'
      const regionSelected = localStorage.getItem(`pta-lab-region`) || 'US-East'
      const keyboardSelected =
        localStorage.getItem(`pta-lab-keyboard`) || 'en-us-qwerty'
      try {
        await this.toggleLab()
        await this.fetchContent()
        await this.createLabSession({
          id: this.currentLab.id,
          region: regionSelected,
          keyboard_layout: keyboardSelected,
          parentId: this.parentId,
          parentType: this.parentType,
        })
      } catch (e) {
        this.loadingText = ''
      } finally {
        this.updateLabStatus({
          isSwitchingLabs: false,
          isRestartingLab: false,
          isStartingLab: false,
        })
      }
    },
  },
}
