import { ICPEProgramState } from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ICPEProgramState> = {
  CPE_CREDITS_UPDATED: (state, credits: Number) => {
    state.cpeGlobalCredits = credits
  },

  CPE_HISTORY_UPDATED: (state, cpeCreditHistory: Array<object>) => {
    state.cpeCreditHistory = cpeCreditHistory
  },

  CPE_HISTORY_EXTERNAL_UPDATED: (
    state,
    cpeCreditHistoryExternal: Array<object>
  ) => {
    state.cpeCreditHistoryExternal = cpeCreditHistoryExternal
  },

  CPE_HISTORY_NEXT_PAGE_UPDATED: (state, hasNextPage: boolean) => {
    state.cpeCreditHistoryHasNextPage = hasNextPage
  },
  CPE_RENEW_MODAL_STATUS_UPDATED: (state, cpeModalStatus: object) => {
    state.cpeRenewModalStatus = {
      ...state.cpeRenewModalStatus,
      ...cpeModalStatus,
    }
  },
}

export default mutations
