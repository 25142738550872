import { djangoErrorHandler } from '@/helpers'
import { BUSINESS_PYTHON, BUSINESS_PYTHON_ANONYMOUS } from '@/clients'

const fetchBusinessUser = async () => {
  const route = '/users/me/'
  return BUSINESS_PYTHON.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchInvitation = async payload => {
  const { id } = payload
  const route = `invite_links/${id}/`
  return BUSINESS_PYTHON_ANONYMOUS.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postInvitation = async payload => {
  const { id, uaa_id } = payload
  const route = `invite_links/${id}/accept/`
  return BUSINESS_PYTHON.post(route, { uaa_id })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const createUserFromBusisess = async payload => {
  const { id, email, first_name, last_name, password } = payload
  const route = `invite_links/${id}/signup/`
  return BUSINESS_PYTHON.post(route, { email, first_name, last_name, password })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const requestLicense = async () => {
  const route = `user_invites/request_license/`
  return BUSINESS_PYTHON.post(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.resolve(djangoErrorHandler(error)))
}

const requestLicenseAddon = async addonCode => {
  const route = `user_invites/request_license_addon/`
  return BUSINESS_PYTHON.post(route, { addon_code: addonCode })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.resolve(djangoErrorHandler(error)))
}

export {
  fetchInvitation,
  postInvitation,
  createUserFromBusisess,
  fetchBusinessUser,
  requestLicense,
  requestLicenseAddon,
}
