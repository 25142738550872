import { UAA } from '@/clients'
import { laravelErrorHandler } from '@/helpers'

const confirmUser = async email => {
  const route = `admin/confirm`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const resetPassword = async emailAndPassword => {
  const { email, password } = emailAndPassword
  const route = `admin/password`
  return UAA.post(route, { email, password })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const suspendUser = async email => {
  const route = `admin/suspend`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const liftSuspension = async email => {
  const route = `admin/unsuspend`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchUserForImpersonation = async email => {
  const route = `impersonate`
  return UAA.post(route, { email })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

export {
  confirmUser,
  resetPassword,
  suspendUser,
  liftSuspension,
  fetchUserForImpersonation,
}
