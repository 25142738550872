<template>
  <div class="sonar-home-banner">
    <div class="sonar-home-banner__container">
      <div class="sonar-home-banner__wrapper">
        <div class="sonar-home-banner__pill">NEW</div>
        <div class="sonar-home-banner__title">Skill Sonar Assessments</div>
        <div class="sonar-home-banner__info">
          See where your skills stack up.
        </div>
        <router-link
          :to="{
            name: 'sonar',
            params: { learningArea: defaultLearningArea },
          }"
          class="btn btn--primary sonar-home-banner__cta"
        >
          Take an assessment
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/constants'

export default {
  name: 'SonarHomeBanner',
  computed: {
    defaultLearningArea() {
      return CONSTANTS.SONAR.LEARNING_AREAS['Cyber Security'].slug
    },
  },
}
</script>

<style lang="scss" scoped>
.sonar-home-banner {
  &__container {
    width: 100%;
    height: var(--home-banner-height);
    background: var(--glass-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white);
    height: 100%;
    background: url('../assets/images/banner-radar.png');
    background-repeat: no-repeat;
    background-position-x: 0;
    padding: 0px 50px;
  }
  &__pill {
    font-size: rem(12);
    line-height: rem(14);
    padding: 5px 8px;
    background: var(--primary-ine-gradient);
    box-shadow: 0px 0px 10px rgba(248, 179, 105, 0.5);
    border-radius: 30px;
  }
  &__title {
    font-size: rem(24);
    line-height: rem(32);
    font-weight: 600;
  }
  &__info {
    font-size: rem(14);
    line-height: rem(20);
  }
  &__cta {
    font-size: rem(14);
    line-height: rem(20);
    height: 36px;
    padding: 16px;
    margin-left: 10px;
  }
}
</style>
