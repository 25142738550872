import { MutationTree } from 'vuex'
import { IBootcampsState, IBootcamp, IUserBootcampsStatus } from '@/@types'

const mutations: MutationTree<IBootcampsState> = {
  CURRENT_BOOTCAMPS: (
    state,
    bootcamps: {
      current?: Array<IBootcamp>
      next?: IBootcamp
      upcoming: Array<IBootcamp>
      past?: Array<IBootcamp>
    }
  ) => {
    state.currentBootcamps = bootcamps
  },
  SELECTED_BOOTCAMP_UPDATED: (state, bootcamp: IBootcamp) => {
    state.selectedBootcamp = bootcamp
  },
  BOOTCAMPS_USER_STATUS_UPDATED: (
    state,
    userStatusList: IUserBootcampsStatus[]
  ) => {
    state.searchUserStatusBootcamps = userStatusList
  },
  BOOTCAMPS_USER_STATUS_CHANGED: (state, status: IUserBootcampsStatus) => {
    const bs = state.searchUserStatusBootcamps.find(
      (item: IUserBootcampsStatus) => item.id === status.id
    )
    if (bs) {
      bs.user_status = status.user_status
    }
  },
}

export default mutations
