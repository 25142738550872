export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login'),
  },
  {
    path: '/two-factor',
    name: 'two-factor',
    component: () => import('@/pages/TwoFactor'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/pages/Logout'),
  },
  {
    path: '/federated-login',
    name: 'federated-login',
    component: () => import('@/pages/FederatedLogin'),
  },
  {
    path: '/confirm-account',
    name: 'confirm-account',
    component: () => import('@/pages/ConfirmAccount.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/PasswordReset.vue'),
  },
  {
    path: '/invite/:id',
    name: 'invitation-landing',
    component: () => import('@/pages/BusinessInvitationLanding.vue'),
  },
  {
    path: '/invite-expired',
    name: 'invite-expired',
    component: () => import('@/pages/ExpiredInvitation.vue'),
  },
  {
    path: '/confirm-invite/:id',
    name: 'confirm-invite',
    component: () => import('@/pages/ConfirmInvitation.vue'),
  },
]
