import { ILabExperience, IRootState } from '@/@types'

import CONSTANTS from '@/constants'
import { GetterTree } from 'vuex'

const getters: GetterTree<ILabExperience, IRootState> = {
  showSkillDive: (state, getters, rootState, rootGetters) => {
    return (
      !rootGetters['business/isBusinessUser'] ||
      (rootGetters['business/isBusinessUser'] &&
        !CONSTANTS.BUSINESS_OLD_PRICING_PLANS.includes(
          rootGetters['business/getBusinessUser'].account?.pricing_plan
        )) ||
      rootGetters['auth/isSkillDiveB2BUser'] ||
      rootGetters['app/labExperienceEnhance']
    )
  },
  showSkillDiveResults: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters['auth/isSkillDiveB2CUser'] ||
      rootGetters['auth/isSkillDiveB2BUser'] ||
      rootGetters['app/labExperienceEnhance']
    )
  },
  userAvailableLearningAreas: (state, getters, rootState, rootGetters) => {
    if (rootGetters['auth/isSkillDiveB2CUser']) {
      return rootState.auth.available_passes
        .filter(pass =>
          CONSTANTS.SKILL_DIVE_B2C_PASSES_LEARNING_AREAS.hasOwnProperty(pass.id)
        )
        .map(
          pass =>
            CONSTANTS.SKILL_DIVE_B2C_PASSES_LEARNING_AREAS[
              pass.id as keyof typeof CONSTANTS.SKILL_DIVE_B2C_PASSES_LEARNING_AREAS
            ]
        )
    } else if (
      rootGetters['auth/isSkillDiveB2BUser'] ||
      rootGetters['app/labExperienceEnhance']
    ) {
      return CONSTANTS.LAB_EXPERIENCE.LEARNING_AREAS
    }
    return []
  },
}

export default getters
