import { GetterTree } from 'vuex'
import { ICPEProgramState, IRootState } from '@/@types'

const getters: GetterTree<ICPEProgramState, IRootState> = {
  cpeGlobalCredits: (state: ICPEProgramState) => state.cpeGlobalCredits,
  cpeCreditHistory: (state: ICPEProgramState) => state.cpeCreditHistory,
  cpeCreditHistoryExternal: (state: ICPEProgramState) =>
    state.cpeCreditHistoryExternal,
  cpeCreditHistoryHasNextPage: (state: ICPEProgramState) =>
    state.cpeCreditHistoryHasNextPage,
  cpeRenewModalStatus: (state: ICPEProgramState) => state.cpeRenewModalStatus,
  cpeRenewModalOption: (state: ICPEProgramState) =>
    state.cpeRenewModalStatus.currentOption,
  cpeRenewModalStep: (state: ICPEProgramState) =>
    state.cpeRenewModalStatus.step,
}

export default getters
