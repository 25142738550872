import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchLab = async payload => {
  const { id, parentType, parentId } = payload
  const route = `labs/${id}`
  return CONTENT.get(route, {
    params: {
      ...(parentType && { parent_type: parentType }),
      ...(parentId && { parent_id: parentId }),
    },
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabSession = async payload => {
  const { id, region, keyboard_layout, parentId, parentType } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${id}/sessions${params}`
  return CONTENT.post(route, { region, keyboard_layout })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabPing = async payload => {
  const { id, parentId, parentType } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${id}/ping${params}`
  return CONTENT.post(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchLabSession = async payload => {
  const { labId, sessionId, parentId, parentType } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/sessions/${sessionId}${params}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const patchLabSession = async payload => {
  const { labId, sessionId, status, parentType, parentId } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/sessions/${sessionId}${params}`
  return CONTENT.patch(route, { status })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchLabSessionVPNFile = async payload => {
  const { labId, sessionId, parentType, parentId } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/sessions/${sessionId}/vpn-config${params}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabFlag = async payload => {
  const { labId, flagId: uuid, answer, parentType, parentId } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/flags${params}`
  return CONTENT.post(route, { uuid, answer })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabPractice = async payload => {
  let params = ''
  if (payload) {
    const { parentType, parentId } = payload
    params = parentType
      ? `?parent_type=${parentType}&parent_id=${parentId}`
      : ''
  }
  const route = `labs/running-session${params}`
  return CONTENT.post(route, { lab_objectives_ids: payload.lab_objectives_ids })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchRunningLab = async payload => {
  let params = ''
  if (payload) {
    const { parentType, parentId } = payload
    params = parentType
      ? `?parent_type=${parentType}&parent_id=${parentId}`
      : ''
  }
  const route = `labs/running-session${params}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabStatus = async payload => {
  const { id, status, parentId, parentType } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''

  const route = `labs/${id}/status${params}`
  return CONTENT.post(route, { status })
}

const postLabsBasicInfo = async payload => {
  const route = `labs/search`
  return CONTENT.post(route, { ids: payload.join(','), fields: 'base' })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postLabStatuses = async payload => {
  const { ids } = payload
  const route = `labs/search`
  return CONTENT.post(route, { ids, fields: 'user_lab_status' })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const postExtendLab = async payload => {
  const { labId, sessionId, parentId, parentType } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/sessions/${sessionId}/extend-session${params}`
  return CONTENT.post(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const resetLabFlags = async payload => {
  const { labId, parentType, parentId } = payload
  const params = parentType
    ? `?parent_type=${parentType}&parent_id=${parentId}`
    : ''
  const route = `labs/${labId}/reset_flags${params}`
  return CONTENT.post(route, {})
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchLab,
  postLabPing,
  postLabSession,
  fetchLabSession,
  patchLabSession,
  fetchLabSessionVPNFile,
  postLabFlag,
  postLabPractice,
  fetchRunningLab,
  postLabStatus,
  postLabStatuses,
  postExtendLab,
  postLabsBasicInfo,
  resetLabFlags,
}
