<template>
  <div class="loading-spinner">
    <ActionLoader loaderClass="loading-spinner__spinner" />
  </div>
</template>

<script>
import ActionLoader from '@/components/shared/ActionLoader.vue'

export default {
  name: 'LoadingSpinner',
  components: {
    ActionLoader,
  },
}
</script>

<style lang="scss" scoped>
.loading-spinner {
  background-color: var(--dark-gray);
  text-align: center;
  z-index: 200;
  height: 100vh;
  margin: 35% 0 0 0;
  @include mobile {
    margin: 20% 0 0 0;
  }

  &__spinner {
    margin: 0 auto;
    color: var(--orange);
  }
}
</style>
