import { differenceInDays, formatDistanceToNow } from 'date-fns'
import { mapGetters, mapState } from 'vuex'

import CONSTANTS from '@/constants'
import { licenseAddonCommons } from './licenseAddonCommons'

export const collectionCommons = {
  mixins: [licenseAddonCommons],
  data() {
    return {
      labs: [],
      labContent: null,
      extraTag: null,
      extraTagTooltip: null,
      skillDiveLabsLoading: false,
      keyConceptList: [],
      checkoutUrl: `https://${process.env.VUE_APP_CHECKOUT_URL}`,
      skillDiveEngage: `https://${process.env.VUE_APP_SKILL_DIVE_ENGANGE}`,
    }
  },
  computed: {
    ...mapState({
      currentCollection: state => state.labExperience.currentCollection,
      availablePasses: state => state.auth.available_passes,
      highlightedCollection: state => state.labExperience.highlightedCollection,
    }),
    ...mapGetters({
      labExperienceEnhance: 'app/labExperienceEnhance',
      isSkillDiveB2CUser: 'auth/isSkillDiveB2CUser',
      isSkillDiveB2BUser: 'auth/isSkillDiveB2BUser',
      isAccOwnerOrTeamManager: 'auth/isAccOwnerOrTeamManager',
      isBusinessUser: 'business/isBusinessUser',
    }),
    totalLabs() {
      return this.labs?.length || 0
    },
    difficulty() {
      return this.currentCollection?.difficulty
    },
    collectionLearningAreaName() {
      return (
        this.currentCollection?.learning_areas?.length &&
        this.currentCollection?.learning_areas[0].name
      )
    },
    collectionLearningAreas() {
      return (
        this.currentCollection?.learning_areas?.length &&
        this.currentCollection?.learning_areas.map(la => la.name)
      )
    },
    description() {
      return this.currentCollection?.description
    },
    formatedDateFromApi() {
      const { published_date } = this.content
      if (typeof published_date === 'string') {
        // comes string from content-api
        return new Date(published_date)
      } else {
        // comes number from algolia
        return new Date(published_date * 1000)
      }
    },
    addedTimeAgoLabel() {
      const days = differenceInDays(
        new Date(),
        new Date(this.formatedDateFromApi)
      )
      if (days >= 15 && days <= 30) {
        return 'Recently added'
      }
      const timeAgo = formatDistanceToNow(this.formatedDateFromApi, {
        addSuffix: true,
      })

      return `Added ${timeAgo}`
    },
    showAddedDaysAgo() {
      const { addedTimeAgoLabel } = this
      return addedTimeAgoLabel.match(/month|year/) ? false : addedTimeAgoLabel
    },
    isVulnerabilityColl() {
      return (
        this.currentCollection?.id ===
        CONSTANTS.LABS_COLLECTIONS.VULNERABILITIES
      )
    },
    availableLearningAreas() {
      if (this.isSkillDiveB2CUser) {
        return this.availablePasses
          .filter(
            pass => CONSTANTS.SKILL_DIVE_B2C_PASSES_LEARNING_AREAS[pass.id]
          )
          .map(pass => CONSTANTS.SKILL_DIVE_B2C_PASSES_LEARNING_AREAS[pass.id])
      } else if (this.isSkillDiveB2BUser || this.labExperienceEnhance) {
        return CONSTANTS.LAB_EXPERIENCE.LEARNING_AREAS
      }
      return []
    },
    skillDiveDescription() {
      return this.labContent.description_html || this.labContent.description
    },
  },
  methods: {
    getLabsFromCollection(collection) {
      let labsList = []
      collection?.content?.forEach(group =>
        group.content?.forEach(topic =>
          topic.content?.forEach(
            content => (labsList = labsList.concat(content))
          )
        )
      )
      this.labs = labsList
      return labsList
    },
    lockedContent(collectionContext) {
      const las = collectionContext.learning_areas.map(la => la.name)
      return !this.availableLearningAreas.some(la => las.includes(la.name))
    },
    async getAccessToLockedSDLab(collectionContext) {
      const collection = collectionContext || this.currentCollection
      if (this.lockedContent(collection)) {
        if (this.isSkillDiveB2CUser) {
          this.$router.push({
            name: 'user-dashboard',
            params: {
              mode: 'subscriptions',
            },
          })
        } else if (this.isBusinessUser && !this.isAccOwnerOrTeamManager) {
          if (!this.skillDiveLabsLoading) {
            this.skillDiveLabsLoading = true
            await this.requestAddonLicense('skill-dive')
            this.skillDiveLabsLoading = false
          }
        } else {
          window.location.href = this.isAccOwnerOrTeamManager
            ? this.skillDiveEngage
            : this.checkoutUrl
        }
      }
    },
    setSkillDiveTags() {
      let tags = []
      const count = 6
      if (this.labContent?.tags?.length) {
        tags = this.labContent.tags.slice(0, count)
      }

      if (this.labContent?.tags?.length > count) {
        this.extraTag = `+ ${this.labContent?.tags?.length - count}`
        this.extraTagTooltip = this.labContent?.tags
          .slice(count - 1, this.labContent?.tags?.length)
          .join('\r\n')
      }

      this.keyConceptList = tags
    },
  },
}
