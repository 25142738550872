import { ActionContext, ActionTree } from 'vuex'
import {
  IPlan,
  IRootState,
  ISubscriptionsState,
  ISubscriptionPayload,
  IError,
  IChallengePayload,
} from '@/@types'
import {
  executePromotionBySlug,
  fetchCouponInfo,
  fetchInvoiceDownload,
  fetchInvoices,
  fetchPassTypes,
  fetchPaymentInfo,
  fetchPlanComparison,
  fetchPlanConsumed,
  fetchPromotions,
  fetchSkillDiveAddHoursPreviewPurchase,
  fetchSkillDiveAddHoursPurchase,
  fetchSubscriptionCancellationFields,
  fetchSubscriptions,
  fetchTermExtensionPricing,
  fetchValidAddons,
  fetchValidPlans,
  getAvailablePlans,
  postCancelSubscription,
  postCheckout,
  postNewTrial,
  postPurchaseVoucherExtension,
  postReactivateSubscription,
  postSubscriptionCancellationReasons,
  postUpdatePaymentMethod,
  putOneTermToSubscription,
  putUpgradePlan,
  updateAddonsForSubscription,
} from '../../../models/subscriptions'
import { getAccessToken } from '@internetworkexpert/js-common'
import router from '@/router'

import CONSTANTS from '@/constants'
import { errorHandler } from '@/utils'

const actions: ActionTree<ISubscriptionsState, IRootState> = {
  getSubscriptions: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const subscriptions = await fetchSubscriptions()
      context.commit('CURRENT_SUBSCRIPTIONS_UPDATED', subscriptions)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getValidAddonsForSubscription: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      const validAddons = await fetchValidAddons(id)
      context.commit('VALID_ADDONS_UPDATED', validAddons)
    } catch (error) {
      return error
    }
  },

  getSkillDivePlanConsumed: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const planConsumed = await fetchPlanConsumed()
      context.commit('PLAN_CONSUMED_UPDATED', planConsumed)
    } catch (error) {
      return error
    }
  },

  addAddonsForSubscription: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    payload
  ) => {
    try {
      const response = await updateAddonsForSubscription(
        payload.id,
        payload.addons
      )
      if (response) {
        context.dispatch(
          'app/setSuccess',
          `You've successfully added learning areas!`,
          { root: true }
        )
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updatePaymentMethod: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    token: string
  ) => {
    try {
      const { success } = await postUpdatePaymentMethod(token)
      if (success) {
        context.dispatch(
          'app/setNotification',
          "You've successfully updated your payment method!",
          { root: true }
        )
      }
    } catch (error) {
      if (
        (error as any).processor_error_code &&
        (error as any).processor_error_code ===
          CONSTANTS.SUBSCRIPTIONS.THREE_D_SECURE
      ) {
        throw error
      } else {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
      }
    }
  },

  cancelSubscription: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      await postCancelSubscription(id)
      const subscriptions = await fetchSubscriptions()
      context.commit('CURRENT_SUBSCRIPTIONS_UPDATED', subscriptions)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  reactivateSubscription: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      await postReactivateSubscription(id)
      const subscriptions = await fetchSubscriptions()
      context.commit('CURRENT_SUBSCRIPTIONS_UPDATED', subscriptions)
      context.dispatch(
        'app/setNotification',
        "You've successfully reactivated the renewal on the subscription.",
        { root: true }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getTermExtensionPricing: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      const pricing = await fetchTermExtensionPricing(id)
      context.commit('CURRENT_ONE_TERM_PRICING_UPDATED', pricing)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  addOneTermToSubscription: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      await putOneTermToSubscription(id)
      const subscriptions = await fetchSubscriptions()
      context.commit('CURRENT_SUBSCRIPTIONS_UPDATED', subscriptions)
      context.dispatch(
        'app/setNotification',
        "You've successfully extended the subscription",
        { root: true }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getAllInvoices: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const invoices = await fetchInvoices()
      context.commit('CURRENT_INVOICES_UPDATED', invoices)
    } catch (error) {
      return error
    }
  },

  downloadInvoice: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    id: string
  ) => {
    try {
      await fetchInvoiceDownload(id)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getPassTypes: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const passes = await fetchPassTypes()
      context.commit('PASS_TYPES_UPDATED', passes)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  fetchAvailablePlans: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const {
        subscriptions,
        certification_vouchers,
      }: { [key: string]: IPlan } = await getAvailablePlans()
      const availablePlans = Object.values(subscriptions).map(current => {
        if (current.merchant_id === CONSTANTS.PLANS.MONTHLY) {
          current.term = 'month'
        } else {
          current.term = 'year'
        }
        return current
      })
      context.commit('AVAILABLE_PLANS_UPDATED', availablePlans.reverse())
      context.commit(
        'PRODUCT_CERTIFICATION_VOUCHERS_UPDATED',
        certification_vouchers
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getPlanComparison: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    idAndPlanCode
  ) => {
    try {
      const comparison = await fetchPlanComparison(idAndPlanCode)
      context.commit('PLAN_COMPARISON_UPDATED', comparison)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  upgradePlan: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    idAndPlanCode
  ) => {
    try {
      const plan = await putUpgradePlan(idAndPlanCode)
      context.commit('SUBSCRIPTION_UPGRADED', plan)
      context.dispatch(
        'app/setNotification',
        'Your upgrade is being processed and should be complete momentarily. Please check back later. A new invoice will be sent to your email',
        { root: true }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  isUpgradePlanAllowed: (
    context: ActionContext<ISubscriptionsState, IRootState>,
    value
  ) => {
    context.commit('IS_UPGRADE_PLAN_ALLOWED_UPDATED', value)
  },

  getValidPlans: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    planId
  ) => {
    try {
      const validPlans = await fetchValidPlans(planId)
      if (validPlans) {
        const subscriptions = context.state.currentSubscriptions
        const subscription = subscriptions?.find(sub => sub.id === planId)
        if (subscription) {
          const { id, plan_code } = subscription
          const subscription_valid_plans = { id, plan_code, validPlans }
          context.commit('VALID_PLANS_UPDATED', subscription_valid_plans)
        }
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getPaymentInfo: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const {
        account_credit,
        payment_method_mask,
        payment_method_object,
        billing_account_code,
      }: {
        account_credit: string
        payment_method_mask: string
        payment_method_object: string
        billing_account_code: string
      } = await fetchPaymentInfo()
      context.commit('PAYMENT_INFO_UPDATED', {
        account_credit,
        payment_method_mask,
        payment_method_object,
        billing_account_code,
      })
    } catch (error) {
      return error
    }
  },

  setBillingToken: (
    context: ActionContext<ISubscriptionsState, IRootState>,
    value
  ) => {
    context.commit('BILLING_TOKEN_UPDATED', value)
  },

  getCancellationFields: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const survey = await fetchSubscriptionCancellationFields()
      context.commit('CANCELLATION_FIELDS_UPDATED', survey)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  sendCancellationResponses: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    payload: object
  ) => {
    try {
      await postSubscriptionCancellationReasons(payload)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  submitTrial: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    payload
  ) => {
    try {
      const user = await postNewTrial(payload)
      return user
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  changePromotionContext: (
    context: ActionContext<ISubscriptionsState, IRootState>,
    promotionContext: String
  ) => {
    context.commit('SET_CURRENT_PROMOTION_CONTEXT', promotionContext)
  },

  purchaseVoucherExtension: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    payload: object
  ) => {
    try {
      const response = await postPurchaseVoucherExtension({ payload })

      if (response.id) {
        context.dispatch(
          'app/setNotification',
          "You've successfully purchased voucher extension!",
          { root: true }
        )
      }
    } catch (error) {
      throw error
    }
  },

  skillDiveAddHoursPreviewPurchase: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    quantity: number
  ) => {
    const payload = {
      items: [
        {
          code: CONSTANTS.SKILL_DIVE.SKILL_DIVE_ADD_HOURS,
          quantity,
        },
      ],
    }
    try {
      context.commit('ADD_HOURS_PRICE_LOADING_UPDATED', true)
      const response = await fetchSkillDiveAddHoursPreviewPurchase({ payload })

      if (response.total) {
        context.commit(
          'SKILL_DIVE_ADD_HOURS_PREVIEW_PURCHASE_UPDATED',
          response.total
        )
        context.commit('ADD_HOURS_PRICE_LOADING_UPDATED', false)
      }
    } catch (error) {
      throw error
    }
    context.commit('ADD_HOURS_PRICE_LOADING_UPDATED', false)
  },
  skillDiveAddHoursPurchase: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    quantity: number
  ) => {
    try {
      const payload = {
        items: [
          {
            code: CONSTANTS.SKILL_DIVE.SKILL_DIVE_ADD_HOURS,
            quantity,
          },
        ],
      }
      const response = await fetchSkillDiveAddHoursPurchase({ payload })
      if (response.success) {
        const addedHours =
          quantity * CONSTANTS.SKILL_DIVE.SKILL_DIVE_ADD_HOURS_MIN_VALUE
        const {
          rootState: {
            auth: { labExperienceUserAccess },
          },
        } = context
        let { available_time, consumed_time, purchased_time, state } =
          labExperienceUserAccess
        available_time += addedHours * 3600
        purchased_time += addedHours * 3600

        context.commit(
          'auth/UPDATE_LAB_EXPERIENCE_USER_ACCESS',
          {
            available_time,
            consumed_time,
            purchased_time,
            state,
          },
          {
            root: true,
          }
        )
        context.dispatch(
          'app/setSuccess',
          `Congratulations! You have just added ${addedHours} Skill Dive hours to your account.`,
          { root: true }
        )
      }
    } catch (error) {
      throw error
    }
  },
  getPromotions: async (
    context: ActionContext<ISubscriptionsState, IRootState>
  ) => {
    try {
      const promotions = await fetchPromotions()
      context.commit('PROMOTIONS_AVAILABLE_UPDATED', promotions.data)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  executePromotionBySlug: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    payload: {
      slug: string
      subscriptionId: string
      billing_token?: string
      payment_challenge_token?: string
    }
  ) => {
    try {
      const response = await executePromotionBySlug(payload)
      if (response.success) {
        context.dispatch(
          'app/setNotification',
          'Promotion has been successfully executed',
          { root: true }
        )
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getCouponInformation: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    couponCode: string
  ) => {
    try {
      const couponInformation = await fetchCouponInfo(couponCode)
      return couponInformation
    } catch (error) {
      throw error
    }
  },
  changePromotionBannerVisibility: (
    context: ActionContext<ISubscriptionsState, IRootState>,
    value: boolean
  ) => {
    context.commit('CHANGE_VISIBILITY_PROMOTION_BANNER', value)
  },
  submitPurchase: async (
    context: ActionContext<ISubscriptionsState, IRootState>,
    tokens: {
      challengeToken: string | null
      captchaToken: string | null
    }
  ) => {
    const { challengeToken, captchaToken } = tokens
    const planCodes = Object.values(CONSTANTS.PLANS.ALL)
    const selectedPlan = context.rootGetters['shoppingCart/selectedPlan']
    const user_data = context.rootState.auth.userProfile ?? ''
    const coupon = context.rootState.shoppingCart?.coupon ?? ''
    const billing_token = context.state?.billingToken ?? ''
    const accessToken = getAccessToken()
    const filteredItems = context.rootState.shoppingCart.shoppingCart.filter(
      i => i && i.merchant_id && !planCodes.includes(i.merchant_id)
    )
    const items =
      filteredItems && filteredItems.length
        ? filteredItems.map(i => {
            return { code: i.merchant_id }
          })
        : []
    const utm = context.rootState.events.utmParams ?? {}
    const plans = []
    if (selectedPlan.merchant_id) {
      const plan = {
        code: selectedPlan.merchant_id,
        trial: selectedPlan.plan_trial ?? false,
        add_ons: [],
      }
      plans.push(plan)
    }

    const payload = {
      plans,
      coupon,
      billing_token,
      origin:
        process.env.VUE_APP_ORIGIN !== undefined
          ? process.env.VUE_APP_ORIGIN
          : '',
      items,
      utm,
      captchaToken,
    }
    try {
      if (accessToken) {
        const payloadWAccessToken: IChallengePayload = Object.assign(
          {},
          payload,
          {
            payment_challenge_token: challengeToken ?? '',
            accessToken,
          }
        )
        return await postCheckout(payloadWAccessToken)
      }
      const payloadWUserData: ISubscriptionPayload = Object.assign(
        {},
        payload,
        {
          user_data,
        }
      )
      const response = await postCheckout(payloadWUserData)
      return response
    } catch (errorObject) {
      const error = errorObject as IError
      const { processor_error_code } = error
      const { message } = error.error ? error.error : (error as Error)
      context.commit('THREE_DS_FLOW_UPDATED', false)
      if (processor_error_code && processor_error_code.length) {
        if (processor_error_code === 'three_d_secure_action_required') {
          const { actionTokenId } = error
          const td_access_token = getAccessToken()
          return context.dispatch('trigger3dsFlow', {
            actionTokenId,
            td_access_token,
          })
        }
        if (processor_error_code === 'declined') {
          context.dispatch(
            'app/setError',
            `Your charge has been declined. Please contact your bank if you believe this was a mistake.`,
            {
              root: true,
            }
          )
          return false
        }
        context.dispatch(
          'app/setError',
          'There was an issue with the credit card provided. Please contact your bank.',
          {
            root: true,
          }
        )
        return false
      }
      if (
        message === 'Token is either invalid or expired' ||
        message === "Trying to get property 'account_code' of non-object"
      ) {
        context.commit('RECURLY_TOKEN_UPDATED', '')
        router.push({ name: 'login' })
        return context.dispatch(
          'app/setError',
          'Session expired. Please re-enter your payment details.',
          {
            root: true,
          }
        )
      }
      context.dispatch('app/setError', message, { root: true })
    } finally {
      context.dispatch('app/setIsComponentLoading', false, { root: true })
    }
  },
}

export default actions
