import { GetterTree } from 'vuex'
import { IBootcampsState, IRootState } from '@/@types'

const getters: GetterTree<IBootcampsState, IRootState> = {
  next: state => {
    const {
      currentBootcamps: { next },
    } = state
    return next
  },
  upcoming: state => {
    const {
      currentBootcamps: { upcoming },
    } = state
    return upcoming && upcoming.length ? upcoming : []
  },
  past: state => {
    const {
      currentBootcamps: { past },
    } = state
    return past && past.length ? past : []
  },
  current: state => {
    const {
      currentBootcamps: { current },
    } = state
    return current && current.length ? current : []
  },
}

export default getters
