import CONSTANTS from '@/constants'

export default [
  {
    path: '/learning-paths',
    name: 'learning-paths',
    component: () => import('@/pages/AllLearningPaths'),
  },
  {
    path: '/path/:id',
    name: 'learning-path',
    props: true,
    component: () => import('@/pages/LearningPath'),
  },
  {
    path: '/path/:id/lab/:labId',
    name: 'learning-path-lab',
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.LEARNING_PATH },
    component: () => import('@/pages/LabPage.vue'),
  },
]
