import { GetterTree } from 'vuex'
import { IBusinessState, IRootState } from '@/@types'

const getters: GetterTree<IBusinessState, IRootState> = {
  isBusinessUser: state => {
    if (state.businessUser) {
      return !!state.businessUser.account
    }
    return false
  },
  getBusinessUser: state => {
    if (state.businessUser) {
      return state.businessUser
    }
    return false
  },
  getBusinessLicense: state => {
    if (state.businessUser) {
      return state.businessUser?.user?.license
    }
    return null
  },
}

export default getters
