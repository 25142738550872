import { djangoErrorHandler } from '@/helpers'
import { CONTENT } from '@/clients'

const fetchBootcamps = async () => {
  const route = `bootcamps`
  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchBootcampsBySchedule = async () => {
  const route = `bootcamps/schedule`
  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchBootcampById = async id => {
  const route = `bootcamps/${id}`
  try {
    const { data } = await CONTENT.get(route, ['Resource not found'])
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const registerForBootcampById = async id => {
  const route = `bootcamps/${id}/registration`
  try {
    const { data } = await CONTENT.post(route, {})
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchUserStatusForBootcamps = async ids => {
  const route = `bootcamps/status?ids=${ids}`
  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

export {
  fetchBootcamps,
  fetchBootcampsBySchedule,
  fetchBootcampById,
  registerForBootcampById,
  fetchUserStatusForBootcamps,
}
