import { IPlaylistsState } from '@/@types'

const state: IPlaylistsState = {
  playlists: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  playlistCache: [],
}

export default state
