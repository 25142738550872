import Vuex, { StoreOptions } from 'vuex'

import { IRootState } from '@/@types'
import Vue from 'vue'
import admin from './modules/admin'
import app from './modules/app'
import auth from './modules/auth'
import bootcamps from './modules/bootcamps'
import business from './modules/business'
import certifications from './modules/certifications'
import content from './modules/content'
import courses from './modules/courses'
import cpeProgram from './modules/cpe-program'
import events from './modules/events'
import exercises from './modules/exercises'
import faqs from './modules/faqs'
import files from './modules/files'
import iframes from './modules/iframes'
import labExperience from './modules/lab-experience'
import labs from './modules/labs'
import learningAreas from './modules/learning-areas'
import learningPaths from './modules/learning-paths'
import playlists from './modules/playlists'
import quizzes from './modules/quizzes'
import search from './modules/search'
import shoppingCart from './modules/shopping-cart'
import sonar from './modules/sonar'
import statistics from './modules/statistics'
import subscriptions from './modules/subscriptions'

Vue.use(Vuex)

const store: StoreOptions<IRootState> = {
  modules: {
    admin,
    app,
    auth,
    bootcamps,
    business,
    certifications,
    content,
    courses,
    cpeProgram,
    events,
    exercises,
    faqs,
    files,
    iframes,
    labExperience,
    labs,
    learningAreas,
    learningPaths,
    playlists,
    quizzes,
    search,
    shoppingCart,
    sonar,
    statistics,
    subscriptions,
  },
}

export default new Vuex.Store<IRootState>(store)
