<template>
  <div class="send-issue" role="alert">
    <DialogModal @close-dialog-modal="closeModal">
      <div v-if="!hasSendIssue" class="send-issue__form">
        <h3 class="send-issue__title">
          What is the problem with this {{ humanContentType }}?
        </h3>
        <div class="send-issue__type">
          <Dropdown
            :options="currentIssueTypes"
            :loading="isLoading"
            :handleSelection="dropdownHandler"
            :placeholder="'Which best describes your issue?'"
          />
        </div>
        <div class="send-issue__comment">
          <textarea
            v-model="comment"
            class="send-issue__comment-textarea send-issue__form-field"
            rows="5"
            cols="50"
            placeholder="Description of the issue"
          ></textarea>
          <small
            >Are you experiencing a different issue? Please
            <a
              target="_blank"
              rel="noopener"
              href="https://ine.com/pages/contact-us"
            >
              contact support</a
            >.
          </small>
        </div>
        <div class="action-buttons">
          <button
            :disabled="isPostingData || !requirements"
            type="submit"
            class="btn btn--secondary"
            @click="sendIssue"
          >
            Submit
          </button>
        </div>
      </div>
      <div v-if="hasSendIssue" class="send-issue__thankyou">
        <div class="send-issue__thankyou__wrapper">
          <h3 class="send-issue__thankyou__title">
            Thank you for providing feedback!
          </h3>
          <p class="send-issue__thankyou__message">
            We’re continually working to improve your experience.
          </p>
        </div>
      </div>
    </DialogModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DialogModal from '@/components/modals/DialogModal.vue'
import Dropdown from '@/components/forms/elements/Dropdown.vue'

export default {
  name: 'SendIssueModal',
  components: {
    DialogModal,
    Dropdown,
  },
  data: () => {
    return {
      comment: '',
      subject: '',
      isPostingData: false,
      hasSendIssue: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      currentCourse: state => state.courses.currentCourse,
      currentLearningPath: state => state.learningPaths.currentLearningPath,
      currentBootcamp: state => state.bootcamps.selectedBootcamp,
      contentIssues: state => state.content.contentIssues,
      currentIssue: state => state.content.currentIssue,
      currentParent: state => state.content.currentParent,
    }),
    currentIssueTypes() {
      return this.contentIssues[this.currentIssue.content_type]?.[
        this.currentIssue.content_subtype || 'default'
      ]
    },
    humanContentType() {
      return this.currentIssue.content_type === 'iframe'
        ? 'slide'
        : this.currentIssue.content_type
    },
    requirements() {
      return !!(this.comment && this.subject)
    },
  },
  async created() {
    if (!this.contentIssues) this.fetchContentIssues()
  },
  methods: {
    ...mapActions('content', [
      'getContentIssues',
      'sendContentIssue',
      'resetContentIssue',
    ]),
    async fetchContentIssues() {
      this.isLoading = true
      await this.getContentIssues()
      this.isLoading = false
    },
    async sendIssue() {
      this.isPostingData = true
      try {
        await this.sendContentIssue({
          ...this.currentIssue,
          subject: this.subject,
          comment: this.comment,
        })

        this.hasSendIssue = true
      } catch (error) {
        this.closeModal()
      }
      this.isPostingData = false
    },
    dropdownHandler(subject) {
      this.subject = subject
    },
    closeModal() {
      this.resetContentIssue()
    },
  },
}
</script>
<style lang="scss" scoped>
.send-issue {
  color: var(--white);
  ::v-deep .dialog-modal__wrapper {
    color: var(--white);
    padding: 0;
    @include tablet {
      max-width: 530px;
    }
    .action-buttons {
      justify-content: flex-end;
    }
  }
  &__title {
    margin: 18px auto;
    font-size: rem(18);
    line-height: rem(30);
    @include mobile {
      line-height: rem(50);
    }
  }
  &__form {
    padding: 2rem 3rem;
  }
  &__type {
    position: relative;
    margin: 0px 0px 30px 0;
    &-select {
      cursor: pointer;
      color: var(--white);
      background: var(--darker-gray);
      border: 1px solid rgba(60, 60, 60, 0.26);
      border-radius: 4px;
      padding: 10px 40px 10px 10px;
      width: 100%;
      height: 46px;
      appearance: none;
      &-icon {
        content: '';
        font-size: 1rem;
        top: 11px;
        right: 13px;
        position: absolute;
        color: var(--light-blue-gray);
        pointer-events: none;
      }
      &:invalid {
        color: var(--gray);
      }
    }
  }
  &__comment {
    &-textarea {
      color: var(--white);
      background: none;
      border: 1px solid rgba(60, 60, 60, 0.26);
      border-radius: 4px;
      padding: 5px;
      width: 100%;
    }
    small {
      color: var(--white);
      font-size: 12px;
    }
    a {
      color: var(--white);
    }
  }
  h3 {
    font-size: 18px;
    width: 100%;
  }

  &__thankyou {
    width: 100%;
    min-height: 250px;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    background-image: url('https://s3.amazonaws.com/my.ine.com/assets/issue-background.png');
    text-align: center;
    color: var(--white);
    flex-wrap: wrap;
    &__wrapper {
      width: 100%;
    }
    &__title {
      font-size: 28px;
      font-weight: bold;
      margin: 0;
    }
    &__message {
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
  &__form-field {
    background: var(--darker-gray);
    border: 1px solid var(--emperor-gray);
  }
}
</style>
