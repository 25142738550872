import { MutationTree } from 'vuex'
import { IEventsState } from '@/@types'

const mutations: MutationTree<IEventsState> = {
  UPDATE_UTM_PARAMS: (state, params: object) => {
    state.utmParams = params
  },
}

export default mutations
