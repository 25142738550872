import { IAuthState, IRootState, IShortPass } from '@/@types'

import CONSTANTS from '@/constants'
import { GetterTree } from 'vuex'

const getters: GetterTree<IAuthState, IRootState> = {
  isBusinessUser: state => {
    if (state.userProfile) {
      const { account_id } = state.userProfile
      return !!account_id
    }
    return false
  },

  canManageMFA: state => {
    if (state.userProfile) {
      const { mfa_required } = state.userProfile
      if (mfa_required && mfa_required === 1) {
        return false
      }
    }
    return true
  },

  hasPlaylists: state => state.hasPlaylists,

  isFreeTrialUser: state => {
    if (state.subscriptionStatus && state.subscriptionStatus.length) {
      return state.subscriptionStatus === 'free'
    }
    return false
  },
  isBusinessManager: state => {
    return state.isBusinessManager
  },
  isLabExperienceLegacyB2BUser: state => {
    if (state.available_passes?.length) {
      // return true if user has two passes: one from CONSTANTS.LAB_EXPERIENCE_LEGACY_B2B_PASSES and none from CONSTANTS.BUSINESS_PASSES
      const labExperienceEnable = state.available_passes.some(
        (pass: IShortPass) =>
          CONSTANTS.LAB_EXPERIENCE_LEGACY_B2B_PASSES.includes(pass.id)
      )
      const businessPassesEnable = state.available_passes.some(
        (pass: IShortPass) => CONSTANTS.BUSINESS_PASSES.includes(pass.id)
      )
      return labExperienceEnable && !businessPassesEnable
    }
    return false
  },
  isSkillDiveB2BUser: state => {
    const labExperienceEnable = state.available_passes.some(
      (pass: IShortPass) => CONSTANTS.SKILL_DIVE_B2B_PASSES.includes(pass.id)
    )
    return labExperienceEnable
  },
  isSkillDiveB2CUser: state => {
    if (state.available_passes?.length) {
      const labExperienceEnable = state.available_passes.some(
        (pass: IShortPass) =>
          CONSTANTS.LAB_EXPERIENCE_B2C_PASSES.includes(pass.id)
      )
      return labExperienceEnable
    }
    return false
  },
  isLabExperienceUser: (state, getters) => {
    return (
      getters.isLabExperienceLegacyB2BUser ||
      getters.isSkillDiveB2BUser ||
      getters.isSkillDiveB2CUser
    )
  },
  isSkillDiveB2CNetworking: state => {
    const { LAB_EXPERIENCE_B2C_NETWORKING_PASS } = CONSTANTS
    return state.available_passes.some(
      p => p.id === LAB_EXPERIENCE_B2C_NETWORKING_PASS
    )
  },
  isSkillDiveB2CCloud: state => {
    const { LAB_EXPERIENCE_B2C_CLOUD_PASS } = CONSTANTS
    return state.available_passes.some(
      p => p.id === LAB_EXPERIENCE_B2C_CLOUD_PASS
    )
  },
  isSkillDiveB2CCyberSecurity: state => {
    const { LAB_EXPERIENCE_B2C_CYBER_SECURITY_PASS } = CONSTANTS
    return state.available_passes.some(
      p => p.id === LAB_EXPERIENCE_B2C_CYBER_SECURITY_PASS
    )
  },
  isAuthenticated: state => {
    return state.isAuthenticated
  },
  isAccOwnerOrTeamManager: state => {
    return state.isAuthenticated && state.isBusinessManager
  },
  isAdminOrContentCreator: state =>
    state.isAuthenticated && (state.isAdmin || state.isContentCreator),
  profileInterestAreas: state => {
    return state.profilePreferences && state.profilePreferences.passes
      ? state.profilePreferences.passes
      : []
  },
  isMarkAsCompletedEnabled: state =>
    state?.enabled_user_features?.mark_as_completed,
  isCpeProgramEnabled: state => state?.enabled_user_features?.cpe,
}

export default getters
