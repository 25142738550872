export default [
  {
    path: '/business-v2',
    name: 'business-v2',
    beforeEnter() {
      window.location.href = `https://${process.env.VUE_APP_DASHBOARD_URL}`
    },
  },
  {
    path: '/business-v1',
    name: 'business-v1',
    beforeEnter() {
      window.location.href = `https://${process.env.VUE_APP_BUSINESS_URL}`
    },
  },
]
