import { ILabsState } from '@/@types'

const state: ILabsState = {
  currentLab: {
    id: undefined,
    user_status: undefined,
    user_status_modified: undefined,
    global_running_session: undefined,
    available_resets: undefined,
    user_lab_attempt: undefined,
  },
  currentLabSession: null,
  focusedLabId: '',
  currentFlags: null,
  userCurrentFlags: null,
  runningLabSession: null,
  labStatus: {
    isSwitchingLabs: false,
    isStartingLab: false,
    isRestartingLab: false,
    isFetchingLab: false,
  },
  labUserStatuses: {},
}

export default state
