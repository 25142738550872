<template>
  <section>
    <Dropdown
      :options="options"
      :handleSelection="handler"
      placeholder="Expertise Level"
      :selectedOption="interestArea.expertiseLevel"
      :appendToBody="append"
      :clearable="true"
    />
  </section>
</template>
<script>
import Dropdown from '@/components/forms/elements/Dropdown.vue'

export default {
  name: 'ExpertiseLevel',
  components: {
    Dropdown,
  },
  props: {
    interestArea: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      reuqired: true,
    },
    append: {
      type: Boolean,
      default: false,
    },
    handleSelection: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handler(level) {
      this.interestArea.expertiseLevel = level
      this.handleSelection(this.interestArea)
    },
  },
}
</script>
