import { ActionContext, ActionTree } from 'vuex'
import { IProductExtended, IRootState, IShoppingCartState } from '@/@types'

const actions: ActionTree<IShoppingCartState, IRootState> = {
  addProductToShoppingCart: (
    context: ActionContext<IShoppingCartState, IRootState>,
    product: IProductExtended
  ) => {
    const newShoppingCartState = context.state.shoppingCart
    newShoppingCartState.push(product)
    context.commit('SHOPPING_CART_UPDATED', newShoppingCartState)
  },
  removeProductFromShoppingCart: (
    context: ActionContext<IShoppingCartState, IRootState>,
    product: IProductExtended
  ) => {
    context.commit('SHOPPING_CART_REMOVED_ITEM_UPDATED', product)
  },
  updateCoupon: (
    context: ActionContext<IShoppingCartState, IRootState>,
    payload
  ) => {
    context.commit('COUPON_UPDATED', payload)
  },
  clearCart: (context: ActionContext<IShoppingCartState, IRootState>) => {
    const shoppingCart = context.state.shoppingCart
    if (shoppingCart.length > 0) {
      shoppingCart.forEach(item => {
        context.dispatch('removeProductFromShoppingCart', item)
      })
    }
  },
}

export default actions
