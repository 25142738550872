import CONSTANTS from '@/constants'
import { getEnvVarNumber } from '@/helpers/env'

const setContentStatusCooldown = getEnvVarNumber(
  'VUE_APP_SET_CONTENT_STATUS_COOLDOWN'
)

export const contentCommons = {
  data: () => {
    return {
      isCooling: false,
    }
  },
  methods: {
    handleContentStatusChangeCooling() {
      if (setContentStatusCooldown) {
        this.isCooling = true
        setTimeout(() => {
          this.isCooling = false
        }, setContentStatusCooldown)
      }
    },
    isContentInParent(contentType, contentId, parentType, parent) {
      return parent?.content?.some(group => {
        if (
          parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.PLAYLIST ||
          parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.LEARNING_PATH
        ) {
          // parent structure for playlist and learnin-path
          // playlist.content -> groups
          // group.content -> items
          return group.content?.some(item => {
            return item.content_type === contentType && item.uuid === contentId
          })
        }
        // parent structure
        // course.content -> groups
        // group.content -> topics
        // topic.content -> items
        return group.content?.some(topic => {
          return topic.content?.some(item => {
            return item.content_type === contentType && item.uuid === contentId
          })
        })
      })
    },
  },
}
