import {
  fetchExerciseById,
  fetchExerciseTestsByExerciseId,
  fetchExerciseSolutionsByExerciseId,
  runCode,
  runTest,
  runAttempt,
  fetchExerciseAttempts,
  setExerciseStatus,
} from '@/models/exercises'
import { ActionTree, ActionContext } from 'vuex'
import CONSTANTS from '@/constants'
import { getParentFromRootState } from '@/helpers'
import { IExercisesState, IRootState, IExercise } from '@/@types'

import { errorHandler } from '@/utils'

const actions: ActionTree<IExercisesState, IRootState> = {
  getExerciseById: async (
    context: ActionContext<IExercisesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchExerciseById(payload)
      context.commit('CURRENT_EXERCISE_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      context.commit('CURRENT_EXERCISE_UPDATED', '')
    }
  },

  getExerciseTestsByExerciseId: async (
    context: ActionContext<IExercisesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchExerciseTestsByExerciseId(payload)
      context.commit('CURRENT_EXERCISE_TESTS_UPDATED', response.results)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getExerciseSolutionsByExerciseId: async (
    context: ActionContext<IExercisesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchExerciseSolutionsByExerciseId(payload)
      context.commit('CURRENT_EXERCISE_SOLUTIONS_UPDATED', response.results)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  getExerciseAttemptsByExerciseId: async (
    context: ActionContext<IExercisesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const response = await fetchExerciseAttempts({
        id,
        parentId,
        parentType,
      })
      context.commit('CURRENT_EXERCISE_ATTEMPTS_UPDATED', response.results)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  runExerciseTest: async (
    context: ActionContext<IExercisesState, IRootState>,
    params: { testId: string }
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      context.commit('SET_TEST_RUNNING', {
        testId: params.testId,
        running: true,
      })
      const response = await runTest({ ...params, parentId, parentType })
      context.commit('UPDATE_TESTS_STATUS', {
        response,
        testId: params.testId,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  runAllExerciseTests: async (
    context: ActionContext<IExercisesState, IRootState>
  ) => {
    try {
      const results = []
      let params = {}
      for (let index = 0; index < context.state.currentTests.length; index++) {
        const test = context.state.currentTests[index]
        params = {
          exerciseId: context.state.currentExercise.id,
          testId: test.id,
          code: context.state.studentCodes[context.state.currentExercise.id],
          flavor: context.state.currentFlavor,
        }
        results.push(context.dispatch('runExerciseTest', params))
      }
      return Promise.all(results)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  runExerciseCode: async (
    context: ActionContext<IExercisesState, IRootState>
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id: context.state.currentExercise.id,
        submitted_code:
          context.state.studentCodes[context.state.currentExercise.id],
        flavor: context.state.currentFlavor,
        parentId,
        parentType,
      }
      context.commit('RESET_CURRENT_OUTPUT')
      const response = await runCode(payload)
      context.commit('UPDATE_CURRENT_OUTPUT', {
        response,
      })
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  runExerciseAttempt: async (
    context: ActionContext<IExercisesState, IRootState>
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id: context.state.currentExercise.id,
        submitted_code:
          context.state.studentCodes[context.state.currentExercise.id],
        flavor: context.state.currentFlavor,
        parentId,
        parentType,
      }
      context.commit('RESET_CURRENT_OUTPUT')
      const response = await runAttempt(payload)
      context.commit('ADD_EXERCISE_ATTEMPT', {
        ...response.attempt,
        exercise: payload.id,
        submitted_code: payload.submitted_code,
      })
      context.commit('UPDATE_CURRENT_OUTPUT', {
        response,
        showSummary: true,
      })
      context.commit(
        'content/EXERCISE_STATUS_UPDATED',
        {
          id: payload.id,
          user_status: CONSTANTS.CONTENT_USER_STATUS.FINISHED,
        },
        { root: true }
      )
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  resetExerciseCode: (
    context: ActionContext<IExercisesState, IRootState>,
    exercise: IExercise
  ) => {
    context.commit('UPDATE_CURRENT_CODE', {
      id: exercise.id,
      code: exercise.starter_code,
    })
  },

  clearExerciseStore: (context: ActionContext<IExercisesState, IRootState>) => {
    context.commit('CURRENT_EXERCISE_TESTS_UPDATED', [])
    context.commit('CURRENT_EXERCISE_SOLUTIONS_UPDATED', [])
    context.commit('CURRENT_EXERCISE_ATTEMPTS_UPDATED', [])
    context.commit('RESET_CURRENT_OUTPUT')
  },

  setCurrentExercise: (
    context: ActionContext<IExercisesState, IRootState>,
    exercise: IExercise
  ) => {
    context.dispatch('clearExerciseStore')
    context.commit('CURRENT_EXERCISE_UPDATED', exercise)
  },
  setExerciseStatus: async (
    context: ActionContext<IExercisesState, IRootState>,
    payload: { exercise: IExercise; status: String }
  ) => {
    const { exercise, status } = payload
    const { id: parentId, type: parentType } = getParentFromRootState(
      context.rootState
    )
    await setExerciseStatus({
      id: exercise.id,
      status,
      parentId,
      parentType,
    })
    context.commit(
      'content/EXERCISE_STATUS_UPDATED',
      {
        id: exercise.id,
        user_status: status,
      },
      { root: true }
    )
  },
  setResumeExerciseId: (
    context: ActionContext<IExercisesState, IRootState>,
    exerciseId: string
  ) => {
    context.commit('RESUME_EXERCISE_UPDATED', exerciseId)
  },
}

export default actions
