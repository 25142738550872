import { Module } from 'vuex'
import { ILabsState, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

const labs: Module<ILabsState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export default labs
