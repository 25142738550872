<template>
  <section class="onboarding-modal__step">
    <div class="onboarding-modal__step-badge">{{ stepBadgeLabel }}</div>
    <div class="onboarding-modal__step-header">
      <div class="onboarding-modal__step-title">
        What are your training goals?
      </div>
    </div>
    <div class="training-goals__items-wrapper">
      <div
        v-for="(goal, i) in trainingGoals"
        :key="`goal-${i}`"
        :class="[
          'onboarding-modal-states',
          'training-goals__item',
          isGoalSelected(goal.id) &&
            goal.hasTextIntput &&
            'training-goals__item-with-input--selected',
          goal.hasTextIntput && 'training-goals__item-with-input',
        ]"
        @click="toggleGoalSelection(goal.id)"
      >
        <div class="training-goals__item-description">
          <span class="ine-checkbox__input training-goals__item-checkbox-input">
            <input
              :id="goal.id"
              type="checkbox"
              :name="`${goal.id}-checkbox`"
              :value="goal.id"
              :checked="isGoalSelected(goal.id)"
            />
            <span
              class="ine-checkbox__control training-goals__item-checkbox-control"
            >
              <CheckboxTick />
            </span>
          </span>
          <div class="training-goals__item-label">
            {{ goal.description }}
          </div>
        </div>
        <div
          v-if="goal.hasTextIntput"
          class="onboarding-modal__input-wrapper training-goals__item-input"
        >
          <transition name="fade">
            <input
              v-show="isGoalSelected(goal.id)"
              v-model="otherGoalInput"
              :class="[
                'training-goals__item-input-area',
                'ine-form__form-control',
                'ine-input',
                errors.fields.includes('text') && 'ine-input--error',
                goal.hasTextIntput &&
                  isGoalSelected(goal.id) &&
                  'training-goals__item-input-area--selected',
              ]"
              type="text"
              :placeholder="goal.inputPlaceholder"
              @focus="clearErrors"
              @click.stop
            />
          </transition>
          <Errors
            v-if="errors.fields.includes('text')"
            :errors="errors.messages[0]"
          />
        </div>
      </div>
    </div>
    <div class="onboarding-modal__step-btn-wrapper">
      <button
        type="submit"
        class="btn btn--primary onboarding-modal__step-save-btn"
        :disabled="isSaveDisabled"
        @click="saveTrainingGoals()"
      >
        Save and close
      </button>
      <button
        class="btn btn--secondary onboarding-modal__step-next-btn"
        @click="$emit('close-onboarding-modal')"
      >
        Skip and close
      </button>
      <button
        class="btn btn--tertiary onboarding-modal__step-back-btn"
        @click="$emit('go-previus-step')"
      >
        Back
      </button>
    </div>
  </section>
</template>

<script>
import { validateForm, clearFormErrors } from '@/helpers'
import CONSTANTS from '@/constants'
import CheckboxTick from '@/assets/images/certification/checkbox-tick.svg'
import Errors from '@/components/forms/elements/Errors.vue'

export default {
  name: 'TrainingGoals',
  components: {
    CheckboxTick: { ...CheckboxTick, name: 'CheckboxTick' },
    Errors,
  },
  props: {
    stepBadgeLabel: {
      type: String,
      required: true,
    },
    onboardingSurvey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: clearFormErrors(),
      isSaveDisabled: true,
      currentSelection: [],
      otherGoalInput: null,
      savedTrainingGoals: [],
    }
  },
  computed: {
    trainingGoals() {
      return CONSTANTS.TRAINING_GOALS
    },
  },
  watch: {
    otherGoalInput() {
      this.updateSaveStatus()
    },
  },
  created() {
    this.$emit('set-component-loader', true)
    this.savedTrainingGoals = Object.values(CONSTANTS.TRAINING_GOALS).filter(
      goal =>
        this.onboardingSurvey?.data && this.onboardingSurvey.data[goal.slug]
    )
    this.currentSelection = this.savedTrainingGoals.map(goal => goal.id)

    this.otherGoalInput =
      this.onboardingSurvey?.data &&
      this.onboardingSurvey.data[CONSTANTS.TRAINING_GOALS.other.slug]
        ? this.onboardingSurvey?.data &&
          this.onboardingSurvey.data[CONSTANTS.TRAINING_GOALS.other.slug]
        : null
    this.$emit('set-component-loader', false)
  },
  methods: {
    isOtherSelected() {
      return this.currentSelection.includes(CONSTANTS.TRAINING_GOALS.other.id)
    },
    isGoalSelected(goal) {
      return this.currentSelection.includes(goal)
    },
    toggleGoalSelection(goal) {
      if (this.isGoalSelected(goal)) {
        const index = this.currentSelection.indexOf(goal)
        this.currentSelection.splice(index, 1)
        if (goal === CONSTANTS.TRAINING_GOALS.other.id) {
          this.otherGoalInput = null
        }
      } else {
        this.currentSelection.push(goal)
      }
      this.updateSaveStatus()
    },
    updateSaveStatus() {
      let enabled =
        this.currentSelection.length !== this.savedTrainingGoals.length ||
        !!this.savedTrainingGoals.find(
          g => !this.currentSelection.includes(g.id)
        )

      if (this.isOtherSelected) {
        const validUpdatedOther =
          !!this.otherGoalInput &&
          this.otherGoalInput !==
            this.onboardingSurvey.data[CONSTANTS.TRAINING_GOALS.other.slug]
        enabled = enabled || validUpdatedOther
      }

      this.isSaveDisabled = !enabled
    },
    async saveTrainingGoals() {
      this.isSaveDisable = true
      if (!this.validateFields()) return
      this.$emit('set-loader', true)
      try {
        const updatedSurvey = {
          ...this.onboardingSurvey.data,
          [CONSTANTS.PROFILE.ONBOARDING.ITEMS_IDS.TRAINING_GOALS]:
            this.currentSelection.length > 0,
        }
        Object.values(CONSTANTS.TRAINING_GOALS).forEach(tg => {
          if (this.currentSelection.includes(tg.id)) {
            updatedSurvey[tg.slug] =
              tg.id !== CONSTANTS.TRAINING_GOALS.other.id
                ? true
                : this.otherGoalInput
          } else {
            delete updatedSurvey[tg.slug]
          }
        })

        await this.$store.dispatch('auth/setOnboardingSurvey', updatedSurvey)

        this.$emit('close-onboarding-modal')
      } catch (e) {
        this.isSaveDisabled = false
      } finally {
        this.$emit('set-loader', false)
      }
    },
    validateFields() {
      const { otherGoalInput } = this
      if (!this.isOtherSelected() && !this.otherGoalInput) return true
      const { isValid, errors } = validateForm({
        text: {
          rules: ['presence'],
          value: otherGoalInput,
        },
      })
      this.errors = errors
      return isValid
    },
    clearErrors() {
      this.errors = clearFormErrors()
    },
  },
}
</script>
<style lang="scss" scoped>
.training-goals {
  &__items-wrapper {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    font-size: rem(14px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 24px;
    color: var(--white);
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    height: 44px;
    cursor: pointer;
    transition: 0.2s height;
    @include tablet {
      padding: 13px 14px 13px 20px;
      height: 50px;
      width: 100%;
    }
    &-icon {
      width: 30px;
      @include tablet {
        width: 50px;
      }
    }
    &-description {
      display: flex;
    }
    &-label {
      margin-left: 10px;
    }
  }
  &__item-checkbox {
    &-input {
      cursor: pointer;
    }
    &-control {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      pointer-events: none;
      border: 1px solid var(--white);
      svg {
        width: 8px;
      }
    }
  }
  &__item-input {
    display: flex;
    padding-left: 11%;

    @include mobile {
      padding-left: 5%;
    }

    @include tablet {
      padding-left: 8%;
      max-width: 413px;
    }
    &-area {
      background: var(--darker-gray);
      border: 1px solid var(--emperor-gray);
      box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
      border-radius: 4px;
      font-size: 12px;
      padding-left: 10px;
      color: var(--gray-nine);

      &--selected {
        height: 46px !important;
      }
    }
  }

  &__item-with-input {
    justify-content: flex-start;
    &--selected {
      height: 90px;
      overflow: hidden;
    }
  }
}
</style>
