import { ICourse, ICoursesState, ILevel } from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ICoursesState> = {
  ACTIVE_COURSES_UPDATED: (state, courses: Array<ICourse>) => {
    state.activeCourses = courses
  },

  CURRENT_COURSE_UPDATED: (state, course: ICourse) => {
    state.currentCourse = course
  },

  CURRENT_LEVELS_UPDATED: (state, level: ILevel) => {
    if (Array.isArray(level)) {
      state.currentLevels = []
    } else {
      state.currentLevels.push(level)
    }
  },

  CLEAR_CURRENT_LEVELS: state => {
    state.currentLevels = []
  },

  COURSE_PROGRESS_UPDATED: (state, progress: object) => {
    state.currentCourseProgress = progress
  },
}

export default mutations
