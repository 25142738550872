import { mapActions, mapMutations } from 'vuex'

export const playlistCommons = {
  methods: {
    ...mapActions({
      getPlaylistById: 'playlists/getPlaylistById',
      fetchContentForPlaylist: 'content/fetchContentForPlaylist',
    }),
    ...mapMutations({
      setParentContent: 'content/CURRENT_PARENT',
    }),
    async fetchPlaylist() {
      const id = this.playlist?.id || this.currentParent?.id
      if (!id) return
      const playlist = await this.getPlaylistById({
        id,
        refresh: true,
      })
      if (playlist) {
        this.setParentContent({ ...playlist, type: 'palylist' })
      }
      await this.fetchContentForPlaylist({
        playlist,
        isAuthenticated: this.isAuthenticated,
      })
    },
  },
}
