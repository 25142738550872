import {
  ICertificate,
  ICertification,
  ICertificationsState,
  IExamAttempt,
  IPublicCertification,
} from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ICertificationsState> = {
  UPDATE_CURRENT_EXAM_ATTEMPT: (state, attempt: IExamAttempt) => {
    state.currentAttempt = attempt
  },
  UPDATE_CURRENT_CERTIFICATIONS: (
    state,
    certifications: Array<ICertification>
  ) => {
    state.currentCertifications = certifications
  },
  UPDATE_CURRENT_CERTIFICATION: (state, certification: ICertification) => {
    state.currentCertification = certification
  },
  UPDATE_PARTIAL_CERTIFICATION: (state, certification: ICertification) => {
    state.currentCertification = {
      ...state.currentCertification,
      ...certification,
    }
  },
  CERTIFICATIONS_CACHE_UPDATED: (
    state,
    certifications: Array<ICertification>
  ) => {
    const entries = new Map(
      certifications.map(certification => [certification.id, certification])
    )

    state.certificationsCache = {
      ...state.certificationsCache,
      ...Object.fromEntries(entries),
    }
  },
  USERS_CERTIFICATIONS: (state, certifications: Array<ICertification>) => {
    state.usersCertifications = certifications
  },
  USERS_VOUCHERS: (state, vouchers) => {
    state.usersVouchers = vouchers
  },
  UPDATE_CERTIFICATIONS_LIST: (
    state,
    certifications: Array<IPublicCertification>
  ) => {
    state.certificationList = certifications
  },
  UPDATE_CURRENT_CERTIFICATE: (state, certificate: ICertificate) => {
    state.currentCertificate = certificate
  },
  UPDATE_CURRENT_SCORED_ATTEMP: (state, attempt: IExamAttempt) => {
    state.currentScoredAttempt = attempt
  },
  UPDATE_CURRENT_SCORED_CERT: (state, certification: ICertification) => {
    state.currentScoredCertification = certification
  },
  UPDATE_USER_LAB_ATTEMPT: (state, userLabAttempt: object) => {
    state.currentAttempt.user_lab_attempt = userLabAttempt
  },
  UPDATE_QUESTIONS_ANSWERED: (state, questionsAnswered: Array<any>) => {
    state.currentAttempt.questionsAnswered = questionsAnswered
  },
  CERTIFICATION_RENEWALS_AVAILABLE: (
    state,
    certRenewalsAvailable: Array<object>
  ) => {
    state.certRenewalsAvailable = certRenewalsAvailable
  },
  CERTIFICATION_RENEWAL_COST_PREVIEW: (
    state,
    certRenewalCost: object | null
  ) => {
    state.certRenewalCost = certRenewalCost
  },
  CERTIFICATION_RENEWAL_PURCHASE_SUCCESS: (
    state,
    certRenewalPurchase: object | null
  ) => {
    state.certRenewalPurchase = certRenewalPurchase
  },
}

export default mutations
