<template>
  <div>
    <div class="profile-menu__arrow"></div>
    <div class="profile-menu" @mouseleave="$emit('closed', true)">
      <div class="profile-menu__header">
        <div class="profile-menu__user-wrapper">
          <ProfileProgress
            class="profile-menu__profile-progress"
            :currentAvatar="currentAvatar"
            :onboardingItems="onboardingItems"
          />
          <div class="profile-menu__user-info">
            <p class="profile-menu__email">{{ userEmail }}</p>
            <p class="profile-menu__name">{{ userFullName }}</p>
          </div>
        </div>
        <div class="profile-menu__profile-btn-cont">
          <button
            type="button"
            class="btn btn--secondary btn--small profile-menu__profile-btn"
            @click="userAction({ action: 'profile' })"
          >
            My profile
          </button>
        </div>
        <div
          v-if="isImpersonating && isAuthenticated"
          class="profile-menu__impersonation"
        >
          <button
            class="profile-menu__impersonation--btn"
            @click="stopImpersonating()"
          >
            Stop Impersonating
          </button>
        </div>
      </div>
      <div class="profile-menu__separator"></div>
      <div class="profile-menu__list-wrapper">
        <ul class="profile-menu__list">
          <li
            v-for="option in options"
            :key="`option-${option.name}`"
            class="profile-menu__list-item"
          >
            <button
              v-if="isOptionShown(option.action)"
              class="profile-menu__option"
              @click="userAction(option)"
            >
              {{ option.name }}
              <ArrowRightIcon class="profile-menu__option-arrow" />
            </button>
          </li>
          <template v-if="isAdmin">
            <li
              v-for="option in adminOptions"
              :key="`option-${option.name}`"
              class="profile-menu__list-item"
              @click="$emit('closed', true)"
            >
              <router-link
                class="profile-menu__option"
                :to="{ name: 'admin', params: { mode: option.action } }"
                >Manage User {{ option.name }}</router-link
              >
            </li>
          </template>
          <li class="profile-menu__list-item">
            <button
              class="btn btn--promo profile-menu__option profile-menu__option--signout"
              @click="logout"
            >
              Sign out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { ArrowRightIcon } from 'vue-feather-icons'
import ProfileProgress from './ProfileProgress.vue'

export default {
  name: 'ProfileMenu',
  components: {
    ProfileProgress,
    ArrowRightIcon,
  },
  props: {
    profile: {
      type: [Object, String],
      required: false,
    },
    currentAvatar: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          name: 'Active Courses',
          action: 'learning',
        },
        {
          name: 'Learning Paths',
          action: 'learning-paths',
        },
        {
          name: 'My Analytics',
          action: 'analytics',
        },
        {
          name: 'Bookmarks',
          action: 'learning',
          section: 'bookmarks',
        },
        {
          name: 'Business Platform',
          action: 'business',
        },
        {
          name: 'Certifications',
          action: 'certifications',
        },
        {
          name: 'Rack Rentals',
          action: 'rentals',
        },
        {
          name: 'Subscriptions',
          action: 'subscriptions',
        },
        {
          name: 'Troubleshooting and FAQs',
          action: 'faqs',
        },
      ],
      adminOptions: [
        {
          name: 'Impersonation',
          action: 'impersonate-user',
        },
        {
          name: 'Suspension',
          action: 'manage-user-suspension',
        },
        {
          name: 'Password',
          action: 'manage-user-password',
        },
        {
          name: 'Confirmation',
          action: 'manage-user-confirmation',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isImpersonating: state => state.admin.isImpersonating,
      isAdmin: state => state.auth.isAdmin,
      isBusinessManager: state => state.auth.isBusinessManager,
      isAuthenticated: state => state.auth.isAuthenticated,
      onboardingItems: state => state.auth.onboardingSurvey,
      subscriptions: state => state.subscriptions.currentSubscriptions,
    }),
    ...mapGetters({
      currentPasses: 'subscriptions/currentPasses',
      isBusinessV2User: 'business/isBusinessUser',
    }),
    membersUrl() {
      return process.env.VUE_APP_MEMBERS_URL
    },
    businessUrl() {
      if (this.isBusinessV2User || this.isAdmin) {
        return process.env.VUE_APP_DASHBOARD_URL
      }
      return process.env.VUE_APP_BUSINESS_URL
    },
    userFullName() {
      const { first_name: firstName, last_name: lastName } = this.profile
      return `${firstName} ${lastName}`
    },
    userEmail() {
      const { email } = this.profile
      return email
    },
    isSubscriptionsShown() {
      return !this.isBusinessV2User || !!this.subscriptions?.length
    },
  },
  methods: {
    ...mapActions({
      logoutUser: 'auth/logout',
    }),
    userAction(option) {
      const { action, section } = option
      if (action === 'rentals') {
        window.open(`https://${this.membersUrl}/`)
      } else if (action === 'bootcamps') {
        this.$router.push({ name: 'bootcamps' })
      } else if (action === 'learning-paths') {
        this.$router.push({ name: 'learning-paths' })
      } else if (action === 'business') {
        window.open(`https://${this.businessUrl}`)
      } else if (action === 'faqs') {
        this.$router.push({ name: 'faqs' })
      } else if (action === 'learning') {
        this.$router.push({
          name: 'user-dashboard',
          params: { mode: action, section: section },
        })
      } else {
        this.$router.push({
          name: 'user-dashboard',
          params: { mode: action },
        })
      }
      this.$emit('closed', true)
    },
    isOptionShown(option) {
      switch (option) {
        case 'business':
          return this.isBusinessManager || this.isAdmin
        case 'subscriptions':
          return this.isSubscriptionsShown
        default:
          return true
      }
    },
    async logout() {
      this.$store.dispatch('auth/setIsAuthLoading', true)
      await this.logoutUser()
      this.$store.dispatch('auth/setIsAuthLoading', false)
      this.$store.dispatch('app/setSuccess', 'You successfully logged out!')
      this.$emit('closed', true)
    },
    async stopImpersonating() {
      this.$store.dispatch('auth/setIsAuthLoading', true)
      this.$store.dispatch('admin/isImpersonating', false)
      await this.$store.dispatch('admin/cancelImpersonation')
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
      this.$store.dispatch('auth/setIsAuthLoading', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-menu {
  @include mobile {
    min-width: 340px;
    width: auto;
    right: 15px;
    padding: 20px 0 0 0;
  }
  @include tablet {
    padding: 30px 0 0 0;
    top: 98%;
  }
  width: 100%;
  background: var(--glass-mid-gray);
  position: absolute;
  right: 0;
  top: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 8px;
  height: auto;
  &::before {
    top: 0;
    background: var(--darker-gray);
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: -1;
  }
  &__header {
    overflow: hidden;
    padding: 25px 45px 15px 45px;
    @include mobile {
      padding: 0 20px;
    }
  }
  &__separator {
    border-top: 1px solid var(--light-gray-darker);
    margin: 5px 45px;
    @include mobile {
      display: none;
    }
  }
  &__user-wrapper {
    display: flex;
    align-items: center;
  }
  &__profile-progress {
    width: 75px;
    height: 75px;
    font-size: 30px;
  }
  &__user-info {
    color: var(--white);
    margin-left: 20px;
  }
  &__email {
    font-size: rem(12px);
    line-height: 14px;
    margin: 0;
    color: var(--white);
    @include mobile {
      color: var(--white);
      font-size: rem(14px);
      line-height: 20px;
    }
  }
  &__name {
    display: none;
  }
  &__progress {
    font-size: rem(10px);
    color: var(--orange);
    line-height: 12px;
  }
  &__profile-btn {
    width: 100%;
    padding: 8px 0;
    &-cont {
      font-size: rem(14px);
      line-height: 20px;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__impersonation {
    font-size: rem(14px);
    margin: 0;
    color: var(--white);
    &--lead {
      color: var(--orange);
    }
    &--btn {
      font-size: 0.8rem;
      background: var(--red);
      text-transform: uppercase;
      color: var(--white);
      padding: 5px 25px;
      border-radius: 20px;
      border: none;
      margin: 10px 0 10px 0;
      transition: background 0.15s ease;
      cursor: pointer;
      width: 100%;
      line-height: 20px;
      &:hover {
        background: var(--green);
      }
    }
  }
  &__list-wrapper {
    position: relative;
    height: 100%;
    max-height: calc(100vh - 227px);
    width: 100%;
    overflow-y: auto;
    scrollbar-width: 4px;
    scrollbar-color: rgba(127, 127, 127, 0.25) rgba(127, 127, 127, 0.05);
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(15, 15, 15);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(127, 127, 127, 0.25);
      border: 4px solid rgba(127, 127, 127, 0);
    }
    @include tablet {
      max-height: calc(100vh - 280px);
    }
  }
  &__list {
    width: 100%;
    height: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 2.5px;
    margin-bottom: 0;
    @include mobile {
      padding-top: 5px;
      border-top: none;
      margin: 0;
    }
    @include tablet {
      padding-top: 10px;
    }
  }
  &__list-item {
    &:last-child {
      margin: 10px 0 0 0;
    }
  }
  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    appearance: none;
    font-size: rem(14px);
    line-height: 20px;
    color: var(--white);
    background: none;
    padding: 7.5px 45px;
    width: 100%;
    text-align: start;
    cursor: pointer;
    @include mobile {
      color: var(--athens-gray);
      font-size: rem(14px);
      line-height: 20px;
      padding: 10px 22.5px 10px 20px;
    }
    &:hover:not(.profile-menu__option--signout) {
      background: var(--glass-extra-light);
      & .profile-menu__option-arrow {
        display: block;
      }
    }
    &--signout {
      font-size: rem(16px);
      line-height: 20px;
      font-weight: 700;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--secondary-promo-purple);
      border-radius: 0px 0px 10px 10px;
      margin-top: 37.5px;
      @include mobile {
        font-size: rem(14px);
        margin-top: 0;
        padding: 10px 0;
      }
    }
    svg :not(.profile-menu__option-arrow) {
      width: 25px;
      height: auto;
      fill: transparent;
      margin: 0 8px 0 0;
    }
    &-arrow {
      display: none;
      font-size: 15px;
      height: auto;
      color: var(--white);
      width: 15px;
      fill: transparent;
      margin: 0;
    }
  }
  &__arrow {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid var(--shark-gray);
    top: 88%;
    right: 24px;
    @include mobile {
      right: 35px;
    }
    @include tablet {
      top: 90%;
      right: 40px;
      z-index: -1;
    }
  }
}
</style>
