import { mapActions } from 'vuex'
import { attemptInProgress } from '@/helpers'
export const voucherActionsCommons = {
  methods: {
    ...mapActions({
      updateCurrentCertification: 'certifications/updateCurrentCertification',
      updateCertificationsCache: 'certifications/updateCertificationsCache',
    }),
    async goToExam() {
      // voucher response from /me/voucher is actually the certificate
      const voucherId = this.voucher?.id
      if (!voucherId) return

      await Promise.all([
        this.updateCurrentCertification(this.voucher),
        this.updateCertificationsCache(this.voucher),
      ])

      const attempt = this.lastAttempt
      const attemptId = attemptInProgress(attempt)?.id || null
      this.$router.push({
        name: 'certification-exam',
        params: { voucherId, attemptId },
      })
    },
  },
}
