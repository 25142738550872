import { ILearningPathsState, IRootState } from '@/@types'

import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'

const learningPaths: Module<ILearningPathsState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export default learningPaths
