import { MutationTree } from 'vuex'
import { IStatisticsState } from '@/@types'

const mutations: MutationTree<IStatisticsState> = {
  TRAINING_HISTORY_STATS_UPDATED: (state, trainingHistoryStats) => {
    state.trainingHistoryStats = trainingHistoryStats
  },
  COURSES_FOR_HISTORY_UPDATED: (state, coursesForHistory) => {
    state.coursesForHistory = coursesForHistory
  },
  MONTHLY_STATS_UPDATED: (state, monthlyStats) => {
    state.monthlyStats = monthlyStats
  },
  YEARLY_STATS_UPDATED: (state, yearlyStats) => {
    state.yearlyStats = yearlyStats
  },
  LAST_MONTH_STATS_UPDATED: (state, monthlyStats) => {
    state.lastMonthStats = monthlyStats
  },
}

export default mutations
