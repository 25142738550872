import { Module } from 'vuex'
import { IBootcampsState, IRootState } from '@/@types'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import state from './state'

const bootcamps: Module<IBootcampsState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default bootcamps
