<template>
  <div
    class="message"
    :style="`--bg-color-1: ${currentStatus.bgColors[0]}; --bg-color-2: ${currentStatus.bgColors[1]}`"
    :data-message-type="ofType"
    @click="handleDismiss"
  >
    <div class="message__inner">
      <component
        :is="currentStatus.icon"
        :stroke="currentStatus.iconColor"
        class="message__icon"
      />
      <p class="message__text">{{ message }}</p>
      <CloseIcon class="message__close" />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue'
import ErrorIcon from '@/assets/icons/error_icon.svg'
import NotificationIcon from '@/assets/icons/notification.svg'
import SuccessIcon from '@/assets/icons/success_icon.svg'
import CloseIcon from '@/assets/icons/close_icon.svg'

export const STATUS_MAPPING = {
  success: {
    icon: SuccessIcon,
    iconColor: '#49E0B3',
    bgColors: ['#00AA77', '#007150'],
  },
  error: {
    icon: ErrorIcon,
    iconColor: '#FF5E5E',
    bgColors: ['#CF2121', '#860606'],
  },
  notification: {
    icon: NotificationIcon,
    iconColor: '#fff',
    bgColors: ['#863AD2', '#5936E4'],
  },
  warning: {
    icon: NotificationIcon,
    iconColor: '#F8B369',
    bgColors: ['#CA6C06', '#804505'],
  },
}

export default defineComponent({
  name: 'Message',
  components: {
    ErrorIcon: { ...ErrorIcon, name: 'ErrorIcon' },
    NotificationIcon: { ...NotificationIcon, name: 'NotificationIcon' },
    SuccessIcon: { ...SuccessIcon, name: 'SuccessIcon' },
    CloseIcon: { ...CloseIcon, name: 'CloseIcon' },
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    ofType: {
      // error, notification, success, warning
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { message } = toRefs(props)
    const interval = ref(null)
    const timeToDismiss = ref(100)

    const handleDismiss = () => emit('dismiss-message', message.value)

    onBeforeMount(() => {
      interval.value = setInterval(() => {
        timeToDismiss.value -= 15
        if (timeToDismiss.value <= 0) {
          handleDismiss()
        }
      }, 1000)
    })

    onBeforeUnmount(() => clearInterval(interval.value))

    return {
      interval,
      timeToDismiss,
      handleDismiss,
    }
  },
  computed: {
    currentStatus() {
      return STATUS_MAPPING[this.ofType]
    },
  },
})
</script>

<style lang="scss" scoped>
@keyframes appearenceAnim {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.message {
  position: relative;
  width: 100%;
  margin: 10px;
  padding: 25px 15px;
  background: linear-gradient(
    270deg,
    var(--bg-color-1, #00aa77) 0%,
    var(--bg-color-2, #007150) 100%
  );
  border-radius: 10px;
  will-change: opacity, transform;
  animation: appearenceAnim 0.5s forwards;
  cursor: pointer;
  @include mobile {
    min-width: 300px;
    max-width: 25vw;
    margin: 0 0 5px 0;
  }
  &__inner {
    position: relative;
    padding-left: 40px;
    padding-right: 32px;
  }
  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
  }
  &__text {
    flex-grow: 1;
    margin: 0;
    font-size: 16px;
    line-height: 23px;
    color: #fff;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
  }
}
</style>
