import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchCollectionById = async id => {
  const route = `collections/${id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchCollections = async params => {
  const route = 'collections'
  return CONTENT.get(route, { params })
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export { fetchCollectionById, fetchCollections }
