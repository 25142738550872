import CONSTANTS from '@/constants'

export function lazyLoadComponent({ componentFactory, loading, loadingData }) {
  let resolveComponent

  return () => ({
    component: new Promise(resolve => {
      resolveComponent = resolve
    }),
    loading: {
      mounted() {
        if (!('IntersectionObserver' in window)) {
          componentFactory().then(resolveComponent)
          return
        }

        const observer = new IntersectionObserver(entries => {
          if (entries[0].intersectionRatio <= 0) return
          observer.unobserve(this.$el)
          componentFactory().then(resolveComponent)
        })
        observer.observe(this.$el)
      },
      render(createElement) {
        return createElement(loading, loadingData)
      },
    },
  })
}

function createLabDeepLink(lab, parent) {
  const { objectID, parent_id, parent_type } = lab
  const name = `${parent_type}-lab`
  if (parent_type === 'collection' || !parent) {
    return {
      name: 'lab-page',
      params: { labId: objectID },
    }
  }
  const params = {
    id: parent_id,
    labId: objectID,
  }
  if (parent_type === 'course') {
    params.slug = parent?.slug ? parent.slug : 'parent-course'
  }

  return {
    name,
    params,
  }
}

export const buildContentLink = (type, content, parent) => {
  const { LAB, LEARNING_PATH } = CONSTANTS.CONTENT_TYPES
  const { BOOTCAMP, COLLECTION } = CONSTANTS.PARENT_CONTENT_TYPES_KEYS
  const { LEARNING_PATH_SHORT_SLUG } = CONSTANTS
  if (type === LAB) {
    return createLabDeepLink(content, parent)
  }
  if (type === COLLECTION) {
    return {
      name: 'collection-detail',
      params: { id: content.id || content.objectID },
    }
  }
  const { id, objectID, slug, learning_areas: areas } = content
  const params = {
    area: areas && areas[0] ? areas[0].name.replace(/\s+/g, '') : 'INE',
    slug: slug && slug.length ? slug : 'ine-content',
  }
  const name =
    type === LEARNING_PATH ? `learning-area-path` : `learning-area-${type}`
  if ([LEARNING_PATH, LEARNING_PATH_SHORT_SLUG, BOOTCAMP].includes(type)) {
    params.id = objectID && objectID.length ? objectID : id
  } else {
    params.id =
      objectID && objectID.length ? objectID.slice(0, 8) : id.slice(0, 8)
  }
  return {
    name,
    params,
  }
}

export const shortenUUIDs = ids => {
  if (!Array.isArray(ids)) return ids
  const shortenIds = ids.flatMap(id => (id ? [id.split('-')[0]] : []))
  return shortenIds
}

export const truncateText = (value, maxLength) => {
  if (value.length >= maxLength) {
    return `${value.substr(0, maxLength)}...`
  }
  return value
}

export const getLearningAreaSnipeColor = area => {
  const colorMap = {
    Networking: '#FAD740',
    'Data Science': '#66C19A',
    'Cyber Security': '#BB85FF',
    Cloud: '#88A9FF',
    Development: '#D73F47',
    'IT Essentials': '#F647C0',
  }
  return colorMap[area] || colorMap['IT Essentials']
}

export const getLearningAreaName = content => {
  const defaultLearningArea = 'INE'
  if (!content || !content.learning_areas) {
    return defaultLearningArea
  }
  return content?.learning_areas[0]?.name ?? defaultLearningArea
}

export const getAllLearningAreas = content => {
  const defaultList = ['INE']
  if (!content || !content.learning_areas?.length) {
    return defaultList
  }
  return content?.learning_areas.map(la => la.name)
}

export const getLearningArea = item => {
  return getLearningAreaName(item)
}

export const getLearningAreaColor = item => {
  return `${getLearningAreaSnipeColor(getLearningAreaName(item))}`
}

export const getLearningAreaColorByName = name => {
  return `${getLearningAreaSnipeColor(name)}`
}

export const getParentFromRootState = rootState => {
  const {
    content: { currentParent },
  } = rootState
  return { id: currentParent?.id ?? '', type: currentParent?.type ?? '' }
}

export const isParentPresent = parentTypeAndParentId => {
  const { parentId, parentType } = parentTypeAndParentId
  return (
    parentId &&
    parentId.length &&
    parentType &&
    parentType.length &&
    CONSTANTS.PARENT_CONTENT_TYPES.includes(parentType)
  )
}

export const isBottomVisible = () => {
  const scrollY = window.scrollY
  const visible = document.documentElement.clientHeight
  const pageHeight = document.documentElement.scrollHeight
  const bottomOfPage = visible + scrollY >= pageHeight - 1000
  return bottomOfPage || pageHeight < visible
}

export const isElementVisible = element => {
  const rect = element.getBoundingClientRect()
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight
  const windowWidth = window.innerWidth || document.documentElement.clientWidth

  // Check if the element is in the viewport
  const isInViewPort =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= windowHeight &&
    rect.right <= windowWidth

  return isInViewPort
}

export const getScrollPosition = () => {
  const scrollY = window.scrollY
  return scrollY
}

export const cleanSearchStorage = () => {
  localStorage.removeItem('searchTile')
  localStorage.removeItem('searchScrollPosition')
  localStorage.removeItem('searchCurrentPage')
}

export const cleanSearchContext = () => {
  localStorage.removeItem('searchContext')
}

export const cleanSearchPage = () => {
  localStorage.removeItem('searchCurrentPage')
}

export const getSearchStorage = () => {
  return {
    searchContext: localStorage.searchContext,
    scrollOffset: localStorage.searchScrollPosition,
    searchTile: localStorage.searchTile,
    searchCurrentPage: localStorage.searchCurrentPage,
    viewStyle: localStorage.searchViewStyle,
  }
}

export const saveSearchContext = context => {
  localStorage.searchContext = context
}

export const saveCurrentPage = page => {
  localStorage.searchCurrentPage = page
}

export const saveCurrentElement = el => {
  localStorage.searchTile = el
}

export const saveSearchViewStyle = style => {
  localStorage.searchViewStyle = style
}

export const flattenObject = (obj, concatenator = '_') =>
  Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== 'object' || !obj[key]) {
      return {
        ...acc,
        [key]: obj[key],
      }
    }

    const flattenedChild = flattenObject(obj[key], concatenator)

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
        }),
        {}
      ),
    }
  }, {})

export const checkRedirectURL = redirectUrl => {
  // Normalize by decoding and converting to lowercase
  const normalizedUrl = decodeURIComponent(redirectUrl).toLowerCase()

  // Check if the param passes a regex test
  const urlRegex =
    /^(https?:\/\/)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+ine\.com(\/[a-z0-9-_/.]*)?$/
  return urlRegex.test(normalizedUrl)
}

export * from './accessControl'
export * from './bootcamps'
export { default as duration } from './duration'
export * from './errorHandling'
export * from './formValidation'
export { default as getEntities } from './getEntities'
export * from './imageHandling'
export * from './isPasswordValid'
export * from './isPhoneNumberValid'
export * from './subscriptions'
export * from './certifications'
export * from './textTransform'
export * from './contentParsers'
export * from './dates'
export * from './externalLinks'
export * from './vouchers'
export * from './woopraTracker'
