import { Module } from 'vuex'
import { IExercisesState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import mutations from './mutations'

const exercises: Module<IExercisesState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export default exercises
