import {
  createPlayer,
  calculateDelta,
  calculatePositions,
  sendKinesisHeartbeat,
} from '@/utils'
import { mapGetters, mapState, mapActions } from 'vuex'
import CONSTANTS from '@/constants'

const { PLAY, SEEK, SEEKED, TIME, COMPLETE } = CONSTANTS.VIDEO_EVENTS

const DELTA_TRESHOLD = 5

let currentDelta = 0
let events = []
let lastPosition = null
let isCasting = false
let isSeeking = false

export const videoCommons = {
  data() {
    return {
      instance: [],
      totalDuration: 0,
      isResumed: false,
      currentRate: null,
    }
  },
  computed: {
    ...mapGetters({
      courseBookmarks: 'content/courseBookmarks',
      currentVideoPreferences: 'app/currentVideoPreferences',
    }),
    ...mapState({
      isImpersonating: state => state.admin.isImpersonating,
      profile: state => state.auth.userProfile,
    }),
    notSingleIsolatedVideo() {
      return this.content !== undefined
    },
    videoBookmarks() {
      return this.courseBookmarks.reduce((acc, bookmark) => {
        if (
          bookmark.video === this.content.id &&
          bookmark.metadata.course_id === this.content.parent_id
        )
          acc.push({
            begin: bookmark.time,
            cueType: 'bookmark',
            text: bookmark.notes,
          })
        return acc
      }, [])
    },
    currentPlayerRate() {
      return this.currentVideoPreferences.playbackSpeed
    },
    deviceType() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (
        userAgent.includes('mobile') ||
        userAgent.includes('android') ||
        userAgent.includes('iphone') ||
        userAgent.includes('ipad')
      ) {
        return userAgent.includes('ipad') || userAgent.includes('tablet')
          ? 'tablet'
          : 'mobile'
      } else if (
        userAgent.includes('macintosh') ||
        userAgent.includes('windows') ||
        userAgent.includes('linux')
      ) {
        return 'desktop'
      }

      return 'unknown'
    },
  },
  methods: {
    ...mapActions({
      setVideoActive: 'app/setVideoActive',
    }),
    setupPlayer(playlist) {
      try {
        this.instance = createPlayer({
          element: 'player',
          key: process.env.VUE_APP_JWPLAYER_KEY,
          playlist,
          events: {
            ready: () => {
              if (this.notSingleIsolatedVideo) this.setupAdvanceContainer()
              this.$refs?.player?.focus()
            },
            play: () => {
              this.totalDuration = this.instance.getDuration()
              this.addEvent(PLAY)
            },
            seek: () => {
              if (!isSeeking) isSeeking = true
              this.addEvent(SEEK)
            },
            seeked: () => {
              if (isSeeking) isSeeking = false
              this.addEvent(SEEKED)
            },
            complete: () => {
              const isCompleted = true
              this.addEvent(COMPLETE)
              const shouldCalculate = true
              this.processEvents(shouldCalculate)
              this.updateVideoPosition(isCompleted)
              this.$emit('update-status')
              currentDelta = 0
              events = []
              lastPosition = null
              if (this.notSingleIsolatedVideo) this.advanceToNextMedia()
            },
            error: () => {
              this.instance.pause()
            },
            fullscreen: () => {
              if (!this.instance.getFullscreen()) {
                setTimeout(() => {
                  this.$scrollTo('#player', 10, {
                    container: 'body',
                    offset: -220,
                  })
                }, 100)
              }
            },
            time: () => {
              if (isSeeking && events.at(-1)?.event === TIME) isSeeking = false
              if (isCasting || isSeeking) return
              this.addEvent(TIME)
              const currentPosition = parseInt(this.instance.getPosition())
              if (currentPosition === lastPosition) return
              const shouldSendPosition = lastPosition !== null //avoid first update after resume
              lastPosition = currentPosition
              if (currentPosition === 15) this.$emit('is-course-active')
              if (currentPosition === parseInt(this.totalDuration) - 30)
                this.$emit('update-status')
              if (this.notSingleIsolatedVideo) {
                this.isAdvanceContentButtonShown =
                  currentPosition >= parseInt(this.totalDuration) - 15
              }
              if (
                currentPosition % process.env.VUE_APP_RESUME_VIDEO_DELTA ===
                  0 &&
                shouldSendPosition
              ) {
                this.updateVideoPosition()
              }
            },
            cast: event => {
              if (isCasting && !event.active) {
                currentDelta = 0
                events = []
                lastPosition = null
                this.addEvent(SEEK, true)
                this.addEvent(SEEKED, true)
                this.addEvent(PLAY, true)
              }
              isCasting = event.active
            },
            playbackRateChanged: () => {
              if (this.currentPlayerRate !== this.currentRate)
                this.processEvents(true, this.currentRate)
            },
          },
        })
        this.instance.on('all', event => {
          const avoidTrack = [
            'buffer',
            'user',
            'click',
            'meta',
            'levels',
            'attempts',
            'before',
            'visual',
            'cast',
          ].some(word => event.toLowerCase().includes(word))
          if (avoidTrack) return
        })
        if (this.notSingleIsolatedVideo) {
          if (this.parentType === CONSTANTS.PARENT_CONTENT_TYPES_KEYS.COURSE)
            this._addBookmarksButton()
          this._addBookmarksClues()
        }
      } catch (error) {
        this.instance = null
      }
    },
    addEvent(event) {
      if (isCasting || this.isCompleted()) return
      this.setCurrentQuality()
      const { TIME } = CONSTANTS.VIDEO_EVENTS
      if (this.isResumed && event === TIME) this.isResumed = false
      const data = this.eventData(event)
      events.push(data)
      this.processEvents()
      this.currentRate = this.currentPlayerRate
    },
    isCompleted() {
      const lastEvent = events.at(-1)
      if (lastEvent?.event !== COMPLETE) return false
      const currentPosition = Number(this.instance.getPosition())
      return lastEvent?.position === currentPosition
    },
    eventData(event) {
      const currentPosition = Number(this.instance.getPosition())
      const data = {
        event: event,
        position: currentPosition,
        playback_rate: this.currentRate,
        isResumed: this.isResumed,
      }
      return data
    },
    processEvents(shouldCalculate = false, currentRate = null) {
      const currentEvents = this.normalizeEvents([...events])
      if (!currentEvents.length) return
      const delta = calculateDelta(currentEvents)
      currentDelta = delta || 0
      if (currentDelta <= 0) return
      if (currentDelta >= DELTA_TRESHOLD || shouldCalculate || currentRate) {
        const lastEvent = {
          ...events.at(-1),
          processed: true,
        }
        events = [lastEvent]

        this.processHeartbeat(currentEvents, currentRate)
      }
    },
    processHeartbeat(currentEvents, currentRate = null) {
      const deltaData = currentDelta
      currentDelta = 0
      const time_range_watched = calculatePositions(currentEvents)
      const { instance } = this
      const user_agent = navigator.userAgent
      const device = this.deviceType
      //Emit hearbeat here
      const video_data = {
        delta: deltaData,
        position: Number(instance.getPosition()),
        playback_rate: this.currentRate,
        quality: this.currentQuality,
        video_viewable: !!instance.getViewable(),
        time_range_watched,
        user_agent,
        device,
      }

      this.handleVideoHeartbeats(video_data)
      if (currentRate) this.currentRate = this.currentPlayerRate
    },

    handleVideoHeartbeats(video_data) {
      if (!this.isImpersonating) {
        const {
          profile: { uaaId: user_id, account_id, team_ids },
        } = this
        const content_video_id = this.content?.id
        const content_video_name = this.content?.name
        const video_id = this.videoId || content_video_id
        const video_name =
          this.videoFetched === undefined
            ? content_video_name
            : this.videoFetched.name
        const parent_data = this.parentData
        sendKinesisHeartbeat(this.$store, {
          ...video_data,
          user_id,
          account_id,
          team_ids,
          video_id,
          video_name,
          parent_data,
        })
      }
    },

    normalizeEvents(events) {
      return events.filter((current, i, arr) => {
        if (i === 0 || i === arr.length - 1) return true
        const { event: prevEvent, position: prevPosition } = arr[i - 1]
        const { event: nextEvent, position: nextPosition } = arr[i + 1]
        const notDropEvent = !(
          [TIME, SEEKED].includes(prevEvent) &&
          current.event === TIME &&
          nextEvent === TIME &&
          nextPosition > prevPosition &&
          current.position < prevPosition &&
          current.position < nextPosition
        )
        const repeteadEvent =
          prevEvent === current.event && prevPosition === current.position

        const isSeekedDrop =
          prevEvent === TIME &&
          current.event === SEEKED &&
          nextEvent === TIME &&
          prevPosition === current.position &&
          current.position === nextPosition
        return notDropEvent && !repeteadEvent && !isSeekedDrop
      })
    },
    setCurrentQuality() {
      const qualityLevel = this.instance.getVisualQuality()?.level?.index
      const currentQuality =
        this.instance.getQualityLevels()[qualityLevel]?.label
      if (currentQuality !== this.currentQuality)
        this.currentQuality = currentQuality
    },
    _addBookmarksClues() {
      // jwPlayer instance
      if (this.instance) this.instance.addCues(this.videoBookmarks)
    },
    handleBeforeUnload() {
      if (isCasting) {
        this.instance.stopCasting()
      } else {
        const shouldCalculate = true
        this.processEvents(shouldCalculate)
      }
    },
    async updateVideoPosition(isCompleted = false) {
      const position = parseInt(this.instance.getPosition())
      const videoContent = this.notSingleIsolatedVideo
        ? this.content
        : this.videoFetched
      const videoId = this.notSingleIsolatedVideo
        ? this.content.id
        : this.videoId
      const roundedPosition = isCompleted ? videoContent.duration_in_seconds : 0

      const currentPosition = roundedPosition || position
      await this.$store.dispatch('content/updateVideoPosition', {
        videoId,
        position: currentPosition,
      })
    },
  },
  watch: {
    courseBookmarks() {
      this._addBookmarksClues()
    },
  },
  created() {
    currentDelta = 0
    events = []
    lastPosition = null

    this.setVideoActive(true)
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    this.setVideoActive(false)
    if (isCasting) {
      this.instance.stopCasting()
    } else {
      const shouldCalculate = true
      this.processEvents(shouldCalculate)
    }
  },
}
