import { fetchIFrameById, fetchIFrameURL } from '@/models/iframes'
import { ActionTree, ActionContext } from 'vuex'
import { getParentFromRootState } from '@/helpers'
import { IIFramesState, IRootState } from '@/@types'

import { errorHandler } from '@/utils'

const actions: ActionTree<IIFramesState, IRootState> = {
  getIFrameById: async (
    context: ActionContext<IIFramesState, IRootState>,
    id
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchIFrameById(payload)
      context.commit('UPDATE_IFRAMES_RENDERED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getSignedIFrameURL: async (
    context: ActionContext<IIFramesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchIFrameURL(payload)
      context.commit('UPDATE_IFRAMES_RENDERED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      if (message === 'You do not have permission to perform this action.') {
        context.dispatch(
          'app/setError',
          'You do not have access to this content.',
          {
            root: true,
          }
        )
      } else {
        context.dispatch('app/setError', message, {
          root: true,
        })
      }
    }
  },

  setPlayingIFrameId: (
    context: ActionContext<IIFramesState, IRootState>,
    iframeId: string
  ) => {
    context.commit('PLAYING_IFRAME_ID_UPDATED', iframeId)
  },
}

export default actions
