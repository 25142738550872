import { GetterTree } from 'vuex'
import { IRootState, IShoppingCartState } from '@/@types'
import CONSTANTS from '@/constants'

const { PLANS } = CONSTANTS

const getters: GetterTree<IShoppingCartState, IRootState> = {
  selectedPlan: state => {
    const planCodesWithoutSkillDive = Object.values(PLANS).filter(
      planId => planId !== PLANS.SKILL_DIVE_ANNUAL
    )
    return (
      state.shoppingCart.find(
        item =>
          item.merchant_id &&
          planCodesWithoutSkillDive.includes(item.merchant_id)
      ) || {}
    )
  },
}

export default getters
