<template>
  <div class="loading-spinner__container">
    <div class="loading-spinner">
      <LoadingSpinner class="loading-spinner__spinner" />
    </div>
    <h3 class="loading-spinner__label">{{ label }}</h3>
  </div>
</template>

<script>
import LoadingSpinner from '@/assets/icons/spinners/loading.svg'

export default {
  name: 'LoadingSpinnerV2',
  components: {
    LoadingSpinner: { name: 'LoadingSpinner', ...LoadingSpinner },
  },
  props: {
    label: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-spinner {
  text-align: center;
  z-index: 200;
  &__spinner {
    width: 76px;
    height: 76px;
  }
  &__label {
    color: var(--white);
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
