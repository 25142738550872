import CONSTANTS from '@/constants'
import { IAuthState } from '@/@types'
import defaultAvatar from '@/assets/images/avatars/default.png'

const state: IAuthState = {
  userProfile: {
    account_id: '',
    mfa_required: null,
    avatar: '',
    preferred_phone_number: '',
    mfa_method: '',
    first_name: '',
    last_name: '',
    email: '',
  },
  isAuthenticated: false,
  isAdmin: false,
  isContentCreator: false,
  isBusinessManager: false,
  isAuthLoading: false,
  isTwoFactorRequired: false,
  isGoogleAuthenticatorMFA: false,
  hasPlaylists: false,
  redirectUrl: '',
  sampleAvatars: [],
  currentAvatar: defaultAvatar,
  subscriptionStatus: '',
  profilePreferences: {
    passes: [],
  },
  authProviders: CONSTANTS.AUTH_PROVIDERS,
  onboardingSurvey: {},
  available_passes: [],
  labExperienceUserAccess: {
    available_time: 0,
    consumed_time: 0,
    purchased_time: 0,
    state: '',
  },
  enabled_user_features: {
    mark_as_completed: false,
    cpe: false,
  },
}

export default state
