import AWS from 'aws-sdk'
import uuid from 'uuid/v4'

AWS.config.region = process.env.VUE_APP_AWS_REGION
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID,
})

let heartbeatsQueue = []
let intervalId = null
let isSendingHeartbeat = false

const kinesis = new AWS.Kinesis()

export const sendKinesisHeartbeat = (
  store,
  {
    position,
    delta,
    playback_rate,
    quality,
    video_viewable,
    time_range_watched,
    device,
    user_agent,
    user_id,
    account_id,
    team_ids,
    video_id,
    video_name,
    parent_data,
  }
) => {
  const trw = time_range_watched?.map(range => {
    return {
      start: Math.round(range.start * 1000),
      end: Math.round(range.end * 1000),
    }
  })
  const heartbeat = {
    event_type: 'heartbeat',
    attributes: {
      position: Math.round(position * 1000),
      delta: Math.round(delta * 1000),
      playback_rate,
      quality,
      video_viewable,
      time_range_watched: trw,
      device,
      user_agent,
      app: process.env.VUE_APP_AWS_APP,
      heartbeat_version: process.env.VUE_APP_AWS_VERSION,
      uuid: uuid(),
      user_id,
      team_id: team_ids && team_ids.length ? team_ids.join(', ') : '',
      account_id: account_id && account_id.length ? account_id : '',
      video_id,
      video_name,
      timestamp: new Date().toISOString(),
    },
  }

  heartbeat.attributes = { ...heartbeat.attributes, ...parent_data }

  const params = heartbeatVideoParams(store, heartbeat)
  kinesis.putRecord(params, err => {
    isSendingHeartbeat = true
    if (err) {
      if (!intervalId)
        intervalId = setInterval(() => processHeartbeatQueue(store), 20000)
      heartbeatsQueue.push({ heartbeat, attempts: 2 })
      isSendingHeartbeat = false
    } else {
      isSendingHeartbeat = false
    }
  })
}

const heartbeatVideoParams = (store, heartbeat) => {
  const heartbeatVideoParams = {
    Data: JSON.stringify(heartbeat),
    PartitionKey: 'heartbeat',
    StreamName: process.env.VUE_APP_AWS_KINESIS_STREAM,
  }
  store.dispatch('app/setKinesisHeartbeatData', heartbeatVideoParams)
  return heartbeatVideoParams
}

const processHeartbeatQueue = store => {
  if (!heartbeatsQueue.length && !isSendingHeartbeat) {
    if (!store.getters['app/isAnyVideoActive']) {
      clearInterval(intervalId)
      intervalId = null
    }
    return
  }
  const heartbeat = heartbeatsQueue.shift()
  const currentHearbeat = { ...heartbeat }
  const params = heartbeatVideoParams(currentHearbeat.heartbeat)
  kinesis.putRecord(params, err => {
    if (err) {
      if (currentHearbeat.attempts > 0) {
        currentHearbeat.attempts--
        heartbeatsQueue.push(currentHearbeat)
      }
    }
  })
}

export const sendKinesisQuizbeat = ({
  user_id,
  account_id,
  team_ids,
  quiz_id,
  score,
  time_elapsed,
  video_id,
  course_id,
  course_slug,
  instructor_id,
}) => {
  const quizbeat = {
    event_type: 'quizbeat',
    attributes: {
      uuid: uuid(),
      app: process.env.VUE_APP_AWS_APP,
      quizbeat_version: process.env.VUE_APP_QUIZBEATS_VERSION,
      user_id,
      account_id: account_id && account_id.length ? account_id : '',
      team_id: team_ids && team_ids.length ? team_ids.join(', ') : '',
      quiz_id,
      score,
      time_elapsed,
      video_id,
      course_id,
      course_slug,
      instructor_id,
      timestamp: new Date().toISOString(),
    },
  }
  const params = {
    Data: JSON.stringify(quizbeat),
    PartitionKey: 'quizbeat',
    StreamName: process.env.VUE_APP_AWS_KINESIS_STREAM,
  }
  kinesis.putRecord(params, err => {
    if (err) {
      //TODO handle error
      return
    }
  })
}
