import { differenceInSeconds, format } from 'date-fns'
import CONSTANTS from '@/constants'

export const lastActiveVoucher = vouchers => {
  if (vouchers?.length) {
    return vouchers[0]
  }
  return null
}

export const allFinishedAttempts = vouchers => {
  return vouchers
    ? vouchers
        .reduce((acc, v) => {
          acc = [...acc, ...v.user_status.inactive_vouchers]
          return acc
        }, [])
        .reduce((acc, v) => {
          acc = [...acc, ...v.inactive_attempts]
          return acc
        }, [])
    : []
}

export const allAttemptsInVoucher = vouchers => {
  return vouchers.reduce((acc, v) => {
    acc = [...acc, ...v.active_attempts, ...v.inactive_attempts]
    return acc
  }, [])
}

export const voucherIsInProgress = voucherAttempts => {
  return !!voucherAttempts.find(
    a => a.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.IN_PROGRESS
  )
}

export const voucherIsFinished = voucherAttempts => {
  return !!voucherAttempts.find(
    a => a.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.FINISHED
  )
}

export const voucherIsInReview = voucherAttempts => {
  return !!voucherAttempts.find(
    a => a.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.REVIEW
  )
}

export const voucherCertificationAvailable = vouchers => {
  let available = true
  const activeVoucher = vouchers?.length && vouchers[0]
  if (
    activeVoucher?.status ===
      CONSTANTS.CERTIFICATIONS.USER_STATUS.NOT_AVAILABLE &&
    !!activeVoucher?.start_date
  ) {
    available =
      differenceInSeconds(
        new Date().getTime(),
        new Date(activeVoucher?.start_date).getTime()
      ) >= 0
  }

  return available
}

export const voucherCertificationAvailableOnDate = vouchers => {
  return vouchers?.length && vouchers[0].start_date
    ? format(new Date(vouchers[0].start_date), 'LLLL d, yyyy')
    : ''
}

export const voucherCertificationAvailableDateTime = vouchers => {
  return vouchers?.length && vouchers[0].start_date
    ? format(new Date(vouchers[0].start_date), 'P HH:mm')
    : ''
}
