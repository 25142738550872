<template>
  <section class="onboarding-modal__step">
    <div class="onboarding-modal__step-badge">{{ stepBadgeLabel }}</div>
    <div class="onboarding-modal__step-header">
      <div class="onboarding-modal__step-title">Add a profile photo</div>
    </div>
    <FileUploader
      v-if="isUploadEnabled"
      :label="'ADD PHOTO'"
      @handle-file-upload="handleAvatarUpload"
    />
    <div v-else class="profile-photo__preview">
      <div class="profile-photo__preview-card ine-card onboarding-modal-states">
        <AvatarPreview
          class="profile-photo__preview-avatar"
          :avatar="avatarUpload || currentAvatar"
        />
        <div class="profile-photo__preview-filename">
          {{ avatarUpload.name }}
        </div>
        <div class="profile-photo__preview-remove" @click="removeAvatar()">
          <DeleteIcon class="message__close" />
        </div>
      </div>
    </div>
    <div class="onboarding-modal__step-btn-wrapper">
      <button
        v-if="!isLoading"
        type="submit"
        class="btn btn--primary onboarding-modal__step-save-btn"
        :disabled="isSaveAndContinueDisabled"
        @click="saveProfilePhoto()"
      >
        Save and continue
      </button>
      <button
        v-if="!isLoading && isSaveAndContinueDisabled"
        class="btn btn--secondary onboarding-modal__step-next-btn"
        @click="$emit('go-next-step')"
      >
        Skip this step
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import FileUploader from '@/components/shared/FileUploader.vue'
import AvatarPreview from '@/components/user-dashboard/AvatarPreview.vue'
import CONSTANTS from '@/constants'
import DeleteIcon from '@/assets/icons/delete_icon.svg'

export default {
  name: 'ProfilePhoto',
  components: {
    FileUploader,
    AvatarPreview,
    DeleteIcon: { ...DeleteIcon, name: 'DeleteIcon' },
  },
  props: {
    stepBadgeLabel: {
      type: String,
      required: true,
    },
    onboardingSurvey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isUploadEnabled: false,
      avatarUpload: '',
      photoDeleted: false,
    }
  },
  computed: {
    ...mapState({
      currentAvatar: state => state.auth.currentAvatar,
      isComponentLoading: state => state.app.isComponentLoading,
    }),
    isDefaultAvatar() {
      const currentAvatar = this.currentAvatar?.split('/').slice(-2).join('/')
      return CONSTANTS.PROFILE.DEFAULT_AVATAR.includes(currentAvatar)
    },
    isSaveAndContinueDisabled() {
      return (this.isUploadEnabled || !this.avatarUpload) && !this.photoDeleted
    },
  },
  watch: {
    avatarUpload() {
      if (this.avatarUpload) this.isUploadEnabled = false
    },
    currentAvatar() {
      this.isUploadEnabled = !this.currentAvatar || this.isDefaultAvatar
    },
  },
  mounted() {
    this.isUploadEnabled = !this.currentAvatar || this.isDefaultAvatar
  },
  methods: {
    handleAvatarUpload(avatarUpload) {
      this.avatarUpload = avatarUpload
      if (this.avatarUpload.size > CONSTANTS.PROFILE.AVATAR_FILE_SIZE_LIMIT) {
        this.$store.dispatch(
          'app/setError',
          'Please select a file that is less than 10mb in size.'
        )
        return (this.avatarUpload = '')
      }
    },
    removeAvatar() {
      this.avatarUpload = ''
      this.isUploadEnabled = true
      this.photoDeleted = true
    },
    async saveProfilePhoto() {
      if (!this.avatarUpload) this.avatarUpload = null
      this.$emit('set-loader', true)
      const postData = {
        type: 'binary',
        file: this.avatarUpload,
      }
      try {
        await this.$store.dispatch('auth/updateUserAvatar', postData)
        const onboardingSurvey = {
          ...this.onboardingSurvey?.data,
          [CONSTANTS.PROFILE.ONBOARDING.ITEMS_IDS.AVATAR]: !!this.avatarUpload,
        }
        await this.$store.dispatch('auth/setOnboardingSurvey', onboardingSurvey)
        this.$emit('go-next-step')
      } finally {
        this.$emit('set-loader', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-photo {
  &__header {
    margin-bottom: 20px;
  }
  &__preview {
    display: flex;
    flex-direction: column;
    height: 243px;
    width: 100%;
    margin-bottom: 10px;
    font-size: rem(12);
    line-height: 14px;
    &-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px 10px 10px;
      height: 70px;
      border: 1px solid var(--emperor-gray);
    }
    &-avatar {
      height: 50px;
      width: 50px;
      border-radius: 12px;
      ::v-deep img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    &-filename {
      color: var(--light-blue-gray);
      margin-left: 10px;
    }
    &-remove {
      margin-left: auto;
      color: var(--white);
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__btn-wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
