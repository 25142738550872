import CONSTANTS from '@/constants'

export default [
  {
    path: '/playlists',
    name: 'playlists',
    component: () => import('@/pages/Playlists.vue'),
  },
  {
    path: '/playlists/:id/lab/:labId',
    name: 'playlists-lab',
    component: () => import('@/pages/LabPage.vue'),
    props: { context: CONSTANTS.PARENT_CONTENT_TYPES_KEYS.PLAYLIST },
  },
]
