<template>
  <ul class="mobile-menu">
    <div class="mobile-menu__arrow-container">
      <div class="mobile-menu__arrow"></div>
    </div>
    <li
      class="mobile-menu__link-wrapper"
      :class="[
        'mobile-menu__link-wrapper',
        isLearningAreasActive && 'mobile-menu__link-wrapper--active',
      ]"
      @click="updateIsLearningAreasActive()"
    >
      <div class="mobile-menu__link">
        <span>Learning Areas</span>
        <ArrowRightIcon class="mobile-menu__categories-arrow" />
      </div>
      <transition name="slide">
        <Categories
          :primaryAreas="primaryAreas"
          :secondaryAreas="secondaryAreas"
          :currentCategory="currentCategory"
          :isLearningAreasActive="isLearningAreasActive"
          @close-mega-menu="$emit('close-mega-menu')"
          @toggleCurrentCategory="toggleCurrentCategory"
        />
      </transition>
    </li>
    <router-link
      v-for="item in subscribersMenuLinks"
      :key="item.route"
      class="mobile-menu__link-wrapper mobile-menu__menu-item"
      :to="{ name: item.route }"
      @click.native="$emit('close-mega-menu')"
    >
      <div class="mobile-menu__link">
        {{ item.name }}
      </div>
      <ArrowRightIcon class="mobile-menu__categories-arrow" />
    </router-link>
    <router-link
      v-if="showSkillDiveLink"
      class="mobile-menu__link-wrapper mobile-menu__menu-item"
      :to="{
        name: skillDiveRouteName,
      }"
      @click.native="$emit('close-mega-menu')"
    >
      <div class="mobile-menu__link">{{ skillDiveLabel }}</div>
      <ArrowRightIcon class="mobile-menu__categories-arrow" />
    </router-link>
    <router-link
      v-if="displaySonar"
      class="mobile-menu__link-wrapper mobile-menu__menu-item"
      :to="{
        name: 'sonar',
      }"
      @click.native="$emit('close-mega-menu')"
    >
      <div class="mobile-menu__link">Skill Sonar</div>
      <ArrowRightIcon class="mobile-menu__categories-arrow" />
    </router-link>
  </ul>
</template>
<script>
import CONSTANTS from '@/constants'
import { ArrowRightIcon } from 'vue-feather-icons'
import { mapGetters, mapActions } from 'vuex'
import Categories from './mobile-mega-menu/Categories.vue'

export default {
  name: 'MobileMegaMenu',
  components: {
    Categories,
    ArrowRightIcon,
  },
  props: {
    primaryAreas: {
      type: Array,
      required: true,
    },
    secondaryAreas: {
      type: Array,
      required: true,
    },
    isAttackDefenseUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLearningAreasActive: false,
      currentCategory: '',
      subscribersMenuLinks: [
        {
          route: 'certifications',
          name: 'Certifications',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      hasPlaylists: 'auth/hasPlaylists',
      displaySonar: 'sonar/displaySonar',
      isSkillDiveB2CUser: 'auth/isSkillDiveB2CUser',
      isSkillDiveB2BUser: 'auth/isSkillDiveB2BUser',
      isLabExperienceUser: 'auth/isLabExperienceUser',
      isLabExperienceLegacyB2BUser: 'auth/isLabExperienceLegacyB2BUser',
      labExperienceEnhance: 'app/labExperienceEnhance',
      isBusinessUser: 'business/isBusinessUser',
      businessUser: 'business/getBusinessUser',
    }),
    showSkillDiveLink() {
      return (
        !this.isBusinessUser ||
        (this.isBusinessUser &&
          !CONSTANTS.BUSINESS_OLD_PRICING_PLANS.includes(
            this.businessUser?.account.pricing_plan
          )) ||
        this.isLabExperienceUser ||
        this.labExperienceEnhance
      )
    },
    skillDiveLabel() {
      return !this.isLabExperienceLegacyB2BUser
        ? 'Skill Dive'
        : 'Lab Experience'
    },
    skillDiveRouteName() {
      return !this.isLabExperienceLegacyB2BUser
        ? 'skill-dive'
        : 'lab-experience'
    },
  },
  watch: {
    isAuthenticated: {
      handler() {
        if (this.isAuthenticated) {
          this.userMeOnContent()
        }
      },
      immediate: true,
    },
    hasPlaylists(value) {
      if (value)
        this.subscribersMenuLinks.push({
          route: 'playlists',
          name: 'Playlists',
        })
    },
  },
  methods: {
    ...mapActions('auth', ['userMeOnContent']),
    toggleCurrentCategory(category) {
      if (category !== this.currentCategory) {
        this.currentCategory = category
      } else {
        this.currentCategory = ''
      }
    },
    updateIsLearningAreasActive() {
      this.isLearningAreasActive = !this.isLearningAreasActive
      this.currentCategory = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.mobile-menu {
  position: absolute;
  width: calc(100% - 18px);
  max-width: 450px;
  top: 68px;
  $this: &;
  display: block;
  margin: 0 9px;
  left: 0;
  padding: 0;
  background: var(--glass-mid-gray);
  border-radius: 10px;
  @include tablet {
    display: none;
  }
  &__menu-item {
    display: flex;
    justify-content: space-between;
  }
  &__link-wrapper {
    position: relative;
    max-height: 50px;
    font-size: rem(14px);
    color: var(--white);
    border-bottom: 1px solid var(--tundora-gray);
    padding: 15px 20px;
    &:nth-child(2) {
      border-radius: 10px 10px 0 0;
    }
    cursor: pointer;
    &:hover {
      background: var(--glass-extra-light);
      & .mobile-menu__categories-arrow {
        display: block;
      }
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      border: none;
    }
    &--active {
      max-height: initial;
      &:hover {
        background: var(--dark-gray);
      }
      .mobile-menu__link {
        padding-bottom: 15px;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
  }
  &__categories-arrow {
    display: none;
    width: 15px;
    height: 20px;
  }
  &__arrow {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid var(--dark-gray);
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -11px;
  }
  &::before {
    position: absolute;
    content: '';
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: var(--menu-background);
  }
}
</style>
