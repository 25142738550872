import VueRouter, { Route } from 'vue-router'

import CONSTANTS from '@/constants'
import routes from './routes'
import { sendToHubspot } from '@/utils/hubspot'
import store from '../store'

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    return { x: 0, y: 0 }
  },
  routes,
})

// eslint-disable-next-line no-unused-vars
Router.afterEach((to, from) => {
  sendToHubspot(to.fullPath)
})

Router.beforeResolve(async (to: Route, from: Route, next: Function) => {
  const isAuthenticated = store.state.auth.isAuthenticated

  if (
    isAuthenticated &&
    to?.name &&
    CONSTANTS.ROUTING_RULES.REDIRECT_IF_AUTHENTICATED.includes(to.name)
  ) {
    next({ name: 'home' })
  } else {
    next()
  }

  if (to?.name && CONSTANTS.ROUTING_RULES.DOORBELL_FEEDBACK.includes(to.name)) {
    if (window.doorbell) {
      window.doorbell?.setOption('hideButton', false)
      window.doorbell?.setOption('onShown', () => {
        const question = store?.getters['sonar/currentQuestion']
        if (question?.id) {
          window.doorbell?.setProperty('questionID', question.id)
        }
      })
      window.doorbell?.refresh()
    } else {
      setTimeout(() => {
        window.doorbell?.setOption('hideButton', false)
        window.doorbell?.setOption('onShown', () => {
          const question = store?.getters['sonar/currentQuestion']
          if (question?.id) {
            window.doorbell?.setProperty('questionID', question.id)
          }
          window.doorbell?.refresh()
        })
      }, 3000)
    }
  } else {
    if (window.doorbell) {
      window.doorbell?.setOption('hideButton', true)
      window.doorbell?.refresh()
    } else {
      setTimeout(() => {
        window.doorbell?.setOption('hideButton', true)
        window.doorbell?.refresh()
      }, 500)
    }
  }
})

const navigationState = {
  stack: [] as string[],
  popStateDetected: false,
  isBack: false,
}

const updateNavigationState = (to: Route) => {
  const currentPath = to.fullPath

  if (
    navigationState.stack.length > 1 &&
    navigationState.stack[navigationState.stack.length - 2] === currentPath
  ) {
    // Backward navigation
    navigationState.isBack = true
    navigationState.stack.pop()
  } else {
    // Forward or normal navigation
    navigationState.isBack = false
    navigationState.stack.push(currentPath)

    // Limit stack size to avoid memory issues
    if (navigationState.stack.length > 3) {
      navigationState.stack.shift()
    }
  }
}

export const updateCurrentUrlParams = (params: string) => {
  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}?${params}`)
  )
  navigationState.stack[navigationState.stack.length - 1] =
    window.location.pathname + window.location.search
}

export const isBackNavigation = () => {
  return navigationState.isBack
}

export const hasPreviousPath = () => {
  return navigationState.stack.length > 1
}

export const getPreviousPath = () => {
  return navigationState.stack[navigationState.stack.length - 2]
}

Router.beforeEach((to: Route, from: Route, next: Function) => {
  updateNavigationState(to)
  next()
})

export default Router
