import { CONTENT } from '@/clients'
import { djangoErrorHandler } from '@/helpers'

const fetchLearningAreas = async () => {
  const route = `learning-areas`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data.results))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchLearningArea = async id => {
  const route = `learning-areas/${id}`
  return CONTENT.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export { fetchLearningAreas, fetchLearningArea }
