import { sub, format } from 'date-fns'
import { STATISTICS } from '@/clients'
import { laravelErrorHandler } from '@/helpers'

const fetchTrainingHistory = async () => {
  const route = `auth/stats`
  const stats = ['all_courses_video_time_watched']
  const start_date = format(sub(new Date(), { years: 1 }), 'yyyy-MM-dd')
  return STATISTICS.post(route, { stats, start_date })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchMonthlyStats = async () => {
  const route = `auth/stats`
  const stats = ['time_watched']
  const start_date = format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd')
  return STATISTICS.post(route, { stats, start_date })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchLastMonthStats = async () => {
  const route = `auth/stats`
  const stats = [
    'videos_watched',
    'courses_viewed',
    'time_watched',
    'all_courses_video_time_watched',
  ]
  const start_date = format(sub(new Date(), { months: 2 }), 'yyyy-MM-dd')
  const end_date = format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd')
  return STATISTICS.post(route, { stats, start_date, end_date })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchYearlyStats = async () => {
  const route = `auth/stats`
  const stats = [
    'videos_watched',
    'courses_viewed',
    'time_watched',
    'time_watched_chart',
  ]
  const start_date = format(sub(new Date(), { years: 1 }), 'yyyy-MM-dd')
  return STATISTICS.post(route, { stats, start_date, interval: 'month' })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

export {
  fetchTrainingHistory,
  fetchMonthlyStats,
  fetchYearlyStats,
  fetchLastMonthStats,
}
