<template>
  <div v-tooltip="{ content: 'Go to Business Dashboard' }">
    <router-link :to="{ name: getRouteName() }" class="switch-business">
      <RepeatIcon class="switch-business__icon" />
    </router-link>
  </div>
</template>

<script>
import { RepeatIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'SwitchToBusiness',
  components: {
    RepeatIcon,
  },
  props: {},
  methods: {
    getRouteName() {
      if (this.isBusinessV2User) {
        return 'business-v2'
      }
      return 'business-v1'
    },
  },
  computed: {
    ...mapGetters({
      isBusinessV2User: 'business/isBusinessUser',
    }),
  },
}
</script>

<style lang="scss" scoped>
.switch-business {
  display: block;
  width: 100%;
  margin-left: 5px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  padding: 10px;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
  @include mobile {
    width: auto;
    margin-left: 10px;
  }
  &:hover,
  &:active {
    background: var(--white);
    svg {
      color: var(--charcoal);
    }
  }
  &:target {
    background: var(--athens-gray);
    svg {
      color: var(--charcoal);
    }
  }
  &__icon {
    color: white;
  }
}
</style>
