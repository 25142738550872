<template>
  <section class="onboarding-modal__step">
    <div class="onboarding-modal__step-badge">{{ stepBadgeLabel }}</div>
    <div class="onboarding-modal__step-header">
      <div class="onboarding-modal__step-title">Add a secondary email</div>
      <div class="onboarding-modal__step-info">
        To make sure that you can get back into your account if you ever can't
        sign in, add a backup email.
      </div>
    </div>
    <div class="backup-email__wrapper">
      <div class="onboarding-modal__input-wrapper backup-email__input">
        <input
          v-model="userBackupEmail"
          type="email"
          :class="[
            'ine-form__form-control',
            'ine-input',
            errors.fields.includes('email') && 'ine-input--error',
          ]"
          autocomplete="email"
          placeholder="Backup email"
          @focus="clearErrors"
        />
        <Errors
          v-if="errors.fields.includes('email')"
          :errors="errors.messages[0]"
        />
      </div>
    </div>
    <div class="onboarding-modal__step-btn-wrapper">
      <button
        type="submit"
        class="btn btn--primary onboarding-modal__step-save-btn"
        :disabled="isSaveDisabled"
        @click="saveEmail()"
      >
        Save and continue
      </button>
      <button
        class="btn btn--secondary onboarding-modal__step-next-btn"
        @click="$emit('go-next-step')"
      >
        Skip this step
      </button>
      <button
        v-if="!isFirstStep"
        class="btn btn--tertiary onboarding-modal__step-back-btn"
        @click="$emit('go-previus-step')"
      >
        Back
      </button>
    </div>
  </section>
</template>

<script>
import { validateForm, clearFormErrors } from '@/helpers'
import Errors from '@/components/forms/elements/Errors.vue'

export default {
  name: 'BackupEmail',
  components: {
    Errors,
  },
  props: {
    stepBadgeLabel: {
      type: String,
      required: true,
    },
    onboardingSurvey: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
    isFirstStep: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: clearFormErrors(),
      isSaveDisabled: true,
      userBackupEmail: '',
    }
  },
  watch: {
    userBackupEmail() {
      if (this.userProfile?.backup_email?.email !== this.userBackupEmail) {
        this.isSaveDisabled = false
      }
    },
  },
  async created() {
    this.$emit('set-component-loader', true)
    if (!this.userBackupEmail) {
      await this.$store.dispatch('auth/getUserBackupEmails')
      this.userBackupEmail = this.userProfile?.backup_email?.email
    }
    this.$emit('set-component-loader', false)
  },
  methods: {
    validateFields() {
      const { userBackupEmail } = this
      if (!userBackupEmail || userBackupEmail === '') return true
      const { isValid, errors } = validateForm({
        email: {
          rules: ['email'],
          value: userBackupEmail,
        },
      })
      this.errors = errors
      return isValid
    },
    clearErrors() {
      this.errors = clearFormErrors()
    },
    async saveEmail() {
      if (!this.validateFields()) return
      const { userBackupEmail } = this
      this.isSaveDisabled = true
      this.$emit('set-loader', true)
      try {
        if (userBackupEmail) {
          await this.$store.dispatch('auth/updateBackupEmail', {
            email: userBackupEmail,
          })
          if (!this.onboardingSurvey?.data?.backup_email) {
            const onboardingSurvey = {
              ...this.onboardingSurvey.data,
              backup_email: true,
            }
            await this.$store.dispatch(
              'auth/setOnboardingSurvey',
              onboardingSurvey
            )
          }
          await this.$store.dispatch('auth/getUserCurrentProfile')
        } else {
          await this.$store.dispatch('auth/removeBackupEmail', {
            email: this.userProfile?.backup_email?.email,
            id: this.userProfile?.backup_email?.id,
          })
          if (this.onboardingSurvey?.data?.backup_email) {
            const onboardingSurvey = {
              ...this.onboardingSurvey.data,
              backup_email: false,
            }
            await this.$store.dispatch(
              'auth/setOnboardingSurvey',
              onboardingSurvey
            )
          }
          await this.$store.dispatch('auth/getUserCurrentProfile')
        }

        this.$emit('go-next-step')
      } finally {
        this.$emit('set-loader', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.backup-email {
  &__wrapper {
    margin-top: 25px;
    height: 220px;
    @include tablet {
      margin-top: 0;
      height: 100%;
    }
  }
  &__input {
    max-width: 100%;
  }
}
</style>
