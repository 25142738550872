export const passwordHasLowercase = value => {
  return /[a-z]/.test(value)
}

export const passwordHasUppercase = value => {
  return /[A-Z]/.test(value)
}

export const passwordHasANumber = value => {
  return /[0-9]/.test(value)
}

export const passwordHasCorrectLength = ({ value, requiredLength }) => {
  return value.length >= requiredLength
}

export const isPasswordValid = ({ value, requiredLength }) => {
  const hasLowercase = passwordHasLowercase(value)
  const hasUppercase = passwordHasUppercase(value)
  const hasNumber = passwordHasANumber(value)
  const isRequiredLength = passwordHasCorrectLength({ value, requiredLength })
  return hasLowercase && hasUppercase && hasNumber && isRequiredLength
}
