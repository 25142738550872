<template>
  <div class="dialog-modal" role="alert">
    <div class="dialog-modal__wrapper">
      <button class="dialog-modal__close" @click="$emit('close-dialog-modal')">
        <XIcon class="dialog-modal__close--icon">clear</XIcon>
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'DialogModal',
  components: {
    XIcon,
  },
  created() {
    const body = document.querySelector('body')
    body.classList.add('dialog-modal-open')
  },
  destroyed() {
    const body = document.querySelector('body')
    body.classList.remove('dialog-modal-open')
  },
  methods: {
    closeModal() {
      this.$emit('close-dialog-modal')
    },
  },
}
</script>
