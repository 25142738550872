import store from '../store'

const elevatedContentStatus = ['coming soon']

export const hasElevatedRoles = () => {
  return store.state.auth.isAdmin || store.state.auth.isContentCreator
}

export const hasBaseAccess = content => {
  if (hasElevatedRoles()) return true
  const access = content.access || content.user_status
  const { status } = content
  const has_access = access?.has_access
  return (
    store.state.auth.isAuthenticated &&
    has_access &&
    !elevatedContentStatus.includes(status)
  )
}

export const isFreeContent = content => {
  const { is_sample } = content
  return store.state.auth.isAuthenticated && is_sample
}

export const hasContentAccess = ({ parent, content }) => {
  if (content.file && isFreeContent(content.file)) return true
  const {
    access: { available_content_types: availableTypes },
  } = parent
  const { type, labType } = content
  if (type === 'lab') {
    return hasBaseAccess(parent) && availableTypes.includes(`lab:${labType}`)
  }
  if (type === 'exercise_group') {
    return hasBaseAccess(parent)
  }
  return hasBaseAccess(parent) && availableTypes.includes(type)
}
