import CONSTANTS from '@/constants'
import { differenceInSeconds, intervalToDuration } from 'date-fns'
import {
  lastActiveVoucher,
  voucherCertificationAvailable,
  voucherCertificationAvailableOnDate,
  voucherCertificationAvailableDateTime,
} from './vouchers'

export const hasPassedAttempt = attempt => {
  let practice = true
  if (!attempt) return false
  if (attempt.practice_lab) {
    if (attempt.user_lab_attempt) {
      practice = attempt.user_lab_attempt.has_passed
    } else {
      practice = false
    }
  }
  return (
    attempt.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.FINISHED &&
    attempt.has_passed &&
    practice
  )
}

const attemptInReview = attempt => {
  if (
    attempt &&
    attempt.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.REVIEW
  ) {
    return attempt
  }
  return null
}

export const attemptFailed = attempt => {
  if (
    attempt &&
    (attempt.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.FINISHED ||
      attempt.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.TIMEDOUT) &&
    !hasPassedAttempt(attempt)
  ) {
    return attempt
  }
  return null
}

export const attemptInProgress = attempt => {
  if (
    attempt &&
    attempt.status === CONSTANTS.CERTIFICATIONS.ATTEMPT_STATUS.IN_PROGRESS &&
    differenceInSeconds(
      new Date(attempt.expiration_date).getTime(),
      new Date().getTime()
    ) >= 0
  ) {
    return attempt
  }
  return null
}

export const activeVouchers = certification => {
  if (!certification.user_status) return null
  return certification.user_status.active_vouchers
}

export const inactiveVouchers = certification => {
  if (!certification.user_status) return null
  return certification.user_status.inactive_vouchers
}

export const lastAttempt = certification => {
  return (
    certification.user_status?.active_vouchers?.[0]?.active_attempts?.[0] ||
    certification.user_status?.active_vouchers?.[0]?.inactive_attempts?.[0] ||
    certification.user_status?.inactive_vouchers?.[0]?.active_attempts?.[0] ||
    certification.user_status?.inactive_vouchers?.[0]?.inactive_attempts?.[0]
  )
}

export const lastActiveVoucherAttempt = certification => {
  return (
    certification.user_status?.active_vouchers?.[0]?.active_attempts?.[0] ||
    certification.user_status?.active_vouchers?.[0]?.inactive_attempts?.[0]
  )
}

export const canDoARetake = certification => {
  if (!certification.user_status) return false
  if (
    activeVouchers(certification)?.length &&
    inactiveVouchers(certification)?.length
  )
    return true

  const activeVoucher = lastActiveVoucher(
    certification.user_status.active_vouchers
  )
  const attempt = lastAttempt(certification)
  return (
    activeVoucher &&
    activeVoucher.remaining_attempts &&
    activeVoucher.next_attempt_expiration_date &&
    attemptFailed(attempt)
  )
}

export const noCurrentVoucher = certification => {
  const attempt = lastAttempt(certification)
  return (
    certification.is_external ||
    (!attemptInReview(attempt) &&
      !hasPassedAttempt(attempt) &&
      !activeVouchers(certification)?.length &&
      certification.user_status)
  )
}

export const hasAnyVoucher = certification => {
  return (
    certification.user_status?.active_vouchers?.length ||
    certification.user_status?.inactive_vouchers?.length
  )
}

export const lastVoucher = certification => {
  const actVouchers = activeVouchers(certification)
  const inactVouchers = inactiveVouchers(certification)

  if (actVouchers?.length) {
    return actVouchers[0]
  }
  if (inactVouchers?.length) {
    return inactVouchers[0]
  }
  return null
}

export const certInBeta = certification => {
  return (
    certification?.versions?.[0]?.is_beta ||
    lastVoucher(certification)?.certification_version?.is_beta
  )
}

export const hasUserStatus = certification => {
  return (
    !certification.is_external &&
    (certification.awarded || certification.user_status)
  )
}

export const certificationUserStatus = certification => {
  const attempt = lastAttempt(certification)

  if (
    certInBeta(certification) &&
    (!hasUserStatus(certification) || noCurrentVoucher(certification))
  ) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.NOT_AVAILABLE
  }

  if (noCurrentVoucher(certification)) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.NO_VOUCHERS
  }

  if (attemptInReview(attempt)) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.ATTEMPT_IN_REVIEW
  }

  if (attemptInProgress(attempt)) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.ATTEMPT_IN_PROGRESS
  }

  if (canDoARetake(certification)) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.CAN_DO_RETAKE
  }

  if (hasPassedAttempt(attempt)) {
    return CONSTANTS.CERTIFICATIONS.USER_STATUS.AWARDED
  }

  return CONSTANTS.CERTIFICATIONS.USER_STATUS.READY_FOR_FIRST_ATTEMPT
}

export const canNotTakeExam = certification => {
  // when we have to buy:
  // no cert
  // no user
  // no voucher
  // no attempt and no progress, review or passed
  // no matters about attempts, but no active and no progress, review or passed
  let voucher = null
  let attempt = null
  if (certification && certification.user_status) {
    voucher = lastVoucher(certification)
    attempt = lastAttempt(certification)
    return (
      !voucher ||
      (!voucher.remaining_attempts &&
        !attemptInProgress(attempt) &&
        !attemptInReview(attempt) &&
        !hasPassedAttempt(attempt)) ||
      (!lastActiveVoucher(certification.user_status.active_vouchers) &&
        !attemptInProgress(attempt) &&
        !attemptInReview(attempt) &&
        !hasPassedAttempt(attempt))
    )
  }

  return true
}

export const certLatestVersion = certification => {
  return certification.versions?.[0]
}

export const getCertificationFullName = content => {
  if (content.certifications?.length) {
    return content.certifications[0].name
  }
  return null
}

export const getCertificateName = content => {
  let certificate = null
  if (content.certifications?.length) {
    certificate = content.certifications[0].short_name
  }
  const externalCert = content.vendor_certifications?.find(
    cert => cert.is_external
  )
  certificate = externalCert ? externalCert.certification_name : certificate

  return certificate
}

export const getCertificationVendor = content => {
  const externalCert = content.vendor_certifications?.find(
    cert => cert.is_external
  )

  const defaultVendor = content.certifications?.length ? 'INE' : null

  return externalCert ? externalCert.vendor_name : defaultVendor
}

export const shouldDisplayCertificate = (content, type) => {
  if (type !== 'Learning Paths') {
    return false
  }
  return !!getCertificateName(content)
}

export const lastActiveVoucherFromCert = certification => {
  const activeVouchersCert = activeVouchers(certification)
  return lastActiveVoucher(activeVouchersCert)
}

export const lastInactiveVoucherFromCert = certification => {
  const inactiveVouchersCert = inactiveVouchers(certification)
  return lastActiveVoucher(inactiveVouchersCert)
}

export const attemptInProgressFromCert = certification => {
  const attempt = lastAttempt(certification)
  return attemptInProgress(attempt)
}

export const attemptInReviewFromCert = certification => {
  const attempt = lastAttempt(certification)
  return attemptInReview(attempt)
}

// Avalability & Start date

export const isCertAvailable = certification => {
  const activeVoucher = lastActiveVoucherFromCert(certification)
  return voucherCertificationAvailable([activeVoucher])
}

export const certAvailableDate = certification => {
  const activeVoucher = lastActiveVoucherFromCert(certification)

  return voucherCertificationAvailableOnDate([activeVoucher])
}

export const certAvailableDateTime = certification => {
  const activeVoucher = lastActiveVoucherFromCert(certification)
  return voucherCertificationAvailableDateTime([activeVoucher])
}

export const attemptRemainingHours = attempt => {
  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(attempt.expiration_date),
  })
  if (duration.days) {
    duration.hours += duration.days * 24
  }
  return `${duration.hours}h${duration.minutes}m`
}

// certification Labs

export const certHasLab = certification => {
  return (
    certification.versions?.[0]?.practice_lab ||
    certification.versions?.[0]?.questions_lab
  )
}
