import { MutationTree } from 'vuex'
import { IIFramesState, IIFrame } from '@/@types'

const mutations: MutationTree<IIFramesState> = {
  UPDATE_IFRAMES_RENDERED: (state, iframe: IIFrame) => {
    state.iframesRendered = {
      ...state.iframesRendered,
      [iframe.id]: iframe.url,
    }
  },
  PLAYING_IFRAME_ID_UPDATED: (state, iframeId: string) => {
    state.playingIFrameId = iframeId
  },
}

export default mutations
