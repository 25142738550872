import { CONTENT, PROFILE, SUBSCRIPTIONS } from '@/clients'

import { getAccessToken } from '@internetworkexpert/js-common'
import { laravelErrorHandler } from '@/helpers'
import { errorHandler } from '@/utils'

const fetchSubscriptions = async () => {
  const route = `subscriptions?embed=passes`
  return SUBSCRIPTIONS.get(route)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchValidAddons = async id => {
  const route = `subscriptions/${id}/valid-addons`
  return SUBSCRIPTIONS.get(route)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const updateAddonsForSubscription = async (id, addons) => {
  const route = `subscriptions/${id}/addons`
  return SUBSCRIPTIONS.post(route, { add_ons: addons })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchPlanConsumed = async () => {
  const route = 'users/plan-consumed'
  try {
    const response = await CONTENT.get(route)
    return await Promise.resolve(response.data)
  } catch (error) {
    return await Promise.reject(laravelErrorHandler(error))
  }
}

const postUpdatePaymentMethod = async token => {
  const route = `manage/update-payment`
  return SUBSCRIPTIONS.post(route, { 'recurly-token': token })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

const postCancelSubscription = async id => {
  const route = `manage/cancel/${id}`
  return SUBSCRIPTIONS.post(route, {})
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const postReactivateSubscription = async id => {
  const route = `manage/reactivate/${id}`
  return SUBSCRIPTIONS.post(route, {})
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchTermExtensionPricing = async id => {
  const route = `manage/extend/${id}`
  try {
    const { data } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const putOneTermToSubscription = async id => {
  const route = `manage/extend/${id}`
  try {
    const { data } = await SUBSCRIPTIONS.put(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchInvoices = async () => {
  const route = `manage/invoices`

  try {
    const {
      data: { data },
    } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchInvoiceDownload = async id => {
  const route = `manage/invoices/${id}`
  return SUBSCRIPTIONS.get(route, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${id}.pdf`
      link.click()
      Promise.resolve()
    })
    .catch(error => Promise.reject(laravelErrorHandler(error)))
}

const fetchPassTypes = async () => {
  const route = `passes?embed=learning_areas`

  try {
    const {
      data: { data },
    } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const getAvailablePlans = async () => {
  const route = `products`
  try {
    const { data } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchValidPlans = async planId => {
  const route = `subscriptions/${planId}/valid`
  try {
    const { data } = await SUBSCRIPTIONS.get(route)
    return data?.data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchPlanComparison = async idAndPlanCode => {
  const { id, plan_code, coupon_code } = idAndPlanCode
  const route = `subscriptions/${id}/compare`

  const payload = { plan_code }
  if (coupon_code && coupon_code.length) {
    payload.coupon_code = coupon_code
  }
  try {
    const {
      data: { data, meta },
    } = await SUBSCRIPTIONS.post(route, payload)
    return { ...data, meta }
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const putUpgradePlan = async idAndPlanCode => {
  const { id, plan_code, coupon_code } = idAndPlanCode
  const route = `subscriptions/${id}/upgrade`

  const payload = { plan_code }
  if (coupon_code && coupon_code.length) {
    payload.coupon_code = coupon_code
  }
  try {
    const {
      data: { data },
    } = await SUBSCRIPTIONS.put(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchPaymentInfo = async () => {
  const route = `manage/account-information`
  try {
    const {
      data: { data },
    } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const fetchSubscriptionCancellationFields = async () => {
  const route = 'users/surveys/subscription_canceled_survey'
  try {
    const {
      data: { data },
    } = await PROFILE.get(route)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postSubscriptionCancellationReasons = async payload => {
  const route = 'users/survey-response'
  try {
    const { data } = await PROFILE.post(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postNewTrial = async ({ payload, isAuthenticated }) => {
  const route = `free-tier`
  try {
    if (isAuthenticated) {
      const headers = {
        Authorization: `Bearer ${getAccessToken()}`,
      }
      const { data } = await SUBSCRIPTIONS.post(route, payload, { headers })
      return data
    }
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postPurchaseVoucherExtension = async ({ payload }) => {
  const route = 'vouchers/extend'
  try {
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (error) {
    const message = errorHandler(error)
    throw { ...error, message }
  }
}

const fetchSkillDiveAddHoursPreviewPurchase = async ({ payload }) => {
  const route = 'preview-purchase'
  try {
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (error) {
    throw error
  }
}

const fetchSkillDiveAddHoursPurchase = async ({ payload }) => {
  const route = 'purchase'
  try {
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (error) {
    throw error
  }
}

const fetchPromotions = async () => {
  const route = 'promotions'
  try {
    const { data } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw error
  }
}

const fetchPromotionBySlug = async slug => {
  const route = `promotions/${slug}/show`
  try {
    const { data } = await SUBSCRIPTIONS.get(route)
    return data
  } catch (error) {
    throw error
  }
}
const executePromotionBySlug = async payload => {
  const route = `promotions/${payload.slug}`
  delete payload.slug
  try {
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (error) {
    throw error
  }
}

const fetchCouponInfo = async payload => {
  const route = `coupon`
  try {
    const {
      data: { data },
    } = await SUBSCRIPTIONS.get(route, { params: { code: payload } })
    return data
  } catch (error) {
    throw laravelErrorHandler(error)
  }
}

const postCheckout = async payload => {
  const route = 'checkout'
  try {
    const { data } = await SUBSCRIPTIONS.post(route, payload)
    return data
  } catch (errorObj) {
    const error = errorObj.response.data
    const { processor_error_code } = error
    if (error.new_user) {
      throw error
    }
    if (processor_error_code && processor_error_code.length) {
      throw error
    } else {
      throw laravelErrorHandler(errorObj)
    }
  }
}

export {
  executePromotionBySlug,
  fetchCouponInfo,
  fetchInvoiceDownload,
  fetchInvoices,
  fetchPassTypes,
  fetchPaymentInfo,
  fetchPlanComparison,
  fetchPlanConsumed,
  fetchPromotionBySlug,
  fetchPromotions,
  fetchSkillDiveAddHoursPreviewPurchase,
  fetchSkillDiveAddHoursPurchase,
  fetchSubscriptionCancellationFields,
  fetchSubscriptions,
  fetchTermExtensionPricing,
  fetchValidAddons,
  fetchValidPlans,
  getAvailablePlans,
  postCancelSubscription,
  postCheckout,
  postNewTrial,
  postPurchaseVoucherExtension,
  postReactivateSubscription,
  postSubscriptionCancellationReasons,
  postUpdatePaymentMethod,
  putOneTermToSubscription,
  putUpgradePlan,
  updateAddonsForSubscription,
}
