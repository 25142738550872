<template>
  <transition name="fade">
    <div class="notifications">
      <Message
        v-for="error in errors"
        :key="error"
        ofType="error"
        :message="error"
        @dismiss-message="clearError"
      />
      <Message
        v-for="notification in notifications"
        :key="notification"
        ofType="notification"
        :message="notification"
        @dismiss-message="clearNotification"
      />
      <Message
        v-for="success in successes"
        :key="success"
        ofType="success"
        :message="success"
        @dismiss-message="clearSuccess"
      />
      <Message
        v-for="warning in warnings"
        :key="warning"
        ofType="warning"
        :message="warning"
        @dismiss-message="clearWarning"
      />
    </div>
  </transition>
</template>

<script>
import Message from './notification-center/Message.vue'

export default {
  name: 'NotificationsCenter',
  components: { Message },
  props: {
    errors: {
      type: Array,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
    successes: {
      type: Array,
      required: true,
    },
    warnings: {
      type: Array,
      required: true,
    },
  },
  methods: {
    clearError(message) {
      this.$store.dispatch('app/setError', message)
    },
    clearNotification(message) {
      this.$store.dispatch('app/setNotification', message)
    },
    clearSuccess(message) {
      this.$store.dispatch('app/setSuccess', message)
    },
    clearWarning(message) {
      this.$store.dispatch('app/setWarning', message)
    },
  },
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  @include mobile {
    display: block;
    right: 100px;
    top: 75px;
    left: unset;
  }
}
</style>
