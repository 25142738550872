import { MutationTree } from 'vuex'
import { IAuthState, IUserProfile } from '@/@types'

const mutations: MutationTree<IAuthState> = {
  IS_AUTHENTICATED_UPDATED: (state, isAuthenticated: boolean) => {
    state.isAuthenticated = isAuthenticated
  },

  PROFILE_UPDATED: (state, userProfile: IUserProfile) => {
    state.userProfile = userProfile
  },

  IS_ADMIN_UPDATED: (state, isAdmin: boolean) => {
    state.isAdmin = isAdmin
  },

  HAS_PLAYLISTS: (state, hasPlaylists: boolean) => {
    state.hasPlaylists = hasPlaylists
  },

  IS_CONTENT_CREATOR_UPDATED: (state, isContentCreator: boolean) => {
    state.isContentCreator = isContentCreator
  },

  IS_BUSINESS_MANAGER_UPDATED: (state, isBusinessManager: boolean) => {
    state.isBusinessManager = isBusinessManager
  },

  IS_TWO_FACTOR_REQUIRED_UPDATED: (state, isTwoFactorRequired: boolean) => {
    state.isTwoFactorRequired = isTwoFactorRequired
  },

  IS_GOOGLE_AUTHENTICATOR_MFA_UPDATED: (
    state,
    isGoogleAuthenticatorMFA: boolean
  ) => {
    state.isGoogleAuthenticatorMFA = isGoogleAuthenticatorMFA
  },

  IS_AUTH_LOADING_UPDATED: (state, isAuthLoading: boolean) => {
    state.isAuthLoading = isAuthLoading
  },

  REDIRECT_URL_UPDATED: (state, redirectUrl: string) => {
    state.redirectUrl = redirectUrl
  },

  SAMPLE_AVATARS_UPDATED: (state, sampleAvatars: Array<object>) => {
    state.sampleAvatars = sampleAvatars
  },

  AVATAR_UPDATED: (state, avatar: string) => {
    state.currentAvatar = avatar
  },

  PHONE_NUMBER_UPDATED: (state, preferred_phone_number: string) => {
    state.userProfile = {
      ...state.userProfile,
      preferred_phone_number,
    }
  },

  MFA_METHOD_UPDATED: (state, method: string | null = null) => {
    state.userProfile = { ...state.userProfile, mfa_method: method }
  },

  SUBSCRIPTION_STATUS_UPDATED: (state, status: string) => {
    state.subscriptionStatus = status
  },

  PROFILE_PREFERENCES_UPDATED: (state, preferences: object) => {
    state.profilePreferences = preferences
  },
  ONBOARDING_SURVEY_COLLECTION_UPDATED: (state, onboardingSurvey: object) => {
    state.onboardingSurvey = onboardingSurvey
  },
  CURRENT_PROFILE_UPDATED: (state, userCurrentProfile) => {
    state.userProfile = {
      ...state.userProfile,
      user_current_profile: userCurrentProfile,
    }
  },
  BACKUP_EMAIL_UPDATED: (state, backup_email) => {
    state.userProfile = {
      ...state.userProfile,
      backup_email,
    }
  },
  UPDATE_PASSES: (state, available_passes) => {
    state.available_passes = available_passes
  },
  UPDATE_LAB_EXPERIENCE_USER_ACCESS: (state, labExperienceUserAccess) => {
    state.labExperienceUserAccess = labExperienceUserAccess
  },
  UPDATED_ENABLED_USER_FEATURES: (state, enabled_user_features) => {
    state.enabled_user_features = enabled_user_features
  },
}

export default mutations
