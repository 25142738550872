import { ActionTree, ActionContext } from 'vuex'
import { ILearningAreasState, IRootState } from '@/@types'
import { fetchLearningAreas } from '../../../models/learning-areas'

import { errorHandler } from '@/utils'

const actions: ActionTree<ILearningAreasState, IRootState> = {
  getLearningAreas: async (context: ActionContext<ILearningAreasState, {}>) => {
    try {
      const response = await fetchLearningAreas()
      context.commit('LEARNING_AREAS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getLearningArea: (
    context: ActionContext<ILearningAreasState, IRootState>,
    id: string
  ) => {
    try {
      const area = context.state.learningAreas.find(
        learningArea => learningArea.id === id
      )
      context.commit('CURRENT_LEARNING_AREA_UPDATED', area)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
}

export default actions
