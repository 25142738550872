export const capitalize = str => {
  return str.replace(/\w/, firstLetter => firstLetter.toUpperCase())
}

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const toKebabCase = str => str.replace(' ', '-').toLowerCase()

export const andText = words => {
  if (!words) return ''
  let sentence = words.join(', ')
  if (words.length > 1) {
    const lastComma = sentence.lastIndexOf(',')
    sentence = `${sentence.substring(0, lastComma)} and${sentence.substring(
      lastComma + 1
    )}`
  }
  return sentence
}

export const pluralizeWord = (word, count, suffix = '') => {
  return count === 1 ? word : `${word}${suffix || 's'}`
}
