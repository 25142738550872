import { Module } from 'vuex'
import { IAppState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const app: Module<IAppState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default app
