<template>
  <svg
    :is="iconShown"
    v-if="isSvgIcon"
    :height="size"
    :width="size"
    :fill="fill"
  ></svg>
  <img v-else :src="iconShown" :width="size" alt="${this.iconShown} Icon" />
</template>

<script>
import AnnualWhite from '@/assets/icons/passes/annual.svg'
import EnterpriseWhite from '@/assets/icons/passes/enterprise.svg'
import MonthlyWhite from '@/assets/icons/passes/monthly.svg'
import PremiumWhite from '@/assets/icons/passes/premium.svg'
import ProfessionalWhite from '@/assets/icons/passes/professional.svg'
import Networking from '@/assets/images/learning-areas/noborders/networking.svg'
import Cloud from '@/assets/images/learning-areas/noborders/cloud.svg'
import CyberSecurity from '@/assets/images/learning-areas/noborders/cybersecurity.svg'
import ITEssentials from '@/assets/images/learning-areas/noborders/itessentials.svg'
import Development from '@/assets/images/learning-areas/noborders/development.svg'
import DataScience from '@/assets/images/learning-areas/noborders/datascience.svg'
import CONSTANTS from '@/constants'

const SVG_ICONS_MAPPING = {
  'Individual Annual': AnnualWhite,
  'Individual Monthly': MonthlyWhite,
  Enterprise: EnterpriseWhite,
  Professional: ProfessionalWhite,
  [CONSTANTS.PLANS.ALL.ine_ind_annual]: AnnualWhite,
  [CONSTANTS.PLANS.ALL.fundamentals_annual]: AnnualWhite,
  [CONSTANTS.PLANS.ALL.ine_ind_monthly]: MonthlyWhite,
  [CONSTANTS.PLANS.ALL.fundamentals_monthly]: MonthlyWhite,
  [CONSTANTS.PLANS.ALL.ejptv2_funda_monthly]: MonthlyWhite,
  ...Object.fromEntries(
    Object.values(CONSTANTS.PLANS.PREMIUM_PLANS).map(value => [
      value,
      PremiumWhite,
    ])
  ),
}

const SVG_LA_ICONS_MAPPING = {
  Networking,
  'Cyber Security': CyberSecurity,
  'IT Essentials': ITEssentials,
  Development,
  'Data Science': DataScience,
  Cloud,
}

const IMG_ICONS_MAPPING = {
  Networking: require('@/assets/images/learning-areas/networking.png'),
  'Cyber Security': require('@/assets/images/learning-areas/cybersecurity.png'),
  'IT Essentials': require('@/assets/images/learning-areas/itessentials.png'),
  Development: require('@/assets/images/learning-areas/development.png'),
  'Data Science': require('@/assets/images/learning-areas/datascience.png'),
  Cloud: require('@/assets/images/learning-areas/cloud.png'),
}

export default {
  name: 'Passes',
  props: {
    passName: {
      type: String,
      required: true,
    },
    passCode: {
      type: String,
      required: false,
    },
    laNoBorders: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
    size: {
      type: Number,
      required: false,
      default() {
        return 50
      },
    },
    fill: {
      type: String,
      required: false,
      default() {
        return 'white'
      },
    },
  },
  data() {
    return {
      component: PremiumWhite,
    }
  },
  computed: {
    isSvgIcon() {
      if (this.laNoBorders) return !!SVG_LA_ICONS_MAPPING[this.passName]
      return !!SVG_ICONS_MAPPING[this.passKey]
    },
    iconShown() {
      if (
        this.passCode &&
        Object.values(CONSTANTS.PLANS.LAB_CODES).includes(this.passCode)
      )
        return require('@/assets/images/learning-areas/lab_experience_bundle.png')
      return this.laNoBorders
        ? SVG_LA_ICONS_MAPPING[this.passName]
        : SVG_ICONS_MAPPING[this.passKey] ||
            IMG_ICONS_MAPPING[this.passName] ||
            IMG_ICONS_MAPPING.Networking
    },
    passKey() {
      return this.passCode || this.passName
    },
  },
}
</script>
