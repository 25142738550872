import { MutationTree } from 'vuex'
import { IFaqsState, IFaqContent } from '@/@types'

const mutations: MutationTree<IFaqsState> = {
  STORE_FAQS: (state, Faqs: IFaqContent[]) => {
    state.currentFaqs = Faqs
  },
}

export default mutations
