import {
  fetchInvitation,
  postInvitation,
  createUserFromBusisess,
  fetchBusinessUser,
  requestLicense,
  requestLicenseAddon,
} from '@/models/business'
import { ActionContext, ActionTree } from 'vuex'
import { IBusinessState, IRootState } from '@/@types'

const actions: ActionTree<IBusinessState, IRootState> = {
  getInvitation: async (
    context: ActionContext<IBusinessState, IRootState>,
    payload: any
  ) => {
    try {
      const response = await fetchInvitation(payload)
      context.commit('UPDATE_INVITATION', response)
      return response
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  acceptInvitation: async (
    context: ActionContext<IBusinessState, IRootState>,
    payload: any
  ) => {
    try {
      const response = await postInvitation(payload)
      return response
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  signUpFromInvitation: async (
    context: ActionContext<IBusinessState, IRootState>,
    payload: any
  ) => {
    try {
      const response = await createUserFromBusisess(payload)
      return response
    } catch (error) {
      const { details } = error as { details: string }
      context.dispatch('app/setError', details, { root: true })
      throw error
    }
  },
  getBusinessUser: async (
    context: ActionContext<IBusinessState, IRootState>
  ) => {
    try {
      const businessUser = await fetchBusinessUser()
      context.commit('BUSINESS_USER_UPDATED', businessUser)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  requestLicense: async (
    context: ActionContext<IBusinessState, IRootState>
  ) => {
    try {
      const response = await requestLicense()
      return response
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  setBusinessInvitationId: (
    context: ActionContext<IBusinessState, IRootState>,
    businessInvitationId: string
  ) => {
    context.commit('BUSINESS_INVITATION_ID_UPDATED', businessInvitationId)
  },
  requestLicenseAddon: async (
    context: ActionContext<IBusinessState, IRootState>,
    addonCode: string
  ) => {
    try {
      const response = await requestLicenseAddon(addonCode)
      return response
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  cleanBusinessUser: async (
    context: ActionContext<IBusinessState, IRootState>
  ) => {
    try {
      context.commit('BUSINESS_USER_UPDATED', null)
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
