import {
  ICertification,
  IContent,
  IContentState,
  IIssue,
  ILab,
  ILabSession,
  ILearningPath,
  IRecentActivity,
  IStringKeys,
  ITrainingHistoryData,
} from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<IContentState> = {
  JW_VIDEO_UPDATED: (state, jwVideo: object) => {
    state.jwVideo = jwVideo
  },

  CURRENT_VIDEOS_UPDATED: (state, videos: Array<IContent>) => {
    const parents = new Set(videos?.map(v => v.parent_id))
    parents.forEach(p => {
      state.currentVideos = state.currentVideos
        .filter(v => v.parent_id !== p)
        .concat(videos.filter(v => v.parent_id === p))
    })
  },

  VIDEO_STATUS_UPDATED: (state, video: IContent) => {
    const videoForUpdate: IContent | undefined = state.currentVideos.find(
      vid =>
        vid.id === video.id &&
        (vid.parent_id === video.parent_id || !video.parent_id)
    )
    if (videoForUpdate) {
      const { user_status, current_position_in_secs } = video
      Object.assign(videoForUpdate, { user_status, current_position_in_secs })
    }
  },

  CURRENT_QUIZZES_UPDATED: (state, quizzes: Array<IContent>) => {
    state.currentQuizzes = quizzes
  },

  QUIZ_STATUS_UPDATED: (state, quiz: IContent) => {
    const quizForUpdate: IContent | undefined = state.currentQuizzes.find(
      q => q.id === quiz.id
    )
    if (quizForUpdate) {
      quizForUpdate.user_status = quiz.user_status
    }
  },

  CURRENT_EXERCISES_UPDATED: (state, exercises: Array<IContent>) => {
    state.currentExercises = exercises
  },

  EXERCISE_STATUS_UPDATED: (state, exercise) => {
    const exerciseForUpdate: IContent | undefined = state.currentExercises.find(
      q => q.id === exercise.id
    )
    if (exerciseForUpdate) {
      exerciseForUpdate.user_status = exercise.user_status
    }
  },

  CURRENT_LABS_UPDATED: (
    state,
    payload: { labs: Array<ILab>; playlistId?: string }
  ) => {
    if (payload.playlistId) {
      state.currentLabs = state.currentLabs
        .filter(l => l.parent_id !== payload.playlistId)
        .concat(payload.labs)
    } else {
      state.currentLabs = payload.labs
    }
  },

  LAB_STATUS_UPDATED: (
    state,
    userLabStatus: {
      lab: string
      status: string
      modified: string
      parent_id?: string
    }
  ) => {
    const { lab: id, status, modified } = userLabStatus
    const labForUpdate: ILab | undefined = userLabStatus.parent_id
      ? state.currentLabs.find(
          l => l.id === id && l.parent_id === userLabStatus.parent_id
        )
      : state.currentLabs.find(l => l.id === id)
    if (labForUpdate) {
      labForUpdate.user_status = status
      labForUpdate.user_status_modified = modified
    }
  },
  UPDATE_LAB_STATUS_ON_LIST: (state, userLabStatus: ILabSession) => {
    const { lab } = userLabStatus
    const labForUpdate: ILab | undefined = state.currentLabs.find(
      l => l.id === lab?.id
    )
    if (labForUpdate) {
      labForUpdate.session = userLabStatus
    }
  },

  CURRENT_IFRAMES_UPDATED: (state, iframes: Array<IContent>) => {
    state.currentIFrames = iframes
  },

  IFRAME_STATUS_UPDATED: (
    state,
    iframeIdAndStatus: { iframeId: string; status: string }
  ) => {
    const { iframeId: id, status } = iframeIdAndStatus
    const iframeForUpdate: IContent | undefined = state.currentIFrames.find(
      q => q.id === id
    )
    if (iframeForUpdate) {
      iframeForUpdate.user_status = status
    }
  },

  CURRENT_BOOKMARKS_UPDATED: (state, bookmarks) => {
    state.currentBookmarks = bookmarks
  },

  PLAYING_VIDEO_ID_UPDATED: (state, videoId: string) => {
    state.playingVideoId = videoId
  },

  PLAYING_VIDEO_TIME_UPDATED: (state, time: number) => {
    state.playingVideoTime = time
  },

  IS_WATCHING_FULLSCREEN_UPDATED: (state, isWatchingFullscreen: boolean) => {
    state.isWatchingFullscreen = isWatchingFullscreen
  },

  CURRENT_QUIZ_ID_UPDATED: (state, quizId: string) => {
    state.currentQuizId = quizId
  },

  CURRENT_EXERCISES_ID_UPDATED: (state, exercisesId: string) => {
    state.currentExercisesId = exercisesId
  },

  IS_CONTENT_LOADING_UPDATED: (state, isLoading: boolean) => {
    state.isContentLoading = isLoading
  },

  CURRENT_HOVERED_NOTE_UPDATED: (state, id: string) => {
    state.currentHoveredNote = id
  },

  COURSES_FOR_BOOKMARKS_UPDATED: (state, courses: Array<object>) => {
    state.coursesForBookmarks = courses
  },

  VIDEOS_FOR_BOOKMARKS_UPDATED: (state, videos: Array<IContent>) => {
    state.videosForBookmarks = videos
  },

  RECENT_ACTIVITY: (state, recentActivity: IRecentActivity) => {
    state.recentActivity = recentActivity
  },

  RECENT_LEARNING_PATH: (state, payload: ILearningPath) => {
    state.recentLearningPath = payload
  },

  RECENT_ACTIVITY_NEXT_UP: (state, payload: object) => {
    state.recentActivityNextUp = payload
  },

  CURRENT_PARENT: (state, payload: { id: string; type: string }) => {
    state.currentParent = payload
  },

  UPDATE_ALL_ISSUES: (state, issues: Array<IIssue>) => {
    state.contentIssues = issues
  },

  CURRENT_ISSUE_PAYLOAD_UPDATED: (state, payload: IIssue) => {
    state.currentIssue = {
      ...state.currentIssue,
      ...payload,
    }
  },

  CURRENT_ISSUE_RESET: state => {
    state.currentIssue = {
      subject: '',
      comment: '',
      parent_id: null,
      parent_type: null,
      content_type: '',
      content_subtype: '',
      content_id: null,
      extra_args: {},
    }
  },
  UPDATE_VIDEOS_WITH_BOOKMARKS: (state, payload: Array<object>) => {
    const moreVideosWithBookmarks = payload.reduce(
      (acc: IStringKeys, video: IStringKeys): IStringKeys => {
        acc[video.id] = video
        return acc
      },
      {}
    )
    state.allVideosWithBookmarks = {
      ...state.allVideosWithBookmarks,
      ...moreVideosWithBookmarks,
    }
  },

  CURRENT_LINKS_UPDATED: (state, links: Array<IContent>) => {
    const parents = new Set(links?.map(l => l.parent_id))
    parents.forEach(p => {
      state.currentLinks = state.currentLinks
        .filter(l => l.parent_id !== p)
        .concat(links.filter(l => l.parent_id === p))
    })
  },

  LINK_STATUS_UPDATED: (
    state,
    userLinkStatus: {
      link: string
      status: string
      modified: string
    }
  ) => {
    const { link: id, status, modified } = userLinkStatus
    const linkForUpdate: IContent | undefined = state.currentLinks.find(
      l => l.uuid === id && l.parent_id === state.currentParent.id
    )
    if (linkForUpdate) {
      linkForUpdate.user_status = status
      linkForUpdate.user_status_modified = modified
    }
  },

  CURRENT_CERTIFICATIONS_UPDATED: (
    state,
    certifications: Array<ICertification>
  ) => {
    state.currentCertifications = certifications
  },

  TRAINING_HISTORY_UPDATED: (state, payload: ITrainingHistoryData) => {
    state.trainingHistoryItems = payload
  },
}

export default mutations
