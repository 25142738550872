import {
  IPass,
  IRootState,
  ISubscription,
  ISubscriptionsState,
  IUsersSubscriptions,
} from '@/@types'

import CONSTANTS from '@/constants'
import { GetterTree } from 'vuex'
import { format } from 'date-fns'
import { isBusinessPlan } from '@/helpers'

const getters: GetterTree<ISubscriptionsState, IRootState> = {
  currentPasses: (_, subscriptionsGetters) => {
    const {
      usersSubscriptions: { activePlans },
    } = subscriptionsGetters
    const ownedPasses: Array<IPass> = []
    if (activePlans && activePlans.length) {
      activePlans.map((sub: ISubscription) => {
        const {
          passes: { data: passes },
        }: { passes: { data: Array<IPass> } } = sub
        passes.map(pass => ownedPasses.push(pass))
      })
    }
    return ownedPasses
  },

  usersSubscriptions: state => {
    const { manageablePassStates, currentSubscriptions } = state
    if (currentSubscriptions?.length) {
      return currentSubscriptions.reduce(
        (acc: IUsersSubscriptions, sub: ISubscription) => {
          const { state: status, plan_code } = sub
          if (manageablePassStates.includes(status)) {
            acc.activePlans.push(sub)
          }
          if (!isBusinessPlan(sub) && manageablePassStates.includes(status)) {
            acc.personalPlans.push(sub)
          }
          if (status === CONSTANTS.SUBSCRIPTIONS.FAILED_STATE) {
            acc.failedPlans.push(sub)
          }
          if (
            status !== CONSTANTS.SUBSCRIPTIONS.FAILED_STATE &&
            !manageablePassStates.includes(status)
          ) {
            acc.inactivePlans.push(sub)
          }
          if (
            Object.values(CONSTANTS.PLANS.PREMIUM_PLANS).includes(plan_code)
          ) {
            acc.premiumPlans.push(sub)
          }
          return acc
        },
        {
          personalPlans: [],
          activePlans: [],
          failedPlans: [],
          inactivePlans: [],
          premiumPlans: [],
        }
      )
    }
    return {
      personalPlans: [],
      activePlans: [],
      failedPlans: [],
      inactivePlans: [],
      migratedPlans: [],
      premiumPlans: [],
    }
  },

  activePlans: (state, subGetters) => {
    const {
      usersSubscriptions: { activePlans, failedPlans },
    } = subGetters
    return [...activePlans, ...failedPlans]
  },

  activeSubscriptions: (state, subGetters) => {
    const {
      usersSubscriptions: { activePlans },
    } = subGetters
    //active subscriptions except skill dive, which is not considered a subscription
    return activePlans.filter(
      (sub: ISubscription) =>
        sub.plan_code !== CONSTANTS.PLANS.SKILL_DIVE_ANNUAL
    )
  },

  currentPlanComparisonBreakdown: state => {
    if (state.currentPlanComparison && state.currentPlanComparison.next) {
      const { next, meta } = state.currentPlanComparison
      if (next) {
        return {
          newPrice: next.price,
          dueToday: next.due_today,
          discountCents: next.discount,
          creditForRenewal: next.credit_fwd,
          renewalTotal: next.renewal_total,
          renewalDate: format(new Date(next.period_end), 'MMMM dd, yyyy'),
          offerId: meta?.offer_id ?? '',
        }
      }
    }
    return {
      newPrice: '$0',
      dueToday: '$0',
      discountCents: '$0',
      creditForRenewal: '$0',
      renewalTotal: '$0',
      renewalDate: '',
      offerId: '',
    }
  },

  currentAvailablePlans: state => {
    const { availablePlans, currentSubscriptions, validPlans, planDetails } =
      state
    const currentUserSubscriptions = currentSubscriptions?.filter(
      subscription =>
        subscription.state === CONSTANTS.SUBSCRIPTIONS.ACTIVE_STATE
    )
    return availablePlans?.map(plan => {
      const details: Array<any> = []
      const isCurrentPlan = currentUserSubscriptions?.length
        ? plan.merchant_id === currentUserSubscriptions[0].plan_code
        : false
      plan.show = true
      if (!isCurrentPlan) {
        const isAnyValidPlan = !!validPlans?.some(validPlan =>
          validPlan.validPlans.some(
            (vp: { code: string | undefined }) => vp.code === plan.merchant_id
          )
        )
        plan.show = !!currentUserSubscriptions?.length && isAnyValidPlan
      }
      planDetails.map(detail => {
        if (plan.merchant_id) {
          if (detail.checked?.includes(plan.merchant_id))
            details.push({
              checked: 1,
              name: detail.name,
              greenCheck: detail.greenCheck,
            })
          if (detail.unchecked?.includes(plan.merchant_id))
            details.push({
              checked: 0,
              name: detail.name,
              greenCheck: detail.greenCheck,
            })
        }
      })
      plan.details = details
      return plan
    })
  },
  isADUser: (state, subscriptionsGetters) => {
    const activePlans = subscriptionsGetters.usersSubscriptions?.activePlans
    if (activePlans?.length) {
      const hasADSubscription = activePlans.some(
        (subscription: { plan_code: string }) =>
          subscription.plan_code === CONSTANTS.PLANS.ATTACK_DEFENSE
      )
      return hasADSubscription
    }
    return false
  },
  isOnTrialSubscription: (subState, subGetters, rootState, rootGetters) => {
    const activePlans = rootGetters['subscriptions/activePlans']
    let isTrial = false
    if (activePlans.length > 0 && activePlans[0]?.trial_ends_at) {
      const today = new Date()
      isTrial =
        new Date(activePlans[0].trial_ends_at).getTime() >= today.getTime()
    }
    return isTrial
  },
  isCourtesyTrialUser: (subState, subGetters, rootState, rootGetters) => {
    const activePlans = rootGetters['subscriptions/activePlans']
    if (activePlans.length > 0 && activePlans[0]?.is_courtesy_trial) {
      return activePlans[0].is_courtesy_trial
    }
    return false
  },
  userHasPaymentMethod: state => {
    return !!state.paymentInfo.payment_method
  },
  userPaymentMethod: state => {
    return state.paymentInfo
  },
  getSkillDiveAddHoursPreviewPurchase: state => {
    return state.skillDiveAddHoursPreviewPurchase
  },
  getProductCertificationVouchers: state => {
    return state.productCertificationVouchers
  },
  getCurrentSellablePlan: (state, subGetters) => {
    const { currentAvailablePlans } = subGetters
    const { usersSubscriptions } = subGetters
    const currentPlan = usersSubscriptions.activePlans.filter(
      (sub: ISubscription) =>
        sub.plan_code === CONSTANTS.PLANS.ANNUAL ||
        sub.plan_code === CONSTANTS.PLANS.PREMIUM
    )[0]
    return currentAvailablePlans.find(
      (plan: { merchant_id: String }) =>
        plan.merchant_id === currentPlan.plan_code
    )
  },
  getBillingToken: state => {
    return state.billingToken
  },
}

export default getters
