import { MutationTree } from 'vuex'
import {
  IExercisesState,
  IExercise,
  IExerciseTest,
  IStringKeys,
} from '@/@types'

const mutations: MutationTree<IExercisesState> = {
  CURRENT_EXERCISE_UPDATED: (state, exercise: IExercise) => {
    state.currentExercise = exercise
  },

  CURRENT_EXERCISE_TESTS_UPDATED: (state, tests: Array<IExerciseTest>) => {
    state.currentTests = tests
    state.currentTestsStatus = tests.reduce(
      (acc: IExerciseTest, test: IExerciseTest) => {
        acc[test.id] = {
          ...test,
          passed: false,
          output: '',
          ran: false,
          running: false,
        }
        return acc
      },
      <IExerciseTest>{}
    )
  },

  SET_TEST_RUNNING: (
    state,
    { testId, running }: { testId: string; running: boolean }
  ) => {
    state.currentTestsStatus[testId].running = running
  },

  CURRENT_EXERCISE_SOLUTIONS_UPDATED: (state, solutions) => {
    state.currentSolutions = solutions
  },

  CURRENT_EXERCISE_ATTEMPTS_UPDATED: (state, attempts) => {
    state.previousAttempts = attempts
  },

  ADD_EXERCISE_ATTEMPT: (state, attempt) => {
    state.previousAttempts.push(attempt)
  },

  UPDATE_TESTS_STATUS: (
    state,
    {
      response,
      testId,
    }: {
      response: {
        tests: Array<IExerciseTest>
        success: boolean
        output: string
      }
      testId: string
    }
  ) => {
    const entries = Object.entries(response.tests)
    if (entries.length) {
      Object.entries(response.tests).map(([k, test]) => {
        state.currentTestsStatus[k].passed = test.passed
        state.currentTestsStatus[k].output = test.output
        state.currentTestsStatus[k].ran = true
        state.currentTestsStatus[k].running = false
      })
    } else {
      // there was a general error, show general output
      state.currentTestsStatus[testId].passed = response.success
      state.currentTestsStatus[testId].output = response.output
      state.currentTestsStatus[testId].ran = true
      state.currentTestsStatus[testId].running = false
    }
  },

  UPDATE_CURRENT_CODE: (state, { id, code }: { id: string; code: string }) => {
    state.studentCodes[id] = code
  },

  SET_STUDENT_CODE_EXERCISES: (state, exercises: Array<IExercise>) => {
    state.studentCodes = {
      ...state.studentCodes,
      ...exercises.reduce((acc: IStringKeys, e) => {
        acc[e.id] = e.starter_code
        return acc
      }, {}),
    }
  },

  UPDATE_CURRENT_OUTPUT: (
    state,
    output: {
      showSummary: boolean
      response: {
        output: string
        success: boolean
        errors: string
        tests_summary: {
          total: number
          passed: number
          failed: number
        }
      }
    }
  ) => {
    const { response } = output
    const exerciseOutput = `<div class="output"><pre>${response.output.replace(
      /[\u00A0-\u9999<>&]/g,
      i => {
        return `&#${i.charCodeAt(0)};`
      }
    )}</pre></div>`

    const exerciseErrors = `<p class="${
      response.success ? 'info' : 'errors'
    }">${response.errors}</p>`

    const exerciseResume = `<strong>Tests summary</strong><p class="summary">Total: ${response.tests_summary.total}<br />Passed: ${response.tests_summary.passed}<br />Failed: ${response.tests_summary.failed}<br /></p>`

    state.currentOutput = exerciseOutput + exerciseErrors
    state.currentOutput += output.showSummary ? exerciseResume : ''
  },

  RESET_CURRENT_OUTPUT: state => {
    state.currentOutput = ''
  },

  CHANGE_CURRENT_FLAVOR: (state, flavor) => {
    state.currentFlavor = flavor
  },
  RESUME_EXERCISE_UPDATED: (state, exerciseId) => {
    state.resumeExerciseId = exerciseId
  },
}

export default mutations
