import CONSTANTS from '@/constants'
import { IAppState } from '@/@types'
import { version as appVersion } from '../../../../package.json'

const getStoredVideoPreferences = () => {
  const raw = localStorage.getItem(
    CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.STORAGE_KEY
  )
  if (raw) {
    return JSON.parse(raw)
  }
  return CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.DEFAULTS
}

const state: IAppState = {
  isAppLoading: true,
  isPageLoading: true,
  isComponentLoading: true,
  onboardingModalStatus: {},
  appErrors: [],
  appNotifications: [],
  appSuccesses: [],
  appWarnings: [],
  appVersion,
  noHeaderRoutes: CONSTANTS.ROUTING_RULES.NAVBAR_HIDDEN,
  plansLink: CONSTANTS.PLANS.LANDING_PAGE_LINK,
  videoPlaybackPreferences: getStoredVideoPreferences(),
  videoActive: false,
  scheduledMaintenanceClosed: false,
  kinesisHeartbeatData: {},
  isWebSocketEnabled: true,
  woopraData: {},
  isLoadingCaptcha: false,
}

export default state
