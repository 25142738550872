<template>
  <section class="onboarding-modal__step">
    <div class="onboarding-modal__step-badge">{{ stepBadgeLabel }}</div>
    <div class="onboarding-modal__step-header">
      <div class="onboarding-modal__step-title">
        Select your Learning Area(s) of interest and expertise level(s)
      </div>
      <div class="onboarding-modal__step-info">
        Help us cater the right kind of content to you.
      </div>
    </div>
    <div class="interest-area__items-wrapper">
      <div
        v-for="(area, i) in interestAreas"
        :key="`area-${i}`"
        :class="[
          'interest-area__item',
          isAreaSelected(area.id) && 'interest-area__item--selected',
          'onboarding-modal-states',
        ]"
        @click.self="toggleAreaSelection(area)"
      >
        <div class="interest-area__item-description">
          <span
            class="ine-checkbox__input interest-area__item-checkbox-input"
            @click.self="toggleAreaSelection(area)"
          >
            <input
              :id="area.id"
              type="checkbox"
              :name="`${area.id}-checkbox`"
              :value="area.id"
              :checked="isAreaSelected(area.id)"
            />
            <span
              class="ine-checkbox__control interest-area__item-checkbox-control"
            >
              <CheckboxTick />
            </span>
          </span>
          <div
            class="interest-area__item-label"
            @click.self="toggleAreaSelection(area)"
          >
            {{ area.name }}
          </div>
        </div>
        <div v-if="isAreaSelected(area.id)" class="interest-area__item-level">
          <ExpertiseLevel
            :options="expertiseLevels"
            :interestArea="getSelectedLevel(area.name)"
            :append="i === interestAreas.length - 1"
            :handleSelection="updateSaveStatus"
          />
        </div>
      </div>
    </div>
    <div class="onboarding-modal__step-btn-wrapper">
      <button
        type="submit"
        class="btn btn--primary onboarding-modal__step-save-btn"
        :disabled="isSaveDisabled"
        @click="saveInterestArea()"
      >
        Save and continue
      </button>
      <button
        class="btn btn--secondary onboarding-modal__step-next-btn"
        @click="$emit('go-next-step')"
      >
        Skip this step
      </button>
      <button
        v-if="!isFirstStep"
        class="btn btn--tertiary onboarding-modal__step-back-btn"
        @click="$emit('go-previus-step')"
      >
        Back
      </button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CONSTANTS from '@/constants'
import CheckboxTick from '@/assets/images/certification/checkbox-tick.svg'
import ExpertiseLevel from './components/ExpertiseLevel.vue'

export default {
  name: 'InterestArea',
  components: {
    CheckboxTick: { ...CheckboxTick, name: 'CheckboxTick' },
    ExpertiseLevel,
  },
  props: {
    stepBadgeLabel: {
      type: String,
      required: true,
    },
    onboardingSurvey: {
      type: Object,
      required: true,
    },
    profilePreferences: {
      type: Object,
      required: true,
    },
    isFirstStep: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSaveDisabled: true,
      currentSelection: [],
      selectionExpertiseLevel: [],
      savedExpertiseLevels: {},
      initialized: false,
    }
  },
  computed: {
    ...mapGetters({
      profileInterestAreas: 'auth/profileInterestAreas',
    }),
    interestAreas() {
      return CONSTANTS.SURVEY_INTERESTS
    },
    expertiseLevels() {
      return Object.values(CONSTANTS.EXPERTISE_LEVELS)
    },
  },
  watch: {
    currentSelection() {
      this.updateSaveStatus()
    },
    profilePreferences() {
      this.initializeProfilePreferences()
    },
  },
  created() {
    this.$emit('set-component-loader', true)

    CONSTANTS.EXPERTISE_LEVELS_SLUGS.forEach(item => {
      let level = null
      if (
        this.onboardingSurvey?.data &&
        this.onboardingSurvey?.data[item.slug]
      ) {
        this.savedExpertiseLevels[item.areaName] = {
          slug: item.slug,
          expertiseLevel: this.onboardingSurvey?.data[item.slug],
        }
        level = this.expertiseLevelMap(this.onboardingSurvey?.data[item.slug])
      }
      this.selectionExpertiseLevel.push({
        area: item.areaName,
        expertiseLevel: level,
      })
    })

    this.initializeProfilePreferences()
    this.$emit('set-component-loader', false)
  },
  methods: {
    expertiseLevelMap(level) {
      return CONSTANTS.EXPERTISE_LEVELS[level?.toUpperCase()]
    },
    notSavedLevels() {
      const deletedOrUpdated = this.selectionExpertiseLevel.find(
        item =>
          !this.savedExpertiseLevels[item.area] ||
          this.expertiseLevelMap(
            this.savedExpertiseLevels[item.area].expertiseLevel
          ) !== item.expertiseLevel
      )
      return (
        !!deletedOrUpdated ||
        Object.keys(this.savedExpertiseLevels).length !==
          this.selectionExpertiseLevel?.length
      )
    },
    updateSaveStatus() {
      // Save button enabled for deleted areas or updates on Areas and/or Levels
      const profileAreaSelected =
        this.profileInterestAreas?.map(it => it.id) || []
      const deletedSavedArea = profileAreaSelected.find(
        item => !this.isAreaSelected(item)
      )

      const addedArea = this.currentSelection.find(
        item => !profileAreaSelected.includes(item)
      )

      this.isSaveDisabled =
        !deletedSavedArea && !addedArea && !this.notSavedLevels()
    },
    toggleAreaSelection({ id, name }) {
      const expertiseItem = this.getSelectedLevel(name)
      let level = null
      if (this.isAreaSelected(id)) {
        const index = this.currentSelection.indexOf(id)
        this.currentSelection.splice(index, 1)
      } else {
        this.currentSelection.push(id)
        if (this.savedExpertiseLevels[name]?.expertiseLevel) {
          level = this.expertiseLevelMap(
            this.savedExpertiseLevels[name].expertiseLevel
          )
        }
      }
      expertiseItem.expertiseLevel = level
    },
    isAreaSelected(area) {
      return this.currentSelection.includes(area)
    },
    async saveInterestArea() {
      this.isSaveDisabled = true
      this.$emit('set-loader', true)
      try {
        await this.$store.dispatch('auth/submitProfilePreferences', {
          ...this.profilePreferences,
          passes: this.currentSelection.map(it => {
            return {
              id: it,
            }
          }),
          primary_interest: this.currentSelection.join(';'),
        })
        const message = 'Your interest area has been successfully updated!'
        await this.$store.dispatch('app/setNotification', message)

        const onboardingSurvey = {
          ...this.onboardingSurvey.data,
          [CONSTANTS.PROFILE.ONBOARDING.ITEMS_IDS.LEARNING_AREAS]:
            this.currentSelection.length > 0,
        }

        CONSTANTS.EXPERTISE_LEVELS_SLUGS.forEach(el => {
          const selectedLevel = this.selectionExpertiseLevel.find(
            item => item.area === el.areaName
          )
          if (selectedLevel && selectedLevel.expertiseLevel) {
            onboardingSurvey[el.slug] =
              selectedLevel.expertiseLevel !== 'Not Sure'
                ? selectedLevel.expertiseLevel.toLowerCase()
                : 'not_sure'
          } else {
            delete onboardingSurvey[el.slug]
          }
        })

        await this.$store.dispatch('auth/setOnboardingSurvey', onboardingSurvey)

        this.$emit('go-next-step')
      } catch (e) {
        this.isSaveDisabled = false
      } finally {
        this.$emit('set-loader', false)
      }
    },
    getSelectedLevel(areaName) {
      const expertiseItem = this.selectionExpertiseLevel.find(
        areaLevel => areaLevel.area === areaName
      )
      return expertiseItem
    },
    initializeProfilePreferences() {
      if (!this.initialized && this.profileInterestAreas) {
        this.initialized = true
        this.profileInterestAreas.forEach(element => {
          this.currentSelection.push(element.id)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.interest-area {
  &__items-wrapper {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    @include mobile {
      flex-wrap: wrap;
      gap: 5px;
    }
    @include tablet {
      gap: 10px;
    }
  }
  &__item {
    font-size: rem(16);
    display: flex;
    align-items: center;
    line-height: 24px;
    color: var(--white);
    padding: 10px 10px 10px 20px;
    margin-bottom: 5px;
    border-radius: 10px;
    height: 44px;
    transition: 0.2s height;

    cursor: pointer;
    @include mobile {
      padding: 30px;
      margin-bottom: 0;
    }
    @include tablet {
      padding: 10px 10px 10px 20px;
      height: 54px;
    }
    &-description {
      display: flex;
      align-self: flex-start;

      @include mobile {
        align-self: center;
      }
    }
    &-icon {
      width: 30px;
      @include tablet {
        width: 50px;
      }
    }
    &-label {
      margin-left: 10px;
    }
    &--selected {
      flex-direction: column;
      height: 100px;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: 7px;

      @include mobile {
        transition: 0.2s height;
        height: 66px;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
  &__item-checkbox {
    &-input {
      cursor: pointer;
    }
    &-control {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      pointer-events: none;
      border: 1px solid var(--white);
      svg {
        width: 8px;
      }
    }
  }
  &__item-level {
    width: 100%;
    @include mobile {
      margin-left: auto;
      width: 240px;
    }
  }
}
</style>
