import { MutationTree } from 'vuex'
import { IBusinessState, IBusinessUser, IInvitation } from '@/@types'

const mutations: MutationTree<IBusinessState> = {
  UPDATE_INVITATION: (state, invitation: IInvitation) => {
    state.invitationInfo = invitation
  },
  BUSINESS_USER_UPDATED: (state, user: IBusinessUser) => {
    state.businessUser = user
  },
  BUSINESS_INVITATION_ID_UPDATED: (state, businessInvitationId: string) => {
    state.businessInvitationId = businessInvitationId
  },
}

export default mutations
