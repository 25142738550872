import { mapActions } from 'vuex'
import CONSTANTS from '@/constants'

const recurlyKey = process.env.VUE_APP_RECURLY_KEY

export const paymentCommons = {
  data() {
    return {
      isLoading: false,
      submitOrderResult: null,
      threeDSecure: null,
    }
  },
  created() {
    window.recurly.configure(recurlyKey)
  },
  methods: {
    ...mapActions('subscriptions', [
      'submitPurchase',
      'getPaymentInfo',
      'setBillingToken',
    ]),
    ...mapActions('app', ['setError', 'setNotification']),
    async handleRecurlyToken(
      token,
      threeDSecureEventName = 'three-d-secure-validated'
    ) {
      const recurlyTokenId = token.id
      try {
        this.isLoading = true
        const response = await this.getPaymentInfo()
        await this.submitToken(recurlyTokenId)
        this.setBillingToken(recurlyTokenId)
        return response
      } catch (errorData) {
        // Example structure of errorData:
        // errorData = {
        //   error: {
        //     code: number | null, // Error code
        //     http_code: number | null, // HTTP status code
        //     exception_id: number | null, // Exception identifier
        //     message: string | null, // Error message
        //   },
        //   processor_error: string | null, // Processor error description
        //   processor_error_code: string | null, // Processor error code
        //   actionTokenId: string | null, // Action token ID for 3-D Secure
        // }
        if (errorData.error) {
          // Access the nested properties
          const errorMessage = errorData.error.message
          const processorErrorCode = errorData.processor_error_code
          const actionTokenId = errorData.actionTokenId
          // Handle specific error cases
          if (processorErrorCode === CONSTANTS.SUBSCRIPTIONS.THREE_D_SECURE) {
            // Handle 3-D Secure action required
            this.setNotification(errorMessage)
            this.start3dsFlow(
              actionTokenId,
              recurlyTokenId,
              threeDSecureEventName
            )
          } else {
            // Handle other error cases
            this.setError(errorMessage)
          }
        } else {
          // Handle errors without a response property
          this.setError(errorData)
        }
      }
      this.isLoading = false
    },
    async submitToken(recurlyTokenId) {
      if (recurlyTokenId) {
        await this.$store.dispatch(
          'subscriptions/updatePaymentMethod',
          recurlyTokenId
        )
      } else {
        this.$store.dispatch(
          'app/setError',
          'Unable to update your payment method with the provided entries. Please try another payment method'
        )
      }
    },
    async submitOrder({ recaptcha }) {
      this.isLoading = true
      try {
        const response = await this.submitPurchase({
          captchaToken: recaptcha,
          challengeToken: this.challengeToken,
        })
        if (response) {
          this.submitOrderResult = response
        }
      } catch (error) {
        this.$store.dispatch('app/setError', error)
      }
      this.isLoading = false
    },
    start3dsFlow(actionTokenId, billingToken, threeDSecureEventName) {
      const risk = window.recurly.Risk()
      this.threeDSecure = risk.ThreeDSecure({
        actionTokenId,
      })
      this.threeDSecure.on('token', token =>
        this.threeDSecureResolved(billingToken, token.id, threeDSecureEventName)
      )
      this.threeDSecure.on('error', error => {
        this.setError(
          error && error.message
            ? error.message
            : 'We were unable to verify your order through 3D-Secure. Please contact your bank.'
        )
        this.threeDSecure.remove()
      })
      this.threeDSecure.attach(document.querySelector('#recurly-3ds'))
    },
    async threeDSecureResolved(
      billingToken,
      challengeToken,
      threeDSecureEventName
    ) {
      try {
        this.$emit(threeDSecureEventName, {
          billingToken,
          challengeToken,
        })
      } catch (error) {
        this.setError(error.message)
      } finally {
        this.threeDSecure.remove()
      }
    },
  },
}
