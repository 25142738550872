<template>
  <div class="maintenance-scheduled-banner">
    <div
      :class="[
        'maintenance-scheduled-banner__container',
        underway && 'maintenance-scheduled-banner__undergoing',
      ]"
    >
      <div class="maintenance-scheduled-banner__wrapper">
        <div
          class="maintenance-scheduled-banner__title maintenance-scheduled-banner__title-not-mobile"
        >
          <AlertIcon
            v-if="!underway"
            class="maintenance-scheduled-banner__icon"
          />
          <AlertTriangleIcon
            v-else
            class="maintenance-scheduled-banner__icon maintenance-scheduled-banner__icon--white"
          />
          <div class="maintenance-scheduled-banner__title-text">
            {{ title }}
          </div>
        </div>
        <div
          :class="[
            'maintenance-scheduled-banner__separator',
            underway && 'maintenance-scheduled-banner__separator--red',
          ]"
        ></div>
        <div
          :class="[
            'maintenance-scheduled-banner__info',
            underway && 'maintenance-scheduled-banner__info--undergoing',
          ]"
        >
          <span
            :class="[
              'maintenance-scheduled-banner__title',
              'maintenance-scheduled-banner__title-mobile',
            ]"
            >{{ title }}:</span
          >
          To better serve you, INE
          {{ underway ? 'is undergoing' : 'will undergo' }} a brief update
          {{ underway ? '' : 'on' }}
          <span v-if="!underway">
            <b>{{ maintenanceDate }}, at {{ maintenanceTime }} EST</b> for
            <b>1 hour</b></span
          >. Some services might be impacted
          <span v-if="underway">for the next <b>1 hour</b></span
          ><span v-else>during this time.</span>
          Thank you for your patience.
        </div>
      </div>
      <XIcon
        v-if="!underway"
        class="maintenance-scheduled-banner__close-icon"
        @click="closeBanner"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns-tz'
import AlertIcon from '@/assets/icons/alert-circle-10.svg'
import { XIcon } from 'vue-feather-icons'
import AlertTriangleIcon from '@/assets/icons/subscriptions/alert-triangle.svg'

export default {
  name: 'MaintenanceScheduledBanner',
  components: {
    AlertIcon,
    XIcon,
    AlertTriangleIcon,
  },
  props: {
    forceUnderway: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      maintenanceScheduled: 'app/maintenanceScheduled',
    }),
    underway() {
      const { forceUnderway, maintenanceScheduled } = this

      if (forceUnderway) return true

      if (maintenanceScheduled) {
        const scheduledDate = new Date(maintenanceScheduled)
        const now = new Date()

        return now > scheduledDate
      }

      return false
    },
    title() {
      return this.underway ? 'Maintenance Underway' : 'Scheduled Maintenance'
    },
    maintenanceDate() {
      return format(new Date(this.maintenanceScheduled), 'MMMM do')
    },
    maintenanceTime() {
      return format(new Date(this.maintenanceScheduled), 'h:mm a')
        .replace(/\s*(\w\w)$/, '$1')
        .toLowerCase()
    },
  },

  methods: {
    closeBanner() {
      this.$store.commit('app/CLOSE_MAINTENANCE_BANNER')
    },
  },
}
</script>

<style lang="scss" scoped>
.maintenance-scheduled-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 203;

  width: 100%;

  color: var(--white);
  font-feature-settings: 'clig' off, 'liga' off;

  @include tablet {
    position: static;
  }
  &__container {
    width: 100%;
    height: 70px;
    background: var(--orange-horizontal-gradient);
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;

    @include mobile {
      height: 81px;
    }
  }

  &__undergoing {
    background: linear-gradient(
        270deg,
        rgba(239, 34, 34, 0) 61.35%,
        #ef2222 98.03%
      ),
      #b20000;

    @include mobile {
      height: 88px;
      padding: 20px 0;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mobile {
      gap: 10px;
    }

    @include tablet {
      gap: 20px;
    }
  }
  &__title {
    display: flex;
    gap: 7px;

    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    @include mobile {
      font-size: 14px;
      line-height: 17px;
    }
    @include tablet {
      font-size: 18px;
      line-height: 24px;
    }

    &-text {
      @include mobile {
        max-width: 84px;
      }
      @include tablet {
        max-width: unset;
      }
    }

    &-mobile {
      display: inline;
      @include mobile {
        display: none;
      }
    }

    &-not-mobile {
      display: none;
      @include mobile {
        display: flex;
        align-items: center;
      }
    }
  }
  &__icon {
    display: none;
    width: 24px;
    height: 24px;
    &--white {
      fill: white;
    }

    @include mobile {
      display: block;
    }
  }
  &__separator {
    display: none;
    width: 1px;
    height: 41px;
    background: var(--orange-light);

    &--red {
      background: var(--red-light);
    }

    @include mobile {
      display: block;
    }
  }
  &__info {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    max-width: 336px;

    &--highlighted {
      font-weight: 700;
    }

    @include mobile {
      font-size: 12px;
      line-height: 14px;

      max-width: 497px;

      &--undergoing {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &__close-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    @include mobile {
      right: 20px;
    }
  }

  @include tablet {
    padding-top: 0;
  }
}
</style>
