import { EXERCISES } from '@/clients'
import { djangoErrorHandler, isParentPresent } from '@/helpers'

const fetchExerciseById = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}`
  return EXERCISES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchExerciseTestsByExerciseId = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/tests?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/tests`
  return EXERCISES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchExerciseSolutionsByExerciseId = async payload => {
  const { id, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/solutions?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/solutions`
  return EXERCISES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const runCode = async payload => {
  const { id, submitted_code, flavor, parentType, parentId } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/executions?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/executions`
  return EXERCISES.post(route, {
    submitted_code,
    flavor,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const runTest = async params => {
  const { exerciseId, testId, code, flavor, parentId, parentType } = params
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${exerciseId}/tests/${testId}/executions?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${exerciseId}/tests/${testId}/executions`
  return EXERCISES.post(route, {
    submitted_code: code,
    flavor,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const runAttempt = async params => {
  const { id, submitted_code, flavor, parentId, parentType } = params
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/attempts?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/attempts`
  return EXERCISES.post(route, {
    submitted_code,
    flavor,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const fetchExerciseAttempts = async payload => {
  const { id, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/attempts?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/attempts`
  return EXERCISES.get(route)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

const setExerciseStatus = async payload => {
  const { id, status, parentId, parentType } = payload
  const route = isParentPresent({ parentId, parentType })
    ? `exercises/${id}/change_status?parent_type=${parentType}&parent_id=${parentId}`
    : `exercises/${id}/change_status`
  return EXERCISES.post(route, {
    status,
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(djangoErrorHandler(error)))
}

export {
  fetchExerciseById,
  fetchExerciseTestsByExerciseId,
  fetchExerciseSolutionsByExerciseId,
  runCode,
  runTest,
  runAttempt,
  fetchExerciseAttempts,
  setExerciseStatus,
}
