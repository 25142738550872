import CONSTANTS from '@/constants'
import { IAppState } from '@/@types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IAppState> = {
  IS_APP_LOADING_UPDATED: (state, isAppLoading: boolean) => {
    state.isAppLoading = isAppLoading
  },
  IS_PAGE_LOADING_UPDATED: (state, isPageLoading: boolean) => {
    state.isPageLoading = isPageLoading
  },
  IS_COMPONENT_LOADING_UPDATED: (state, isComponentLoading: boolean) => {
    state.isComponentLoading = isComponentLoading
  },
  APP_ONBOARDING_MODAL_STATUS_UPDATED: (
    state,
    onboardingModalStatus: object
  ) => {
    state.onboardingModalStatus = onboardingModalStatus
  },
  APP_ERROR_UPDATED: (state, appError: string) => {
    if (state.appErrors.includes(appError)) {
      state.appErrors = state.appErrors.filter(e => e !== appError)
    } else {
      state.appErrors.push(appError)
    }
  },
  APP_NOTIFICATION_UPDATED: (state, notification: string) => {
    if (state.appNotifications.includes(notification)) {
      state.appNotifications = state.appNotifications.filter(
        n => n !== notification
      )
    } else {
      state.appNotifications.push(notification)
    }
  },
  APP_SUCCESS_UPDATED: (state, success: string) => {
    if (state.appSuccesses.includes(success)) {
      state.appSuccesses = state.appSuccesses.filter(s => s !== success)
    } else {
      state.appSuccesses.push(success)
    }
  },
  APP_WARNING_UPDATED: (state, warning: string) => {
    if (state.appWarnings.includes(warning)) {
      state.appWarnings = state.appWarnings.filter(s => s !== warning)
    } else {
      state.appWarnings.push(warning)
    }
  },
  VIDEO_PLAYBACK_PREFERENCES_UPDATED: (
    state,
    preferenceAndValue: { preference: string; value: string | number | boolean }
  ) => {
    const { preference, value } = preferenceAndValue
    state.videoPlaybackPreferences[preference] = value
    const storedVideoPreferences = localStorage.getItem(
      CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.STORAGE_KEY
    )
    if (storedVideoPreferences) {
      const parsed = JSON.parse(storedVideoPreferences)
      parsed[preference] = value
      localStorage.setItem(
        CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.STORAGE_KEY,
        JSON.stringify(parsed)
      )
    } else {
      const defaults = CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.DEFAULTS
      defaults[preference] = value
      localStorage.setItem(
        CONSTANTS.VIDEO_PLAYBACK_PREFERENCES.STORAGE_KEY,
        JSON.stringify(defaults)
      )
    }
  },
  VIDEO_ACTIVE_UPDATED: (state, videoActive: boolean) => {
    state.videoActive = videoActive
  },
  CLOSE_MAINTENANCE_BANNER: state => {
    state.scheduledMaintenanceClosed = true
  },
  KINESIS_HEARTBEAT_DATA_UPDATED: (state, kinesisHeartbeatData: object) => {
    state.kinesisHeartbeatData = kinesisHeartbeatData
  },
  WOOPRA_DATA_UPDATED: (state, woopraData: object) => {
    state.woopraData = woopraData
  },
  IS_LOADING_CAPTCHA_UPDATED: (state, isLoading: boolean) => {
    state.isLoadingCaptcha = isLoading
  },
}

export default mutations
