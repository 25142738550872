var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"onboarding-modal__step"},[_c('div',{staticClass:"onboarding-modal__step-badge"},[_vm._v(_vm._s(_vm.stepBadgeLabel))]),_vm._m(0),_c('div',{staticClass:"training-goals__items-wrapper"},_vm._l((_vm.trainingGoals),function(goal,i){return _c('div',{key:`goal-${i}`,class:[
        'onboarding-modal-states',
        'training-goals__item',
        _vm.isGoalSelected(goal.id) &&
          goal.hasTextIntput &&
          'training-goals__item-with-input--selected',
        goal.hasTextIntput && 'training-goals__item-with-input',
      ],on:{"click":function($event){return _vm.toggleGoalSelection(goal.id)}}},[_c('div',{staticClass:"training-goals__item-description"},[_c('span',{staticClass:"ine-checkbox__input training-goals__item-checkbox-input"},[_c('input',{attrs:{"id":goal.id,"type":"checkbox","name":`${goal.id}-checkbox`},domProps:{"value":goal.id,"checked":_vm.isGoalSelected(goal.id)}}),_c('span',{staticClass:"ine-checkbox__control training-goals__item-checkbox-control"},[_c('CheckboxTick')],1)]),_c('div',{staticClass:"training-goals__item-label"},[_vm._v(" "+_vm._s(goal.description)+" ")])]),(goal.hasTextIntput)?_c('div',{staticClass:"onboarding-modal__input-wrapper training-goals__item-input"},[_c('transition',{attrs:{"name":"fade"}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGoalSelected(goal.id)),expression:"isGoalSelected(goal.id)"},{name:"model",rawName:"v-model",value:(_vm.otherGoalInput),expression:"otherGoalInput"}],class:[
              'training-goals__item-input-area',
              'ine-form__form-control',
              'ine-input',
              _vm.errors.fields.includes('text') && 'ine-input--error',
              goal.hasTextIntput &&
                _vm.isGoalSelected(goal.id) &&
                'training-goals__item-input-area--selected',
            ],attrs:{"type":"text","placeholder":goal.inputPlaceholder},domProps:{"value":(_vm.otherGoalInput)},on:{"focus":_vm.clearErrors,"click":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing)return;_vm.otherGoalInput=$event.target.value}}})]),(_vm.errors.fields.includes('text'))?_c('Errors',{attrs:{"errors":_vm.errors.messages[0]}}):_vm._e()],1):_vm._e()])}),0),_c('div',{staticClass:"onboarding-modal__step-btn-wrapper"},[_c('button',{staticClass:"btn btn--primary onboarding-modal__step-save-btn",attrs:{"type":"submit","disabled":_vm.isSaveDisabled},on:{"click":function($event){return _vm.saveTrainingGoals()}}},[_vm._v(" Save and close ")]),_c('button',{staticClass:"btn btn--secondary onboarding-modal__step-next-btn",on:{"click":function($event){return _vm.$emit('close-onboarding-modal')}}},[_vm._v(" Skip and close ")]),_c('button',{staticClass:"btn btn--tertiary onboarding-modal__step-back-btn",on:{"click":function($event){return _vm.$emit('go-previus-step')}}},[_vm._v(" Back ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onboarding-modal__step-header"},[_c('div',{staticClass:"onboarding-modal__step-title"},[_vm._v(" What are your training goals? ")])])
}]

export { render, staticRenderFns }