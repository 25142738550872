import { ILabExperience } from '@/@types'

const state: ILabExperience = {
  currentCollection: null,
  highlightedCollection: null,
  collectionsList: null,
  collectionsFeaturedList: null,
}

export default state
