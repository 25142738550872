import CONSTANTS from '@/constants'

export const skillAreaScoring = skillAreaData => {
  const { user_status: scoring } = skillAreaData
  const { difficulty } = scoring
  const constants_difficulty = Object.values(
    CONSTANTS.SONAR.ASSESSMENT.DIFFICULTY
  )
  const diffIndex = constants_difficulty.findIndex(d => d === difficulty)
  const diffQuota = 100 / constants_difficulty.length
  var total_score = 0
  const difficulty_scores = constants_difficulty.map((diff, index) => {
    if (index === diffIndex) {
      const currentScoring = scoring.score
      total_score += diffQuota * currentScoring
      return (scoring.difficulty_scores = {
        difficulty: diff,
        score: currentScoring,
        status: CONSTANTS.SONAR.ASSESSMENT.LEVEL_STATUS.UNLOCKED,
      })
    }
    const score = index < diffIndex ? 1 : 0
    total_score += score ? diffQuota : 0
    const status = score
      ? CONSTANTS.SONAR.ASSESSMENT.LEVEL_STATUS.PASSED
      : CONSTANTS.SONAR.ASSESSMENT.LEVEL_STATUS.LOCKED
    return (scoring.difficulty_scores = { difficulty: diff, score, status })
  })
  return { difficulty_scores, total_score }
}

export const assessmentLevel = payload => {
  const { difficulty, assessment_status, user_status } = payload
  const { PASSED, UNLOCKED, LOCKED } = CONSTANTS.SONAR.ASSESSMENT.LEVEL_STATUS
  const levels = []
  const constants_difficulty = Object.values(
    CONSTANTS.SONAR.ASSESSMENT.DIFFICULTY
  )
  const diffIndex = constants_difficulty.findIndex(d => d === difficulty)
  constants_difficulty.map((diff, index) => {
    if (index === diffIndex) {
      const currentLevelStatus = user_status?.is_passed ? PASSED : UNLOCKED
      levels.push({
        difficulty: diff,
        status: currentLevelStatus,
        assessment_status,
        is_current: true,
      })
    } else {
      const score = index < diffIndex ? 1 : 0
      const status = score ? PASSED : LOCKED
      levels.push({
        difficulty: diff,
        status,
        assessment_status,
        is_current: false,
      })
    }
  })
  return levels
}

export const iconMap = group => {
  const iconsMap = {
    'red-team': require('@/sonar/assets/images/icons/groups/red-team-icon.png'),
    'blue-team': require('@/sonar/assets/images/icons/groups/blue-team-icon.png'),
    'yellow-team': require('@/sonar/assets/images/icons/groups/yellow-team-icon.png'),
    cisco: require('@/sonar/assets/images/icons/groups/cisco-icon.png'),
  }
  return iconsMap[group]
}

export const hoverLevelMap = level => {
  const hoverMap = {
    novice: require('@/sonar/assets/images/icons/novice-icon-hover.png'),
    professional: require('@/sonar/assets/images/icons/professional-icon-hover.png'),
    advanced: require('@/sonar/assets/images/icons/advanced-icon-hover.png'),
  }
  return hoverMap[level]
}

export const groupSetup = group => {
  return {
    postfix: group ? `--${group}` : '',
    icon: group ? iconMap(group) : '',
  }
}
