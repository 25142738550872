import { GetterTree } from 'vuex'
import { IPlaylistsState, IRootState } from '@/@types'
import CONSTANTS from '@/constants'

const { SONAR_RECOMMENDED, SONAR_GOAL } = CONSTANTS.PLAYLIST_TYPES
const sonarPlaylistsTypes = [SONAR_RECOMMENDED, SONAR_GOAL]
const getters: GetterTree<IPlaylistsState, IRootState> = {
  playlists: ({ playlists }) => playlists.results,
  playlistById:
    ({ playlistCache }) =>
    (playlistId: String) =>
      playlistCache.find(p => p.id == playlistId),
  sonarPlaylists: ({ playlists }) => {
    return playlists.results.filter(p => sonarPlaylistsTypes.includes(p.type))
  },
}

export default getters
