import { MutationTree } from 'vuex'
import { IQuizzesState, IQuiz, IQuizAttempt } from '@/@types'

const mutations: MutationTree<IQuizzesState> = {
  CURRENT_QUIZ_UPDATED: (state, quiz: IQuiz) => {
    state.currentQuiz = quiz
  },

  PREVIOUS_QUIZ_ATTEMPTS_UPDATED: (state, attempts: Array<IQuizAttempt>) => {
    state.previousAttempts = attempts
  },

  UPDATE_CURRENT_QUIZ_ATTEMPT: (state, attempt: IQuizAttempt) => {
    state.currentAttempt = attempt
  },

  FINISHED_QUIZ_ATTEMPT: (state, attempt: IQuizAttempt) => {
    state.finishedAttempt = attempt
  },
}

export default mutations
