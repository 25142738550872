<template>
  <main
    :class="[
      (hasBanner || scheduledMaintenance) &&
        !maintenanceOngoing &&
        'main__with-banner',
    ]"
  >
    <template v-if="maintenanceOngoing">
      <Maintenance />
    </template>
    <template v-else>
      <template v-if="showHeader">
        <NavBar
          :showSkillDive="showSkillDiveTopBar"
          class="navbar-app"
          v-bind="{ hasBanner, scheduledMaintenance }"
        />
        <MobileNavBar
          :showSkillDive="showSkillDiveTopBar"
          v-bind="{ scheduledMaintenance }"
          class="navbar-app"
        />
      </template>
      <NotificationCenter
        v-if="isNotificationsShown"
        v-bind="{ errors, notifications, successes, warnings }"
      />
      <LoadingSpinner v-if="isAuthLoading" />
      <div v-else class="container">
        <transition name="fade">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <OnboardingModal v-if="onboardingModalStatus.opened" />
      <SendIssueModal v-if="showIssueModal" />
      <Footer v-show="showHeader" />
    </template>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getAccessToken } from '@internetworkexpert/js-common'
import NavBar from '@/components/navbar/NavBar.vue'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import NotificationCenter from '@/components/layout/NotificationCenter.vue'
import Footer from '@/components/layout/Footer.vue'
import LoadingSpinner from '@/components/loaders/LoadingSpinner.vue'
import OnboardingModal from '@/components/modals/onboarding/OnboardingModal.vue'
import { windowResize } from '@/helpers/mixins'
import Maintenance from '@/pages/Maintenance.vue'
import SendIssueModal from '@/components/modals/SendIssueModal.vue'
import { checkRedirectURL } from '@/helpers'
import CONSTANTS from '@/constants'

export default {
  name: 'App',
  components: {
    NavBar,
    MobileNavBar,
    NotificationCenter,
    Footer,
    SendIssueModal,
    LoadingSpinner,
    OnboardingModal,
    Maintenance,
  },
  mixins: [windowResize],
  computed: {
    ...mapState({
      notifications: state => state.app.appNotifications,
      errors: state => state.app.appErrors,
      successes: state => state.app.appSuccesses,
      warnings: state => state.app.appWarnings,
      isAuthenticated: state => state.auth.isAuthenticated,
      isAuthLoading: state => state.auth.isAuthLoading,
      onboardingModalStatus: state => state.app.onboardingModalStatus,
      profile: state => state.auth.userProfile,
      noHeaderRoutes: state => state.app.noHeaderRoutes,
      substatus: state => state.auth.subscriptionStatus,
      currentIssue: state => state.content.currentIssue,
      profilePreferences: state => state.auth.profilePreferences,
    }),
    ...mapGetters({
      isBusinessUser: 'auth/isBusinessUser',
      displaySonar: 'sonar/displaySonar',
      isSkillDiveB2BUser: 'auth/isSkillDiveB2BUser',
      labExperienceEnhance: 'app/labExperienceEnhance',
      showScheduledBanner: 'app/showScheduledBanner',
      maintenanceOngoing: 'app/maintenanceOngoing',
      isLabExperienceLegacyB2BUser: 'auth/isLabExperienceLegacyB2BUser',
    }),
    userEmail() {
      const { email } = this.profile
      return email
    },
    showIssueModal() {
      return this.currentIssue?.content_type
    },
    showSkillDiveTopBar() {
      if (
        this.isSkillDiveB2BUser ||
        this.labExperienceEnhance ||
        this.isLabExperienceLegacyB2BUser
      )
        return false

      const skillDivePages = [
        'skill-dive',
        'collection-all',
        'collection-detail',
      ]

      return skillDivePages.includes(this.$route?.name)
    },
    showHeader() {
      return !this.noHeaderRoutes.includes(this.$route.name)
    },
    isNotificationsShown() {
      return (
        !!this.errors.length > 0 ||
        !!this.notifications.length > 0 ||
        !!this.successes.length > 0 ||
        !!this.warnings.length > 0
      )
    },
    hasBanner() {
      return this.displaySonar && ['home', 'sonar'].includes(this.$route.name)
    },
    scheduledMaintenance() {
      return this.showScheduledBanner
    },
  },
  mounted() {
    this.storeWoopraParams()
  },
  async created() {
    await this.startApp()
    this.saveUTMVars()
  },
  methods: {
    ...mapActions('app', ['storeWoopraParams']),
    ...mapActions('events', ['updateStoredUTMParams']),
    async startApp() {
      const token = getAccessToken()
      if (token) {
        const redirectUrl = new URLSearchParams(window.location.search).get(
          'redirect_url'
        )
        if (redirectUrl && checkRedirectURL(redirectUrl)) {
          this.$store.dispatch('auth/setRedirectUrl', redirectUrl)
        }
        this.$store.dispatch('auth/setIsAuthLoading', true)
        await this.$store.dispatch('auth/tryAutoLogin')
        this.$store.dispatch('auth/setIsAuthLoading', false)
      }
    },
    saveUTMVars() {
      const params = new URLSearchParams(window.location.search)
      const payload = {}
      CONSTANTS.UTM_VARS.forEach(key => {
        if (params.has(key)) {
          payload[key] = params.get(key)
        }
      })

      this.updateStoredUTMParams(payload)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/app.scss';
@import '~vue-loaders/dist/vue-loaders.css';
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
.container {
  min-height: 100vh;
}
</style>
