import store from '@/store'
import CONSTANTS from '@/constants'
export const createPlayer = ({ element, key, playlist, events }) => {
  if (!key) {
    throw new Error('Must supply a jwplayer API key')
  }
  if (!element) {
    throw new Error('Must supply a valid html element ID')
  }
  if (!playlist) {
    throw new Error('Must supply a valid video playlist')
  }

  const isMuted = () => {
    return store?.state?.app?.videoPlaybackPreferences?.isMuted ?? false
  }

  const rate = () => {
    return store?.state?.app?.videoPlaybackPreferences?.playbackSpeed ?? 1
  }

  const prefersFullscreen = () => {
    return store?.state?.app?.videoPlaybackPreferences?.isFullscreen ?? false
  }

  const preferredVolume = () => {
    return store?.state?.app?.videoPlaybackPreferences?.volume ?? 90
  }

  const player = jwplayer(element)

  if (!player.setup) {
    throw new Error('No Player found')
  }

  player.setup({
    key,
    displaytitle: true,
    logo: true,
    playlist,
    width: '100%',
    cast: {},
    floating: {},
    mute: false,
    aspectratio: '16:9',
    visualplaylist: false,
    controls: true,
    autostart: true,
    playbackRateControls: [0.75, 1, 1.25, 1.5, 2],
  })
  const defaultEvents = {
    fullscreen: e => {
      store.commit('app/VIDEO_PLAYBACK_PREFERENCES_UPDATED', {
        preference: 'isFullscreen',
        value: e?.fullscreen ?? false,
      })
    },
    mute: e => {
      store.commit('app/VIDEO_PLAYBACK_PREFERENCES_UPDATED', {
        preference: 'isMuted',
        value: e?.mute ?? false,
      })
    },
    play: () => {
      if (prefersFullscreen() && !player.getFullscreen()) {
        player.setFullscreen()
      }
      player.setPlaybackRate(rate())
      player.setMute(isMuted())
      if (!player.getMute()) {
        player.setVolume(preferredVolume())
      }
    },
    playbackRateChanged: e => {
      store.commit('app/VIDEO_PLAYBACK_PREFERENCES_UPDATED', {
        preference: 'playbackSpeed',
        value: e?.playbackRate ?? 1,
      })
    },
    volume: e => {
      store.commit('app/VIDEO_PLAYBACK_PREFERENCES_UPDATED', {
        preference: 'volume',
        value: e?.volume ?? 90,
      })
    },
  }
  if (events) {
    if (!events.fullscreen) {
      events.fullscreen = e => defaultEvents.fullscreen.call(null, e)
    } else {
      const passedCallback = events.fullscreen
      delete events.fullscreen
      events.fullscreen = e => {
        defaultEvents.fullscreen.call(null, e)
        passedCallback.call(null, e)
      }
    }
    events.mute = e => {
      defaultEvents.mute.call(null, e)
    }
    if (!events.play) {
      events.play = e => defaultEvents.play.call(null, e)
    } else {
      const passedCallback = events.play
      delete events.play
      events.play = e => {
        defaultEvents.play.call(null, e)
        passedCallback.call(null, e)
      }
    }
    if (!events.playbackRateChanged) {
      events.playbackRateChanged = e =>
        defaultEvents.playbackRateChanged.call(null, e)
    } else {
      const passedCallback = events.playbackRateChanged
      delete events.playbackRateChanged
      events.playbackRateChanged = e => {
        defaultEvents.playbackRateChanged.call(null, e)
        passedCallback.call(null, e)
      }
    }
    events.volume = e => {
      defaultEvents.volume.call(null, e)
    }
    // eslint-disable-next-line no-unused-vars
    Object.entries(events).map(([evt, callback], _) => {
      player.on(evt, e => callback(e))
    })
  } else {
    // eslint-disable-next-line no-unused-vars
    Object.entries(defaultEvents).map(([evt, callback], _) => {
      player.on(evt, e => callback(e))
    })
  }
  return player
}

const { TIME, PLAY, COMPLETE } = CONSTANTS.VIDEO_EVENTS
const DELTA_OFFSET = 15

export const calculateDelta = events => {
  if (!events.length) return

  let delta = 0
  let previousEvent = events[0]

  for (let index = 1; index < events.length; index++) {
    const { event, position } = events[index]
    const {
      event: prevEvent,
      position: prevPosition,
      isResumed,
    } = previousEvent

    if (isResumed) {
      previousEvent = events[index]
      continue
    }

    const positionDiff = position - prevPosition
    if (
      positionDiff > 0 && // Moving forward
      positionDiff < DELTA_OFFSET && // Within valid delta range
      [PLAY, TIME].includes(prevEvent) &&
      [PLAY, TIME, COMPLETE].includes(event) // Both events are valid
    ) {
      delta += positionDiff
    }

    previousEvent = events[index]
  }

  return delta
}

export const calculatePositions = events => {
  if (events.length < 2) return []

  const timeWatched = []
  let startPosition = null
  let endPosition = 0
  let previousEvent = events[0]

  for (let index = 1; index < events.length; index++) {
    const { event, position } = events[index]
    const {
      event: prevEvent,
      position: prevPosition,
      isResumed,
    } = previousEvent

    if (isResumed) {
      previousEvent = events[index]
      continue
    }

    const positionDiff = position - prevPosition
    const isPlaying =
      [PLAY, TIME].includes(prevEvent) &&
      [PLAY, TIME, COMPLETE].includes(event) &&
      positionDiff >= 0
    const isTimeSeek = !isPlaying || Math.abs(positionDiff) > DELTA_OFFSET

    // Update positions
    startPosition = startPosition ?? prevPosition
    endPosition = isTimeSeek ? prevPosition : position

    const isLastEvent = index === events.length - 1

    if (isTimeSeek || isLastEvent || event === COMPLETE) {
      if (startPosition !== null && endPosition > startPosition) {
        timeWatched.push({ start: startPosition, end: endPosition })
      }
      startPosition = null
      endPosition = 0
    }

    previousEvent = events[index]
  }

  return timeWatched
}
