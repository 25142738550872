import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=784a581a&scoped=true"
import script from "./Message.vue?vue&type=script&lang=js"
export * from "./Message.vue?vue&type=script&lang=js"
import style0 from "./Message.vue?vue&type=style&index=0&id=784a581a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784a581a",
  null
  
)

export default component.exports