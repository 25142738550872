import { IExercisesState } from '@/@types'

const state: IExercisesState = {
  currentExercise: {},
  previousAttempts: [],
  currentAttempt: {},
  currentTests: [],
  currentTestsStatus: {},
  currentSolutions: [],
  currentFiles: {},
  currentOutput: '',
  studentCodes: {},
  currentFlavor: 'ds-3.8',
  resumeExerciseId: '',
}

export default state
