import { ICollection, ILabExperience } from '@/@types'

import { MutationTree } from 'vuex'

const mutations: MutationTree<ILabExperience> = {
  CURRENT_COLLECTION_UPDATED: (state, collection: ICollection) => {
    state.currentCollection = collection
  },
  CURRENT_HIGHLIGHTED_COLLECTION_UPDATED: (state, collection: ICollection) => {
    state.highlightedCollection = collection
  },
  CURRENT_COLLECTIONS_LIST: (state, collections: Array<ICollection>) => {
    state.collectionsList = collections
  },
  CURRENT_FEATURED_COLLECTIONS_LIST: (
    state,
    collections: Array<ICollection>
  ) => {
    state.collectionsFeaturedList = collections
  },
}

export default mutations
