import { MutationTree } from 'vuex'
import { IShoppingCartState, IProductExtended } from '@/@types'

const mutations: MutationTree<IShoppingCartState> = {
  SHOPPING_CART_UPDATED: (state, shoppingCart: Array<IProductExtended>) => {
    state.shoppingCart = shoppingCart
  },
  COUPON_UPDATED: (state, coupon: string) => {
    state.coupon = coupon
  },
  SHOPPING_CART_REMOVED_ITEM_UPDATED: (state, product: IProductExtended) => {
    state.shoppingCart = state.shoppingCart.filter(item => {
      return item.merchant_id !== product.merchant_id
    })
  },
}

export default mutations
