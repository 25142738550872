import { Module } from 'vuex'
import { ICoursesState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const courses: Module<ICoursesState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default courses
