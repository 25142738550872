import { Module } from 'vuex'
import { IRootState, IEventsState } from '@/@types'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const events: Module<IEventsState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export default events
