import { ActionTree, ActionContext } from 'vuex'
import { IRootState, IEventsState } from '@/@types'

const actions: ActionTree<IEventsState, IRootState> = {
  updateStoredUTMParams: (
    context: ActionContext<IEventsState, IRootState>,
    payload
  ) => {
    context.commit('UPDATE_UTM_PARAMS', payload)
  },
}

export default actions
