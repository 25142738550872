// mixin is used to handle the open/close modal state by adding the class to the body avoiding the scrolling behavior
export const modalOpenHooks = {
  created() {
    const body = document.querySelector('body')
    body.classList.add('modal-open')
  },
  destroyed() {
    const body = document.querySelector('body')
    body.classList.remove('modal-open')
  },
}
