import { MutationTree } from 'vuex'
import { IAdminState } from '@/@types'

const mutations: MutationTree<IAdminState> = {
  ADMIN_IS_IMPERSONATING: (state, isImpersonating: boolean) => {
    state.isImpersonating = isImpersonating
  },
  ADMIN_NEW_USER_PASSWORD_UPDATED: (state, newPassword: string) => {
    state.usersNewPassword = newPassword
  },
}

export default mutations
