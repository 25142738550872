import CONSTANTS from '@/constants'
import { ISubscriptionsState } from '@/@types'

const state: ISubscriptionsState = {
  currentSubscriptions: [],
  currentInvoices: [],
  passTypes: [],
  manageablePassStates: CONSTANTS.SUBSCRIPTIONS.MANAGEABLE_STATES,
  addTermPricing: {},
  availablePlans: [],
  productCertificationVouchers: [],
  planDetails: [
    {
      name: 'Learning Paths',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: 'In-course Hands-on Labs',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: 'Quizzes',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: 'Course Files',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: 'Usage Analytics',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: '200+ Hours of Training Content (Novice Level)',
      checked: [
        CONSTANTS.PLANS.MONTHLY,
        CONSTANTS.PLANS.ANNUAL,
        CONSTANTS.PLANS.PREMIUM,
      ],
      unchecked: [],
      greenCheck: false,
    },
    {
      name: '3500+ Hours of Training Content (All Expertise Levels)',
      checked: [CONSTANTS.PLANS.PREMIUM],
      unchecked: [CONSTANTS.PLANS.MONTHLY, CONSTANTS.PLANS.ANNUAL],
      greenCheck: true,
    },
    {
      name: '1000+ Hours of Hands-on Labs (All Expertise Levels)',
      checked: [CONSTANTS.PLANS.PREMIUM],
      unchecked: [CONSTANTS.PLANS.MONTHLY, CONSTANTS.PLANS.ANNUAL],
      greenCheck: false,
    },
    {
      name: '50% Off One Certification Voucher/Year',
      checked: [CONSTANTS.PLANS.PREMIUM],
      unchecked: [CONSTANTS.PLANS.MONTHLY, CONSTANTS.PLANS.ANNUAL],
      greenCheck: false,
    },
  ],
  currentPlanComparison: {},
  newPlanCodes: [
    CONSTANTS.PLANS.PREMIUM,
    CONSTANTS.PLANS.ANNUAL,
    CONSTANTS.PLANS.MONTHLY,
    ...Object.values(CONSTANTS.PLANS.BUSINESS),
  ],
  premiumPlanCode: CONSTANTS.PLANS.PREMIUM,
  paymentInfo: {
    last4_of_cc: '',
    account_credit: '$0',
    payment_method: '',
    billing_account_code: '',
  },
  cancellationFields: {},
  currentPromotionContext: '',
  isUpgradePlanAllowed: false,
  validPlans: [],
  validAddons: [],
  skillDivePlanConsumed: [],
  skillDiveAddHoursPreviewPurchase: 0,
  addHoursPriceLoading: false,
  promotionsAvailable: [],
  isEarlyYearPromoActivated: true,
  showEarlyYearPromo: false,
  billingToken: '',
}

export default state
