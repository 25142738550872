var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skill-dive-top-bar"},[_c('div',{staticClass:"skill-dive-top-bar__container"},[_c('SkillDiveLogo',{staticClass:"skill-dive-top-bar__container-logo"}),_c('img',{staticClass:"skill-dive-top-bar__container-logo--png",attrs:{"src":require("@/assets/images/lab-experience/skilldive-logo.png")}}),_c('div',{staticClass:"skill-dive-top-bar__container__right"},[(_vm.isLabExperienceUser)?[_c('div',{staticClass:"skill-dive-top-bar__container__right__remaining-pill"},[_c('div',{staticClass:"skill-dive-top-bar__container__right__remaining-pill__label"},[(_vm.remainingHoursLabel < 10)?_c('InfoIcon',{staticClass:"skill-dive-top-bar__container-info-icon",attrs:{"size":"16"}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.remainingHoursLabel)+" ")])],1),_c('div',{class:[
              'skill-dive-top-bar__container__right__remaining-pill__bar',
              `skill-dive-top-bar__container__right__remaining-pill__bar--${_vm.remainingColor}`,
            ],style:({
              width: `${
                !_vm.remainingHours ? '100%' : `${_vm.remainingPercentage}%`
              }`,
            })})]),_c('button',{staticClass:"skill-dive-top-bar__container__right__arrow-btn btn btn--small btn--tertiary btn--default-icon-color",on:{"click":_vm.goToDashboard}},[_c('ArrowRightIcon')],1)]:[_c('a',{class:[
            'btn',
            'btn--small',
            _vm.isLoading && 'skill-dive-top-bar--disabled',
          ],on:{"click":_vm.getOrRequestAccess}},[_vm._v(" "+_vm._s(_vm.isBusinessUser && !_vm.isAccOwnerOrTeamManager ? 'Request access to Skill Dive' : 'Get access to Skill Dive')+" ")])]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }