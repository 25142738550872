import { ActionTree, ActionContext } from 'vuex'

import { IBootcampsState, IRootState, IBootcamp } from '@/@types'
import {
  fetchBootcampsBySchedule,
  fetchBootcampById,
  registerForBootcampById,
  fetchUserStatusForBootcamps,
} from '../../../models/bootcamps'

import { errorHandler } from '@/utils'

const actions: ActionTree<IBootcampsState, IRootState> = {
  getCurrentBootcamps: async (
    context: ActionContext<IBootcampsState, IRootState>
  ) => {
    try {
      const bootcamps: Array<IBootcamp> = await fetchBootcampsBySchedule()
      context.commit('CURRENT_BOOTCAMPS', bootcamps)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getBootcampById: async (
    context: ActionContext<IBootcampsState, IRootState>,
    id: string
  ) => {
    try {
      const bootcamp: IBootcamp = await fetchBootcampById(id)
      context.commit('SELECTED_BOOTCAMP_UPDATED', bootcamp)
      return bootcamp
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  registerForBootcamp: async (
    context: ActionContext<IBootcampsState, IRootState>,
    id: string
  ) => {
    try {
      const bootcamp: IBootcamp = await registerForBootcampById(id)
      await context.dispatch('getCurrentBootcamps')
      context.dispatch('app/setNotification', 'Registration successful!', {
        root: true,
      })
      context.dispatch('updateBootcampsUserStatus', id)
      return bootcamp
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getBootcampsUserStatus: async (
    context: ActionContext<IBootcampsState, IRootState>,
    ids: string
  ) => {
    try {
      const isUserAuthenticated = context.rootState.auth.isAuthenticated
      if (!isUserAuthenticated) return
      const response = await fetchUserStatusForBootcamps(ids)
      context.commit('BOOTCAMPS_USER_STATUS_UPDATED', response.results)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  updateBootcampsUserStatus: async (
    context: ActionContext<IBootcampsState, IRootState>,
    id: string
  ) => {
    try {
      const isUserAuthenticated = context.rootState.auth.isAuthenticated
      if (!isUserAuthenticated) return
      const response = await fetchUserStatusForBootcamps(id)
      context.commit('BOOTCAMPS_USER_STATUS_CHANGED', response.results[0])
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
