import {
  lastAttempt as certLastAttempt,
  certificationUserStatus,
} from '@/helpers/certifications'
import { mapGetters, mapState } from 'vuex'

import CONSTANTS from '@/constants'

const {
  CERTIFICATIONS: { USER_STATUS, SLUGS, IDS },
} = CONSTANTS

export const certificationCommons = {
  data() {
    return {
      checkoutSite: process.env.VUE_APP_CHECKOUT_URL,
      isRenewalAvailableHigherCertProp: false,
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
    }),
    ...mapGetters({
      activePlans: 'subscriptions/activePlans',
      certRenewalsAvailable: 'certifications/certRenewalsAvailable',
      usersCertifications: 'certifications/usersCertifications',
    }),
    certificationId() {
      return this.certification?.id
    },
    userStatusCert() {
      return this.certification.awarded
        ? USER_STATUS.AWARDED
        : certificationUserStatus(this.certification)
    },
    buttonText() {
      let text = null
      if (this.userStatusCert === USER_STATUS.ATTEMPT_IN_PROGRESS) {
        text = 'Resume exam'
      } else if (this.userStatusCert === USER_STATUS.CAN_DO_RETAKE) {
        text = 'Retake exam'
      } else if (this.userStatusCert === USER_STATUS.AWARDED) {
        text = 'Go to certification'
      } else if (this.userStatusCert === USER_STATUS.NO_VOUCHERS) {
        text = 'Shop vouchers'
      } else if (this.userStatusCert === USER_STATUS.READY_FOR_FIRST_ATTEMPT) {
        text = 'Start exam'
      } else if (this.userStatusCert === USER_STATUS.NOT_AVAILABLE) {
        text = 'Learn more'
      }
      return text
    },
    certlearningPath() {
      return this.certification.learning_paths?.length
        ? this.certification.learning_paths[0]
        : null
    },
    hasLearningPath() {
      return !!this.certlearningPath
    },
    lastAttempt() {
      return certLastAttempt(this.certification)
    },
    currentVersion() {
      return this.certification?.versions?.[0] || this.voucher?.versions?.[0]
    },
    isEDA() {
      return this.certificationId === IDS.EDA
    },
    isEWPT() {
      return this.certificationId === IDS.eWPT
    },
    isEWPTX() {
      return this.certificationId === IDS.eWPTX
    },
    isECPPT() {
      return this.certificationId === IDS.ECPPT
    },
    isCDFP() {
      return this.certificationId === IDS.CDFP
    },
    isMCIT() {
      return this.certificationId === IDS.MCIT
    },
    isEJPT() {
      return this.certificationId === IDS.eJPT
    },
    isRenewalAvailableHigherCert() {
      return this.isRenewalAvailableHigherCertProp
    },
  },
  created() {
    this.isRenewalAvailableHigherCertCalculator(this.certification)
  },
  methods: {
    certificationRedirectURL(certId, isBeta = false) {
      if (!certId) return this.checkoutSite
      const { PARAMS, REDIRECT_URL, BETA } = SLUGS[certId] || SLUGS.DEFAULT
      if (isBeta) {
        return BETA ? `${BETA.REDIRECT_URL}${BETA.PATH}` : REDIRECT_URL
      }
      let slug = ''
      const product =
        this.isAuthenticated && this.activePlans?.length
          ? PARAMS?.VALUES?.VOUCHER
          : PARAMS?.VALUES?.PLAN || PARAMS?.VALUES?.VOUCHER
      if (product) slug = `${PARAMS.KEY}${product}`
      return `${REDIRECT_URL}${slug}`
    },
    getTrainingClick(params = {}) {
      this.$router.push({
        name: 'learning-area-path',
        params: {
          area: this.certification.learning_area?.name?.replace(/\s/g, ''),
          id: this.certlearningPath?.id,
          slug: this.certlearningPath?.slug,
          ...params,
        },
      })
    },
    isRenewalAvailableHigherCertCalculator(thisCertification) {
      if (!thisCertification) return false
      const upperLevelCertifications =
        thisCertification?.upper_level_certifications?.map(cert => cert.id) ||
        []
      const userEarnedHigherCert =
        this.usersCertifications &&
        this.usersCertifications.some(cert =>
          upperLevelCertifications.includes(cert.id)
        )
      this.isRenewalAvailableHigherCertProp = userEarnedHigherCert
      return userEarnedHigherCert
    },
    isRenewalAvailableCalculator(thisCertification) {
      const renewalObj = this.certRenewalsAvailable.find(renewal => {
        return renewal.user_exam_attempt_id === thisCertification.attempt_id
      })
      if (renewalObj) {
        return {
          isRenewalAvailable: true,
          hasRenewalCertAttempt: renewalObj,
        }
      }
      return {
        isRenewalAvailable: false,
        hasRenewalCertAttempt: null,
      }
    },
  },
}
