<template>
  <nav class="mega-menu">
    <div class="mega-menu__arrows-container">
      <div class="mega-menu__arrows mega-menu__arrows-1"></div>
    </div>
    <div class="mega-menu__categories">
      <div class="mega-menu__categories-list">
        <template v-for="area in areas">
          <router-link
            :key="area.id"
            class="mega-menu__categories-item"
            :to="{ name: 'learning-area', params: { id: area.id } }"
            @click.native="$emit('close-mega-menu')"
          >
            <Passes :passName="area.name" />
            <span class="mega-menu__categories-item--text">{{
              area.name
            }}</span>
            <ArrowRightIcon class="mega-menu__categories-arrow" />
          </router-link>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import Passes from '@/components/properties/Passes.vue'
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
  name: 'DesktopMegaMenu',
  components: {
    Passes,
    ArrowRightIcon,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    primaryAreas: {
      type: Array,
      required: true,
    },
    secondaryAreas: {
      type: Array,
      required: true,
    },
  },
  computed: {
    areas() {
      const { primaryAreas, secondaryAreas } = this
      return [...primaryAreas, ...secondaryAreas]
    },
  },
}
</script>

<style lang="scss">
.mega-menu {
  display: none;
  @include tablet {
    display: block;
    width: 100%;
    background: var(--menu-background);
  }
  &__categories {
    background: var(--menu-background);
    border-radius: 10px;
    position: absolute;
    top: 77px;
    left: -42px;
    width: 231px;
    &-list {
      background: var(--glass-mid-gray);
      border-radius: 10px;
    }
  }
  &__categories-item {
    &:first-child,
    &:first-child::after {
      border-radius: 10px 10px 0 0;
    }
    color: var(--white);
    font-family: var(--font-book);
    font-size: rem(14px);
    line-height: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px 22.5px 15px 20px;
    white-space: nowrap;
    cursor: pointer;
    &::after {
      position: absolute;
      content: '';
      background: var(--glass-extra-light);
      opacity: 0;
      height: 80px;
      width: 100%;
      left: 0;
    }
    &:hover {
      & .mega-menu__categories-arrow {
        display: block;
      }
      &:after {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }
    &--text {
      margin: 0 0 0 15px;
    }
    &:last-child {
      &::after {
        border-radius: 0 0 10px 10px;
      }
    }
  }
  &__categories-arrow {
    margin-left: auto;
    height: 15px;
    width: 15px;
    display: none;
  }
  &__arrows {
    &-container {
      position: absolute;
      content: '';
      left: -42px;
      bottom: -7px;
      width: 231px;
      height: 14px;
      z-index: -1;
    }
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid var(--shark-gray);
    left: 50%;
    transform: translateX(-50%);
    &-1 {
      top: -0;
    }
    &-2 {
      top: -5px;
    }
  }
}
</style>
