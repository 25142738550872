<template ref="groupwrapper">
  <div ref="selectwrapper">
    <v-select
      class="vue-select"
      :value="selectedOption"
      v-bind="$props"
      @input="handleSelection"
    />
  </div>
</template>
<script>
import VSelectOpenIndicator from '@/components/shared/VSelectOpenIndicator.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

vSelect.props.components.default = () => ({
  OpenIndicator: VSelectOpenIndicator,
})

export default {
  name: 'Dropdown',
  components: {
    vSelect,
  },
  props: {
    handleSelection: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Select',
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [String, Object],
      required: false,
    },
    label: {
      type: String,
    },
    uid: {
      type: String,
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loading: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
