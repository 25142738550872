<template>
  <div class="pass-upgrade">
    <div class="pass-upgrade__indicator">
      <p class="pass-upgrade__copy pass-upgrade__pass">
        You have a Starter Pass Subscription<br />
        <span class="pass-upgrade__pass--unlock"
          >Unlock features and content when you upgrade!</span
        >
      </p>
    </div>
    <div class="pass-upgrade__button-wrapper">
      <a href="//ine.com/pages/plans" class="btn btn--primary btn--small">
        Upgrade
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PassUpgrade',
}
</script>

<style lang="scss" scoped>
.pass-upgrade {
  margin: 0;
  display: flex;
  align-items: center;
  @include desktop {
    margin: 0 25px 0 0;
  }
  &__indicator {
    margin: 0 10px 0 0;
  }
  &__copy {
    color: var(--white);
    margin: 0;
    padding: 0;
    @include desktop {
      white-space: nowrap;
    }
  }
  &__pass {
    line-height: 14px;
    margin: 0;
    font-weight: 700;
    @include desktop {
      margin: 0 0 0 27px;
    }
    &--unlock {
      font-size: rem(10px);
      color: var(--business-gray);
      font-weight: 400;
    }
  }
}
</style>
