import { Module } from 'vuex'
import { IFilesState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const files: Module<IFilesState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default files
