export default {
  lowerBound(num, limit) {
    return num >= limit ? num : limit
  },
  durationOn(totalDuration, type) {
    const colon = totalDuration.split(':')
    let seconds = +colon[0] * 60 * 60 + +colon[1] * 60 + +colon[2]
    seconds = Number(seconds)
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = Math.floor((seconds % 3600) % 60)
    const hDisplay = h > 0 ? h + (h === 1 ? 'h ' : 'h ') : ''
    const hoursDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : ''
    const mDisplay = m > 0 ? m + (m === 1 ? 'm ' : 'm ') : ''
    const minutesDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : ''
    const sDisplay = s > 0 ? s + (s === 1 ? 's' : 's') : ''
    if (type === 'hours') {
      return hDisplay
    }
    if (type === 'minutes') {
      return hDisplay + mDisplay
    }
    if (type === 'seconds') {
      return hDisplay + mDisplay + sDisplay
    }
    if (type === 'zeros') {
      return `${hDisplay || '0h'}${mDisplay || '0m'}`.replace(/ /g, '')
    }
    if (type === 'HOURSMIN') {
      return `${hoursDisplay || ''}${minutesDisplay || ''}`
    }
    return hDisplay + mDisplay
  },
  pad(num) {
    return `0${num}`.slice(-2)
  },
  convertToSeconds(time) {
    const split = time.split(':')
    return +split[0] * 60 * 60 + +split[1] * 60 + +split[2]
  },

  hhmmss(secs) {
    let minutes = Math.floor(secs / 60)
    secs %= 60
    const hours = Math.floor(minutes / 60)
    minutes %= 60
    return `${hours}:${this.pad(minutes)}:${this.pad(secs)}`
  },

  mmss(secs) {
    let minutes = Math.floor(secs / 60)
    secs %= 60
    minutes %= 60
    return `${this.pad(minutes)}:${this.pad(secs)}`
  },

  onlymmss(secs) {
    const minutes = Math.floor(secs / 60)
    secs %= 60
    return `${this.pad(minutes)}:${this.pad(secs)}`
  },

  HOURSMINUTES(secs) {
    let minutes = Math.floor(secs / 60)
    secs %= 60
    const hours = Math.floor(minutes / 60)
    minutes %= 60
    return `${hours}:${this.pad(minutes)}:${this.pad(secs)}`
  },

  durationFromSecs(sec, type = '') {
    return this.durationOn(this.hhmmss(sec), type)
  },
  getCourseDuration(course) {
    if (!course.duration) return ''
    const rawDuration = course.duration.split(':')
    if (rawDuration.length === 3) {
      return `${rawDuration[0]}hrs ${rawDuration[1]}min`
    }
    return course.duration
  },
}
