import { ActionTree, ActionContext } from 'vuex'
import {
  ISonarState,
  IRootState,
  ISonarSubmittedAnswer,
  ISonarGroups,
  ISonarSkillArea,
  ISonarAssessment,
  ISonarSubmitResponse,
  ISonarLaAttempts,
} from '@/@types'
import {
  fetchUserStatus,
  postSubmitQuestion,
  fetchScoreByLearningArea,
  postScoringByLearningArea,
  fetchSharedScore,
  fetchAssessments,
  fetchAssessmentsBySkillArea,
  fetchAssessmentRecommendations,
  fetchAssessmentById,
  createAssessmentAttempt,
  fetchAssessmentAttempt,
  putAssessmentStatus,
  fetchAssessmentAttemptsByStatus,
} from '@/models/sonar'
import { skillAreaScoring } from '@/sonar/helpers/utils'
import CONSTANTS from '@/constants'

import { errorHandler } from '@/utils'

const groupSkillAreas = (skillAreas: ISonarSkillArea[]) => {
  const groupedSkillAreas: { [key: string]: ISonarGroups } = {}
  skillAreas.forEach((skillArea: ISonarSkillArea) => {
    const { difficulty_scores, total_score } = skillAreaScoring(skillArea)
    skillArea.difficulty_scores = difficulty_scores
    skillArea.total_score = total_score
    const skillAreaGroupName = skillArea.group || ''
    const group: ISonarGroups = {
      name: skillAreaGroupName,
      skill_areas: groupedSkillAreas[skillAreaGroupName]?.skill_areas || [],
    }
    group.skill_areas?.push(skillArea)
    group.skill_areas?.sort((ska: ISonarSkillArea, skb: ISonarSkillArea) => {
      if (skb.total_score && ska.total_score)
        return skb?.total_score - ska?.total_score
    })
    groupedSkillAreas[skillAreaGroupName] = group
  })
  const groupedSkillAreasArray = Object.entries(groupedSkillAreas).map(
    ([name, skill_areas]) => ({
      name,
      skill_areas: skill_areas.skill_areas,
    })
  )
  return groupedSkillAreasArray
}

const normalizeSubmittedAssessmentScore = (
  submitResponse: ISonarSubmitResponse
) => {
  const formattedScore = submitResponse?.score_per_skill_area.map(ska => {
    const skill_area = <ISonarSkillArea>{}
    const { id, name } = ska.skill_area
    const { level, difficulty } = ska.user_status
    skill_area.group = ''
    skill_area.id = id
    skill_area.name = name
    skill_area.level = level
    skill_area.difficulty = difficulty
    skill_area.last_score = ska?.last_score
    skill_area.user_status = ska.user_status
    const skill_area_scoring = skillAreaScoring(skill_area)
    return { ...skill_area, ...skill_area_scoring }
  })
  submitResponse.score_per_skill_area = formattedScore
  return submitResponse
}

const actions: ActionTree<ISonarState, IRootState> = {
  getUserStatus: async (context: ActionContext<ISonarState, IRootState>) => {
    try {
      const response = await fetchUserStatus()
      const activeLearningAreas = Object.values(CONSTANTS.SONAR.LEARNING_AREAS)
        .filter(la => !la.disabled)
        .map(la => la.id)
      const currentLearningAreas =
        response?.attempts_per_learning_area?.filter((la: ISonarLaAttempts) =>
          activeLearningAreas.includes(la.learning_area_id)
        ) || []
      context.commit('CURRENT_USER_STATUS_UPDATED', {
        ...response,
        attempts_per_learning_area: currentLearningAreas,
      })
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
    }
  },
  updateSonarStatus: (
    context: ActionContext<ISonarState, IRootState>,
    payload: Object
  ) => {
    context.commit('CURRENT_SONAR_STATUS_UPDATED', payload)
  },
  getAssessments: async (
    context: ActionContext<ISonarState, IRootState>,
    learning_area_id: String
  ) => {
    try {
      const response = await fetchAssessments(learning_area_id)
      const assessments = response?.map((a: ISonarAssessment) => ({
        ...a,
        learning_area_id,
      }))
      context.commit('CURRENT_ASSESSMENTS_UPDATED', assessments)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getAssessmentsBySkillArea: async (
    context: ActionContext<ISonarState, IRootState>,
    skill_area_ids: Array<string>
  ) => {
    try {
      const response = await fetchAssessmentsBySkillArea(skill_area_ids)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  createAssessmentAttempt: async (
    context: ActionContext<ISonarState, IRootState>,
    id: String
  ) => {
    try {
      const assessmentAttempt = await createAssessmentAttempt(id)
      context.commit('CURRENT_ASSESSMENT_ATTEMPT_UPDATED', assessmentAttempt)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getAssessmentRecommendations: async (
    context: ActionContext<ISonarState, IRootState>,
    payload: Object
  ) => {
    try {
      const results = await fetchAssessmentRecommendations(payload)
      return results
    } catch (error) {
      const { message } = error as { message: string }
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getAssessmentAttempt: async (
    context: ActionContext<ISonarState, IRootState>,
    payload: Object
  ) => {
    try {
      const assessmentAttempt = await fetchAssessmentAttempt(payload)
      context.commit('CURRENT_ASSESSMENT_ATTEMPT_UPDATED', assessmentAttempt)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getAssessmentAttemptsByStatus: async (
    context: ActionContext<ISonarState, IRootState>,
    attempt_status: String
  ) => {
    try {
      const assessmentAttempts = await fetchAssessmentAttemptsByStatus(
        attempt_status
      )
      context.commit('ASSESSMENT_ATTEMPTS_UPDATED', assessmentAttempts)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getUserScoreByLearningArea: async (
    context: ActionContext<ISonarState, IRootState>,
    learning_area_id: String
  ) => {
    try {
      const score = await fetchScoreByLearningArea(learning_area_id)
      const groupedSkillAreasArray = groupSkillAreas(
        score.user_status.skill_areas
      )
      const { id, name, slug, user_status } = score
      const { highest_score, lowest_score, score_average } = user_status
      const payload = {
        id,
        name,
        slug,
        user_status: {
          highest_score,
          lowest_score,
          score_average,
          groups: groupedSkillAreasArray,
        },
      }
      context.commit('CURRENT_SCORE_UPDATED', payload)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getAssessmentById: async (
    context: ActionContext<ISonarState, IRootState>,
    id: String
  ) => {
    try {
      const assessment = await fetchAssessmentById(id)
      context.commit('CURRENT_ASSESSMENT_ATTEMPT_UPDATED', assessment)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  setCurrentQuestion: (
    context: ActionContext<ISonarState, IRootState>,
    currentQuestion: Object
  ) => {
    context.commit('CURRENT_QUESTION_UPDATED', currentQuestion)
  },

  setCurrentAssessmentOutOfTime: (
    context: ActionContext<ISonarState, IRootState>,
    currentAssessmentTimerState: Boolean
  ) => {
    context.commit(
      'CURRENT_ASSESSMENT_TIMER_UPDATED',
      currentAssessmentTimerState
    )
  },

  setCanAnswerQuestions: (
    context: ActionContext<ISonarState, IRootState>,
    canAnswerQuestions: Boolean
  ) => {
    context.commit('CAN_ANSWER_QUESTIONS_UPDATED', canAnswerQuestions)
  },

  setLastQuestionAnswered: (
    context: ActionContext<ISonarState, IRootState>,
    isLastQuestionAnswered: Boolean
  ) => {
    context.commit('LAST_QUESTION_ANSWERED_UPDATED', isLastQuestionAnswered)
  },

  updateAssessmentAttemptStatus: (
    context: ActionContext<ISonarState, IRootState>,
    payload: Object
  ) => {
    context.commit('CURRENT_ASSESSMENT_ATTEMPT_STATUS_UPDATED', payload)
  },

  setIsAssessmentRunning: (
    context: ActionContext<ISonarState, IRootState>,
    isAssessmentRunning: Boolean
  ) => {
    const payload = {
      isAssessmentRunning,
    }
    context.commit('CURRENT_ASSESSMENT_ATTEMPT_STATUS_UPDATED', payload)
  },

  resetAssessmentAttemptStatus: (
    context: ActionContext<ISonarState, IRootState>
  ) => {
    const payload = {
      isAssessmentRunning: false,
      isRequiredAssessment: false,
      current_step: CONSTANTS.SONAR.ASSESSMENT.STEP.SUMMARY,
    }
    const labStatus = {
      isStartingLab: false,
      isSwitchingLabs: false,
      isRestartingLab: false,
      isFetchingLab: false,
    }
    context.commit('labs/CURRENT_LAB_STATUS_UPDATED', labStatus, { root: true })
    context.commit('CURRENT_ASSESSMENT_ATTEMPT_STATUS_UPDATED', payload)
  },

  setDisplayCorrectAnswers: (
    context: ActionContext<ISonarState, IRootState>,
    displayCorrectAnswers: Boolean
  ) => {
    context.commit('DISPLAY_CORRECT_ANSWERS_UPDATED', displayCorrectAnswers)
  },

  nextQuestion: (context: ActionContext<ISonarState, IRootState>) => {
    const { assessmentAttempt, currentQuestion } = context.state
    const newPosition =
      currentQuestion.position + 1 > assessmentAttempt.questions.length
        ? currentQuestion.position
        : currentQuestion.position + 1
    const newCurrentQuestion = assessmentAttempt.questions[newPosition - 1]
    context.dispatch('setCurrentQuestion', {
      ...newCurrentQuestion,
      position: newPosition,
    })
  },
  previousQuestion: async (context: ActionContext<ISonarState, IRootState>) => {
    const { assessmentAttempt, currentQuestion } = context.state
    const newPosition =
      currentQuestion.position - 1 < 1
        ? currentQuestion.position
        : currentQuestion.position - 1
    context.commit('CURRENT_QUESTION_UPDATED', {
      ...assessmentAttempt.questions[newPosition - 1],
      position: newPosition,
    })
  },
  submitAssessmentAnswer: async (
    context: ActionContext<ISonarState, IRootState>,
    payload: {
      question_id: string
      assessment_attempt_id: string
      submitted_answer: ISonarSubmittedAnswer
      lab_session_id: boolean | null
    }
  ) => {
    try {
      const { questionsAnswered } = context.state
      const questions = questionsAnswered.concat(payload.question_id)
      await postSubmitQuestion(payload)
      context.commit('UPDATE_QUESTIONS_ANSWERED', questions)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  setAssessmentStatus: async (
    context: ActionContext<ISonarState, IRootState>,
    payload: {
      assessment_attempt_id: string
      assessment_id: string
      status: string
      metadata: object | null
    }
  ) => {
    try {
      const assessmentAttemptStatus = await putAssessmentStatus(payload)
      const assessmentAttempt = context.rootState.sonar.assessmentAttempt
      context.commit('CURRENT_ASSESSMENT_ATTEMPT_UPDATED', {
        ...assessmentAttempt,
        status: assessmentAttemptStatus.status,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  submitAssessment: async (
    context: ActionContext<ISonarState, IRootState>,
    payload: {
      assessment_attempt_id: string
      assessment_id: string
    }
  ) => {
    try {
      const status = CONSTANTS.SONAR.ASSESSMENT.STATUS.FINISHED
      const response = await putAssessmentStatus({ ...payload, status })
      const submitResponse = normalizeSubmittedAssessmentScore(response)
      context.commit('LAST_ASSESSMENT_UPDATED', submitResponse)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getScoringIdByLearningArea: async (
    context: ActionContext<ISonarState, IRootState>,
    learning_area_id: String
  ) => {
    try {
      const la_id =
        learning_area_id || CONSTANTS.SONAR.LEARNING_AREAS['Cyber Security'].id
      const response = await postScoringByLearningArea(la_id)
      return response?.user_public_score_id
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getSharedScore: async (
    context: ActionContext<ISonarState, IRootState>,
    score_id: String
  ) => {
    try {
      const score = await fetchSharedScore(score_id)
      const groupedSkillAreasArray = groupSkillAreas(
        score.score.user_status.skill_areas
      )
      const { id, name, slug, user_status } = score.score
      const { highest_score, lowest_score, score_average } = user_status
      const payload = {
        user_name: score.user_name,
        id,
        name,
        slug,
        user_status: {
          highest_score,
          lowest_score,
          score_average,
          groups: groupedSkillAreasArray,
        },
      }
      return payload
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  setAlgoliaSearchIndex: (
    context: ActionContext<ISonarState, IRootState>,
    searchIndex: Object
  ) => {
    context.commit('IS_SEARCH_INDEX_UPDATED', searchIndex)
  },
}

export default actions
