import { GetterTree } from 'vuex'
import { ICertificationsState, IRootState } from '@/@types'
import { format } from 'date-fns-tz'
import { cloneDeep } from 'lodash'
import CONSTANTS from '@/constants'
import { lastActiveVoucherFromCert } from '@/helpers'

const getters: GetterTree<ICertificationsState, IRootState> = {
  answeredAt: state => (questionId: String) => {
    const questions = state.currentAttempt.questionsAnswered
    const currentQuestionAnsweredAt = questions?.find(
      question => question.uuid === questionId
    )?.answered_at
    return currentQuestionAnsweredAt
      ? format(new Date(currentQuestionAnsweredAt), 'LLL do, yyyy')
      : ''
  },
  orderedListOfCertifications: state => {
    let orderedCertifications = state.certificationList.filter(cert => {
      if (cert.id === CONSTANTS.CERTIFICATIONS.IDS.EWPTX_old) {
        return lastActiveVoucherFromCert(cert)
      }

      return !CONSTANTS.CERTIFICATIONS.CANT_SHOW.includes(cert.id ?? '')
    })
    orderedCertifications = cloneDeep(orderedCertifications).sort(
      (a, b) => +a.is_external - +b.is_external
    )
    return orderedCertifications
  },
  usersVouchers: state => state.usersVouchers,
  usersCertifications: state => state.usersCertifications,
  currentScoredAttempt: state => state.currentScoredAttempt,
  currentScoredCertification: state => state.currentScoredCertification,
  certRenewalsAvailable: state => state.certRenewalsAvailable,
  certRenewalCost: state => state.certRenewalCost,
  certRenewalPurchase: state => state.certRenewalPurchase,
}

export default getters
