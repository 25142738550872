export default [
  {
    path: '/dashboard/:mode/:section?',
    name: 'user-dashboard',
    props: true,
    component: () => import('@/pages/UserDashboard'),
  },
  {
    path: '/certification/:voucherId/attempt/:attemptId?',
    name: 'certification-exam',
    props: true,
    component: () => import('@/pages/CertificationExam'),
  },
]
