import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { VPopover, VTooltip } from 'v-tooltip'
import {
  buildContentLink,
  duration,
  getBackgroundImg,
  getBootcampBackgroundImg,
  getGradient,
  getInstructorImg,
  getLearningPathBackground,
  pluralizeWord,
} from '@/helpers'

import App from './App.vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import LoadScript from 'vue-plugin-load-script'
import Toasted from 'vue-toasted'
import Vue from 'vue'
import VueLoaders from 'vue-loaders'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import { mixpanelEvent } from '@/helpers/mixins'
import router from './router'
import { setupJsCommon } from '@internetworkexpert/js-common'
import store from './store'
import vClickOutside from 'v-click-outside'

setupJsCommon(process.env.VUE_APP_ENVIRONMENT || 'production')

const appVersion = store?.state?.app?.appVersion ?? '2.148.0'

require('./assets/js/jwplayer/jwplayer')

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_KEY || '',
  releaseStage: process.env.VUE_APP_ENVIRONMENT,
  plugins: [new BugsnagPluginVue()],
  autoDetectErrors: false,
  enabledReleaseStages: ['production', 'staging', 'development'],
  appVersion,
})

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(VueLoaders)
Vue.use(VueScrollTo)
Vue.use(LoadScript)
Vue.use(Toasted, { singleton: true })
Vue.use(vClickOutside)

Vue.directive('tooltip', VTooltip)
Vue.component('VPopover', VPopover)

Vue.mixin(mixpanelEvent)

Vue.prototype.$duration = duration
Vue.prototype.$getCourseBackground = getBackgroundImg
Vue.prototype.$getPlaylistBackground = getLearningPathBackground
Vue.prototype.$getLearningPathBackground = getLearningPathBackground
Vue.prototype.$getGradient = getGradient
Vue.prototype.$buildContentLink = buildContentLink
Vue.prototype.$getInstructorImg = getInstructorImg
Vue.prototype.$getBootcampBackgroundImg = getBootcampBackgroundImg
Vue.prototype.$pluralizeWord = pluralizeWord

// Provides performance data to User Timing API
Vue.config.performance = true

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
