import { GetterTree } from 'vuex'
import { IContentState, IRootState, IBookMark } from '@/@types'

const getters: GetterTree<IContentState, IRootState> = {
  courseBookmarks: (state: IContentState) => {
    const bookmarks: Array<IBookMark> = []
    if (state.currentBookmarks.length && state.currentVideos.length) {
      state.currentVideos.map(video => {
        state.currentBookmarks.map(bookmark => {
          if (bookmark.video === video.id) {
            bookmarks.push(bookmark)
          }
        })
      })
    }
    return bookmarks
  },
  currentLabs: (state: IContentState) => state.currentLabs,
  currentParent: (state: IContentState) => state.currentParent,
  allVideosWithBookmarks: (state: IContentState) =>
    state.allVideosWithBookmarks,
  getTrainingHistoryItems: (state: IContentState) => {
    return state.trainingHistoryItems
  },
}

export default getters
