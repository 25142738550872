import CONSTANTS from '@/constants'

const cyberSecurityRoute = {
  name: 'sonar',
  params: {
    learningArea: CONSTANTS.SONAR.LEARNING_AREAS['Cyber Security'].slug,
  },
}
export default [
  {
    path: '/sonar/:learningArea?',
    name: 'sonar',
    beforeEnter(to, from, next) {
      if (!to.params.learningArea && !to.params.assessmentId) {
        next(cyberSecurityRoute)
      } else {
        if (to.params.learningArea) {
          const validLearningArea = Object.values(
            CONSTANTS.SONAR.LEARNING_AREAS
          ).find(la => la.slug === to.params.learningArea)?.slug
          if (!validLearningArea) return next(cyberSecurityRoute)
        }
        next()
      }
    },
    component: () => import('@/sonar/pages/SonarPage.vue'),
    children: [
      {
        path: 'assessment/:assessmentId',
        name: 'sonar-quiz',
        component: () =>
          import('@/sonar/components/quiz-modal/SonarQuizModal.vue'),
      },
    ],
  },
  {
    path: '/sonar-share',
    name: 'sonar-share',
    component: () => import('@/sonar/pages/SonarShare.vue'),
  },
]
