<template>
  <div class="action-loader">
    <vue-loaders
      :name="name"
      :scale="size"
      :class="loaderClass"
      :color="color"
    ></vue-loaders>
  </div>
</template>

<script>
export default {
  name: 'ActionLoader',
  props: {
    loaderClass: {
      type: String,
      required: false,
      default: 'action-loader__loader',
    },
    size: {
      type: Number,
      required: false,
      default: 1,
    },
    name: {
      type: String,
      required: false,
      default: 'line-scale',
    },
    color: {
      type: String,
      required: false,
      default: '#ef7d22',
    },
  },
}
</script>

<style lang="scss">
.action-loader {
  white-space: nowrap;
}
</style>
