import { MutationTree } from 'vuex'
import { ISearchState } from '@/@types'

const mutations: MutationTree<ISearchState> = {
  SEARCH_QUERY_UPDATED: (state, query: string) => {
    state.searchQuery = query
  },
}

export default mutations
