<template>
  <div class="file-uploader">
    <div
      :class="[
        'file-uploader__upload-area-wrapper ine-card',
        isAdvancedUpload && 'file-uploader__has-advanced-upload',
        isDragover && 'file-uploader__is-dragover',
      ]"
      method="post"
      action=""
      enctype="multipart/form-data"
      @dragover.prevent="dragoverEvent"
      @dragleave.prevent="dragleaveEvent"
      @drop.prevent="dropEvent"
    >
      <input
        ref="fileUploadInputAdd"
        class="file-uploader__input file-uploader__input--area"
        type="file"
        placeholder="Avatar"
        accept=".jpg, .jpeg, .png"
        name="file"
        @change="handlefileUpload()"
      />
      <div class="file-uploader__input--hidden">
        <input
          ref="fileUpload"
          class="file-uploader__input--hidden"
          type="file"
          placeholder="Avatar"
          accept=".jpg, .jpeg, .png"
          name="file"
          @change="handlefileUpload()"
        />
      </div>
      <div class="file-uploader__upload">
        <PlusIcon class="file-uploader__upload-icon" size="3x"></PlusIcon>
        <div class="file-uploader__upload-text">ADD PHOTO</div>
      </div>
    </div>
    <div class="file-uploader__upload-label">
      Drag and drop or
      <input
        ref="fileUploadInput"
        class="file-uploader__input"
        type="file"
        placeholder="Avatar"
        accept=".jpg, .jpeg, .png"
        name="file"
        @change="handlefileUpload()"
      />
      <span>browse</span>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'FileUploader',
  components: {
    PlusIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDragover: false,
      fileUpload: '',
      fileUploadFiles: [],
    }
  },
  computed: {
    isAdvancedUpload() {
      const div = document.createElement('div')
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },
  },
  created() {},
  methods: {
    dragoverEvent() {
      this.isDragover = true
    },
    dragleaveEvent() {
      this.isDragover = false
    },
    dropEvent(e) {
      if (this.isAdvancedUpload) {
        const droppedFiles = e.dataTransfer.files
        this.fileUploadFiles = droppedFiles
        this.handlefileUpload(e)
      }
    },
    async handlefileUpload(e) {
      const fileUpload = e
        ? this.fileUploadFiles[0]
        : this.$refs.fileUploadInput.files[0] ||
          this.$refs.fileUploadInputAdd.files[0]
      this.$emit('handle-file-upload', fileUpload)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-uploader {
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  &__upload {
    pointer-events: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--white);
    font-size: 12px;
    line-height: 14px;
    &-icon {
      width: 38px;
      margin-bottom: 10px;
      pointer-events: none;
    }
    &-label {
      color: var(--light-blue-gray);
      span {
        cursor: pointer;
        color: var(--white);
        text-decoration: underline;
      }
    }
    &-area-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px dashed var(--perfect-gray);
      height: 243px;
      cursor: pointer;
      margin-bottom: 10px;
      @include mobile {
        height: 340px;
      }
      &:hover {
        background: var(--glass-extra-light);
      }
      &:active {
        background: var(--glass-dark);
      }
    }
    &-avatar {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      appearance: none;
      font-size: 0;
      z-index: 400;
      margin: 0;
      &:hover + button {
        --btn-border-color: var(
          --btn-hover-border-color
        ) !important; // emulating natural secondary button hover style
      }
    }
  }
  &__has-advanced-upload {
    outline: 2px dashed black;
    outline-offset: -10px;
    .file-uploader__dragndrop {
      display: inline;
    }
  }
  &__is-dragover {
    opacity: 0.6;
  }
  &__input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 50px;
    &--area {
      width: 100%;
      height: 100%;
    }
    &--hidden {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
    }
  }
}
</style>
