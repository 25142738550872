export const eventsConstants = {
  CONTENT_IFRAME_STARTED: 'content.iframe.started',
  CONTENT_IFRAME_MEDIA_REQUESTED: 'content.iframe.media.requested',
  CONTENT_IFRAME_FINISHED: 'content.iframe.finished',
  CONTENT_VIDEO_STARTED: 'content.video.started',
  CONTENT_VIDEO_MEDIA_REQUESTED: 'content.video.media.requested',
  CONTENT_VIDEO_FINISHED: 'content.video.finished',
  CONTENT_QUIZ_STARTED: 'content.quiz.started',
  CONTENT_QUIZ_FINISHED: 'content.quiz.finished',
  CONTENT_COURSE_ACTIVE_ADDED: 'content.course.active.added',
  CONTENT_COURSE_ACTIVE_REMOVED: 'content.course.active.removed',
  CONTENT_EXERCISE_SUBMITTED: 'content.exercise.submitted',
  CONTENT_EXERCISE_FINISHED: 'content.exercise.finished',
  CONTENT_LAB_SESSION_STARTED: 'content.lab_session.started',
  CONTENT_LAB_SESSION_STOPPED: 'content.lab_session.stopped',
  CONTENT_LAB_SESSION_ERROR: 'content.lab_session.error',
  CONTENT_LAB_STARTED: 'content.lab.started',
  CONTENT_LAB_FINISHED: 'content.lab.finished',
  CONTENT_ISSUE_SENT: 'content.issue.sent',
  CONTENT_RATING_SENT: 'content.rating.sent',
  CONTENT_LINK_VISITED: 'content.link.visited',

  SUBSCRIPTION_PAYMENT_RECURLY_ERROR:
    'subscription.payment.update.recurly.error',

  ACCOUNT_USER_LOGIN: 'account.user.login',

  BOOTCAMP_REGISTRATION: 'bootcamp.user.registration',

  CERTIFICATION_ATTEMPT_CREATED: 'certifications.attempt.created',
  CERTIFICATION_ATTEMPT_RESUMED: 'certifications.attempt.resumed',
  CERTIFICATION_ATTEMPT_FINISHED: 'certifications.attempt.finished',
  CERTIFICATION_ATTEMPT_SCORED: 'certifications.attempt.scored',
  CERTIFICATION_PRACTICAL_SUBMIT: 'certifications.practical.submit',
}

export const eventsList = {
  [eventsConstants.CONTENT_IFRAME_STARTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'iframe_id',
    'iframe_name',
    'type',
  ],
  [eventsConstants.CONTENT_IFRAME_MEDIA_REQUESTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'iframe_id',
    'iframe_name',
    'type',
  ],
  [eventsConstants.CONTENT_IFRAME_FINISHED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'iframe_id',
    'iframe_name',
    'type',
  ],
  [eventsConstants.CONTENT_VIDEO_STARTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'video_id',
    'video_name',
    'type',
  ],
  [eventsConstants.CONTENT_VIDEO_MEDIA_REQUESTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'video_id',
    'video_name',
    'type',
    'trigger',
  ],
  [eventsConstants.CONTENT_VIDEO_FINISHED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'video_id',
    'video_name',
    'type',
  ],
  [eventsConstants.CONTENT_QUIZ_STARTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'quiz_id',
  ],
  [eventsConstants.CONTENT_QUIZ_FINISHED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'quiz_id',
  ],
  [eventsConstants.CONTENT_COURSE_ACTIVE_ADDED]: [
    'course_id',
    'course_name',
    'trigger',
  ],
  [eventsConstants.CONTENT_COURSE_ACTIVE_REMOVED]: [
    'course_id',
    'course_name',
    'trigger',
  ],
  [eventsConstants.CONTENT_EXERCISE_SUBMITTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'exercise_id',
    'exercise_name',
  ],
  [eventsConstants.CONTENT_EXERCISE_FINISHED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'exercise_id',
    'exercise_name',
  ],
  [eventsConstants.CONTENT_LAB_SESSION_STARTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'lab_id',
    'lab_name',
    'lab_type',
    'lab_context',
  ],
  [eventsConstants.CONTENT_LAB_SESSION_STOPPED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'lab_id',
    'lab_name',
    'lab_type',
    'lab_context',
  ],
  [eventsConstants.CONTENT_LAB_SESSION_ERROR]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'lab_id',
    'lab_name',
    'lab_type',
    'lab_context',
    'error_code',
  ],
  [eventsConstants.CONTENT_LAB_STARTED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'lab_id',
    'lab_name',
    'lab_context',
    'type',
  ],
  [eventsConstants.CONTENT_LAB_FINISHED]: [
    'course_id',
    'course_name',
    'learning_path_id',
    'learning_path_name',
    'bootcamp_id',
    'bootcamp_name',
    'lab_id',
    'lab_name',
    'lab_context',
    'type',
  ],
  [eventsConstants.CONTENT_LINK_VISITED]: [
    'playlist_id',
    'playlist_name',
    'link_context',
    'link_name',
    'link_status',
    'link_id',
    'type',
  ],
  [eventsConstants.CONTENT_ISSUE_SENT]: [
    'course_id',
    'content_subtype',
    'content_type',
    'content_id',
    'subject',
  ],
  [eventsConstants.CONTENT_RATING_SENT]: [
    'course_id',
    'course_name',
    'instructor_id',
    'instructor_name',
    'confirmed',
  ],

  [eventsConstants.SUBSCRIPTION_PAYMENT_RECURLY_ERROR]: ['error_fields'],

  [eventsConstants.ACCOUNT_USER_LOGIN]: 'account.user.login',

  [eventsConstants.BOOTCAMP_REGISTRATION]: [
    'bootcamp_id',
    'bootcamp_name',
    'instructor_name',
    'instructor_id',
    'start_datetime',
    'end_datetime',
    'occurrences',
  ],

  [eventsConstants.CERTIFICATION_ATTEMPT_CREATED]: [
    'certification_id',
    'certification_name',
    'attempt_id',
    'created',
    'expiration_date',
    'learning_area_id',
    'learning_area_name',
    'latest_version',
    'is_beta',
    'certification_status',
    'attempt_status',
  ],

  [eventsConstants.CERTIFICATION_ATTEMPT_RESUMED]: [
    'certification_id',
    'certification_name',
    'attempt_id',
    'created',
    'expiration_date',
    'learning_area_id',
    'learning_area_name',
    'latest_version',
    'is_beta',
    'certification_status',
    'attempt_status',
  ],

  [eventsConstants.CERTIFICATION_ATTEMPT_FINISHED]: [
    'certification_id',
    'certification_name',
    'attempt_id',
    'created',
    'expiration_date',
    'learning_area_id',
    'learning_area_name',
    'latest_version',
    'is_beta',
    'certification_status',
    'attempt_status',
    'finished_at',
    'has_passed',
  ],

  [eventsConstants.CERTIFICATION_ATTEMPT_SCORED]: [
    'certification_id',
    'certification_name',
    'attempt_id',
    'created',
    'expiration_date',
    'learning_area_id',
    'learning_area_name',
    'latest_version',
    'is_beta',
    'certification_status',
    'attempt_status',
    'finished_at',
    'has_passed',
    'score',
  ],

  [eventsConstants.CERTIFICATION_PRACTICAL_SUBMIT]: [
    'attempt_id',
    'certification_id',
    'certification_name',
    'lab_id',
  ],
}
