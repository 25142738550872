import { GetterTree } from 'vuex'
import { ICoursesState, IRootState } from '@/@types'

const getters: GetterTree<ICoursesState, IRootState> = {
  activeCourses: (state: ICoursesState) => state.activeCourses,
  currentLevels: (state: ICoursesState) => state.currentLevels,
  currentCourse: (state: ICoursesState) => state.currentCourse,
  currentCourseProgress: (state: ICoursesState) => state.currentCourseProgress,
}

export default getters
