<template>
  <header class="mobile-navbar" role="navigation">
    <transition name="fadeHeight">
      <MaintenanceScheduledBannerVue v-if="scheduledMaintenance" />
    </transition>
    <nav
      :class="[
        'mobile-navbar__wrapper',
        scheduledMaintenance && 'mobile-navbar__wrapper-maintenance',
      ]"
    >
      <div class="mobile-navbar">
        <div class="mobile-navbar__logo-wrapper">
          <router-link :to="{ name: 'home' }" replace>
            <img
              class="mobile-navbar__ine-logo"
              src="@/assets/images/home-logo.png"
              alt="INE Logo"
            />
          </router-link>
        </div>
        <div
          :class="[
            'mobile-navbar__open-menu',
            isMegaMenuShown && 'mobile-navbar__open-menu--opened',
          ]"
          @click="toggleMegaMenu()"
        >
          <ChevronDownIcon class="mobile-navbar__menu-icon" />
        </div>
        <div class="mobile-navbar__right-section">
          <div class="mobile-navbar__switch-search-wrapper">
            <div
              v-if="isAccOwnerOrTeamManager"
              class="mobile-navbar__switch-container"
            >
              <SwitchToBusiness />
            </div>
            <SearchIcon
              class="mobile-navbar__icon"
              @click="toggleSearchForm()"
            />
          </div>
          <SearchForm
            class="mobile-navbar__search-form"
            :isSearchFormShown="isSearchFormShown"
            @closed="closeSearchForm()"
          />
          <Profile
            v-click-outside="closeProfileMenu"
            :isAuthenticated="isAuthenticated"
            :profile="profile"
            :isProfileMenuShown="isProfileMenuShown"
            class="mobile-navbar__user-profile"
            @click.native="openProfileMenu()"
          />
        </div>
      </div>
      <SkillDiveTopBar v-if="showSkillDive" />

      <MegaMenu
        v-show="isMegaMenuShown"
        v-click-outside="closeMegaMenu"
        :primaryAreas="primaryAreas"
        :secondaryAreas="secondaryAreas"
        :isAttackDefenseUser="isAttackDefenseUser"
        @close-mega-menu="closeMegaMenu"
        @mouseleave="closeMegaMenu()"
      />
    </nav>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchForm from '@/components/forms/SearchForm.vue'
import { ChevronDownIcon, SearchIcon } from 'vue-feather-icons'
import MegaMenu from './MobileMegaMenu.vue'
import Profile from './Profile.vue'
import SwitchToBusiness from './SwitchToBusiness.vue'
import SkillDiveTopBar from '@/components/lab-experience/SkillDiveTopBar.vue'
import MaintenanceScheduledBannerVue from '@/components/banners/MaintenanceScheduledBanner.vue'

export default {
  name: 'MobileNavBar',
  components: {
    SearchForm,
    MegaMenu,
    Profile,
    SkillDiveTopBar,
    ChevronDownIcon,
    SearchIcon,
    SwitchToBusiness,
    MaintenanceScheduledBannerVue,
  },
  props: {
    showSkillDive: {
      type: Boolean,
      required: true,
    },
    scheduledMaintenance: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isMegaMenuShown: false,
      isSearchFormShown: false,
      isProfileMenuShown: false,
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      profile: state => state.auth.userProfile,
    }),
    ...mapGetters({
      primaryAreas: 'learningAreas/primaryAreas',
      secondaryAreas: 'learningAreas/secondaryAreas',
      isAttackDefenseUser: 'subscriptions/isADUser',
      isAccOwnerOrTeamManager: 'auth/isAccOwnerOrTeamManager',
      isSkillDiveB2CUser: 'auth/isSkillDiveB2CUser',
    }),
  },
  methods: {
    toggleMegaMenu() {
      this.isMegaMenuShown = !this.isMegaMenuShown
      if (this.isMegaMenuShown) {
        this.closeSearchForm()
        this.closeProfileMenu()
      }
    },
    toggleSearchForm() {
      this.isSearchFormShown = !this.isSearchFormShown
      if (this.isSearchFormShown) {
        this.closeMegaMenu()
        this.closeProfileMenu()
      }
    },
    openProfileMenu() {
      this.isProfileMenuShown = true
      this.closeSearchForm()
      this.closeMegaMenu()
    },
    closeSearchForm() {
      this.isSearchFormShown = false
    },
    closeMegaMenu(event) {
      if (event?.target?.classList?.contains('mobile-navbar__menu-icon')) {
        return
      }
      this.isMegaMenuShown = false
    },
    closeProfileMenu() {
      this.isProfileMenuShown = false
    },
  },
}
</script>

<style lang="scss">
.mobile-navbar {
  max-width: 100vw;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  background-color: var(--black);

  @include tablet {
    display: none;
  }
  &__right-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 202;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    &-maintenance {
      top: 70px;

      @include mobile {
        top: 81px;
      }
    }
  }
  &__user-profile {
    margin-left: 0;
  }
  &__switch-search-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__switch-container {
    display: flex;
    align-items: center;
  }
  &__search-form {
    position: absolute;
    background: black;
    width: 100%;
    height: 54px;
    left: 0;
    top: 63px;
  }
  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__ine-logo {
    width: 76.23px;
  }
  &__open-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 16px;
    color: var(--white);
    margin-left: 20px;
    svg {
      width: 16px;
      transition: transform 0.1s ease-out;
    }
    &--opened {
      svg {
        transform: rotate(180deg);
        transition: transform 0.1s ease-out;
      }
    }
    @media only screen and (max-width: 361px) {
      margin-left: 5px;
    }
  }
  &__mega-menu-btn {
    background: transparent;
    color: var(--white);
    border: none;
    font-size: 1rem;
  }
  &__icon {
    width: auto;
    margin-left: auto;
    margin-right: 5px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    color: var(--white);
    padding: 10px;
    height: 44px;
    border-radius: 5px;
    cursor: pointer;
    &:hover,
    &:active {
      background: var(--white);
      color: var(--charcoal);
    }
    &:target {
      background: var(--athens-gray);
      color: var(--charcoal);
    }
    @media only screen and (max-width: 361px) {
      padding: 7px;
      height: 28px;
    }
  }
}
</style>
