import { ActionContext, ActionTree } from 'vuex'
import { ILab, ILabStatus, ILabsState, IRootState } from '@/@types'
import {
  fetchLab,
  fetchLabSession,
  fetchLabSessionVPNFile,
  fetchRunningLab,
  patchLabSession,
  postLabFlag,
  postLabPing,
  postLabPractice,
  postLabSession,
  postLabStatuses,
  postExtendLab,
  resetLabFlags,
} from '@/models/labs'

import CONSTANTS from '@/constants'
import { errorHandler } from '@/utils'
import { getParentFromRootState } from '@/helpers'

const actions: ActionTree<ILabsState, IRootState> = {
  getLabById: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      id: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const response = await fetchLab(payload)
      context.commit('CURRENT_LAB_UPDATED', response)
      context.commit('CURRENT_LAB_SESSION_UPDATED', response.session)
      context.commit('UPDATE_USER_FLAGS_STATUS', response.user_flags)
      if (response.session) {
        context.commit('content/UPDATE_LAB_STATUS_ON_LIST', response.session, {
          root: true,
        })
      }
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  createLabSession: async (
    context: ActionContext<ILabsState, IRootState>,
    { id, region, keyboard_layout, parentId, parentType }
  ) => {
    try {
      const payload = {
        id,
        region,
        keyboard_layout,
        parentId,
        parentType,
      }
      const response = await postLabSession(payload)
      if (!response.errorCode)
        context.commit('CURRENT_LAB_SESSION_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  createLabPing: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      id: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      await postLabPing(payload)
    } catch (error) {
      return error
    }
  },

  getLabSession: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      labId: string
      sessionId: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const response = await fetchLabSession(payload)
      context.commit('CURRENT_LAB_SESSION_UPDATED', response)
      if (response) {
        context.commit('content/UPDATE_LAB_STATUS_ON_LIST', response, {
          root: true,
        })
      }
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  partialUpdateLabSession: async (
    context: ActionContext<ILabsState, IRootState>,
    {
      labId,
      sessionId,
      status,
      cleanGlobalSession,
      parentId,
      parentType,
    }: {
      labId: string
      sessionId: string
      status: string
      cleanGlobalSession: boolean
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const response = await patchLabSession({
        labId,
        sessionId,
        status,
        parentId,
        parentType,
      })
      if (cleanGlobalSession) {
        context.commit('CURRENT_LAB_GLOBAL_SESSION_UPDATED', null)
      } else {
        if (!response.errorCode)
          context.commit('CURRENT_LAB_SESSION_UPDATED', response)
      }

      switch (status) {
        case CONSTANTS.LABS.SESSION_STATUS.STARTED:
          context.dispatch('labs/getRunningLab')
          break
        case CONSTANTS.LABS.SESSION_STATUS.TERMINATED:
          context.commit('RUNNING_LAB_SESSION_UPDATED', null)
          context.commit('CURRENT_LAB_GLOBAL_SESSION_UPDATED', null)
          context.commit('DECREMENT_CURRENT_LAB_AVAILABLE_RESETS')
          break
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },

  getLabSessionVPNFile: async (
    context: ActionContext<ILabsState, IRootState>,
    {
      labId,
      sessionId,
      parentId,
      parentType,
    }: {
      labId: string
      sessionId: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const payload = {
        labId,
        sessionId,
        parentId,
        parentType,
      }
      const response = await fetchLabSessionVPNFile(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  setFocusLabId: (
    context: ActionContext<ILabsState, IRootState>,
    labId: string
  ) => {
    context.commit('FOCUSED_LAB_ID_UPDATED', labId)
  },

  sendPTAFlag: async (
    context: ActionContext<ILabsState, IRootState>,
    { flagId, answer, parentId, parentType }
  ) => {
    try {
      const payload = {
        labId: context.state.currentLab?.id,
        flagId,
        answer,
        parentId,
        parentType,
      }
      const response = await postLabFlag(payload)
      context.commit('UPDATE_USER_FLAGS_STATUS', response.flags)
      context.commit('content/LAB_STATUS_UPDATED', response, {
        root: true,
      })
      context.commit('LAB_STATUS_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  sendPTAPractice: async (
    context: ActionContext<ILabsState, IRootState>,
    objectivesIds: Array<string>
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        labId: context.state.currentLab?.id,
        sessionId: context.state.currentLabSession?.id,
        lab_objectives_ids: objectivesIds,
        parentType,
        parentId,
      }
      const response = await postLabPractice(payload)
      context.commit(
        'LAB_OBJETIVES_ATTEMPT_UPDATED',
        response?.user_lab_attempt
      )
      context.commit('LAB_USER_STATUS_UPDATED', response?.user_lab_status)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  updateCurrentLab: (
    context: ActionContext<ILabsState, IRootState>,
    lab: ILab
  ) => {
    context.commit('CURRENT_LAB_UPDATED', lab)
    context.commit('CURRENT_LAB_SESSION_UPDATED', lab.session)
    context.commit('UPDATE_USER_FLAGS_STATUS', lab.user_flags)
  },
  getLabByIdWithoutContext: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      id: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const response = await fetchLab(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  setCurrentHoveredObjectiveInfo: (
    context: ActionContext<ILabsState, IRootState>,
    id: string
  ) => {
    context.commit('CURRENT_HOVERED_OBJECTIVE_INFO_UPDATED', id)
  },
  getRunningLab: async (
    context: ActionContext<ILabsState, IRootState>,
    payload?: {
      parentType: string
      parentId: string
    }
  ) => {
    try {
      const labSession = await fetchRunningLab(payload)
      context.commit('RUNNING_LAB_SESSION_UPDATED', labSession)
      return labSession
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  updateLabStatus: (
    context: ActionContext<ILabsState, IRootState>,
    labStatus: ILabStatus
  ) => {
    context.commit('CURRENT_LAB_STATUS_UPDATED', labStatus)
  },
  updateLabStatuses: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: { ids: string[] }
  ) => {
    const labStatusesResponse = await postLabStatuses(payload)
    context.commit('UPDATE_LAB_STATUSES', labStatusesResponse.results)
  },
  extendLabSession: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      labId: string
      sessionId: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      context.dispatch(
        'app/setNotification',
        'Please wait while we extend the lab session.',
        {
          root: true,
        }
      )
      await postExtendLab(payload)
      let attempts = 0
      const maxAttempts = 7
      const checkStatus = async (): Promise<void> => {
        const fetchResponse = await context.dispatch('getLabSession', payload)
        if (
          fetchResponse.metadata.extend_status ===
          CONSTANTS.LABS.EXTEND_STATUSES.SUCCESS
        ) {
          context.dispatch(
            'app/setSuccess',
            'Your lab session has been extended by an additional 30 minutes',
            {
              root: true,
            }
          )
          return
        } else if (
          fetchResponse.metadata.extend_status ===
          CONSTANTS.LABS.EXTEND_STATUSES.ERROR
        ) {
          context.dispatch(
            'app/setError',
            fetchResponse.metadata.pta_error_message,
            {
              root: true,
            }
          )
          return
        } else {
          if (attempts < maxAttempts) {
            attempts++
            await new Promise(resolve => setTimeout(resolve, 5000))
            return checkStatus()
          }
        }
      }
      return await checkStatus()
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  resetUserFlags: async (
    context: ActionContext<ILabsState, IRootState>,
    payload: {
      labId: string
      parentId: string
      parentType: string
    }
  ) => {
    try {
      const currentLab = context.state.currentLab
      if (currentLab?.user_flags) {
        // initial loading state with empty answers
        const updatedFlags = (
          currentLab.user_flags as Array<{
            uuid: string
            has_passed: boolean
            user_value: string
          }>
        ).map(flag => ({
          ...flag,
          has_passed: false,
          user_value: '',
        }))
        context.commit('UPDATE_USER_FLAGS_STATUS', updatedFlags)
        const resp = await resetLabFlags(payload)
        context.commit('UPDATE_USER_FLAGS_STATUS', resp.flags)
      }
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
