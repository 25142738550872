<template>
  <div class="profile-progress">
    <div v-if="!isProfileCompleted">
      <svg
        v-for="(status, index) in progressStatus"
        :key="index"
        :class="[
          'profile-progress__ellipsis',
          status && 'profile-progress__ellipsis--completed',
          ellipsisClass(index),
        ]"
        width="100%"
        height="100%"
        viewBox="-25 -25 400 400"
      >
        <circle
          cx="177"
          cy="177"
          r="188"
          stroke-dasharray="1100"
          stroke-width="20"
          stroke-dashoffset="840"
          stroke-linecap="round"
          fill="none"
        ></circle>
      </svg>
    </div>
    <button class="profile-progress__toggle-btn" type="button">
      <img
        v-if="showAvatar"
        :src="currentAvatar"
        :class="[
          'profile-progress__image',
          isProfileCompleted && 'profile-progress__image--completed',
        ]"
        alt="Icon representing the user"
      />
      <span v-else class="profile-progress__image-user-letter">{{
        nameLetters(userProfile)
      }}</span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isCurrentDefaultAvatar, getEntities } from '@/helpers'

export default {
  name: 'ProfileProgress',
  props: {
    currentAvatar: {
      type: String,
      required: true,
    },
  },
  methods: {
    ellipsisClass(index) {
      return `profile-progress__ellipsis--${(index + 1) * 25}`
    },
    nameLetters({ first_name, last_name }) {
      return getEntities.getFullnameLetters(first_name, last_name)
    },
  },
  computed: {
    ...mapState({
      onboardingSurvey: state => state.auth.onboardingSurvey,
      userProfile: state => state.auth.userProfile,
    }),
    isProfileCompleted() {
      return this.onboardingSurvey?.completedPercentage === 100
    },
    progressStatus() {
      let itemsCount = this.onboardingSurvey?.itemsChecked
      const totalItems = this.onboardingSurvey?.totalItems || 4
      const progress = []
      for (let i = 0; i < totalItems; i++) {
        const value = !!itemsCount
        progress.push(value)
        itemsCount = itemsCount > 0 ? --itemsCount : 0
      }
      return progress
    },
    showAvatar() {
      return !isCurrentDefaultAvatar(this.currentAvatar)
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-progress {
  position: relative;
  &__toggle-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    color: var(--white);
    transition: 0.5s all ease;
    width: 100%;
    padding: 0;
    &:hover {
      cursor: pointer;
      #{this}__image {
        opacity: 0.8;
      }
    }
  }
  &__image {
    width: 100%;
    min-width: 45px;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    transition: 0.5s all ease;
    padding: 12%;
    &--completed {
      padding: 0;
    }
    &-user-letter {
      display: flex;
      width: 87%;
      height: 87%;
      border-radius: 100%;
      justify-content: center;
      align-items: center;

      font-size: 80%;
      background: var(--fog);
      font-weight: bolder;
      color: var(--purple-dark);
    }
  }
  &__ellipsis {
    position: absolute;
    stroke: rgba(128, 128, 128, 0.329);
    &--25 {
      transform: rotate(277deg);
    }
    &--50 {
      transform: rotate(7deg);
    }
    &--75 {
      transform: rotate(97deg);
    }
    &--100 {
      transform: rotate(187deg);
    }
    &--completed {
      stroke: var(--orange);
    }
  }
}
</style>
