<template>
  <div class="ine-form__error-wrapper">
    <p
      :class="['ine-form__error', onlyOneField && 'ine-form__error--one-field']"
    >
      <AlertCircleIcon class="ine-form__error-icon" />{{ errors }}
    </p>
  </div>
</template>

<script>
import { AlertCircleIcon } from 'vue-feather-icons'

export default {
  name: 'Errors',
  components: {
    AlertCircleIcon,
  },
  props: {
    errors: {
      type: String,
      required: true,
    },
    onlyOneField: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style></style>
