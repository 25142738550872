import { djangoErrorHandler } from '@/helpers'
import { CONTENT } from '@/clients'

const fetchPlaylists = async () => {
  const route = `playlists`
  try {
    const { data } = await CONTENT.get(route)
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchPlaylistById = async id => {
  const route = `playlists/${id}`
  try {
    const { data } = await CONTENT.get(route, ['Resource not found'])
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

const fetchPlaylistChangelogById = async id => {
  const route = `playlists/${id}/content-changes`
  try {
    const { data } = await CONTENT.get(route, ['Resource not found'])
    return data
  } catch (error) {
    throw djangoErrorHandler(error)
  }
}

export { fetchPlaylists, fetchPlaylistById, fetchPlaylistChangelogById }
