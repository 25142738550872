<template>
  <ul v-if="isLearningAreasActive" class="mobile-menu__sub-menu">
    <template v-for="area in areas">
      <router-link
        :key="`mobile-cat-${area.id}`"
        :to="{ name: 'learning-area', params: { id: area.id } }"
        class="mobile-menu__sub-link-wrapper"
        @click.native="$emit('close-mega-menu')"
      >
        <div class="mobile-menu__sub-link">
          <Passes
            class="mobile-menu__sub-link-pass"
            :passName="area.name"
            :laNoBorders="true"
          />
          {{ area.name }}
          <div class="mobile-menu__sub-link-arrow-wrapper">
            <ArrowRightIcon class="mobile-menu__sub-link-arrow"
              >keyboard_arrow_right</ArrowRightIcon
            >
          </div>
        </div>
      </router-link>
    </template>
  </ul>
</template>

<script>
import { ArrowRightIcon } from 'vue-feather-icons'
import Passes from '@/components/properties/Passes.vue'

export default {
  name: 'Categories',
  components: {
    ArrowRightIcon,
    Passes,
  },
  props: {
    primaryAreas: {
      type: Array,
      required: true,
    },
    secondaryAreas: {
      type: Array,
      required: true,
    },
    isLearningAreasActive: {
      type: Boolean,
      required: true,
    },
    currentCategory: {
      type: String,
      required: true,
    },
  },
  computed: {
    areas() {
      const { primaryAreas, secondaryAreas } = this
      return [...primaryAreas, ...secondaryAreas]
    },
  },
}
</script>

<style lang="scss" scoped>
$mobile: 320px;
$tablet: 769px;
$desktop: 1024px;
$lg_desktop: 1300px;
.mobile-menu {
  $this: &;
  &__sub-menu {
    margin: 0;
    padding: 0;
  }
  &__sub-link-wrapper {
    position: relative;
    color: var(--white);
  }
  &__sub-link {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 0 20px;
    position: relative;
    border-radius: 5px;
    &:hover {
      background: var(--glass-extra-light);
      & .mobile-menu__sub-link-arrow {
        display: block;
      }
    }
  }
  &__sub-link-active {
    position: absolute;
    width: 8px;
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--orange);
    opacity: 0;
    transition: all 0.2s ease-out;
    &--active {
      opacity: 1;
    }
  }
  &__sub-link-arrow {
    display: none;
    width: 15px;
    height: 20px;
  }
  &__sub-link-arrow-wrapper {
    margin-left: auto;
  }
  &__sub-link-pass {
    width: 19px;
    margin-right: 16.5px;
  }
}
</style>
