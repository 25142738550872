import { ActionContext, ActionTree } from 'vuex'
import { ILearningPathsState, IRootState } from '@/@types'
import {
  fetchLearningPaths,
  fetchNextContent,
  fetchSelectedLearningPath,
} from '../../../models/learning-paths'

import { errorHandler } from '@/utils'

const actions: ActionTree<ILearningPathsState, IRootState> = {
  getLearningPaths: async (
    context: ActionContext<ILearningPathsState, IRootState>
  ) => {
    try {
      const response = await fetchLearningPaths()
      context.commit('LEARNING_PATHS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getCurrentLearningPath: async (
    context: ActionContext<ILearningPathsState, IRootState>,
    id: string
  ) => {
    try {
      const response = await context.dispatch('getLearningPathById', id)

      context.commit('CURRENT_LEARNING_PATH_UPDATED', response)

      if (response.user_status) {
        context.commit('LEARNING_PATH_PROGRESS_UPDATED', response.user_status)
      } else {
        context.commit('LEARNING_PATH_PROGRESS_UPDATED', {})
      }
      return response
    } catch (error) {
      return error
    }
  },

  getLearningPathById: async (
    context: ActionContext<ILearningPathsState, IRootState>,
    id: string
  ) => {
    try {
      const response = await fetchSelectedLearningPath(id)
      context.commit('LEARNING_PATHS_CACHE_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      return error
    }
  },

  getSelectedStarterPassLearningPath: async (
    context: ActionContext<ILearningPathsState, IRootState>,
    id: string
  ) => {
    try {
      const response = await fetchSelectedLearningPath(id)
      context.commit('CURRENT_LEARNING_PATH_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      return error
    }
  },

  getNextContent: async (
    context: ActionContext<ILearningPathsState, IRootState>,
    pathId: string
  ) => {
    try {
      const next = await fetchNextContent(pathId)
      context.commit('LEARNING_PATHS_NEXT_CONTENT_CACHE_UPDATED', {
        pathId,
        next,
      })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
}

export default actions
