import { GetterTree } from 'vuex'
import { IStatisticsState, IRootState } from '@/@types'
import { format, parseISO } from 'date-fns'
const getters: GetterTree<IStatisticsState, IRootState> = {
  trainingHistoryStats: state => {
    const { trainingHistoryStats: stats } = state
    if (stats && stats.all_courses_video_time_watched) {
      return stats.all_courses_video_time_watched
    }
    return stats
  },
  formattedChart: state => {
    const {
      yearlyStats: { time_watched_chart: chart },
    } = state
    if (chart && Object.keys(chart).length) {
      const chartArray: Array<Object> = []
      Object.entries(chart).forEach(entry => {
        const [key, value] = entry
        const date = parseISO(key)
        const month = format(date, 'MMMM')
        chartArray.push({
          month: month?.charAt(0),
          monthLabel: month,
          value: value,
        })
      })
      return chartArray
    }
    return chart
  },
}

export default getters
