<template>
  <footer class="footer" role="contentinfo">
    <div class="footer__top-wrapper">
      <section class="footer__top">
        <div
          v-for="linkGroup in linkGroups"
          :key="linkGroup.title.trim()"
          class="footer__links-list"
        >
          <div class="footer__links-list-title">{{ linkGroup.title }}</div>
          <nav class="footer__links-list-nav">
            <template v-for="linkItem in linkGroup.items">
              <div
                v-if="linkItem.showLink"
                :key="linkItem.label.trim()"
                class="footer__links-list-item"
              >
                <router-link
                  v-if="linkItem.name"
                  :to="{
                    name: linkItem.name,
                    params: linkItem.params,
                  }"
                >
                  {{ linkItem.label }}
                </router-link>
                <a
                  v-if="linkItem.href"
                  target="_blank"
                  rel="noopener"
                  :href="linkItem.href"
                >
                  {{ linkItem.label }}
                </a>
              </div>
            </template>
          </nav>
        </div>
        <div class="footer__community-section">
          <div class="footer__community-title">
            {{ communitySection.title }}
          </div>
          <div class="footer__community-text">
            {{ communitySection.text }}
          </div>
          <div class="footer__community-button">
            <a
              :href="communitySection.button.href"
              target="_blank"
              rel="noopener nofollow"
              class="btn btn--primary footer__community-button-cta"
            >
              {{ communitySection.button.label }}
            </a>
          </div>
        </div>
      </section>
    </div>
    <div class="footer__bottom-wrapper">
      <section class="footer__bottom">
        <div class="footer__all-rights-reserved">
          {{ allRightsReservedText }}
        </div>
        <div class="footer__links-policies">
          <a
            v-for="linkPolicies in linkGroupPolicies"
            :key="linkPolicies.label.trim()"
            class="footer__links-policies-item"
            target="_blank"
            rel="noopener nofollow"
            :href="linkPolicies.href"
          >
            {{ linkPolicies.label }}
          </a>
        </div>
        <div class="footer__links-social">
          <a
            v-for="linkSocial in linksSocialGroup"
            :key="linkSocial.name.trim()"
            class="footer__links-social-item"
            target="_blank"
            rel="noopener nofollow"
            :href="linkSocial.href"
          >
            <component
              :is="linkSocial.asset"
              :class="`footer__links-social-item--${linkSocial.name}`"
            />
          </a>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
import { getYear } from 'date-fns'
import { mapState } from 'vuex'
import SocialFacebook from '@/assets/icons/social/social-facebook.svg'
import SocialTwitter from '@/assets/icons/social/social-twitter.svg'
import SocialLinkedin from '@/assets/icons/social/social-linkedin.svg'
import SocialYoutube from '@/assets/icons/social/social-youtube.svg'
import SocialInstagram from '@/assets/icons/social/social-instagram.svg'

export default {
  name: 'Footer',
  data() {
    return {
      communitySection: {
        title: 'Join the community',
        text: `Interact with instructors, students, and IT experts in the INE Community!`,
        button: {
          label: 'INE Community',
          href: 'https://community.ine.com/',
        },
      },
      linkGroupPolicies: [
        {
          href: 'https://ine.com/terms-of-service',
          label: 'Terms of service',
        },
        {
          href: 'https://checkout.shopify.com/17217507/policies/16723845.html',
          label: 'Privacy Policy',
        },
      ],
      allRightsReservedText: `© ${getYear(new Date())} INE.
        All Rights Reserved. All logos, trademarks and registered trademarks are the property of their respective owners.`,
      linksSocialGroup: [
        {
          name: 'facebook',
          href: 'https://www.facebook.com/inetraining/',
          asset: SocialFacebook,
        },
        {
          name: 'twitter',
          href: 'https://twitter.com/ine',
          asset: SocialTwitter,
        },
        {
          name: 'linkedin',
          href: 'https://www.linkedin.com/company/inetraining',
          asset: SocialLinkedin,
        },
        {
          name: 'youtube',
          href: 'https://www.youtube.com/user/INEtraining',
          asset: SocialYoutube,
        },
        {
          name: 'instagram',
          href: 'https://www.instagram.com/inetraining/',
          asset: SocialInstagram,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
    }),
    linkGroups() {
      return [
        {
          title: 'Helpful Links',
          items: [
            {
              name: 'user-dashboard',
              params: { mode: 'active-courses' },
              label: 'Active Courses',
              showLink: this.isAuthenticated,
            },
            {
              name: 'user-dashboard',
              params: { mode: 'bookmarks' },
              label: 'Bookmarks',
              showLink: this.isAuthenticated,
            },
            {
              name: 'user-dashboard',
              params: { mode: 'profile' },
              label: 'Profile',
              showLink: this.isAuthenticated,
            },
            {
              name: 'user-dashboard',
              params: { mode: 'certifications' },
              label: 'My Certifications',
              showLink: this.isAuthenticated,
            },
            {
              href: 'https://my.ine.com/certifications',
              label: 'Certifications',
              showLink: true,
            },
            {
              href: 'https://ine.com/learning/instructors',
              label: 'Instructors',
              showLink: true,
            },
            {
              href: 'https://careers.ine.com/',
              label: 'Careers',
              showLink: true,
            },
          ],
        },
        {
          title: 'Plans',
          items: [
            {
              href: 'https://ine.com/pricing',
              label: 'Plans & Pricing',
              showLink: true,
            },
            {
              href: 'https://ine.com/business-solutions',
              label: 'Business Solutions',
              showLink: true,
            },
          ],
        },
        {
          title: 'Support',
          items: [
            {
              href: 'https://releases.ine.com',
              label: 'Releases',
              showLink: true,
            },
            {
              href: 'https://ine.com/faqs',
              label: 'Help Center',
              showLink: true,
            },
            {
              href: 'https://ine.com/contact-us',
              label: 'Contact',
              showLink: true,
            },
            {
              name: 'faqs',
              label: 'Troubleshooting and FAQs',
              showLink: this.isAuthenticated,
            },
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  font-family: var(--font-medium);
  font-style: normal;
  font-weight: normal;
  color: var(--white);
  &__top,
  &__bottom {
    &-wrapper {
      background: var(--glass-mid-gray);
    }
    max-width: 1440px;
    margin: auto;
    padding: 50px 20px;
  }
  &__top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include tablet {
      grid-template-columns: repeat(5, 1fr);
      padding: 50px 24px;
    }
    @include desktop {
      padding: 50px 100px;
      grid-template-columns: repeat(6, 1fr);
    }
  }
  &__bottom {
    padding-bottom: 40px;
    @include tablet {
      padding: 40px 24px 24px;
    }
    @include desktop {
      display: grid;
      grid-template-columns: 57% 23% 20%;
      align-items: center;
      padding: 40px 100px 24px;
    }
  }
  &__links-list {
    margin-bottom: 20px;
    &:nth-of-type(1) {
      grid-column: 1 / 2;
      grid-row: 2 / 4;
      @include tablet {
        grid-column: 1 / 2;
        grid-row: 1;
      }
      @include desktop {
        grid-column: 1 / 2;
        grid-row: 1;
      }
    }
    &:nth-of-type(2) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      @include tablet {
        grid-column: 2 / 3;
        grid-row: 1;
      }
      @include desktop {
        grid-column: 2 / 3;
        grid-row: 1;
      }
    }
    &:nth-of-type(3) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      @include tablet {
        grid-column: 3 / 4;
        grid-row: 1;
      }
      @include desktop {
        grid-column: 3 / 4;
        grid-row: 1;
      }
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      color: var(--gray-storm);
    }
    &-item {
      font-size: 14px;
      line-height: 20px;
      padding: 4px 0;
      a {
        transition: padding 0.15s ease;
        color: var(--white);
        &:hover {
          padding-left: 5px;
        }
        &:visited {
          color: var(--white);
        }
      }
    }
  }
  &__community {
    &-section {
      grid-column: 1 / 7;
      grid-row: 1 / 2;
      @include tablet {
        grid-column: 4 / 6;
        grid-row: 1;
      }
      @include desktop {
        grid-column: 5 / 7;
        grid-row: 1;
      }
    }
    &-title {
      font-size: 12px;
      line-height: 14px;
      color: var(--chetwode-blue);
      margin-bottom: 15px;
    }
    &-text {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
      @include tablet {
        width: 95%;
      }
    }
    &-button-cta {
      height: 36px;
      font-size: 14px;
      line-height: 20px;
      color: var(--charcoal);
      padding: 8px 16px;
      margin-bottom: 30px;
      position: inherit;
    }
  }
  &__all-rights-reserved {
    font-size: 12px;
    line-height: 14px;
    @include tablet {
      text-align: center;
    }
  }
  &__links-policies {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 45px 0;
    @include desktop {
      padding: unset;
      grid-template-columns: repeat(2, 140px);
    }
    &-item {
      font-size: 14px;
      line-height: 20px;
      padding: 0 30px;
      @include desktop {
        padding: unset;
        text-align: center;
      }
      &:nth-of-type(1) {
        width: 100%;
        border-right: 1px var(--gray-storm) solid;
        text-align: right;
        padding-right: 30px;
        @include desktop {
          width: unset;
          border-right: unset;
          padding: unset;
          text-align: center;
        }
      }
      &:link,
      &:visited {
        color: var(--white);
      }
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
  &__links-social {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0 67.5px;
    max-width: 380px;
    margin: auto;
    @include tablet {
      padding: unset;
      justify-content: center;
      grid-template-columns: repeat(4, 52px) 42px;
    }
    @include desktop {
      padding: unset;
      justify-content: flex-end;
      grid-template-columns: repeat(4, 52px) 42px;
    }
    &-item {
      justify-self: center;
      width: 32px;
      height: 32px;
      background: var(--glass-light-gray);
      border-radius: 50%;
      transition: background 0.15s ease;
      svg path {
        transition: fill 0.15s ease;
      }
      @include desktop {
        margin: 0 10px;
      }
      &:last-child {
        @include desktop {
          margin: unset;
          margin-left: 10px;
        }
      }
      &:hover {
        background: var(--white);
        svg path {
          fill: var(--black);
        }
      }
      &--youtube {
        margin: 10px 8px;
      }
      &--instagram {
        margin: 7px;
      }
      &--facebook,
      &--twitter,
      &--linkedin {
        margin: 8px;
      }
    }
  }
}
</style>
