import pick from 'lodash/pick'
import { GetterTree } from 'vuex'
import { IFilesState, IRootState } from '@/@types'

const getters: GetterTree<IFilesState, IRootState> = {
  getCurrentFilesById: state => (ids: Array<string>) => {
    return pick(state.contentFiles, ids)
  },

  getCurrentFilesRendered: state => (ids: Array<string>) => {
    return pick(state.filesRendered, ids)
  },
}

export default getters
