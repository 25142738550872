var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"maintenance-scheduled-banner"},[_c('div',{class:[
      'maintenance-scheduled-banner__container',
      _vm.underway && 'maintenance-scheduled-banner__undergoing',
    ]},[_c('div',{staticClass:"maintenance-scheduled-banner__wrapper"},[_c('div',{staticClass:"maintenance-scheduled-banner__title maintenance-scheduled-banner__title-not-mobile"},[(!_vm.underway)?_c('AlertIcon',{staticClass:"maintenance-scheduled-banner__icon"}):_c('AlertTriangleIcon',{staticClass:"maintenance-scheduled-banner__icon maintenance-scheduled-banner__icon--white"}),_c('div',{staticClass:"maintenance-scheduled-banner__title-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('div',{class:[
          'maintenance-scheduled-banner__separator',
          _vm.underway && 'maintenance-scheduled-banner__separator--red',
        ]}),_c('div',{class:[
          'maintenance-scheduled-banner__info',
          _vm.underway && 'maintenance-scheduled-banner__info--undergoing',
        ]},[_c('span',{class:[
            'maintenance-scheduled-banner__title',
            'maintenance-scheduled-banner__title-mobile',
          ]},[_vm._v(_vm._s(_vm.title)+":")]),_vm._v(" To better serve you, INE "+_vm._s(_vm.underway ? 'is undergoing' : 'will undergo')+" a brief update "+_vm._s(_vm.underway ? '' : 'on')+" "),(!_vm.underway)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.maintenanceDate)+", at "+_vm._s(_vm.maintenanceTime)+" EST")]),_vm._v(" for "),_c('b',[_vm._v("1 hour")])]):_vm._e(),_vm._v(". Some services might be impacted "),(_vm.underway)?_c('span',[_vm._v("for the next "),_c('b',[_vm._v("1 hour")])]):_c('span',[_vm._v("during this time.")]),_vm._v(" Thank you for your patience. ")])]),(!_vm.underway)?_c('XIcon',{staticClass:"maintenance-scheduled-banner__close-icon",on:{"click":_vm.closeBanner}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }