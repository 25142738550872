import { ISonarState } from '@/@types'
import CONSTANTS from '@/constants'

const state: ISonarState = {
  userStatus: {
    attempts_per_learning_area: [],
    sonar_enabled: false,
  },
  sonarStatus: {
    currentLearningArea: {
      id: '',
      name: '',
      slug: '',
    },
    showStartAssessmentModal: false,
  },
  assessmentAttemptStatus: {
    isAssessmentRunning: false,
    isRequiredAssessment: false,
    current_step: CONSTANTS.SONAR.ASSESSMENT.STEP.SUMMARY,
  },
  currentScores: [],
  currentQuestion: {
    id: '',
    case_sensitive: false,
    question_type: '',
    modality: [],
    widget: '',
    content_html: '',
    answers: [],
    position: 0,
    time: 0,
  },
  isAssessmentOutOfTime: false,
  isLastQuestionAnswered: false,
  canAnswerQuestions: false,
  isSonarQuizRunning: false,
  assessments: [],
  assessmentAttempt: {
    id: '',
    name: '',
    slug: '',
    status: '',
    duration_in_seconds: 0,
    questions: [],
    learning_area_group: {
      name: '',
      slug: '',
    },
    required_assessments: [],
  },
  assessmentAttempts: [],
  lastAssessment: {},
  questionsAnswered: [],
  displayCorrectAnswers: false,
  isFirstSwitchedLabQuestion: false,
  algoliaSearchIndex: {},
}

export default state
