import { Module } from 'vuex'
import { ISonarState, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

const search: Module<ISonarState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default search
