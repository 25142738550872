import { IQuizzesState } from '@/@types'

const state: IQuizzesState = {
  currentQuiz: {},
  previousAttempts: [],
  currentAttempt: {},
  finishedAttempt: {},
}

export default state
