var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"mobile-navbar",attrs:{"role":"navigation"}},[_c('transition',{attrs:{"name":"fadeHeight"}},[(_vm.scheduledMaintenance)?_c('MaintenanceScheduledBannerVue'):_vm._e()],1),_c('nav',{class:[
      'mobile-navbar__wrapper',
      _vm.scheduledMaintenance && 'mobile-navbar__wrapper-maintenance',
    ]},[_c('div',{staticClass:"mobile-navbar"},[_c('div',{staticClass:"mobile-navbar__logo-wrapper"},[_c('router-link',{attrs:{"to":{ name: 'home' },"replace":""}},[_c('img',{staticClass:"mobile-navbar__ine-logo",attrs:{"src":require("@/assets/images/home-logo.png"),"alt":"INE Logo"}})])],1),_c('div',{class:[
          'mobile-navbar__open-menu',
          _vm.isMegaMenuShown && 'mobile-navbar__open-menu--opened',
        ],on:{"click":function($event){return _vm.toggleMegaMenu()}}},[_c('ChevronDownIcon',{staticClass:"mobile-navbar__menu-icon"})],1),_c('div',{staticClass:"mobile-navbar__right-section"},[_c('div',{staticClass:"mobile-navbar__switch-search-wrapper"},[(_vm.isAccOwnerOrTeamManager)?_c('div',{staticClass:"mobile-navbar__switch-container"},[_c('SwitchToBusiness')],1):_vm._e(),_c('SearchIcon',{staticClass:"mobile-navbar__icon",on:{"click":function($event){return _vm.toggleSearchForm()}}})],1),_c('SearchForm',{staticClass:"mobile-navbar__search-form",attrs:{"isSearchFormShown":_vm.isSearchFormShown},on:{"closed":function($event){return _vm.closeSearchForm()}}}),_c('Profile',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeProfileMenu),expression:"closeProfileMenu"}],staticClass:"mobile-navbar__user-profile",attrs:{"isAuthenticated":_vm.isAuthenticated,"profile":_vm.profile,"isProfileMenuShown":_vm.isProfileMenuShown},nativeOn:{"click":function($event){return _vm.openProfileMenu()}}})],1)]),(_vm.showSkillDive)?_c('SkillDiveTopBar'):_vm._e(),_c('MegaMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMegaMenuShown),expression:"isMegaMenuShown"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMegaMenu),expression:"closeMegaMenu"}],attrs:{"primaryAreas":_vm.primaryAreas,"secondaryAreas":_vm.secondaryAreas,"isAttackDefenseUser":_vm.isAttackDefenseUser},on:{"close-mega-menu":_vm.closeMegaMenu,"mouseleave":function($event){return _vm.closeMegaMenu()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }