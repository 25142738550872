<template>
  <section class="onboarding-modal">
    <div class="ine-card onboarding-modal__wrapper">
      <div class="onboarding-modal__wrapper-overlay"></div>
      <div class="onboarding-modal__header">
        <ArrowLeftIcon
          v-if="currentStep !== steps[0]"
          class="onboarding-modal__header-icon onboarding-modal__header-icon--back"
          @click="goPreviusStep()"
        />
        <XIcon
          class="onboarding-modal__header-icon onboarding-modal__header-icon--close"
          @click="closeOnboardingModal()"
        />
      </div>
      <div class="onboarding-modal__profile">
        <div
          class="onboarding-modal__background-1 onboarding-modal__background--mobile"
        />
        <div
          class="onboarding-modal__background-2 onboarding-modal__background--mobile"
        />
        <ProfileProgress
          class="onboarding-modal__profile-progress"
          :currentAvatar="currentAvatar"
        />
        <ActionLoader
          v-if="isLoadingSurvey"
          class="onboarding-modal__profile-label--loading"
        ></ActionLoader>
        <div v-if="!isLoadingSurvey" class="onboarding-modal__profile-label">
          {{ profileCompleteLabel }}
        </div>
        <div class="onboarding-modal__profile-info">
          Finish setting up your profile to customize your experience!
        </div>
        <div class="onboarding-modal__profile-bullets-container">
          <div
            v-if="showBullets"
            class="onboarding-modal__profile-bullets-wrapper"
          >
            <div
              v-for="(step, i) in steps"
              :key="`step-${i}`"
              :class="[
                'onboarding-modal__profile-bullet',
                'bullet',
                'bullet--clickeable',
                step === currentStep && 'bullet--active',
              ]"
              @click="goToStep(step)"
            ></div>
          </div>
        </div>
      </div>
      <div class="onboarding-modal__steps">
        <XIcon
          class="onboarding-modal__header-icon onboarding-modal__steps-icon onboarding-modal__steps-icon--close"
          @click="closeOnboardingModal()"
        />
        <ProfilePhoto
          v-if="currentStep === profilePhoto"
          v-bind="{
            stepBadgeLabel,
            onboardingSurvey,
          }"
          @go-next-step="goNextStep"
          @set-loader="setLoader"
          @set-component-loader="setComponentLoader"
        />
        <InterestArea
          v-if="currentStep === interestArea"
          v-bind="{
            stepBadgeLabel,
            onboardingSurvey,
            profilePreferences,
          }"
          :isFirstStep="isFirstStep(interestArea)"
          @go-next-step="goNextStep"
          @go-previus-step="goPreviusStep"
          @set-loader="setLoader"
          @set-component-loader="setComponentLoader"
        />
        <BackupEmail
          v-if="currentStep === backupEmail"
          v-bind="{
            stepBadgeLabel,
            onboardingSurvey,
            userProfile,
          }"
          :isFirstStep="isFirstStep(backupEmail)"
          @go-next-step="goNextStep"
          @go-previus-step="goPreviusStep"
          @set-loader="setLoader"
          @set-component-loader="setComponentLoader"
        />
        <TrainingGoals
          v-if="currentStep === trainingGoals"
          v-bind="{
            stepBadgeLabel,
            onboardingSurvey,
            userProfile,
          }"
          @close-onboarding-modal="closeOnboardingModal"
          @go-previus-step="goPreviusStep"
          @set-loader="setLoader"
          @set-component-loader="setComponentLoader"
        />
        <ActionLoader
          v-if="isLoading"
          :class="[
            'onboarding-modal__loader',
            isLoadingComponent && 'onboarding-modal__loader--black',
            profileProgressLoading &&
              'onboarding-modal__progress-loader--black',
          ]"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import CONSTANTS from '@/constants'
import ProfileProgress from '@/components/navbar/ProfileProgress.vue'
import ProfilePhoto from '@/components/modals/onboarding/steps/ProfilePhoto.vue'
import InterestArea from '@/components/modals/onboarding/steps/InterestArea.vue'
import BackupEmail from '@/components/modals/onboarding/steps/BackupEmail.vue'
import TrainingGoals from '@/components/modals/onboarding/steps/TrainingGoals.vue'
import { XIcon, ArrowLeftIcon } from 'vue-feather-icons'
import ActionLoader from '@/components/shared/ActionLoader.vue'

export default {
  name: 'OnboardingModal',
  components: {
    XIcon,
    ArrowLeftIcon,
    ProfilePhoto,
    InterestArea,
    BackupEmail,
    TrainingGoals,
    ProfileProgress,
    ActionLoader,
  },
  props: {
    onboardingStep: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentStep: CONSTANTS.PROFILE.ONBOARDING.STEPS.PROFILE_PHOTO,
      steps: [
        CONSTANTS.PROFILE.ONBOARDING.STEPS.PROFILE_PHOTO,
        CONSTANTS.PROFILE.ONBOARDING.STEPS.INTEREST_AREA,
        CONSTANTS.PROFILE.ONBOARDING.STEPS.BACKUP_EMAIL,
        CONSTANTS.PROFILE.ONBOARDING.STEPS.TRAINING_GOALS,
      ],
      modalHeight: '415px',
      isLoading: false,
      isLoadingComponent: false,
      showBullets: true,
      profilePhoto: CONSTANTS.PROFILE.ONBOARDING.STEPS.PROFILE_PHOTO,
      interestArea: CONSTANTS.PROFILE.ONBOARDING.STEPS.INTEREST_AREA,
      backupEmail: CONSTANTS.PROFILE.ONBOARDING.STEPS.BACKUP_EMAIL,
      trainingGoals: CONSTANTS.PROFILE.ONBOARDING.STEPS.TRAINING_GOALS,
    }
  },
  computed: {
    ...mapState({
      onboardingSurvey: state => state.auth.onboardingSurvey,
      profilePreferences: state => state.auth.profilePreferences,
      userProfile: state => state.auth.userProfile,
      currentAvatar: state => state.auth.currentAvatar,
      onboardingModalStatus: state => state.app.onboardingModalStatus,
    }),
    stepBadgeLabel() {
      return `${this.currentStepNumber} of ${this.steps.length}`
    },
    profileCompleteLabel() {
      return `Your profile is ${this.onboardingSurvey.completedPercentage}% complete`
    },
    currentStepNumber() {
      return this.steps.indexOf(this.currentStep) + 1
    },
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep)
    },
    profileProgressLoading() {
      return this.onboardingSurvey?.firstAttempt && this.isLoading
    },
    isLoadingSurvey() {
      return !this.onboardingSurvey.data
    },
  },
  async created() {
    const body = document.querySelector('body')
    body.classList.add('modal-open')
    this.setComponentLoader(true)
    this.showBullets = false
    await this.$store.dispatch('auth/getUserCurrentProfile')
    if (this.onboardingModalStatus.step)
      this.currentStep = this.onboardingModalStatus.step
    if (this.onboardingSurvey?.firstAttempt) {
      const { primary_interest } = this.profilePreferences
      const primaryInterest = !!primary_interest
      const currentAvatar = this.currentAvatar?.split('/').slice(-2).join('/')
      const isCurrentDefaultAvatar =
        CONSTANTS.PROFILE.DEFAULT_AVATAR.includes(currentAvatar)
      const onboardingSurvey = {
        avatar: !isCurrentDefaultAvatar,
        learning_areas: primaryInterest,
        backup_email: false,
        training_goals: false,
      }
      this.setFirstAttempSteps({ isCurrentDefaultAvatar, primaryInterest })
      await this.$store.dispatch('auth/setOnboardingSurvey', onboardingSurvey)
    }
    this.setComponentLoader(false)
    this.showBullets = true
  },
  destroyed() {
    const body = document.querySelector('body')
    body.classList.remove('modal-open')
  },
  methods: {
    closeOnboardingModal() {
      this.$store.dispatch('app/setOnboardingModalStatus', {
        opened: false,
        step: CONSTANTS.PROFILE.ONBOARDING.STEPS.PROFILE_PHOTO,
      })
    },
    goPreviusStep() {
      if (this.currentStepIndex > 0)
        this.currentStep = this.steps[this.currentStepIndex - 1]
    },
    goNextStep() {
      if (this.currentStepIndex < this.steps.length)
        this.currentStep = this.steps[this.currentStepIndex + 1]
    },
    goToStep(step) {
      this.currentStep = step
    },
    setLoader(loaderState) {
      this.isLoading = loaderState
    },
    setComponentLoader(loaderState) {
      this.setLoader(loaderState)
      this.isLoadingComponent = loaderState
    },
    setFirstAttempSteps(optionalSteps) {
      const { isCurrentDefaultAvatar, primaryInterest } = optionalSteps
      if (isCurrentDefaultAvatar && !primaryInterest) return
      if (!isCurrentDefaultAvatar)
        this.steps = this.steps.filter(
          step => step !== CONSTANTS.PROFILE.ONBOARDING.STEPS.PROFILE_PHOTO
        )
      if (primaryInterest)
        this.steps = this.steps.filter(
          step => step !== CONSTANTS.PROFILE.ONBOARDING.STEPS.INTEREST_AREA
        )
      this.currentStep = this.steps[0]
    },
    isFirstStep(step) {
      return step === this.steps[0]
    },
  },
}
</script>
