import { ICoursesState } from '@/@types'

const state: ICoursesState = {
  activeCourses: [],
  currentCourse: {
    id: '',
    name: '',
    content: [],
    user_status: null,
  },
  currentLevels: [],
  currentCourseProgress: {},
}

export default state
