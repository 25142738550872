export const woopraParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_adgroup',
  'utm_network',
  'utm_placement',
  'gclid',
  'fbclid',
]

export const trackWoopraEvent = (eventName, userData, woopraData) => {
  if (typeof window !== 'undefined' && window.woopra) {
    const identificationData = {
      name: `${userData.firstname} ${userData.lastname}`.trim(),
      email: userData.email,
    }
    const fullTrackingData = {
      ...userData,
      ...woopraData,
      page_url: window.location.href,
    }

    window.woopra.identify(identificationData).push(() => {
      window.woopra.track(eventName, fullTrackingData)
    })
  }
}
