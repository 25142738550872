import { ActionTree, ActionContext } from 'vuex'

import { IPlaylistsState, IRootState, IPlaylist } from '@/@types'
import {
  fetchPlaylists,
  fetchPlaylistById,
  fetchPlaylistChangelogById,
} from '@/models/playlists'

import { errorHandler } from '@/utils'

const actions: ActionTree<IPlaylistsState, IRootState> = {
  getPlaylists: async (context: ActionContext<IPlaylistsState, IRootState>) => {
    try {
      const playlists: {
        count: number
        next: IPlaylist | null
        previous: IPlaylist | null
        results: Array<IPlaylist>
      } = await fetchPlaylists()

      context.commit('STORE_PLAYLISTS', playlists)
      return playlists
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  getPlaylistById: async (
    context: ActionContext<IPlaylistsState, IRootState>,
    payload: any
  ) => {
    const { id, refresh } = payload

    if (refresh || !context.getters.playlistById(id)) {
      try {
        const playlist: IPlaylist = await fetchPlaylistById(id)

        context.commit('STORE_PLAYLIST', playlist)
        return playlist
      } catch (error) {
        const message = errorHandler(error)
        context.dispatch('app/setError', message, { root: true })
        throw error
      }
    }
  },
  getPlaylistChangeLog: async (
    context: ActionContext<IPlaylistsState, IRootState>,
    id: string
  ) => {
    try {
      const { results } = await fetchPlaylistChangelogById(id)
      return results
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
