import { differenceInDays, format } from 'date-fns'
import CONSTANTS from '@/constants'

export const formatSubDate = date => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  return format(dtDateOnly, 'MMMM dd, yyyy')
}

export const formatSubTrialDate = date => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, 'MM-dd-yyyy')
}

export const getDaysRemaining = date => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  const today = new Date()
  const todayDateOnly = new Date(
    today.valueOf() + today.getTimezoneOffset() * 60 * 1000
  )
  return differenceInDays(dtDateOnly, todayDateOnly)
}

export const getDaysRemainingForSub = sub => {
  const { expires_at } = sub
  return getDaysRemaining(expires_at)
}

export const getDaysRemainingForTrialSub = sub => {
  const { trial_ends_at } = sub
  return getDaysRemaining(trial_ends_at)
}

export const isBusinessPlan = sub => {
  const { plan_code } = sub
  return (
    plan_code &&
    plan_code.length &&
    Object.values(CONSTANTS.PLANS.BUSINESS).includes(plan_code)
  )
}

export const getSubType = sub => {
  if (sub.plan) {
    const {
      plan: { name },
    } = sub
    return name && name.length ? name : 'Custom Plan'
  }
  return 'Custom Plan'
}
