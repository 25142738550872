export default [
  {
    path: '/certification/:voucherId/attempt/:attemptId',
    name: 'exam',
    component: () => import('@/pages/CertificationExam.vue'),
  },
  {
    path: '/certifications',
    name: 'certifications',
    beforeEnter: (to, from, next) => {
      const { VUE_APP_CERTIFICATIONS_ENABLED: certificationEnabled } =
        process.env
      if (certificationEnabled) {
        next()
      } else {
        next({ name: 'home' })
      }
    },
    component: () => import('@/pages/Certifications.vue'),
  },
  {
    path: '/certificate/:attemptId',
    name: 'certificate-verified',
    component: () => import('@/pages/CertificateVerified'),
  },
]
