import { RouteConfig } from 'vue-router'
import {
  admin,
  auth,
  business,
  certifications,
  courses,
  learningAreas,
  learningPaths,
  playlists,
  search,
  users,
  sonar,
  labExperience,
  faqs,
} from './routes/index'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/Home.vue'),
  },
  ...admin,
  ...auth,
  ...business,
  ...certifications,
  ...courses,
  ...learningAreas,
  ...learningPaths,
  ...playlists,
  ...search,
  ...users,
  ...sonar,
  ...labExperience,
  ...faqs,
  {
    // Always leave this as last one
    path: '*',
    name: 'NotFound',
    component: () => import('@/pages/404.vue'),
  },
]

export default routes
