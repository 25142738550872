import { Module } from 'vuex'
import { ICertificationsState, IRootState } from '@/@types'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

const certifications: Module<ICertificationsState, IRootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

export default certifications
