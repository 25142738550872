export const checkEnvVarExist = envKey =>
  typeof process.env[envKey] !== 'undefined'

export const getEnvVarNumber = envKey => {
  if (!checkEnvVarExist(envKey)) return undefined
  return Number(process.env[envKey])
}

export const getEnvVarBoolean = envKey => {
  if (!checkEnvVarExist(envKey)) return undefined
  switch (process.env[envKey]) {
    case 'True':
      return true
    case 'False':
      return false
    default:
      return null
  }
}

export const getEnvVarString = envKey => {
  if (!checkEnvVarExist(envKey)) return undefined
  return process.env[envKey] || ''
}
