import { ActionContext, ActionTree } from 'vuex'
import { ILabExperience, IRootState } from '@/@types'
import { fetchCollectionById, fetchCollections } from '@/models/lab-experience'

import { errorHandler } from '@/utils'

const actions: ActionTree<ILabExperience, IRootState> = {
  getCollectionById: async (
    context: ActionContext<ILabExperience, IRootState>,
    { id, mutate = true }: { id: string; mutate: boolean }
  ) => {
    try {
      const response = await fetchCollectionById(id)
      if (mutate) context.commit('CURRENT_COLLECTION_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getHighlightedCollectionById: async (
    context: ActionContext<ILabExperience, IRootState>,
    id: string
  ) => {
    try {
      const response = await fetchCollectionById(id)
      context.commit('CURRENT_HIGHLIGHTED_COLLECTION_UPDATED', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getCollections: async (
    context: ActionContext<ILabExperience, IRootState>,
    params?: { ids?: string[] }
  ) => {
    try {
      const response = await fetchCollections(params)
      context.commit('CURRENT_COLLECTIONS_LIST', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getFeaturedCollections: async (
    context: ActionContext<ILabExperience, IRootState>
  ) => {
    try {
      const response = await fetchCollections()
      for (let index = 0; index < 4; index++) {
        response[Math.floor(Math.random() * 20)].is_featured = true
      }
      context.commit(
        'CURRENT_FEATURED_COLLECTIONS_LIST',
        response.filter(
          (collection: { is_featured: any }) => collection.is_featured
        )
      )
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  cleanCurrentCollection: (
    context: ActionContext<ILabExperience, IRootState>
  ) => {
    context.commit('CURRENT_COLLECTION_UPDATED', null)
  },
}

export default actions
