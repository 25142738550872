import { debounce, throttle } from 'lodash'
import { mapActions, mapState } from 'vuex'
import algoliasearch from 'algoliasearch'
import algoliasearchHelper from 'algoliasearch-helper'
import { isBottomVisible } from '@/helpers'
import { updateCurrentUrlParams } from '@/router'

const defaultSearchOptions = {
  triggerSearch: false,
  useUrl: false,
  useDebounceSearchQuery: false,
  hiddenFacets: [],
  hitsPerLoad: 12,
}
export const algoliaSearchMixin = {
  data() {
    return {
      algoliaClient: null,
      algoliaHelpers: {},
      searchOptions: {},
      debouncedSearchQuery: null,
      debouncedSearchQueryDelay: 500,
      throttleScroll: null,
      throttleScrollDelay: 100,
    }
  },
  computed: {
    ...mapState({
      algoliaKeys: state => state.search.algoliaKeys,
      stateQuery: state => state.search.searchQuery,
    }),
    searchQuery: {
      get() {
        return this.stateQuery
      },
      set(query) {
        this.setQuery(query)
      },
    },
  },
  beforeDestroy() {
    const { debouncedSearchQuery, throttleScroll } = this
    if (debouncedSearchQuery) debouncedSearchQuery.cancel()
    if (throttleScroll)
      window.removeEventListener('scroll', this.throttleScroll)
  },
  beforeRouteLeave(to, from, next) {
    next(vm => {
      if (from.name !== 'skill-dive') {
        vm.searchQuery = ''
      }
      next()
    })
  },
  watch: {
    stateQuery: {
      handler(query) {
        if (this.debouncedSearchQuery) {
          this.debouncedSearchQuery(query)
        }
      },
    },
  },
  created() {
    const { appKey, appId } = this.algoliaKeys

    this.algoliaClient = algoliasearch(appId, appKey)
  },
  methods: {
    ...mapActions({
      setQuery: 'search/setQuery',
    }),
    async initializeAlgoliaSearch(indexName, searchParameters, options = {}) {
      const client = this.algoliaClient
      const helper = algoliasearchHelper(client, indexName, searchParameters)
      let localFireDebounce = false

      helper.algoliaIndex = client.initIndex(indexName)

      this.$set(this.algoliaHelpers, indexName, helper)
      this.$set(this.searchOptions, indexName, {
        ...defaultSearchOptions,
        ...options,
      })

      helper.on('change', () => this.handleAlgoliaChange(indexName))
      helper.on('error', this.handleAlgoliaError)

      if (options.useUrl) {
        // At the moment it only supports one index at a time.
        localFireDebounce = this.handleUrlSearchQuery()
        this.handleUrlSearchParams(indexName)
      }

      if (options.useDebounceSearchQuery) {
        // At the moment it only supports one index at a time.
        this.debouncedSearchQuery = debounce(query => {
          this.handleAlgoliaSearchQuery(indexName, query)
        }, this.debouncedSearchQueryDelay)
      }
      if (options.triggerSearch) await helper.search()
      if (localFireDebounce) this.debouncedSearchQuery(this.searchQuery)

      return helper
    },
    handleAlgoliaLazyLoad(indexName) {
      this.throttleScroll = throttle(() => {
        if (isBottomVisible()) {
          this.handleAlgoliaLoadMore(indexName)
        }
      }, this.throttleScrollDelay)
      window.addEventListener('scroll', this.throttleScroll)
    },
    handleAlgoliaSearchQuery(indexName, query) {
      const helper = this.algoliaHelpers[indexName]
      if (this.searchOptions[indexName].useUrl) this.updateUrlSearchQuery(query)
      helper.setQuery(query).search()
    },
    handleUrlSearchParams(indexName) {
      const params = new URLSearchParams(window.location.search)

      params.forEach((values, facet) => {
        if (!this.searchOptions[indexName].hiddenFacets.includes(facet)) {
          this.applyFilterByType(indexName, facet, values.split(','))
        }
      })
    },
    applyFilterByType(indexName, facet, values) {
      const helper = this.algoliaHelpers[indexName]
      const facetType = this.findOutRefinementType(indexName, facet)

      switch (facetType) {
        case 'and':
          values.forEach(value => {
            helper.addFacetRefinement(facet, value)
          })
          break
        case 'or':
          values.forEach(value => {
            helper.addDisjunctiveFacetRefinement(facet, value)
          })
          break
      }
    },
    findOutRefinementType(indexName, facet) {
      const helper = this.algoliaHelpers[indexName]

      if (helper.state?.disjunctiveFacets?.includes(facet)) {
        return 'or'
      }
      if (helper.state?.facets?.includes(facet)) {
        return 'and'
      }

      return null
    },
    encodeRefinements(indexName, refinements, params) {
      const { hiddenFacets } = this.searchOptions[indexName]

      Object.entries(refinements).forEach(([facet, values]) => {
        if (!hiddenFacets.includes(facet)) {
          if (values.length) {
            params.set(
              facet,
              values.map(value => encodeURIComponent(value)).join(',')
            )
          } else {
            params.delete(facet)
          }
        }
      })

      hiddenFacets.forEach(hf => params.delete(hf))
    },
    handleUrlSearchQuery() {
      const params = new URLSearchParams(window.location.search)
      let fireDebounce = false
      if (params.has('search') && params.get('search') === this.searchQuery)
        fireDebounce = true
      this.searchQuery = params.get('search') || ''
      return fireDebounce
    },
    updateUrlSearchParams(indexName) {
      const params = new URLSearchParams(window.location.search)
      const helper = this.algoliaHelpers[indexName]
      const { facetsRefinements, disjunctiveFacetsRefinements } = helper.state
      this.encodeRefinements(indexName, facetsRefinements, params)
      this.encodeRefinements(indexName, disjunctiveFacetsRefinements, params)

      updateCurrentUrlParams(params)
    },
    updateUrlSearchQuery(query) {
      const params = new URLSearchParams(window.location.search)

      if (query) {
        params.set('search', query)
      } else {
        params.delete('search')
      }

      updateCurrentUrlParams(params)
    },
    clearUrlParams() {
      const params = new URLSearchParams('')
      if (this.searchQuery) {
        params.set('search', this.searchQuery)
      }
      updateCurrentUrlParams(params)
    },
    handleAlgoliaChange(indexName) {
      if (this.searchOptions[indexName].useUrl) {
        this.updateUrlSearchParams(indexName)
      }
    },
    handleAlgoliaError(error) {
      this.$store.dispatch('app/setError', error)
    },
    handleAlgoliaLoadMore(indexName) {
      const helper = this.algoliaHelpers[indexName]
      const nbHits = helper.lastResults?.nbHits || 0
      const { hitsPerPage } = helper.state
      const { hitsPerLoad } = this.searchOptions[indexName]

      if (hitsPerPage >= nbHits) return

      helper
        .setQueryParameter('hitsPerPage', hitsPerPage + hitsPerLoad)
        .search()
    },
    handleAlgoliaNextPage(indexName) {
      const helper = this.algoliaHelpers[indexName]

      helper.nextPage().search()
    },
    handleAlgoliaPreviousPage(indexName) {
      const helper = this.algoliaHelpers[indexName]

      helper.previousPage().search()
    },
    isAlgoliaFilterOrEnabled(indexName, { facet, value }) {
      const helper = this.algoliaHelpers[indexName]
      const disjunctiveFacetsRefinements =
        helper.state?.disjunctiveFacetsRefinements

      return disjunctiveFacetsRefinements[facet]?.includes(value)
    },
    toggleAlgoliaFilterAnd(indexName, filter) {
      const helper = this.algoliaHelpers[indexName]
      const { facet, value } = filter

      helper.toggleFacetRefinement(facet, value).search()
    },
    toggleFacetWithoutSearch(indexName, filter) {
      const helper = this.algoliaHelpers[indexName]
      const { facet, value } = filter

      helper.toggleFacetRefinement(facet, value)
    },
    toggleAlgoliaFilterOr(indexName, filter) {
      const helper = this.algoliaHelpers[indexName]
      const { facet, value } = filter
      // toggleDisjunctiveFacetRefinement is not working properly, this is a workaround using add/remove
      if (this.isAlgoliaFilterOrEnabled(indexName, filter)) {
        helper.removeDisjunctiveFacetRefinement(facet, value)
      } else {
        helper.addDisjunctiveFacetRefinement(facet, value)
      }

      helper.search()
    },
    getIndexHits(indexName) {
      return this.algoliaHelpers[indexName]?.lastResults?.hits || []
    },
    clearAlgoliaRefinements(indexName) {
      const helper = this.algoliaHelpers[indexName]
      helper.clearRefinements()
      this.clearUrlParams()
    },
    setSearchParametersFilter(indexName, filters) {
      const helper = this.algoliaHelpers[indexName]
      helper.state.filters = filters
    },
  },
}
