import Bugsnag from '@bugsnag/js'
import CONSTANTS from '@/constants'
import store from '@/store'

const { KNOWN_API_ERRORS } = CONSTANTS

const redactPersonalInfo = obj => {
  const redactedKeys = [
    'username',
    'first_name',
    'last_name',
    'phone_number',
    'email',
    'password',
    'recurly-token',
    'old_password',
    'new_password',
    'confirm_password',
  ]
  const received = typeof obj === 'string' ? JSON.parse(obj) : obj
  // eslint-disable-next-line no-unused-vars
  const data = Object.entries(received).reduce((acc, [key, val], _) => {
    if (redactedKeys.includes(key)) {
      acc[key] = '[redacted]'
    } else {
      acc[key] = val
    }
    return acc
  }, {})

  return data
}

const isErrorKnown = error => {
  const ineMessage = error?.response?.data?.error?.message
  const rmotrDetail = error?.response?.data?.detail
  if (ineMessage && ineMessage.length) {
    return KNOWN_API_ERRORS.PHP.includes(ineMessage)
  }
  if (rmotrDetail && rmotrDetail.length) {
    return KNOWN_API_ERRORS.PYTHON.includes(rmotrDetail)
  }
}

const prepareErrorResponse = error => {
  const status = error?.response?.status ?? 'unknown'
  const message = error?.response?.data ?? {}
  return { status, message }
}

const prepareErrorRequest = error => {
  const method = error?.config?.method ?? 'get'
  const headers = error?.config?.headers ?? {}
  const service = error?.config?.baseURL ?? ''
  const route = error?.config?.url ?? ''
  const post_body = redactPersonalInfo(error?.response?.config?.data ?? {})
  return { method, headers, service, route, post_body }
}

export const logError = error => {
  if (!isErrorKnown(error)) {
    const notification = {
      request: prepareErrorRequest(error),
      response: prepareErrorResponse(error),
      app_state: {
        user_id: store?.state?.auth?.userProfile?.uaaId ?? '',
        is_authenticated: store?.state?.auth?.isAuthenticated ?? false,
        has_starter_pass: store?.getters['auth/isFreeTrialUser'] ?? false,
        is_business_user: store?.getters['auth/isBusinessUser'] ?? false,
      },
    }
    if (notification?.response?.status) {
      if (notification.response.status !== 'unknown') {
        Bugsnag.addMetadata('request data', notification.request)
        Bugsnag.addMetadata('response data', notification.response)
        Bugsnag.addMetadata('app state', notification.app_state)
        Bugsnag.notify(JSON.stringify(notification))
      }
    }
  }
}
