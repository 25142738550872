import { setupClients } from '@internetworkexpert/js-common'

const env = process.env.VUE_APP_ENVIRONMENT || 'development'
const {
  API,
  BUSINESS,
  CONTENT,
  EXERCISES,
  FILES,
  IFRAME,
  PROFILE,
  QUIZ,
  STATISTICS,
  SUBSCRIPTIONS,
  UAA,
  VIDEOS,
  MIXPANEL,
  CERTIFICATIONS,
  BUSINESS_PYTHON,
  BUSINESS_PYTHON_ANONYMOUS,
} = setupClients(env)

export {
  API,
  BUSINESS,
  CONTENT,
  EXERCISES,
  FILES,
  IFRAME,
  PROFILE,
  QUIZ,
  STATISTICS,
  SUBSCRIPTIONS,
  UAA,
  VIDEOS,
  MIXPANEL,
  CERTIFICATIONS,
  BUSINESS_PYTHON,
  BUSINESS_PYTHON_ANONYMOUS,
}
