import {
  fetchQuizById,
  fetchQuizAttemptsByQuizId,
  postQuizAttemptByQuizId,
  putQuizAttemptByQuizId,
  patchQuizAttemptByQuizId,
} from '@/models/quizzes'
import { ActionTree, ActionContext } from 'vuex'
import { getParentFromRootState } from '@/helpers'
import { IQuizzesState, IQuizAttempt, IRootState } from '@/@types'

import { errorHandler } from '@/utils'

const actions: ActionTree<IQuizzesState, IRootState> = {
  getQuizById: async (
    context: ActionContext<IQuizzesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchQuizById(payload)
      context.commit('CURRENT_QUIZ_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      context.commit('CURRENT_QUIZ_UPDATED', '')
    }
  },

  getQuizAttemptsByQuizId: async (
    context: ActionContext<IQuizzesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await fetchQuizAttemptsByQuizId(payload)
      context.commit('PREVIOUS_QUIZ_ATTEMPTS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  startQuizAttemptByQuizId: async (
    context: ActionContext<IQuizzesState, IRootState>,
    id: string
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        id,
        parentId,
        parentType,
      }
      const response = await postQuizAttemptByQuizId(payload)
      context.commit('UPDATE_CURRENT_QUIZ_ATTEMPT', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  finishQuizAttempt: async (
    context: ActionContext<IQuizzesState, IRootState>,
    params: {
      quizId: string
      attemptId: string
      submission: IQuizAttempt
    }
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        ...params,
        parentId,
        parentType,
      }
      const response = await putQuizAttemptByQuizId(payload)
      context.commit('FINISHED_QUIZ_ATTEMPT', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  updateQuizAttempt: async (
    context: ActionContext<IQuizzesState, IRootState>,
    params: {
      quizId: string
      attemptId: string
      submission: IQuizAttempt
      status: string
    }
  ) => {
    try {
      const { id: parentId, type: parentType } = getParentFromRootState(
        context.rootState
      )
      const payload = {
        ...params,
        parentId,
        parentType,
      }
      const response = await patchQuizAttemptByQuizId(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  resumeQuizAttempt: (
    context: ActionContext<IQuizzesState, IRootState>,
    attempt: IQuizAttempt
  ) => {
    context.commit('UPDATE_CURRENT_QUIZ_ATTEMPT', attempt)
  },

  clearQuizStore: (context: ActionContext<IQuizzesState, IRootState>) => {
    context.commit('CURRENT_QUIZ_UPDATED', {})
    context.commit('PREVIOUS_QUIZ_ATTEMPTS_UPDATED', [])
    context.commit('UPDATE_CURRENT_QUIZ_ATTEMPT', {})
    context.commit('FINISHED_QUIZ_ATTEMPT', {})
  },

  restartQuiz: (context: ActionContext<IQuizzesState, IRootState>) => {
    context.commit('FINISHED_QUIZ_ATTEMPT', {})
  },
}

export default actions
