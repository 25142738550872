import CONSTANTS from '@/constants'
import { ISearchState } from '@/@types'

const state: ISearchState = {
  searchQuery: '',
  features: CONSTANTS.SEARCH.FEATURES,
  filterMap: CONSTANTS.SEARCH.FILTER_MAP,
  facets: CONSTANTS.SEARCH.AVAILABLE_FACETS,
  algoliaKeys: CONSTANTS.SEARCH.ALGOLIA_KEYS,
  contexts: CONSTANTS.SEARCH.CONTEXTS,
  skillDive: CONSTANTS.SEARCH.SKILL_DIVE,
}

export default state
