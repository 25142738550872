import { ActionTree, ActionContext } from 'vuex'

import { IRootState, IFaqsState, IFaqContent } from '@/@types'
import { fetchAllFaqs } from '@/models/content'

import { errorHandler } from '@/utils'

const actions: ActionTree<IFaqsState, IRootState> = {
  getAllFaqs: async (context: ActionContext<IFaqsState, IRootState>) => {
    try {
      const playlists: {
        count: number
        next: string | null
        previous: string | null
        results: Array<IFaqContent>
      } = await fetchAllFaqs()

      context.commit('STORE_FAQS', playlists?.results)
      return playlists?.results
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
}

export default actions
