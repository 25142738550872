import { Module } from 'vuex'
import { IAuthState, IRootState } from '@/@types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const auth: Module<IAuthState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default auth
