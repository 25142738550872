import { Module } from 'vuex'
import { IAdminState, IRootState } from '@/@types'
import state from './state'
import actions from './actions'
import mutations from './mutations'

const admin: Module<IAdminState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default admin
