import { ActionContext, ActionTree } from 'vuex'
import {
  IAnswerText,
  ICertificate,
  ICertification,
  ICertificationsState,
  IExamAttempt,
  IRootState,
} from '@/@types'
import {
  fetchUsersCertifications,
  fetchUsersVouchers,
  fetchVoucher,
  getCertificate,
  getCertifications,
  getExamAttempt,
  getPdfCertificate,
  patchExamAttempt,
  postExamAttempt,
  postSubmitQuestion,
  postVerifyLab,
  putExamAttempt,
  postExamFeedback,
  getAvailableRenewals,
  postAvailableRenewals,
  postRenewalCostPreview,
  postRenewalPurchase,
} from '@/models/certifications'

import { fetchCertificationsForLearningPath } from '../../../models/learning-paths'

import { errorHandler } from '@/utils'

const actions: ActionTree<ICertificationsState, IRootState> = {
  startExamAttempt: async (
    context: ActionContext<ICertificationsState, IRootState>,
    data: { voucherId: string }
  ) => {
    try {
      const {
        state: { certificationsCache },
      } = context
      const attempt = await postExamAttempt(data)
      context.commit('UPDATE_CURRENT_EXAM_ATTEMPT', attempt)

      const currentCertification = certificationsCache[attempt.certification.id]
      const active_vouchers = currentCertification.user_status?.active_vouchers

      if (active_vouchers && active_vouchers.length) {
        for (const active_voucher of active_vouchers) {
          if (active_voucher.id === data.voucherId) {
            const index = active_voucher.active_attempts.findIndex(
              active_attempt => active_attempt.id === attempt.id
            )
            if (index !== -1) {
              active_voucher.active_attempts.splice(index, 1, attempt)
            } else {
              active_voucher.active_attempts.push(attempt)
            }
          }
        }
      }

      context.commit('CERTIFICATIONS_CACHE_UPDATED', [currentCertification])
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  finishExamAttempt: async (
    context: ActionContext<ICertificationsState, IRootState>,
    data: { examId: string; attemptId: string; submission: IExamAttempt }
  ) => {
    try {
      const response = await putExamAttempt(data)
      context.commit('UPDATE_CURRENT_EXAM_ATTEMPT', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getCertificationsForLearningPath: async (
    context: ActionContext<ICertificationsState, IRootState>,
    payload: { pathId: string }
  ) => {
    try {
      const response = await fetchCertificationsForLearningPath(payload)

      context.commit('CERTIFICATIONS_CACHE_UPDATED', response.results)

      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      return error
    }
  },

  updateExamAttempt: async (
    context: ActionContext<ICertificationsState, IRootState>,
    data: {
      examId: string
      attemptId: string
      submission: IExamAttempt
      status: string
    }
  ) => {
    try {
      const response = await patchExamAttempt(data)
      context.commit('UPDATE_CURRENT_EXAM_ATTEMPT', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },
  resumeExamAttempt: async (
    context: ActionContext<ICertificationsState, IRootState>,
    data: {
      voucherId: string
      attemptId: string
    }
  ) => {
    try {
      const response = await getExamAttempt(data)
      context.commit('UPDATE_CURRENT_EXAM_ATTEMPT', response)
      context.commit('UPDATE_PARTIAL_CERTIFICATION', response.certification)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  submitQuestion: async (
    context: ActionContext<ICertificationsState, IRootState>,
    data: { voucherId: string; attemptId: string; answer: IAnswerText }
  ) => {
    try {
      const response = await postSubmitQuestion(data)
      context.commit('UPDATE_QUESTIONS_ANSWERED', response.questions)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  clearExamStore: (
    context: ActionContext<ICertificationsState, IRootState>
  ) => {
    context.commit('UPDATE_CURRENT_EXAM_ATTEMPT', {})
  },

  updateCurrentCertification: (
    context: ActionContext<ICertificationsState, IRootState>,
    certification: ICertification
  ) => {
    context.commit('UPDATE_CURRENT_CERTIFICATION', certification)
  },

  getUsersCertifications: async (
    context: ActionContext<ICertificationsState, IRootState>
  ) => {
    try {
      const response = await fetchUsersCertifications()
      context.commit('USERS_CERTIFICATIONS', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getUsersVouchers: async (
    context: ActionContext<ICertificationsState, IRootState>
  ) => {
    try {
      const response = await fetchUsersVouchers()
      context.commit('USERS_VOUCHERS', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getVoucherById: async (
    context: ActionContext<ICertificationsState, IRootState>,
    voucherId: string
  ) => {
    try {
      const response = await fetchVoucher(voucherId)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  getAllCertifications: async (
    context: ActionContext<ICertificationsState, IRootState>
  ) => {
    try {
      const response = await getCertifications()
      context.commit('UPDATE_CERTIFICATIONS_LIST', response.results)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getCertificate: async (
    context: ActionContext<ICertificationsState, IRootState>,
    attemptId: string
  ) => {
    try {
      const response = await getCertificate(attemptId)
      context.commit('UPDATE_CURRENT_CERTIFICATE', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  getPdfCertificate: async (
    context: ActionContext<ICertificationsState, IRootState>,
    certification: ICertificate
  ) => {
    try {
      const { attempt_id, certification_name } = certification
      await getPdfCertificate(certification_name, attempt_id)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  setCurrentAttemptScored: (
    context: ActionContext<ICertificationsState, IRootState>,
    currentScoredAttempt: IExamAttempt
  ) => {
    context.commit('UPDATE_CURRENT_SCORED_ATTEMP', currentScoredAttempt)
  },
  setCurrentScoredCertification: (
    context: ActionContext<ICertificationsState, IRootState>,
    currentScoredCertification: ICertification
  ) => {
    context.commit('UPDATE_CURRENT_SCORED_CERT', currentScoredCertification)
  },
  verifyLab: async (
    context: ActionContext<ICertificationsState, IRootState>,
    payload: { voucherId: string; attemptId: string }
  ) => {
    try {
      const response = await postVerifyLab(payload)
      context.commit('UPDATE_USER_LAB_ATTEMPT', response)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  updateCertificationsCache: (
    context: ActionContext<ICertificationsState, IRootState>,
    certification: ICertificate
  ) => {
    context.commit('CERTIFICATIONS_CACHE_UPDATED', [certification])
  },

  saveExamFeedback: async (
    context: ActionContext<ICertificationsState, IRootState>,
    payload: { voucherId: string; attemptId: string; feedback: String }
  ) => {
    try {
      const response = await postExamFeedback(payload)
      return response
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
      throw error
    }
  },

  certRenewalsAvailable: async (
    context: ActionContext<ICertificationsState, IRootState>
  ) => {
    try {
      const response = await getAvailableRenewals()
      context.commit('CERTIFICATION_RENEWALS_AVAILABLE', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  postCertRenewalsAvailable: async (
    context: ActionContext<ICertificationsState, IRootState>,
    attemptId: string
  ) => {
    try {
      const response = await postAvailableRenewals(attemptId)
      context.commit('CERTIFICATION_RENEWALS_AVAILABLE', [response])
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  certRenewalCostPreview: async (
    context: ActionContext<ICertificationsState, IRootState>,
    certRenewalAttemptId: string
  ) => {
    try {
      context.commit('CERTIFICATION_RENEWAL_COST_PREVIEW', {})
      const response = await postRenewalCostPreview({
        cert_renewal_attempt_id: certRenewalAttemptId,
      })
      context.commit('CERTIFICATION_RENEWAL_COST_PREVIEW', response.data)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  certRenewalPurchase: async (
    context: ActionContext<ICertificationsState, IRootState>,
    payload: {
      cert_renewal_attempt_id: string
      billing_token?: string
      payment_challenge_token?: string
    }
  ) => {
    try {
      const response = await postRenewalPurchase(payload)
      context.commit('CERTIFICATION_RENEWAL_PURCHASE_SUCCESS', response.data)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
}

export default actions
