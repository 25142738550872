import { format } from 'date-fns-tz'

export const formatDateForBootcamp = ({ start, end, epoch = false }) => {
  const startDate = epoch ? new Date(start * 1000) : new Date(start)
  const endDate = epoch ? new Date(end * 1000) : new Date(end)
  return `${format(startDate, 'MMMM do')} - ${format(
    endDate,
    'MMMM do'
  )}, ${format(startDate, 'h:mmaaa')} - ${format(endDate, 'h:mmaaa')}`
}
