import { MutationTree } from 'vuex'
import { IPlaylistsState, IPlaylist } from '@/@types'

const mutations: MutationTree<IPlaylistsState> = {
  STORE_PLAYLISTS: (state, playlists) => {
    state.playlists = playlists
  },
  STORE_PLAYLIST: (state, playlist: IPlaylist) => {
    const index = state.playlistCache.findIndex(p => p.id === playlist.id)
    index !== -1
      ? state.playlistCache.splice(index, 1, playlist)
      : state.playlistCache.push(playlist)
  },
}

export default mutations
