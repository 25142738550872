import {
  fetchMonthlyStats,
  fetchYearlyStats,
  fetchLastMonthStats,
} from '@/models/statistics'
import { fetchCoursesByIds } from '@/models/content'
import { ActionTree, ActionContext } from 'vuex'
import { IStatisticsState, IRootState } from '@/@types'

import { errorHandler } from '@/utils'

const actions: ActionTree<IStatisticsState, IRootState> = {
  getCoursesForHistory: async (
    context: ActionContext<IStatisticsState, IRootState>,
    payload: {
      courseIds: Array<string>
      page_size: string
      page: number
      ordering?: string
    }
  ) => {
    try {
      const { courseIds, page_size, page, ordering } = payload
      const courses = await fetchCoursesByIds(
        courseIds,
        page_size,
        page,
        ordering
      )
      context.commit('COURSES_FOR_HISTORY_UPDATED', courses)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getMonthlyStats: async (
    context: ActionContext<IStatisticsState, IRootState>
  ) => {
    try {
      const response = await fetchMonthlyStats()
      context.commit('MONTHLY_STATS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getLastMonthStats: async (
    context: ActionContext<IStatisticsState, IRootState>
  ) => {
    try {
      const response = await fetchLastMonthStats()
      context.commit('LAST_MONTH_STATS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  getYearlyStats: async (
    context: ActionContext<IStatisticsState, IRootState>
  ) => {
    try {
      const response = await fetchYearlyStats()
      context.commit('YEARLY_STATS_UPDATED', response)
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
}

export default actions
