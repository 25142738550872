import {
  confirmUser,
  resetPassword,
  suspendUser,
  liftSuspension,
  fetchUserForImpersonation,
} from '@/models/admin'
import {
  getAccessToken,
  setAccessToken,
  setOriginalAccessToken,
  getOriginalAccessToken,
  removeOriginalAccessToken,
} from '@internetworkexpert/js-common'
import { ActionTree, ActionContext } from 'vuex'
import { IAdminState, IRootState } from '@/@types'
import { errorHandler } from '@/utils'

const actions: ActionTree<IAdminState, IRootState> = {
  adminConfirmUser: async (
    context: ActionContext<IAdminState, IRootState>,
    email: string
  ) => {
    try {
      await confirmUser(email)
      context.dispatch(
        'app/setNotification',
        'User was successfully confirmed',
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, {
        root: true,
      })
    }
  },
  adminResetPassword: async (
    context: ActionContext<IAdminState, IRootState>,
    emailAndPassword: { email: string; password: string }
  ) => {
    try {
      await resetPassword(emailAndPassword)
      const { password } = emailAndPassword
      context.dispatch('setNewPassword', password)
      context.dispatch(
        'app/setNotification',
        `Password was changed successfuly changed to ${password}`,
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },
  adminSuspendUser: async (
    context: ActionContext<IAdminState, IRootState>,
    email: string
  ) => {
    try {
      await suspendUser(email)
      context.dispatch(
        'app/setNotification',
        'User was successfully suspended',
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  adminLiftSuspension: async (
    context: ActionContext<IAdminState, IRootState>,
    email: string
  ) => {
    try {
      await liftSuspension(email)
      context.dispatch(
        'app/setNotification',
        'User was successfully unsuspended',
        {
          root: true,
        }
      )
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  impersonateUser: async (
    context: ActionContext<IAdminState, IRootState>,
    email: string
  ) => {
    try {
      const accessToken = getAccessToken()
      setOriginalAccessToken(accessToken)
      const user = await fetchUserForImpersonation(email)
      const {
        tokens: {
          data: { Bearer },
        },
      } = user
      setAccessToken(Bearer)
      context.dispatch('isImpersonating', true)
      context.dispatch('resetStore')
      context.dispatch('auth/tryAutoLogin', {}, { root: true })
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  isImpersonating: (context, impersonating) => {
    context.commit('ADMIN_IS_IMPERSONATING', impersonating)
  },

  cancelImpersonation: async (
    context: ActionContext<IAdminState, IRootState>
  ) => {
    try {
      const Bearer = getOriginalAccessToken()
      setAccessToken(Bearer)
      context.dispatch('isImpersonating', false)
      context.dispatch('resetStore')
      context.dispatch('auth/userMeOnContent', {}, { root: true })
      context.dispatch('auth/tryAutoLogin', {}, { root: true })
      removeOriginalAccessToken()
    } catch (error) {
      const message = errorHandler(error)
      context.dispatch('app/setError', message, { root: true })
    }
  },

  setNewPassword: (
    context: ActionContext<IAdminState, IRootState>,
    password: string
  ) => {
    context.commit('ADMIN_NEW_USER_PASSWORD_UPDATED', password)
  },
  resetStore: (context: ActionContext<IAdminState, IRootState>) => {
    context.dispatch('auth/cleanSonarModule', {}, { root: true })
  },
}

export default actions
